import React from 'react'

const AttendanceRequestModal = () => {
    return (
        <div id="attendance-request-modal" class="modal">
            <div className="row modal-content attendance-request-modal">
                <div className="row modal-title configure-modal-title valign-wrapper"
                style={{ "background-color": "#5d1b8f", minHeight: 10 }}>
                    <span>Attendance Request</span>
                </div>
                <div className="row">
                    <form class="col s12">
                        <div class="row">
                        <div class="input-field col s12">
                            <textarea id="textarea1" class="materialize-textarea"></textarea>
                            <label for="textarea1">Reason</label>
                        </div>
                        </div>
                    </form>
                </div>
                <div className="modal-footer">
                    <button className="modal-close waves-effect waves btn-flat">
                    CANCEL
                    </button>
                    <button
                    type="submit"
                    form="dashboard"
                    className="waves-effect waves btn-flat"
                    >
                    SUBMIT
                    </button>
                </div>
            </div>
        </div>
    )
}

export default AttendanceRequestModal
