import React, { Component } from "react";
import $ from "jquery";
import { connect } from "react-redux";
import { getProfessor, requestTime } from "../../store/actions/ProfessorAction";
import { getTimeSlots } from "../../store/actions/TimeTableAction";
import { Redirect } from "react-router-dom";
import Navbar from "../layout/Navbar";
// import "../style.css";

export class TimeTable extends Component {
  state = {
    flag: true,
    slotId: [],
    timeslot: [],
    timePropLength: [],
    index: null,
  };
  componentDidMount() {
    console.log("...loding");
    this.props.GetTimeSlots(this.props.auth.user.accessToken);
  }

  componentDidUpdate() {
    if (this.state.flag && this.props.timeSlotSuccess) {
      var user = this.props.auth.user;
      var timeslot = this.props.timeSlot;
      var arr = [];
      var info;
      for (var k = 0; k < timeslot.length; k++) {
        for (var i = 0; i < timeslot[k].ProfessorTimes.length; i++) {
          if (timeslot[k].ProfessorTimes[i].professorId === user.id) {
            info = {
              timeslotId: timeslot[k].ProfessorTimes[i].timeSlotId,
              isApproved: timeslot[k].ProfessorTimes[i].isApproved,
              day: timeslot[k].day,
              slot: timeslot[k].slot,
            };
            arr.push(info);
          }
        }
      }
      this.setState({ timeslot: arr, flag: false });
    }
  }

  checkedTimeSlot = (day, slot) => {
    // console.log(day,slot)
    var timeslot = this.props.timeSlot;
    var check = this.state.timeslot;
    for (var i = 0; i < timeslot.length; i++) {
      if (i < check.length && check[i].day == day && check[i].slot == slot) {
        if (check[i].isApproved == true) {
          return true;
        } else {
          return false;
        }
      }
    }
  };

  handleChange = (e) => {
    console.log(e.target.value);
    $('input[type="checkbox"]').change(
      function () {
        var checkedValue = $("input:checkbox:checked")
          .map(function () {
            return {
              day: this.value,
              slot: this.id,
            };
          })
          .get();
        this.setState({ slotId: checkedValue });
      }.bind(this)
    );
  };

  onClick = (e) => {
    var timeslots = [];
    var arr = this.state.slotId;
    for (var i = 0; i < arr.length; i++) {
      var f = this.props.timeSlot.find(function (time) {
        return time.slot == arr[i].slot && time.day == arr[i].day;
      });
      timeslots.push(f.id);
    }
    var info = { timeslots };
    console.log(info)
    this.props.RequestTime(info, this.props.auth.user.accessToken);
  };

  render() {
    const user = this.props.auth.user;
    // console.log(this.state.index)
    if (!user) return <Redirect to="/login" />;
    return (
      <div>
        <Navbar heading="Admin" user={user} />
        <table class="color-table">
          <thead class="thead-dark">
            <tr>
              <th scope="col">Day</th>
              {this.props.slots &&
                this.props.slots.map((slot) => <th scope="col">{slot}</th>)}
            </tr>
          </thead>
          <tbody>
            {this.props.days &&
              this.props.days.map((day) => (
                <tr>
                  <th scope="row">{day}</th>
                  {this.props.slots &&
                    this.props.slots.map((slot) => (
                      <td>
                        {/* <Form.Check
                      // custom
                      id={slot}
                      value={day}
                      className="custom-checkbox"
                      style={{ "font-size": "3rem" }}
                      aria-label="option 1"
                      onChange={this.handleChange}
                      defaultChecked={this.checkedTimeSlot(day,slot)}
                    /> */}
                        <p>
                          <label>
                            <input
                              type="checkbox"
                              id={slot}
                              value={day}
                              className="custom-checkbox"
                              style={{ width: 30, height: 30 }}
                              aria-label="option 1"
                              onChange={this.handleChange}
                              defaultChecked={this.checkedTimeSlot(day, slot)}
                            />
                            <span></span>
                          </label>
                        </p>
                      </td>
                    ))}
                </tr>
              ))}
          </tbody>
        </table>
        <button
          className="btn submit"
          id="submit"
          style={{
            "margin-top": "2%",
            "margin-left": "90%",
            border: "1px solid",
          }}
          onClick={this.onClick}
        >
          Submit
        </button>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  console.log(state);
  return {
    auth: state.auth,
    timeSlot: state.TimeTable.timeSlot,
    days: state.TimeTable.days,
    slots: state.TimeTable.slot,
    professor: state.Professor.professor,
    timeSlotLoading: state.TimeTable.timeSlotLoading,
    timeSlotSuccess: state.TimeTable.timeSlotSuccess,
    professorSuccess: state.Professor.professorSuccess,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    GetTimeSlots: (token) => {
      dispatch(getTimeSlots(token));
    },
    GetProfessor: (id, token) => {
      dispatch(getProfessor(id, token));
    },
    RequestTime: (body, token) => {
      dispatch(requestTime(body, token));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TimeTable);
