import React, { Component } from "react";
import $ from "jquery";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import Navbar from "../layout/Navbar"
import {
  approveCourse,
  getCourses,
  getDeptProfessors,
  getSemester,
} from "../../store/actions/HodAction";

class CourseAllocation extends Component {
  state = {
    flag: true,
    checkFlag: true,
    approvedData: [],
    check:[],
    courses:[]
  };

  componentDidMount() {
    this.props.GetProfessors(
      this.props.auth.user.deptId,
      this.props.auth.user.accessToken
    );

    this.props.GetCourse(this.props.auth.user.deptId,this.props.auth.user.accessToken);
  }

  componentDidUpdate(){
    if(this.state.flag && this.props.courseSuccess){
      var course=this.props.course['0'].Semesters;
      console.log("course",course)
      var arr=[];
      var info;
      for(var i=0;i<course.length;i++){
        for(var j=0;j<course[i].Courses.length;j++){
          info={
            name: course[i].Courses[j].courseName,
            id: course[i].Courses[j].id
          }
          arr.push(info)
        }
      }
      this.setState({ courses:arr, flag: false})
    }
    if(this.state.checkFlag && this.props.courseSuccess){
      var course=this.props.course['0'].Semesters;
      console.log("course",course)
      var arr=[];
      var info;
      for(var i=0;i<course.length;i++){
        for(var j=0;j<course[i].Courses.length;j++){
          for(var k=0; k<course[i].Courses[j].ProfessorCourses.length;k++){
              info={
                courseId: course[i].Courses[j].ProfessorCourses[k].courseId,
                isApproved: course[i].Courses[j].ProfessorCourses[k].isApproved,
                professorId: course[i].Courses[j].ProfessorCourses[k].professorId
              }
              arr.push(info)
          }
        }
      }
      this.setState({ check:arr, checkFlag: false})
    }
  }

  checkedTimeSlot = (pid,cid) =>{
    // console.log(pid,cid)
    var course=this.state.courses;
    var check = this.state.check;
    for(var i=0; i<check.length; i++){
      if (i < check.length && check[i].professorId == pid && check[i].courseId == cid){
        if(check[i].isApproved == true){
          return true;
        }else{
          return false;
        }
      }
    }
  }

  handleChange = (e) => {
    $('input[type="checkbox"]').change(
      function () {
        var checkedValue = $("input:checkbox:checked")
          .map(function () {
            return {
              course: this.id,
              prof: this.value,
            };
          })
          .get();
        this.setState({ approvedData: checkedValue });
      }.bind(this)
    );
  };

  onClick = (e) => {
    console.log(this.state.approvedData);
    var approvedData = this.state.approvedData;
    var data = [];
    var courses = [];
    var course = [];
    var prof;
    for (var i = 0; i < approvedData.length; i++) {
      if (data.length == 0) {
        courses.push(approvedData[0].course);
        var info1 = {
          professorId: Number(approvedData[0].prof),
          approvedCourses: courses,
        };
        data.push(info1);
      }
      if (data.length > 0 && i > 0) {
        for (var j = 0; j < data.length; j++) {
          var found = data.find((x) => x.professorId == approvedData[i].prof);
          if (found == undefined) {
            course.push(approvedData[i].course);
            var info = {
              professorId: Number(approvedData[i].prof),
              approvedCourses: course,
            };
            data.push(info);
          } else {
            var index = data.findIndex(
              (x) => x.professorId == approvedData[i].prof
            );
            data[index].approvedCourses.push(approvedData[i].course);
          }
        }
      }
    }
    for (var k = 0; k < data.length; k++) {
      var cids = data[k].approvedCourses;
      const removeRepeatNumbers = (array) => [...new Set(array)];
      var Data = removeRepeatNumbers(cids);
      data[k].approvedCourses = Data;
    }
    var information = {
      data: data,
    };
    console.log("final form", information);
    this.props.ApprovedCourse(information, this.props.auth.user.accessToken);
  };
  render() {
    const user = this.props.auth.user;
    if (!user) return <Redirect to="/login" />;
    return (
      <div>
      <Navbar heading="Admin" user={user} />
        <table class="color-table" id="header-fixed">
          <thead class="thead-dark">
            <tr>
              <th>Course</th>
              {this.props.professors &&
                this.props.professors.map((prof) => (
                  <th>{prof.userName}</th>
                ))}
            </tr>
          </thead>
          <tbody>
            {this.state.courses &&
              this.state.courses.map((course,index) => (
                <tr>
                  <th>{course.name}</th>

                  {this.props.professors &&
                    this.props.professors.map((prof) => (
                      <td>
                        {/* <Form.Check
                          // custom
                          id={course.id}
                          value={prof.id}
                          className="custom-checkbox"
                          style={{ "font-size": "3rem" }}
                          aria-label="option 1"
                          onChange={this.handleChange}
                          defaultChecked={this.checkedTimeSlot(prof.id,course.id)}
                        /> */}
                        <p>
                          <label>
                            <input
                              type="checkbox"
                              id={course.id}
                              value={prof.id}
                              className="custom-checkbox"
                              onChange={this.handleChange}
                              defaultChecked={this.checkedTimeSlot(prof.id,course.id)}
                            />
                            <span></span>
                          </label>
                        </p>
                      </td>
                    ))}
                </tr>
              ))}
          </tbody>
        </table>
        <button className="btn submit"
        id="submit"
        style={{
          "margin-top": "2%",
          "margin-left": "90%",
          border: "1px solid",
        }}
         onClick={this.onClick}>
          Submit
        </button>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  console.log(state);
  return {
    auth: state.auth,
    professors: state.Hod.departmentProfessor,
    semester: state.Hod.semester,
    semesterSuccess: state.Hod.semesterSuccess,
    course: state.Hod.course,
    courseSuccess: state.Hod.courseSuccess
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    GetProfessors: (deptId, token) => {
      dispatch(getDeptProfessors(deptId, token));
    },
    GetCourse: (deptId,token) => {
      dispatch(getCourses(deptId,token));
    },
    ApprovedCourse: (body, token) => {
      dispatch(approveCourse(body, token));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CourseAllocation);
