import React from "react";
import "../stylesheets/qcList.css";
import moment from 'moment'

export default function ResponseListItem({
  id,
  interviwerName,
  interviewerId,
  dateTime,
  phone,
  image,
  status,
  pushed,
  displayId,
  onDetails,
  onReject,
  onApprove,
  cssType,
  marks,
  getMarks
}) {
  return (
      <tr className={"row valign-wrapper " + cssType}>
        <td style={{ width: "7%", marginLeft:9 }}>
          <span>{displayId}</span>
        </td>

        <td style={{ width: "35%" }}>
          <span>{cssType=='response-list'?moment(dateTime).format('MM-DD-YYYY, h:mm a'):dateTime }</span>
        </td>

        <td style={{ width: "40%" }}>
          <span>{interviwerName}</span>
        </td>
        <td style={{ width: "30%" }}>
          <span>{marks?marks:null}</span>
        </td>
        <td style={{ width: "10%" }}>
        {
          pushed?
          <i className="material-icons Medium">redo</i>
          :
          null
        }
        </td>
        {cssType != "response-list-header" ? (
          <td style={{ width: "30%", paddingTop: 5, display:"flex", justifyContent:"space-between" }}>
            <button className='btn-floating btn-small' onClick={()=>getMarks(id)}>
              <i className="material-icons Small ">
                play_arrow
              </i>
            </button>
            <button
              data-target="delete-user-modal"
              id="reject"
              className="btn-floating btn-small red"
              style={{ color: "red" }}
              onClick={(e)=>onReject("reject",id)}
              disabled= {status === "Complete" ? false : true}
            >
            <i className="material-icons Small">
              clear
            </i>
            </button>
            <button
              data-target="delete-user-modal"
              id="approve"
              className="btn-floating btn-small green "
              style={{ color: "green" }}
              onClick={(e)=>onApprove("approve",id)}
              disabled= {status === "Complete" ? false : true}
            >
            <i className="material-icons Small">
              check
            </i>
            </button>
            <button className='btn-floating btn-small' onClick={onDetails}>
              <i className="material-icons Small ">
                chevron_right
              </i>
            </button>  
          </td>
          
        ) : (
          <td style={{ width: "22%" }}>
            <span></span>
          </td>
        )}
      </tr>
  );
}
