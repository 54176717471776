import React from "react";

const ChangePassword = ({HandleChange, OnSubmit}) => {
  return (
    <div id="change-password-modal" className="modal modal-fixed-footer">
      <div className="row modal-content">
        <div className="row modal-title">
          <span>Change Password</span>
        </div>
        <div className="row">
              <div className="col s12">
                <form id="changePassword" onSubmit={OnSubmit}>
                  <div className="input-field col s12 center">
                    <img
                      className="circle"
                      type="image"
                      id="impage"
                      height="100"
                      width="100"
                      style={{ marginRight: 5 }}
                      src={require("../../image/image_unavailable.jpg")}
                    />
                  </div>
                  <div className="input-field col s12">
                    <input
                      type="password"
                      id="password"
                      onChange={HandleChange}
                      required
                    />
                    <label>Password</label>
                  </div>
                  <div className="input-field col s12">
                    <input
                      type="password"
                      id="newPassword"
                      onChange={HandleChange}
                      required
                    />
                    <label>New Password</label>
                  </div>
               {/*   <div className="input-field col s12">
                    <input
                      type="password"
                      id="retypePassword"
                    //   onChange={HandleChange}
                    //   value={state.email}
                      required
                    />
                    <label>Re-Type Password</label>
                    </div> */}
                </form>
              </div>
            </div>
      </div>
      <div className="modal-footer">
        <button type="submit" form="changePassword" className="modal-close waves-effect waves btn-flat">Yes</button>
        <button className="modal-close waves-effect waves btn-flat">No</button>
      </div>
    </div>
  );
};

export default ChangePassword;