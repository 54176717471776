import { backendUrl } from "../../config/api";

export const getInterviewers = (token)=>{
    return (dispatch) =>{
        dispatch({type:'INTERVIEWER_FETCH_LOADING'})
        
        fetch(backendUrl
        +'/surveys/assigned', {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        }).then(response => response.json().then(data=>{
            if (response.status == 200) {
            dispatch({
                type:'INTERVIEWER_FETCH_SUCCESS',
                data: data.data,
            });
         }else{
           dispatch({
             type: 'INTERVIEWER_FETCH_ERROR',
           });
         }
        }))

    }
}


export const syncInterviewer = ()=>{
    return (dispatch) =>{
        dispatch({type:'INTERVIEWER_SYNCED'})
    }
}

export const checkExistingUser = (id, users)=>{
    return (dispatch) =>{
        for (var i=0; i<users.length;i++){
          if (users[i].id == id){
            users[i]['checked'] = users[i].checked? !(users[i].checked) : true
          }
        }
        dispatch({type:'INTERVIEWER_CHECKED', data:users})
    }
}


export const getSurveyInterviewers = (surveyId, token)=>{
    return (dispatch) =>{
        dispatch({type:'SURVEY_INTERVIEWER_FETCH_LOADING'});

        fetch(backendUrl
        +'/survey/'+surveyId+'/users', {
          headers: {
            'Authorization': `Bearer ${token}`
          },
        }).then(response => response.json().then(data=>{
            if (response.status == 200) {
            dispatch({
                type:'SURVEY_INTERVIEWER_FETCH_SUCCESS',
                data: data.data?data.data.UserSurveys:[],
            });
         }else{
           dispatch({
             type: 'SURVEY_INTERVIEWER_FETCH_ERROR',
           });
         }
        }))

    }
}

export const assignInterviewers = (users, sId, token)=>{
    return (dispatch) =>{
        dispatch({type:'ASSIGN_INTERVIEWER_LOADING'})

        fetch(backendUrl
        +'/survey/'+sId+'/assign', {
          method: 'post',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          },
          body: JSON.stringify({users:users}),
        }).then(response => response.json().then(data=>{
            if (response.status == 200) {
            dispatch({
                type:'ASSIGN_INTERVIEWER_SUCCESS'
            });
         }else{
           dispatch({
             type: 'ASSIGN_INTERVIEWER_ERROR',
           });
         }
        }))
    }
}

export const deassignInterviewers = (user, sId, token)=>{
  return (dispatch) =>{
      dispatch({type:'DEASSIGN_INTERVIEWER_LOADING'})

      fetch(backendUrl
      +'/user/'+user+'/survey/'+sId+'/deassign', {
        method: 'post',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        // body: JSON.stringify({users:users}),
      }).then(response => response.json().then(data=>{
          if (response.status == 200) {
          dispatch({
              type:'DEASSIGN_INTERVIEWER_SUCCESS'
          });
       }else{
         dispatch({
           type: 'DEASSIGN_INTERVIEWER_ERROR',
         });
       }
      }))
  }
}

export const getSurveys = (token)=>{
    return (dispatch) =>{
        dispatch({type:'FETCH_SURVEYS_LOADING'})
        fetch(backendUrl
        +"/survey?status=Live", {
          headers:{
            'Authorization': `Bearer ${token}`
          },
        }).then(response => response.json().then(data=>{
            if (response.status == 200) {
            dispatch({
                type:'FETCH_SURVEYS_SUCCESS',
                data: data.data,
            });
         }else{
           dispatch({
             type: 'FETCH_SURVEYS_ERROR',
           });
         }
        }))

    }
}

export const modifySupervisor = (data, sId, token)=>{
  return (dispatch) =>{
      dispatch({type:'MODIFY_SUPERVISOR_LOADING'})

      fetch(backendUrl
      +'/survey/'+sId+'/supervisor', {
        method: 'put',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(data),
      }).then(response => response.json().then(data=>{
          if (response.status == 200) {
          dispatch({
              type:'MODIFY_SUPERVISOR_SUCCESS'
          });
       }else{
         dispatch({
           type: 'MODIFY_SUPERVISOR_ERROR',
         });
       }
      }))
  }
}

export const onHomeClick = ()=>{
  return (dispatch) =>{
      dispatch({type:'HOME_CLICK'})
  }
}


