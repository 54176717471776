import React, {Component} from "react";
import { connect } from "react-redux";
import M from 'materialize-css'
import { Redirect, Link} from "react-router-dom";
import Navbar from "../layout/Navbar"
import Stats from '../layout/Stats'

class LiveSurvey extends Component {
      state = {
          frequency:this.props.initData?this.props.initData.frequency:null,
          company:this.props.initData?this.props.initData.company:null,
          startDate:this.props.initData?this.props.initData.startDate:null,
          endDate:this.props.initData?this.props.initData.endDate:null,
          columns:[]
        }
      

      componentDidMount = () => {
        // console.log("nis", this.state)
        var elems = document.querySelectorAll('select');
        var instances = M.FormSelect.init(elems, {});
      }

      HandleChange=(e)=>{
        
      }
      onDateClose=()=>{
        this.setState({
            ...this.state,
            startDate:document.getElementById('startDate').value,
            endDate:document.getElementById('endDate').value
          })
        // console.log(this.state)
      }
      componentDidUpdate = () =>{

      }
    
    render(){
      const {auth, responses, CompanyName, loading, error} = this.props 
      // if (!(auth.user&&auth.user.id)) return <Redirect to="/login" />;
    //   if (!(ChartData) || ChartData.length === 0) return <Redirect to="/" />;
      return (
        <div className='row '>
          <Navbar heading="Archived Survey" user={auth.user}/>
          <div className=''>
            <div className="container">
              <div className="row card">
                <div className="container">
                  <div className="row input-field">
                    <select>
                      <option>Select another survey</option>
                    </select>
                  </div>
                </div>
                
              </div>
              <div className="row card">
                <div className="row">
                  <Stats
                    items={[
                      { heading: "Total Interview", value: this.props.stat ? this.props.stat.numBorrowers : "23" },
                      { heading:"Supervisors", value:this.props.stat ? this.props.stat.unpaidPrincipal.toFixed(2) : "9"},
                      { heading:"Number of Versions", value:this.props.stat ? this.props.stat.defaultPrincipal != null && this.props.stat.defaultPrincipal !== "null" ?this.props.stat.defaultPrincipal.toFixed(2):0 : "7", color: "green"},
                      {heading:"Total Interviewer", value:this.props.stat&&this.props.stat.impactScore ? this.props.stat.impactScore.score.toFixed(2) : "10"} 
                    ]}
                  />
                </div>

                {/* <div className="row"> */}
                  {/* <Link to="/survey/1/design" className="col s12 btn">
                    hello
                  </Link> */}
                {/* </div> */}
              </div>
            </div>
          </div>
        </div>
      );
    }

};

const mapStateToProps = (state, ownProps) => {
  return {
    auth: state.auth,
    id: ownProps.match.params.id,
    responses:[
      {
        'id': 1,
        'interviewerId': 1,
        'name': 'Pilu Roy',
        'status': 'pending',
        'phone': '1234567898', 
        'dateTime': '12/12/202010:15:15',
        'phone1': '1234567898', 
        'phone2': '1234567898', 
        'phone3': '1234567898', 
        'phone4': '1234567898', 
        'phone5': '1234567898',

      },
      {
        'id': 2,
        'interviewerId': 2,
        'name': 'Soma pal',
        'status': 'pending',
        'phone': '8745693214', 
        'dateTime': '10/10/202010:15:15',
        'phone1': '1234567898', 
        'phone2': '1234567898', 
        'phone3': '1234567898', 
        'phone4': '1234567898', 
        'phone5': '1234567898',

      },
      {
        'id': 3,
        'interviewerId': 3,
        'name': 'Pipul Roy',
        'status': 'pending',
        'phone': '894567747', 
        'dateTime': '11/11/202008:15:15',
        'phone1': '1234567898', 
        'phone2': '1234567898', 
        'phone3': '1234567898', 
        'phone4': '1234567898', 
        'phone5': '1234567898',

      },
      {
        'id': 4,
        'interviewerId': 4,
        'name': 'Mona Roy',
        'status': 'pending',
        'phone': '7845567898',
        'dateTime': '01/06/202007:56:15' ,
        'phone1': '1234567898', 
        'phone2': '1234567898', 
        'phone3': '1234567898', 
        'phone4': '1234567898', 
        'phone5': '1234567898',
      },
      {
        'id': 5,
        'interviewerId': 1,
        'name': 'Pilu Roy',
        'status': 'pending',
        'phone': '1234567898', 
        'dateTime': '12/12/202010:15:15',
        'phone1': '1234567898', 
        'phone2': '1234567898', 
        'phone3': '1234567898', 
        'phone4': '1234567898', 
        'phone5': '1234567898',

      },
      {
        'id': 6,
        'interviewerId': 2,
        'name': 'Soma pal',
        'status': 'pending',
        'phone': '8745693214', 
        'dateTime': '10/10/202010:15:15',
        'phone1': '1234567898', 
        'phone2': '1234567898', 
        'phone3': '1234567898', 
        'phone4': '1234567898', 
        'phone5': '1234567898',

      },
      {
        'id': 7,
        'interviewerId': 3,
        'name': 'Pipul Roy',
        'status': 'pending',
        'phone': '894567747', 
        'dateTime': '11/11/202008:15:15',
        'phone1': '1234567898', 
        'phone2': '1234567898', 
        'phone3': '1234567898', 
        'phone4': '1234567898', 
        'phone5': '1234567898',

      },
      {
        'id': 8,
        'interviewerId': 4,
        'name': 'Mona Roy',
        'status': 'pending',
        'phone': '7845567898',
        'dateTime': '01/06/202007:56:15' ,
        'phone1': '1234567898', 
        'phone2': '1234567898', 
        'phone3': '1234567898', 
        'phone4': '1234567898', 
        'phone5': '1234567898',

      },
      {
        'id': 9,
        'interviewerId': 3,
        'name': 'Pipul Roy',
        'status': 'pending',
        'phone': '894567747', 
        'dateTime': '11/11/202008:15:15',
        'phone1': '1234567898', 
        'phone2': '1234567898', 
        'phone3': '1234567898', 
        'phone4': '1234567898', 
        'phone5': '1234567898',

      },
      {
        'id': 10,
        'interviewerId': 4,
        'name': 'Mona Roy',
        'status': 'pending',
        'phone': '7845567898',
        'dateTime': '01/06/202007:56:15' ,
        'phone1': '1234567898', 
        'phone2': '1234567898', 
        'phone3': '1234567898', 
        'phone4': '1234567898', 
        'phone5': '1234567898', 

      }
    ]
  };
};
const mapDispatchToProps = (dispatch)  => {
  return {
    
  }
};



export default connect(mapStateToProps, mapDispatchToProps)(LiveSurvey);



