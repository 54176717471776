import React, { PureComponent } from "react";
import { Link, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import M from "materialize-css";
import AddUser from "../layout/ModalAdd";
import ModalView from "../layout/ModalView";
// import ItemList from "../layout/ItemList";
import Navbar from "../layout/Navbar";
// import { backendUrl } from "../../config/api";
import "../stylesheets/admin.css";
import SupervisorList from "../layout/SupervisorList";
import DeleteModal from "../layout/DeleteModal";
import ResetPasswordModal from "../layout/ResetPasswordModal";
import {
  getSurveyInterviewers,
  getInterviewers,
  getSurveys,
  syncInterviewer,
  deassignInterviewers,
  modifySupervisor
} from "../../store/actions/resourceActions";
import {
  getRoles,
  addUser,
  updateUser,
  resetPassword,
} from "../../store/actions/AdminAction";

class SupervisorDashBoard extends PureComponent {
  state = {
    addUser: false,

    firstName: null,
    surName: null,
    role: null,
    email: null,
    password: null,
    contactNo: null,
    displayPhoto:null,
    photo:null,

    survey: null,
    existsError: false,

    deleteUser:null,
    supervisor:null,
  };

  componentDidMount = () => {
    var elems = document.querySelectorAll(".modal");
    M.Modal.init(elems, {
      preventScrolling: false,
      onOpenEnd:()=>{
        console.log("ON OPEN");
        M.updateTextFields()
      },
      onCloseEnd: () => {
        this.setState({
          addUser: false,
        });
      },
    });
    
    var elems = document.querySelectorAll("select");
    M.FormSelect.init(elems, {});

    var user = this.props.auth.user;
    if (user) {
      this.props.GetSurveys(user ? user.accessToken : null);
      // this.props.GetSurveyInterviewers(this.state.survey, user.accessToken);
      this.props.GetRoles(user.accessToken);
    }
  };

  componentDidUpdate = () => {
    if (this.props.newAssign) {
      {
        var user = this.props.auth.user;
        if (user) {
          this.props.GetSurveyInterviewers(this.state.survey, user.accessToken);
          this.props.InterviewersSynced();
          var modal = document.getElementById("view-modal");
          var instance = M.Modal.getInstance(modal);
          instance.close();
        }
      }
    }
  };

  addUser = (e) => {
    this.setState({ addUser: true }, () => {});
  };

  handleChange = (e) => {
    this.setState({ ...this.state, [e.target.id]: e.target.value });
    // console.log("here", e.target.id, e.target.value);
  };

  handleChangeSurvey = (e) => {
    this.setState({ ...this.state, [e.target.id]: e.target.value });
    var user = this.props.auth.user;
    if (user)
      this.props.GetSurveyInterviewers(e.target.value, user.accessToken);
  };

  OnSubmit = (e) => {
    e.preventDefault();
    const personData = {
      userName: this.state.firstName + " " + this.state.surName,
      roleId: this.state.role,
      email: this.state.email,
      password: this.state.password,
      contactNo: this.state.contactNo,
      // check: false,
    };
    this.setState({
      ...this.state,
      firstName: "",
      surName: "",
      role: null,
      email: "",
      id: 0,
      check: false,
      existsError: false,
      password: "",
      contactNo: "",
    });

    this.props.AddUsers(personData, this.props.auth.user.accessToken);

    var modal = document.getElementById("add-user-modal");
    var instance = M.Modal.getInstance(modal);
    instance.close();
  };

  editUser = (e) => {
    e.preventDefault();
    var uid =  e.target.id.split('-')[1]
    this.setState({ selectedUser: uid});
    const user = this.props.surveyInterviewers.find(function (user) {
      return user.User.id == uid;
    });
    this.setState({
      firstName: user.User.userName.split(" ").slice(0, -1).join(" "),
      surName: user.User.userName.split(" ").slice(-1).join(" "),
      role: user.User.Role.id,
      contactNo: Number(user.User.contactNo),
      displayPhoto: user.User.photo&&user.User.photo!="null"?user.User.photo:null,
      supervisor:user.supervisorId,
    });
    console.log("opening", user);
    var modal = document.getElementById("add-user-modal");
    var instance = M.Modal.getInstance(modal);
    instance.open();
  };

  onUpdate = (e) => {
    e.preventDefault();
    var id = this.state.selectedUser;
    const personData = {
      userName: this.state.firstName + " " + this.state.surName,
      roleId: this.state.role,
      contactNo: this.state.contactNo,
      photo:this.state.photo
    };
    const supervisorData = {
      user:this.state.selectedUser,
      supervisor:this.state.supervisor,
    }
    this.props.GetUpdate(personData, id, this.props.auth.user.accessToken);
    if(this.state.supervisor)
      this.props.UpdateSupervisor(supervisorData, this.state.survey, this.props.auth.user.accessToken);

    var modal = document.getElementById("add-user-modal");
    var instance = M.Modal.getInstance(modal);
    instance.close();
  };


  resetPassword = (e) => {
    e.preventDefault();
    var uid =  e.target.id.split('-')[1]
    this.props.ResetPassword(uid, this.props.auth.user.accessToken);
    var modal = document.getElementById("reset-password-modal");
    var instance = M.Modal.getInstance(modal);
    instance.open();
  };

  deAssign = (e) => {
    // alert("API is not ready yet");
    this.props.DeAssignInterviwers(this.state.deleteUser,this.state.survey,this.props.auth.user.accessToken);
    var modal = document.getElementById("delete-user-modal");
    var instance = M.Modal.getInstance(modal);
    instance.close();
    this.props.GetSurveyInterviewers(this.state.survey, this.props.auth.user.accessToken)
  };

  delete = (e) => {
    // alert("API is not ready yet");
    var uid =  e.target.id.split('-')[1]
    this.setState({
      ...this.state,
      deleteUser:uid
    })
  };

  HandlePhotoChange = (e) =>{
    this.setState({
      [e.target.id]:e.target.files[0]
    })
    const reader = new FileReader();
    reader.onload = (e => {
       this.setState({displayPhoto: e.target.result});          
    }).bind(this);
    reader.readAsDataURL(e.target.files[0]);
  }

  render() {
    const user = this.props.auth.user;
    console.log("localprops", this.props)
    const env = "server";
    if (!user) return <Redirect to="/login" />;

    return (
      <div>
        <Navbar heading="EIC" user={user} />
        <AddUser
          HandleChange={this.handleChange}
          OnSubmit={this.OnSubmit}
          user={this.props.surveyInterviewers}
          state={this.state}
          roles={this.props.roles}
          OnUpdate={this.onUpdate}
          PhotoChange={this.HandlePhotoChange}
          supervisors={this.props.surveyInterviewers?
            this.props.surveyInterviewers.filter(user=>user.User&&user.User.Role.name=="Supervisor")
          :[]}
          point="um"
        />
        <DeleteModal
          HandleDelete={this.deAssign}
        />
        <ResetPasswordModal
          resetPasswordMessage={this.props.resetPasswordMessage}
          resetPasswordId={this.props.resetPasswordId}
        />
        <ModalView selected={this.state.survey} />
        <div className="row container" style={{ width: "90%" }}>
          <div className="row card">
            <div
              className="row list-item input-field"
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-evenly",
              }}
            >
              <div className="col s4">
                <select
                  type="text"
                  onChange={this.handleChangeSurvey}
                  id="survey"
                  defaultValue=""
                  className="browser-default"
                >
                  <option value="" disabled>
                    Choose a test
                  </option>
                  {this.props.surveys.map((survey, index) => (
                    <option value={survey.id} key={index}>
                      {survey.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col s3 offset-s1" style={{ paddingTop: 8 }}>
                <button
                  data-target="add-user-modal"
                  className="rounded-button modal-trigger"
                  disabled={!this.state.survey ? true : false}
                  onClick={this.addUser}
                >
                  <i className="material-icons left">add</i>Add new user
                </button>
              </div>
              <div className="col s3 offset-s1" style={{ paddingTop: 8 }}>
                <button
                  data-target="view-modal"
                  className="rounded-button modal-trigger"
                  disabled={!this.state.survey ? true : false}
                >
                  <i className="material-icons left">add</i>Add from existing
                </button>
              </div>
            </div>
            <SupervisorList
              HandleCheck={this.handleCheck}
              survey={this.state.survey}
              surveyInterviewers={this.props.surveyInterviewers}
              SelectedUser={this.selectedUser}
              EditUser={this.editUser}
              resetPassword={this.resetPassword}
              DeleteUser={this.delete}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  console.log("State in products", state);
  return {
    auth: state.auth,

    rolesCanAdd: state.auth && state.auth.roles ? state.auth.roles : [],

    surveys: state.resource.surveys ? state.resource.surveys : [],

    surveyInterviewers: state.resource.surveyInterviewers
      ? state.resource.surveyInterviewers
      : [],

    interviewers: state.resource.surveyInterviewers
      ? state.resource.surveyInterviewers
      : [],

    newAssign: state.resource.assignSuccess,
    roles: state.admin.roles,
    resetPasswordId: state.admin.resetPasswordId,
    resetPasswordMessage: state.admin.resetPasswordMessage,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    GetSurveyInterviewers: (surveyId, token) => {
      dispatch(getSurveyInterviewers(surveyId, token));
    },
    GetSurveys: (token) => {
      dispatch(getSurveys(token));
    },
    GetRoles: (token) => {
      dispatch(getRoles(token));
    },
    AddUsers: (userInfo, token) => {
      dispatch(addUser(userInfo, token));
    },
    InterviewersSynced: () => {
      dispatch(syncInterviewer());
    },
    ResetPassword: (userId, token) => {
      dispatch(resetPassword(userId, token));
    },
    GetUpdate: (userInfo, userId, token) => {
      dispatch(updateUser(userInfo, userId, token));
    },
    DeAssignInterviwers: (user, sId, token) => {
      dispatch(deassignInterviewers(user, sId, token))
    },
    UpdateSupervisor:(data, sId, token) => {
      dispatch(modifySupervisor(data, sId, token))
    }
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SupervisorDashBoard);
