import React from "react";

function TeacherRatingCard({
  title,
  value,
  feedback,
}) {
  return (
    <div>
      <div className="row" style={{ margin: 60 }}>
        <div class="col s12 m3 l3">
          <div
            class="card horizontal"
            style={{
              width: 230,
              borderRadius: 10,
              height: 130,
              marginLeft: "-70px",
            }}
          >
            <div class="card-stacked">
              <div class="card-content">
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <p>{title}</p>
                  <i class="material-icons" style={{ color: "coral" }}>
                    mood
                  </i>
                </div>
                <p style={{ fontSize: 30 }}>
                  <strong>{value}</strong>
                </p>
                <p style={{color: "grey" }}>
                  <strong>*Out of 10</strong>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

       <div
        className="row"
        style={{ marginLeft: 10, marginRight: 60, marginTop: "-70px" }}
      >
        <div class="col s12 m3 l3" style={{ marginLeft: "-24%" }}>
          <div
            class="card horizontal"
            style={{
              width: 230,
              borderRadius: 10,
              height: 270,
              marginLeft: "-60px",
            }}
          >
            <div class="card-stacked">
              {
                feedback && feedback.map((feedback)=>(
                  <div class="card-content">
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <p>{Object.keys(feedback)[0]}</p>
                  <p>{feedback[Object.keys(feedback)[0]]}</p>
                </div>
                <hr />
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <p>{Object.keys(feedback)[1]}</p>
                  <p>{feedback[Object.keys(feedback)[1]]}</p>
                </div>
                <hr />
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                <p>{Object.keys(feedback)[2]}</p>
                <p>{feedback[Object.keys(feedback)[2]]}</p>
                </div>
                <hr />
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                <p>{Object.keys(feedback)[3]}</p>
                <p>{feedback[Object.keys(feedback)[3]]}</p>
                </div>
                <div>
                <p style={{color: "grey",paddingTop:"10%" }}>
                  <strong>*Out of 10</strong>
                </p>
                </div>
              </div>
                ))
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TeacherRatingCard;
