export const buildLogic = (q, state, logicType) => {
  // console.log("in build loogic", q);
  if(logicType == "skip"){
    var val = q.questionType=="TEXT"|| q.questionType=="NUMERIC"?state.logicAnswer: "Op."+state.logicAnswer
    return {
      type: "logic",
      logicType: "skip",
      value:"S"+state.logicOperandSec
          +".Q"+state.logicOperandQ+". " +state.operator+ val 
          + "-> S"+state.logicResultSec+".Q"+state.logicResultQ,
      mainValue:[
          {
              type:'logic',
              opSec:state.logicOperandSec,
              opQ:state.logicOperandQ,
              extraOperand:q.questionType=="NUMERIC"&&state.extraOperand.length>0?
              state.extraOperand.map(opEx=>{
                return {
                  opSec:opEx.logicOperandSec,
                  opQ:opEx.logicOperandQ,
                  operator:opEx.operator
                }
              })
              :[],
              operator:state.operator,
              answer:{
                  type: q.questionType,
                  // group: q.questionType=="GRID"
                  opId: q.questionType=="SINGLE"||q.questionType=="MULTIPLE"?
                      state.logicAnswer:"",
                  text: q.questionType=="TEXT"|| q.questionType=="NUMERIC"
                      ?state.logicAnswer:""

              }
          }
      ],
      action:{sectionId:state.logicResultSec, questionId:state.logicResultQ},
      checked: false,
    }  
  }
    else if (logicType=="display"){
      var val = q.questionType=="TEXT"|| q.questionType=="NUMERIC"?state.logicAnswer: "Op."+state.logicAnswer
      return {
        type: "logic",
        logicType: "display",
        value:"S"+state.logicOperandSec+".Q"+state.logicOperandQ+". " +state.operator+ val + "block S"+state.logicResultSec+".Q"+state.logicResultQ + (state.logicBlockType=="option"?"Op."+state.logicBlockOption:""),
        mainValue:[
            {
              type:'logic',
                opSec:state.logicOperandSec,
                opQ:state.logicOperandQ,
                operator:state.operator,
                extraOperand:q.questionType=="NUMERIC"&&state.extraOperand.length>0?
                state.extraOperand.map(opEx=>{
                  return {
                    opSec:opEx.logicOperandSec,
                    opQ:opEx.logicOperandQ,
                    operator:opEx.operator
                  }
                })
                :[],
                answer:{
                    type: q.questionType,
                    // group: q.questionType=="GRID"
                    opId: q.questionType=="SINGLE"||q.questionType=="MULTIPLE"?
                        state.logicAnswer:"",
                    text: q.questionType=="TEXT"|| q.questionType=="NUMERIC"
                        ?state.logicAnswer:""
  
                }
            }
        ],
        action:{
          sectionId:state.logicResultSec, 
          questionId:state.logicResultQ, 
          blockType:state.logicBlockType, 
          opOrGrId:state.logicBlockOption
        },
        checked: false,
      }
    }
      else return {
        type: "logic",
        logicType: "pipe",
        value:"S1.Q1 == Op2 -> S2.Q2",
        mainValue:[
            {
                type:'logic',
                opSec:state.logicOperandSec,
                opQ:state.logicOperandQ,
                operator:state.operator,
                answer:{
                    type: q.questionType,
                    // group: q.questionType=="GRID"
                    opId: q.questionType=="SINGLE"||q.questionType=="MULTIPLE"?
                        state.logicAnswer:"",
                    text: q.questionType=="TEXT"|| q.questionType=="NUMERIC"
                        ?state.logicAnswer:""
  
                }
            }
        ],
        action:{sectionId:state.logicResultSec, questionId:state.logicResultQ},
        checked: false,
      }
  };

  function evaluateLogic(answer, operator, logic){
    if (answer[0].input&&logic.answer.type == "TEXT"){
      console.log( "data",answer[0].input ,operator , logic.answer.text);
      // console.log( "eval",eval(""+answer[0].input + operator + logic.answer.text));
      if(operator=="==")
        return eval(answer[0].input ==  logic.answer.text)
      else if(operator=="!=")
        return eval(answer[0].input !=  logic.answer.text)
    }
    else if(answer[0].input&&logic.answer.type == "NUMERIC"){
        return eval(""+answer[0].input + operator + logic.answer.text)
    }
    else if(answer[0].optionId&&logic.answer.type == "SINGLE"){
      return eval(""+answer[0].optionId + operator + logic.answer.opId)
    }
    else if(answer[0]&&logic.answer.type == "MULTIPLE"){
      for(var i=0;i<answer.length;i++){
        var r = eval(""+answer[i].optionId + operator + logic.answer.opId)
        if(r){
          return r
        }
      }
      return false
    }
  }

//answer is the full array of answer. Logic is the array of skip logics
  export const skipLogicEvaluator=(answer, logic)=>{
    var fullLogic = []
    for(var i=0;i<logic.length;i++){
      var l = logic[i]
      var str = ""
      for(var j=0;j<l.mainValue.length;j++){
        var logStr = l.mainValue[j]
        if (logStr.type == "logic"){
          // console.log("LOGIC", answer, logic);
          if(answer.sections
            &&answer.sections[logStr.opSec]
            &&answer.sections[logStr.opSec].questions[logStr.opQ]
            &&answer.sections[logStr.opSec].questions[logStr.opQ].answer
            &&answer.sections[logStr.opSec].questions[logStr.opQ].answer.length>0){
              if (logStr.extraOperand&&logStr.extraOperand.length>0){
                var all = answer.sections[logStr.opSec].questions[logStr.opQ].answer[0].input
                // console.log("Start all", all);
                logStr.extraOperand.map(op => {
                  // console.log("map",op);
                  if(answer.sections
                    &&answer.sections[op.opSec]
                    &&answer.sections[op.opSec].questions[op.opQ]
                    &&answer.sections[op.opSec].questions[op.opQ].answer
                    &&answer.sections[op.opSec].questions[op.opQ].answer.length>0){
                      // console.log("val", answer.sections[op.opSec].questions[op.opQ].answer);
                      all+=op.operator+answer.sections[op.opSec]
                      .questions[op.opQ].answer[0].input
                  }
                  // console.log("map",all);
                })
                // console.log("full string", all);
                str += evaluateLogic( [{input:all}], logStr.operator, logStr)
              }
              else
              str += evaluateLogic( answer.sections[logStr.opSec].questions[logStr.opQ].answer, 
                  logStr.operator, logStr)
            }
          else{
            str += "false"
          }
          
        }
        else{
          if (logStr.value == "AND"){
            str += "&&"
          }
          else if (logStr.value == "OR"){
            str += "||"
          }
        }
      }
      console.log("FULL STR", str);
      if(eval(str)){
        fullLogic.push(l.action)
      }
    }
    if(fullLogic.length > 0 ){
      return [fullLogic[0], true]
    }
    else{
      return [{}, false]
    }
  }

//answer is the full array of answers. Logic is the array of 2 logics
  export const displayLogicEvaluator=(answer, logic)=>{
    var block = {question:false, options:[], groups:[]}
    for(var i=0;i<logic.length;i++){
      var l = logic[i]
      var str = ""
      for(var j=0;j<l.mainValue.length;j++){
        var logStr = l.mainValue[j]
        if (logStr.type == "logic"){
          // console.log("LOGIC", answer, logic);
          if(answer.sections
            &&answer.sections[logStr.opSec]
            &&answer.sections[logStr.opSec].questions[logStr.opQ]
            &&answer.sections[logStr.opSec].questions[logStr.opQ].answer
            &&answer.sections[logStr.opSec].questions[logStr.opQ].answer.length>0){
              if (logStr.extraOperand&&logStr.extraOperand.length>0){
                var all = answer.sections[logStr.opSec].questions[logStr.opQ].answer[0].input
                // console.log("Start all", all);
                logStr.extraOperand.map(op => {
                  // console.log("map",op);
                  if(answer.sections
                    &&answer.sections[op.opSec]
                    &&answer.sections[op.opSec].questions[op.opQ]
                    &&answer.sections[op.opSec].questions[op.opQ].answer
                    &&answer.sections[op.opSec].questions[op.opQ].answer.length>0){
                      // console.log("val", answer.sections[op.opSec].questions[op.opQ].answer);
                      all+=op.operator+answer.sections[op.opSec]
                      .questions[op.opQ].answer[0].input
                  }
                  // console.log("map",all);
                })
                // console.log("full string", all);
                str += evaluateLogic( [{input:all}], logStr.operator, logStr)
              }
              else
              str += evaluateLogic( answer.sections[logStr.opSec].questions[logStr.opQ].answer, 
                  logStr.operator, logStr)
            }
          else{
            str+= false
          }
          
        }
        else{
          if (logStr.value == "AND"){
            str += "&&"
          }
          else if (logStr.value == "OR"){
            str += "||"
          }
        }
      }
      if(eval(str)){
        if(l.action.blockType == "question"){
          block.question = true
          return [block, true]
        }
        else if(l.action.blockType == "option"){
          block.options.push(l.action.opOrGrId)
        }
        else if(l.action.blockType == "option"){
          block.groups.push(l.action.opOrGrId)
        }
      }
    }
    if(block.question||block.options.length>0||block.groups.length>0 ){
      return [block, true]
    }
    else{
      return [{}, false]
    }
  }
