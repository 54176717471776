import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router";
import Navbar from "../layout/Navbar";

export class AddStudentForm extends Component {
  render() {
    const user = this.props.auth.user;
    // console.log(user);
    if (!user) return <Redirect to="/login" />;
    return (
      <div>
        <Navbar heading="Admin" user={user} />
        <div className="container" style={{ marginTop: "-13px"}}>
        <div
            className="row"
            style={{
              height: 60,
              backgroundColor: "#5d1b8f",
              //   borderBottomLeftRadius: 55,
              // borderBottomRightRadius: 55,
            }}
          >
            <div style={{ marginLeft: "38%" }}>
              <h5 style={{color:"aliceblue", paddingTop: 12}}>Student Add Form</h5>
            </div>
          </div>
        </div>
        <div
          className="container"
          style={{ marginTop: 15, height: "75vh", overflow: "auto" }}
        >
          <form style={{ width: "98%" }}>
            <div className="row">
              <div className="col s6 m6 l6">
                <label className="active" for="app_no">
                  Application No.
                </label>
                <input id="app_no" type="text" class="validate" required />
              </div>
              <div className="col s6 m6 l6">
                <label className="active" for="app_date">
                  Application date
                </label>
                <input id="app_date" type="date" class="validate" required />
              </div>
            </div>
            <div className="row">
              <div class="input-field col s4 m4 l4">
                <input id="fname" type="text" class="validate" />
                <label className="active" for="fname">
                  First Name
                </label>
              </div>
              <div class="input-field col s4 m4 l4">
                <input id="mname" type="text" class="validate" />
                <label className="active" for="mname">
                  Middle Name
                </label>
              </div>
              <div class="input-field col s4 m4 l4">
                <input id="lname" type="text" class="validate" />
                <label className="active" for="lname">
                  Last Name
                </label>
              </div>
            </div>
            <div className="row">
              <div class="input-field col s4 m4 l4">
                <select class="browser-default">
                  <option value="" disabled selected>
                    Choose your Course
                  </option>
                  <option value="1">CE</option>
                  <option value="2">ECE</option>
                  <option value="3">IT</option>
                  <option value="4">CSE</option>
                </select>
              </div>
              <div class="input-field col s4 m4 l4">
                <select class="browser-default">
                  <option value="" disabled selected>
                    Choose your Section
                  </option>
                  <option value="1">S1</option>
                  <option value="2">S2</option>
                  <option value="3">S3</option>
                </select>
              </div>
              <div class="input-field col s4 m4 l4">
                <input id="dob" type="date" class="validate" />
                <label className="active" for="dob">
                  DOB
                </label>
              </div>
            </div>
            <div className="row">
              <div class="input-field col s4 m4 l4">
                <select class="browser-default">
                  <option value="" disabled selected>
                    Choose your Gender
                  </option>
                  <option value="1">Male</option>
                  <option value="2">Female</option>
                  <option value="3">Transgender</option>
                  <option value="4">Other</option>
                </select>
              </div>
              <div class="input-field col s4 m4 l4">
                <select class="browser-default">
                  <option value="" disabled selected>
                    Choose your Blood Group
                  </option>
                  <option value="1">AB+</option>
                  <option value="2">O+</option>
                  <option value="3">O-</option>
                  <option value="4">AB-</option>
                </select>
              </div>
              <div class="input-field col s4 m4 l4">
                <select class="browser-default">
                  <option value="" disabled selected>
                    Choose your Cast
                  </option>
                  <option value="1">Gen</option>
                  <option value="2">OBC</option>
                  <option value="3">SC</option>
                  <option value="4">ST</option>
                </select>
              </div>
            </div>
            <div className="row">
              <div class="input-field col s4 m4 l4">
                <input id="mt" type="text" class="validate" />
                <label className="active" for="mt">
                  Mother Tongue
                </label>
              </div>
              <div class="input-field col s4 m4 l4">
                <input id="rel" type="text" class="validate" />
                <label className="active" for="rel">
                  Religion
                </label>
              </div>
              <div class="input-field col s4 m4 l4">
                <input id="nation" type="text" class="validate" />
                <label className="active" for="nation">
                  Nationality
                </label>
              </div>
            </div>
            <div className="row">
              <div className="col s6 m6 l6">
                <textarea rows="2" cols="40" name="add1" form="usrform">
                  Address Line 1
                </textarea>
              </div>
              <div className="col s6 m6 l6">
                <textarea rows="2" cols="40" name="add2" form="usrform">
                  Address Line 2
                </textarea>
              </div>
            </div>
            <div className="row">
              <div class="input-field col s4 m4 l4">
                <input id="pin" type="text" class="validate" />
                <label className="active" for="pin">
                  Pin
                </label>
              </div>
              <div class="input-field col s4 m4 l4">
                <input id="state" type="text" class="validate" />
                <label className="active" for="state">
                  State
                </label>
              </div>
              <div class="input-field col s4 m4 l4">
                <input id="country" type="text" class="validate" />
                <label className="active" for="country">
                  Country
                </label>
              </div>
            </div>
            <div className="row">
              <div class="input-field col s4 m4 l4">
                <input id="mobile" type="tel" class="validate" />
                <label className="active" for="mobile">
                  Mobile
                </label>
              </div>
              <div class="input-field col s4 m4 l4">
                <input id="email" type="email" class="validate" />
                <label className="active" for="email">
                  Email
                </label>
              </div>
              <div class="input-field col s4 m4 l4">
                <input id="phone" type="text" class="validate" />
                <label className="active" for="phone">
                  Phone(Parent's)
                </label>
              </div>
            </div>
            <div className="row"></div>
          </form>
          <div>
            <a className="btn right">Save</a>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(AddStudentForm);
