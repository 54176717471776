import { backendUrl } from "../../config/api";
import createFormData from "../../utils/helper";

//fetch users
export const getUsers = (token) => {
  return (dispatch) => {
    fetch(backendUrl + "/users", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((response) =>
      response.json().then((data) => {
        if (response.status == 200) {
          dispatch({
            type: "USER_FETCH_SUCCESS",
            payload: data.data,
          });
        } else if (response.status == 403) {
          dispatch({
            type: "AUTHENTICATION_ERROR",
          });
        } else {
          dispatch({
            type: "USER_FETCH_ERROR",
          });
        }
      })
    );
  };
};

//add new user
export const addUser = (userInfo, token) => {
  console.log("user data", userInfo);
    return dispatch => {
      dispatch({
        type: "ADD_USER_LOADING"
      });
      fetch(backendUrl + "/user", {
        method: 'post',
        body:createFormData(userInfo.photo,"photo", {...userInfo, photo:null}),
        headers: {
          'Authorization': `Bearer ${token}`
        },
      }).then(response =>
        response.json().then(data => {
         if (response.status === 200) {
            dispatch({
              type: "ADD_USER_SUCCESS",
              payload: data
            });
          } 
          else if (response.status === 403) {
            dispatch({
              type: "AUTHENTICATION_ERROR"
            });
          }
           else {
            console.log(data);
            dispatch({
              type: "ADD_USER_FAILED"
            });
          }
        }).catch((err)=>{
          dispatch({
            type: "ADD_USER_FAILED"
          });
        })
      );
    };

};

//fetch role
export const getRoles = (token) => {
    return (dispatch) => {
      fetch(backendUrl + "/roles", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }).then((response) =>
        response.json().then((data) => {
          if (response.status == 200) {
            dispatch({
              type: "ROLE_FETCH_SUCCESS",
              payload: data.data,
            });
          } else if (response.status == 403) {
            dispatch({
              type: "AUTHENTICATION_ERROR",
            });
          } else {
            dispatch({
              type: "ROLE_FETCH_ERROR",
            });
          }
        })
      );
    };
  };

//User checkbox action
export const checkUser=(user,id) => {
  return(dispatch) => {
      // console.log(id)
      // console.log(user)
      var index = user.findIndex((x) => x.id === id);
      let newArray = [...user];
      newArray[index] = { ...newArray[index], check: newArray[index].check ? !newArray[index].check: true };
      dispatch({type:'CHECK_USER', payload:newArray})
  }
}

//modify user details
export const updateUser = (userInfo, userId, token) => {
  return (dispatch) => {
    var photo = userInfo.photo
    delete userInfo.photo
    dispatch({
      type: "UPDATE_USER_LOADING"
    });
    fetch(backendUrl + '/user/' +userId, {
      method: "put",
      headers: {
        'Authorization': `Bearer ${token}`
      },
      body:createFormData(photo,"photo", userInfo),
    }).then(response =>
      response.json().then(data => {
       if (response.status === 200) {
          dispatch({
            type: "UPDATE_USER_SUCCESS",
            payload: data
          });
        } 
        else if (response.status === 403) {
          dispatch({
            type: "AUTHENTICATION_ERROR"
          });
        }
         else {
          dispatch({
            type: "UPDATE_USER_FAILED"
          });
        }
      }).catch((err)=>{
        dispatch({
          type: "UPDATE_USER_FAILED"
        });
      })
    );
  }
}

//deactivate user
export const deactivateUser= (userInfo, userId, token) => {
  return (dispatch) => {
    dispatch({
      type: "DEACTIVATE_USER_LOADING"
    });
    fetch(backendUrl + '/user/' +userId, {
      method: "put",
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify(userInfo)
    }).then(response =>
      response.json().then(data => {
       if (response.status === 200) {
          dispatch({
            type: "DEACTIVATE_USER_SUCCESS",
            payload: data
          });
        } 
        else if (response.status === 403) {
          dispatch({
            type: "AUTHENTICATION_ERROR"
          });
        }
         else {
          dispatch({
            type: "DEACTIVATE_USER_FAILED"
          });
        }
      }).catch((err)=>{
        dispatch({
          type: "DEACTIVATE_USER_FAILED"
        });
      })
    );
  }
}

//reset password
export const resetPassword = (userId,token) => {
  return (dispatch) => {
    dispatch({
      type: "RESET_PASSWORD_LOADING"
    });
    fetch(backendUrl + '/user/' +userId+'/resetpassword', {
      method: "put",
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
    }).then(response =>
      response.json().then(data => {
       if (response.status === 200) {
          dispatch({
            type: "RESET_PASSWORD_SUCCESS",
            payload: data
          });
        } 
        else if (response.status === 403) {
          dispatch({
            type: "AUTHENTICATION_ERROR"
          });
        }
         else {
          dispatch({
            type: "RESET_PASSWORD_FAILED"
          });
        }
      }).catch((err)=>{
        dispatch({
          type: "RESET_PASSWORD_FAILED"
        });
      })
    );
  }
}

//reset password
export const ResetAddUser = () => {
  return (dispatch) => {
    dispatch({
      type: "RESET_ADD_SUCCESS"
    });
  }
}



export const addInterviewers = (add, all) => {
  return (dispatch) => {
    dispatch({ type: "ADD_USER_LOADING" });
    for (var i = 0; i < add.length; i++) {
      var n = all.find((u) => u.id == add[i]);
    }
    dispatch({
      type: "ADD_INTERVIEWER_SUCCESS",
      data: [
        {
          id: 1,
          name: "Soap Project",
          agents: [
            {
              id: 1,
              name: "Sumanta Mukherjee",
              role: "Interviewer",
              email: "sumanta.m@vixplor.com",
            },
            {
              id: 2,
              name: "Dip Roy",
              role: "Interviewer",
              email: "dip.r@vixplor.com",
            },
            {
              id: 3,
              name: "Shymal Gayen",
              role: "Interviewer",
              email: "shymal.g@vixplor.com",
            },
          ],
        },
        {
          id: 2,
          name: "Cigeratte Project",
          agents: [
            {
              id: 1,
              name: "Sumanta Mukherjee",
              role: "Interviewer",
              email: "sumanta.m@vixplor.com",
            },
          ],
        },
        {
          id: 2,
          name: "Block Project",
          agents: [
            {
              id: 2,
              name: "Dip Roy",
              role: "Interviewer",
              email: "dip.r@vixplor.com",
            },
            {
              id: 3,
              name: "Shymal Gayen",
              role: "Interviewer",
              email: "shymal.g@vixplor.com",
            },
          ],
        },
      ],
    });
    // fetch(backendUrl
    // +'/login', {
    //   method: 'post',
    //   headers: {
    //     Accept: 'application/json',
    //     'Content-Type': 'application/json',
    //   },
    //   body: JSON.stringify({
    //     email: credential.email,
    //     password: credential.password,
    //   }),
    // }).then(response => response.json().then(data=>{
    //     if (response.status == 200) {
    //     dispatch({
    //         type:'LOGIN_SUCCESS',
    //         user: {...data.data, type: data.type, accessToken:data.accessToken, refreshAccessToken:data.refreshAccessToken},
    //     });
    //  }else{
    //    dispatch({
    //      type: 'LOGIN_ERROR',
    //    });
    //  }
    // }))
  };
};
