import React from "react";
import "../stylesheets/modals.css";
import "../stylesheets/common.css";
import OptionViewer from "../designer/OptionViewer";

const EditQcAnswer = ({ questions, selectedQ,response, state, HandleChange, onSubmit }) => {
  return (
    <div id="edit-answer-modal" className="modal modal-fixed-footer">
      <div className="row modal-content">
        <div className="row modal-title valign-wrapper">
          <span>Edit</span>
        </div>
        <div className="row">
          <div className="col s12">
            <form id="editUser" onSubmit={onSubmit}>
              {(questions[selectedQ] &&
                questions[selectedQ].questionType === "TEXT")  ? (
                <div className="input-field col s12">
                        <input
                          id="input"
                          name="text"
                          type="text"
                          onChange={HandleChange}
                          value={state.text}
                          placeholder="new data"
                          required
                        />
                  <label className="Active">Edit Answer</label>
                </div>
              ) : 
              (questions[selectedQ] &&
                questions[selectedQ].questionType === "NUMERIC") ? (
                <div className="input-field col s12">
                        <input
                          type="number"
                          id="num"
                          onChange={HandleChange}
                          value={state.num}
                          placeholder="new data"
                          required
                        />
                  <label className="Active">Edit Answer</label>
                </div>
              ) :(questions[selectedQ] &&
                  questions[selectedQ].questionType === "SINGLE") ? (
                <div className="input-field col s12">
                      <form action="#">
                      <p>{questions[selectedQ].question&&questions[selectedQ].question.default?
                        questions[selectedQ].question.default:questions[selectedQ].question}</p>                  
                        {questions[selectedQ].options &&
                          Object.keys(questions[selectedQ].options).map(
                            (optionId, index) => (
                              <>
                              <label className="" key={index}>
                                <input
                                  id={"single-"+optionId}
                                  class="with-gap"
                                  name="single"
                                  type="radio"
                                  value={optionId}
                                  onChange={HandleChange}
                                  checked={state.optionId == optionId}
                                  required
                                />
                                <span></span>
                                </label>
                              <OptionViewer option={questions[selectedQ].options[optionId]} index={index} lang={"default"}/>
                              </>
                            )
                          )}
                        
                      </form>
                </div>
              ) :
              (questions[selectedQ] &&
                questions[selectedQ].questionType === "MULTIPLE") ? (
              <div className="input-field col s12">
                    <form action="#">
                    <p>{questions[selectedQ].question&&questions[selectedQ].question.default?
                       questions[selectedQ].question.default:questions[selectedQ].question}</p>                  
                        {questions[selectedQ].options &&
                        Object.keys(questions[selectedQ].options).map(
                          (optionId, index) => (
                            <div className="" key={index}>
                            <label>
                              <input
                                id={"multiple-"+optionId}
                                name="multiple"
                                // class="with-gap"
                                value={optionId}
                                type="checkbox"
                                checked= {state.multiple[optionId]
                                  ? state.multiple[optionId]
                                  : false}
                                onChange={HandleChange}
                                required
                              />
                              <span></span>
                              </label>
                              <OptionViewer option={questions[selectedQ].options[optionId]} index={optionId} lang={"default"}/>
                            </div>
                          )
                        )}
                      
                    </form>
              </div>
            ) :
              (questions[selectedQ] &&
                questions[selectedQ].questionType === "GRID")  ? (
              <div className="input-field col s12">
                    <form action="#">
                    <p>{questions[selectedQ].question&&questions[selectedQ].question.default?
                questions[selectedQ].question.default:questions[selectedQ].question}</p>                  
                    {questions[selectedQ].groups &&
                      Object.keys(
                        questions[selectedQ].groups
                      ).map((groupId, ind) => (
                        <div>
                          <p key={ind}>
                            {
                              questions[selectedQ].groups[
                                groupId
                              ].group["default"]
                            }
                          </p>
                          {Object.keys(
                            questions[selectedQ].options
                          ).map((optionId, index) => (
                            <div key={index}>
                              <label>
                                <input
                                  className="with-gap browser-default"
                                  type="radio"
                                  id={"gr-"+ groupId+'-op-'+ optionId}
                                  name={"gridGroup"+groupId}
                                  checked={
                                    state.grid[groupId]&&state.grid[groupId][optionId]? true : false
                                  }
                                  // value={optionId}
                                  onChange={HandleChange}
                                  />
                                <span></span>
                              </label>
                                <OptionViewer
                                  option={
                                    questions[selectedQ]
                                      .options[optionId]
                                  }
                                  lang={"default"}
                                />
                            </div>
                          ))}
                        </div>
                      )
                      )} 
                    </form>
              </div>
            ) :
            (questions[selectedQ] &&
              questions[selectedQ].questionType === "RANK")  ? (
                <div className="input-field col s12" style={{display: "flex", flexDirection:"column"}}>
                <form action="#">
                <p>{questions[selectedQ].question&&questions[selectedQ].question.default?
                questions[selectedQ].question.default:questions[selectedQ].question}</p>                  
                {questions[selectedQ].options &&
                    Object.values(questions[selectedQ].options).map(
                      (option, index) => (
                        <label className="row" key={index} style={{fontSize:"15px", paddingLeft: 10}}>
                          <input
                            id={"rank-"+index}
                            className="input-field col s2"
                            type="number"
                            name="rank"
                            onChange={HandleChange}
                            min={Math.min(...Object.keys(questions[selectedQ].options))}
                            max={Math.max(...Object.keys(questions[selectedQ].options))}
                            required
                          />
                          {" "}
                          <span className="input-field col s9">{option.option.default}</span>
                          </label>
                      )
                    )}
                  
                </form>
          </div>
          ) : 
          (questions[selectedQ] &&
            questions[selectedQ].questionType === "MIXED") ? (
          <div className="input-field col s12">
                <form action="#">
                <p>{questions[selectedQ].question&&questions[selectedQ].question.default?
                questions[selectedQ].question.default:questions[selectedQ].question}</p>
                {Object.keys(
                  questions[selectedQ].options
                ).map((optionId, index) =>
                  questions[selectedQ].options[optionId].optionTypeGroup=="MULTIPLE" ? (
                      <div key={index}>
                        <label>
                          <input
                            id={"mix-multiple-"+optionId}
                            name="mix"
                            type="checkbox"
                            checked={
                              state.mix.multiple[optionId]
                                ? state.mix.multiple[optionId]
                                : false
                            }
                            onChange={HandleChange}
                            // value={optionId}
                          />
                        <span></span>
                        </label>
                        <OptionViewer
                            option={
                              questions[selectedQ]
                                .options[optionId]
                            }
                            lang={"default"}
                          />
                      </div>
                    ) : 

                      <div key={index}>
                        <label>
                          <input
                            id={"mix-single-"+optionId}
                            name="mix"
                            type="checkbox"
                            checked={
                              state.mix.single[optionId]
                                ? state.mix.single[optionId]
                                : false
                            }
                            onChange={HandleChange}
                            />
                          <span></span>
                        </label>
                          <OptionViewer
                            option={
                              questions[selectedQ]
                                .options[optionId]
                            }
                            lang={"default"}
                          />
                      </div>
                )}
                  
                </form>
          </div>
        ) :questions[selectedQ] &&
          (questions[selectedQ].questionType === "AUDIO"
          ||questions[selectedQ].questionType === "VIDEO"
          ||questions[selectedQ].questionType === "IMAGE") ?
                <div className="input-field col s12 center">
                  <p><b>Sorry! Not Editable</b></p>
                </div>
              : null}
            </form>
          </div>
        </div>
      </div>
      <div className="modal-footer">
        <button className="modal-close waves-effect waves btn-flat">
          CANCEL
        </button>
        <button
          type="submit"
          form="editUser"
          className="waves-effect waves btn-flat"
        >
          Save
        </button>
      </div>
    </div>
  );
};

export default EditQcAnswer;
