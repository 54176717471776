import React from "react";
import "../stylesheets/modals.css";
import "../stylesheets/common.css";
import NewOption from "./NewOption";
import NewGroup from "./NewGroup";

const AddQuestion = ({ HandleChange, OnSubmit, state, OnAddOption, OnAddGroup }) => {
  // console.log(supervisors)
  // console.log(state)
  return (
    <div id="add-question-modal" className="modal modal-fixed-footer">
      <div className="row modal-content">
        <div className="row modal-title valign-wrapper">
          <span>Add question</span>
        </div>

        <div className="row">
          <div className="col s12">
            <form id="addQuestion" onSubmit={OnSubmit}>
              <div className="col s12">
                <label style={{color:'black'}}>New questions type</label>
              </div>
              <div className="input-field col s12">
                <select
                  className="browser-default"
                  type="text"
                  required
                  onChange={HandleChange}
                  value={state.newQuestionType}
                  id="newQuestionType"
                >
                  <option value="" disabled defaultValue="">
                    Choose the question type
                  </option>
                    <option value="TEXT">
                      TEXT
                    </option>
                    <option value="NUMERIC">
                      NUMERIC
                    </option>
                    <option value="DATETIME">
                      DATETIME
                    </option>
                    <option value="SINGLE">
                      SINGLE
                    </option>
                    <option value="MULTIPLE">
                      MULTIPLE
                    </option>
                    <option value="RANK">
                      RANK
                    </option>
                    <option value="GRID">
                      GRID
                    </option>
                    <option value="MIX">
                      MIX
                    </option>
                </select>
              </div>
              <div className="col s12">
                <label style={{color:'black'}}>New questions body type</label>
              </div>
              <div className="input-field col s12">
                <select
                  className="browser-default"
                  type="text"
                  required
                  onChange={HandleChange}
                  value={state.newQuestionBodyType}
                  id="newQuestionBodyType"
                >
                  <option value="" disabled selected defaultValue="">
                    Choose the question body type
                  </option>
                    <option value="text" key="text">
                      Text
                    </option>
                    <option value="image" key="image">
                      Image
                    </option>
                    <option value="audio" key="audio">
                      Audio
                    </option>
                    <option value="video" key="video">
                      Video
                    </option>
                </select>
              </div>
              {
                state.newQuestionBodyType == "text"?
                <div className="input-field col s12">
                  <input
                    type="text"
                    id="newQuestionText"
                    className="validate"
                    onChange={HandleChange}
                    value={state.newQuestionText}
                    required
                  />
                  <label className="active" for="newQuestionText">
                    Add question text
                  </label>
                </div>
                :null
              }
              {
                state.newQuestionType == "SINGLE"
                ||state.newQuestionType == "MULTIPLE"
                ||state.newQuestionType == "RANK"
                ||state.newQuestionType == "MIXED"
                ||state.newQuestionType == "GRID"?
                <div className="row">
                  <div className="col s12">
                    <div className="col s12">
                      <button className="addOption btn btn-floating" onClick={OnAddOption}>
                        <i className="material-icons">add</i>
                      </button>
                      <span style={{marginLeft:10}}>Add Option</span>
                    </div>
                    {
                      state.newOptionsData.map((option, ind) => 
                        <NewOption state={state} HandleChange={HandleChange} ind={ind}/>
                      )
                    }
                  </div>
                  {
                    state.newQuestionType=="GRID"?
                    <div className="col s12" style={{marginBottom:10}}>
                      <div className="col s12">
                        <button className="addGroup btn btn-floating" onClick={OnAddGroup}>
                          <i className="material-icons">add</i>
                        </button>
                        <span style={{marginLeft:10}}>Add Group</span>
                      </div>
                      {
                        state.newGroupsData.map((group, ind) => 
                          <NewGroup state={state} HandleChange={HandleChange} ind={ind}/>
                        )
                      }
                    </div>
                    :null
                  }
                </div>
                
                :null
              }
              {/* <div className="red-text center">
                {state.existsError ? <p>Email exists</p> : null}
              </div> */}
            </form>
          </div>
        </div>
      </div>
      <div className="modal-footer">
        <button className="modal-close waves-effect waves btn-flat">
          CANCEL
        </button>
        {
          <button
            type="submit"
            form="addQuestion"
            className="waves-effect waves btn-flat"
          >
            Add Question
          </button>
        }
      </div>
    </div>
  );
};

export default AddQuestion;
