import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Redirect } from "react-router";
import Navbar from "../layout/Navbar";

export class LeaveApproval extends Component {
    state={
        leaves:[
            {
                date:"20/01/2021",
                to: "27/01/2021",
                subject: "Brother's marriage",
                faculty:"professor 1",
                type: "Full day"
            },
            {
                date:"03/04/2021",
                to: "07/04/2021",
                subject: "Tour",
                faculty:"professor 2",
                type: "Full day"
            },
            {
                date:"02/01/2021",
                to: "02/01/2021",
                subject: "Sick leave",
                faculty:"professor 3",
                type: "half day"
            },
        ]
    }
    render() {
        const user = this.props.auth.user;
        if (!user) return <Redirect to="/login" />;
        return (
          <div>
            <Navbar heading="Admin" user={user} />
                <div className="container" style={{marginTop: 10, backgroundColor:"aliceblue"}}>
                    <div className="row">
                        <div className="col s12 m12 l12" style={{marginTop: 10, backgroundColor:"beige"}}>
                            <h4><strong><center>Leave Approval</center></strong></h4>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col s2 m2 l2"><b>Faculty</b></div>
                        <div className="col s2 m2 l2"><b>Type</b></div>
                        <div className="col s2 m2 l2"><b>From</b></div>
                        <div className="col s2 m2 l2"><b>To</b></div>
                        <div className="col s2 m2 l2"><b>Reason</b></div>
                        <div className="col s2 m2 l2"><b>Action</b></div>
                    </div>
                    {
                        this.state.leaves.map((leave)=>(
                            <div className="row">
                                <div className="col s2 m2 l2">{leave.faculty}</div>
                                <div className="col s2 m2 l2">{leave.type}</div>
                                <div className="col s2 m2 l2">{leave.date}</div>
                                <div className="col s2 m2 l2">{leave.to}</div>
                                <div className="col s2 m2 l2">{leave.subject}</div>
                                <div className="col s2 m2 l2">
                                    <div className="row">
                                        <div className="col s6 m6 l6">
                                            <a className="btn-floating"><i className="material-icons">check</i></a>
                                        </div>
                                        <div className="col s6 m6 l6">
                                            <a className="btn-floating"><i className="material-icons">clear</i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
      auth: state.auth,
    };
  };

const mapDispatchToProps = {
    
}

export default connect(mapStateToProps, mapDispatchToProps)(LeaveApproval)
