
import React, { PureComponent } from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import M from 'materialize-css';
import Navbar from "../layout/Navbar";
import '../stylesheets/common.css';
import '../stylesheets/designer.css';

import {uploadFile, getSurveys, loadExistingSurvey, uploadNewVersion} from '../../store/actions/designActions'
import {getCourses} from '../../store/actions/HodAction'

class DesignerDashboard extends PureComponent {
    state = {
        selectedFile: null,
        liveoption:'upload',
        surveyName:null,
        description:null,
  
        status:'Design',
        // company:null,
        // startDate:null,
        // endDate:null,
        // nis:false,
        selectedSurvey:null,
        versionFile:null,
        
        // smFrequency:'daily',
        // sector:null,
        // smStartDate:null,
        // smEndDate:null,
        // smRelevanceThreshold:0.8,
        // smNoveltyThreshold:0.4,
        // sm:false,

        // design:false
      }      
     
  
    componentDidMount = () => {
        var elems = document.querySelectorAll('.datepicker');

        M.Datepicker.init(elems,
           {
             minDate:new Date(2010,0,1), 
             maxDate:new Date (Date.now()),
             autoClose:true,
             onClose:()=>{this.onDateClose()}
          }
        );
        if(this.props.auth.user){
            this.props.GetSurveys(this.state.status, this.props.auth.user.accessToken)
            this.props.GetCourse(
                this.props.auth.user.deptId,
                this.props.auth.user.accessToken
            );
        }
    }
  
    onChangeHandler=event=>{
      this.setState({
        [event.target.id]: event.target.value,
      })
      if(event.target.id == "status"){
        this.props.GetSurveys(event.target.value, this.props.auth.user.accessToken)
      }
    }
  
    onDateClose=()=>{
      this.setState({
          ...this.state,
          startDate:document.getElementById('startDate').value,
          endDate:document.getElementById('endDate').value,
          smStartDate:document.getElementById('smStartDate').value,
          smEndDate:document.getElementById('smEndDate').value
        })
      // console.log(this.state)
    }
  
    onFileChangeHandler=event=>{
      this.setState({
        [event.target.id]: event.target.files[0]
      })
    }

    handleChange = (e) => {
        this.setState({ [e.target.id]: e.target.value });
      };
  
    onClickHandler = () => {
      const profile=this.props.auth&&this.props.auth.user?this.props.auth.user:null;
    //   this.setState({...this.state, design:true})
    var course = this.state.course;
      if(profile&&this.state.selectedFile){
        this.props.UploadFile(this.state.selectedFile, this.state.surveyName, this.state.description,course, profile.accessToken);
  
      }else{
        alert("please select a file to upload");
      }
    }
  
    OnLiveSurvey = (e) => {
      e.preventDefault();
      console.log("this.state", this.state);
      var user = this.props.auth.user
      if(user){
        if(this.state.status=="Design"){
            var surveyData = this.props.surveys.find((survey) => {return survey.id==this.state.selectedSurvey})
            console.log("here", surveyData);
            this.props.LoadExistingSurvey(surveyData, surveyData.id)
            this.props.history.push("/survey/"+surveyData.id+"/design")
        }
        else if(this.state.status=="Live"){
            var surveyData = this.props.surveys.find((survey) => {return survey.id==this.state.selectedSurvey})
            console.log("here", surveyData);
            if(this.state.selectedSurvey&& 
                this.props.surveys.find(survey => survey.id == this.state.selectedSurvey&& survey.SurveyVersions[0].status=="Design" )
            ){
                var surveyData = this.props.surveys.find((survey) => {return survey.id==this.state.selectedSurvey})
                console.log("here", surveyData);
                this.props.LoadExistingSurvey(surveyData, surveyData.id)
                this.props.history.push("/survey/"+surveyData.id+"/design")
            }
            else{
                if(this.state.liveoption == "upload"){
                    var course=this.state.course
                    this.props.UploadNewVersion(surveyData.id, null, this.state.versionFile,true,course, user.accessToken )
                }
                else if(this.state.liveoption =="modify"){
                    this.props.UploadNewVersion(surveyData.id, surveyData.SurveyVersions[0].questionnaire, this.state.versionFile,false,null, user.accessToken )
                }
                else if(this.state.liveoption =="versionControl"){
                    var surveyData = this.props.surveys.find((survey) => {return survey.id==this.state.selectedSurvey})
                    console.log("here", surveyData);
                    this.props.LoadExistingSurvey(surveyData, surveyData.id)
                    this.props.history.push("/survey/"+surveyData.id+"/design")
                }
            }
        }
        else{
            var surveyData = this.props.surveys.find((survey) => {return survey.id==this.state.selectedSurvey})
            this.props.history.push("/livesurvey/"+surveyData.id+"/stat")
        }
    }
    //   if (!(this.state.company) || !(this.state.startDate) || !(this.state.endDate)){
    //     alert("Please fill the the fields...")
    //   }
    //   else
    
        // this.props.GetLiveSurvey(this.state)
    }
  
    onSMSubmit = (e) => {
      e.preventDefault();
      this.props.history.push("/archivedsurvey/1/stat")
    }
  
  
    render() {
      const { profile, auth,uploadLoading,uploadSuccess,uploadError, surveyId, surveys, surveyLoading} = this.props;
      const user = this.props.auth.user;

    if (!(user&&user.id)) return <Redirect to="/login" />;
    //   if(uploadRespose){
    if(uploadSuccess){
       return <Redirect to={"/survey/"+surveyId+"/design"} />;
      }

    //   if(NIS&&NIS.NISSuccess&&NIS.NewsNISSuccess){
    //     return <Redirect to="/nis" />;
    //   }
    //   if(SM&&SM.SMSuccess&&SM.NewsSMSuccess){
    //     return <Redirect to="/sm" />;
    //   }
    return (
    <div>
        <Navbar user={auth.user} heading={"Designer"}/>
        <div className="container main_container_wrapper " style={{width:'100%'}}>
            <div className="row ">
                <div className="col s12 l6 m6">
                    <div className="card cardDiv medium">
                        
                        <div className="card-content row"> 
                            <div className="card-title">
                                <strong>Design</strong>
                            </div>
                            <hr/>                   
                            <h6>Upload Master Excel</h6>
                            <p>Excel File should contain all questionare. Code for answers and types of questions 
                                and option types in the predefined format.
                            </p>
        
                            <input id="selectedFile" type="file" name="file" accept=".xlsx " onChange={this.onFileChangeHandler} style={{marginTop:10, marginBottom:5}}/>
                            <div className="row">
                            <div className="col s12 m6 l6">
                            <input 
                                type="text"
                                id="surveyName"
                                onChange={this.onChangeHandler}
                                name=""  
                                placeholder="Enter project name" 
                                style={{marginTop:0, marginBottom:0}}
                            />
                            </div>
                            <div className="col s12 m6 l6">
                            <select
                                class="browser-default"
                                id="course"
                                onChange={this.handleChange}
                            >
                                <option value="" disabled selected>
                                Select Subject
                                </option>
                                {this.props.course &&
                                this.props.course.map((course) =>
                                    course.Semesters.map((sem) =>
                                    sem.Courses.map((course) => (
                                        <option value={course.id}>{course.courseName}</option>
                                    ))
                                    )
                                )}
                            </select>
                            </div>
                            </div>
                            <div className="red-text">
                                {uploadError? <p>{uploadError}</p> :null}
                            </div>
                            
                            <div className="center center-align" style={{padding:25}}>
                                <button type="button" className="btn card-button" onClick={this.onClickHandler}>SUBMIT</button> 
                            </div>
                        </div>
                    </div>
                </div>


                <div className="col s12 l6 m6">
                    <div className="card cardDiv medium" >
                        <div className="card-content"> 
                            <div className="card-title valign-wrapper">
                                <strong>Ongoing Tests</strong>
                            </div>
                            <hr/>
                            <div className="row" style={{marginBottom:0}}>
                                <form onSubmit={this.onNISSubmit}>
                                    <div className="">
                                        <div className="col s12">
                                            <div className="col s4">
                                                <label>
                                                    <input id="status"className="with-gap" name="surveyState" type="radio" value='Design' 
                                                    checked={this.state.status=='Design'? true : false}
                                                    onChange={this.onChangeHandler}/>
                                                    <span>Design</span>
                                                </label>
                                            </div>
                                            <div className="col s4">
                                                <label>
                                                    <input id="status" className="with-gap" name="surveyState" type="radio" value='Live' 
                                                    checked={this.state.status=='Live'? true : false}
                                                    onChange={this.onChangeHandler}/>
                                                    <span>App</span>
                                                </label>
                                            </div>
                                            <div className="col s4">
                                                <label>
                                                    <input id="status" className="with-gap" value name="surveyState" type="radio" value='Test' 
                                                    checked={this.state.status=='Test'? true : false}
                                                    onChange={this.onChangeHandler}/>
                                                    <span>Web</span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="input-field left col s12" style={{ backgroundColor: "#fff", height: 50 }}>
                                        <select required name="selectedSurvey" id='selectedSurvey' className="validate browser-default" onChange={this.onChangeHandler} >
                                            <option value="" key="">Choose a test</option>
                                            {surveys? surveys.map((survey) => <option value={survey.id} key={survey.id}>{survey.name}</option>):null}
                                        </select>
                                    </div>
                                    {
                                        this.state.status == 'Live'?
                                        <div className="">
                                            {
                                            this.state.selectedSurvey&& 
                                            surveys.find(survey =>this.state.selectedSurvey == survey.id
                                                &&survey.SurveyVersions[0].status == "Design")?
                                            <div className="col s12">
                                                <h5> Submit and Complete the design of latest version </h5>
                                            </div>
                                            :
                                            <>
                                            <div className="col s12">
                                                <div style={{paddingBottom:10}}>
                                                <select
                                                    class="browser-default"
                                                    id="course"
                                                    onChange={this.handleChange}
                                                >
                                                    <option value="" disabled selected>
                                                    Select Subject
                                                    </option>
                                                    {this.props.course &&
                                                    this.props.course.map((course) =>
                                                        course.Semesters.map((sem) =>
                                                        sem.Courses.map((course) => (
                                                            <option value={course.id}>{course.courseName}</option>
                                                        ))
                                                        )
                                                    )}
                                                </select>
                                                </div>
                                                <div className="col s4">
                                                    <label>
                                                        <input className="with-gap" id="liveoption" name="liveoption" type="radio" value='upload' 
                                                        checked={this.state.liveoption=='upload'? true : false}
                                                        onChange={this.onChangeHandler}/>
                                                        <span>upload file</span>
                                                    </label>
                                                </div>
                                                <div className="col s4">
                                                    <label>
                                                        <input id="liveoption"className="with-gap" name="liveoption" type="radio" value='modify' 
                                                        checked={this.state.liveoption=='modify'? true : false}
                                                        onChange={this.onChangeHandler}/>
                                                        <span>modify on gui</span>
                                                    </label>
                                                </div>
                                                <div className="col s4">
                                                    <label>
                                                        <input className="with-gap" id="liveoption" name="liveoption" type="radio" value="versionControl"
                                                        checked={this.state.liveoption=='versionControl'? true : false}
                                                        onChange={this.onChangeHandler}/>
                                                        <span>Control existing versions</span>
                                                    </label>
                                                </div>
                                            </div>
                                            {this.state.liveoption == 'upload' && this.state.status == 'Live'?
                                            <div className="col s12">
                                                <input id="versionFile" type="file" name="file" accept=".xlsx " onChange={this.onFileChangeHandler} style={{margin:10}}/>
                                            </div>
                                            :
                                            null}
                                            </>
                                            }
                                            
                                        </div>
                                        :
                                            <div className="col s12">
                                                <h5>Select test and complete the design</h5>
                                            </div>
                                    }
                                    {
                                        surveyLoading?
                                        <div className="center center-align" style={{marginTop:20}}>
                                            <div className="preloader-wrapper active" style={{marginTop:20}}>
                                                <div className="spinner-layer spinner-green-only">
                                                <div className="circle-clipper left">
                                                    <div className="circle"></div>
                                                </div><div className="gap-patch">
                                                    <div className="circle"></div>
                                                </div><div className="circle-clipper right">
                                                    <div className="circle"></div>
                                                </div>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <div className="center center-align" style={{padding:180}}>
                                            <button  className="btn card-button" onClick={this.OnLiveSurvey}>SUBMIT</button> 
                                        </div> 
                                    }
                                    <div className="red-text">
                                        {surveyLoading? <p>Data not available</p> :auth.authError?<p>Not Authorised</p>:null}
                                    </div> 
                                    {/* </fieldset> */}
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>        
      );
    }
}

const mapStateToProps = (state,ownProps) => {
  console.log("State in design", state)
  return {
      auth:state.auth,
      uploadLoading:state.designer.uploadMasterLoading,
      uploadError:state.designer.uploadMasterError,
      uploadSuccess:state.designer.uploadMasterSuccess,
      surveyId:state.designer.surveyId,
      course: state.Hod.course,
      surveys:state.designer.surveys?state.designer.surveys:[],
      surveyLoading:state.designer.surveyLoading?state.designer.surveyLoading:null
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    UploadFile: (file, surveyName, description, course, token) => {
        dispatch(uploadFile(file, surveyName, description,course,token));
      },
    GetSurveys: (status,  token) => {
        dispatch(getSurveys(status,token));
      },
    LoadExistingSurvey: (data,  id) => {
        dispatch(loadExistingSurvey(data,id));
      },
      GetCourse: (deptId, token) => {
        dispatch(getCourses(deptId, token));
      },
      UploadNewVersion: (id, data, file, excel,course, token) => {
        dispatch(uploadNewVersion(id, data, file, excel,course, token));
      },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(DesignerDashboard);
