import React from 'react'

const SelectCard = ({name, value, handleChange}) => {
    return (
        <div>
        <div class="col s12 m3 l3">
        <div class="card horizontal" style={{width:300, borderRadius: 10}}>
          <div class="card-stacked">
            <div class="card-content">
            <select class="browser-default" onChange={handleChange}>
              <option value="" disabled selected>{name}</option>
              {value.map((value)=>(
                <option value={value.id}>{value.userName}</option>
              ))}
            </select>
            </div>
          </div>
        </div>
      </div>
        </div>
    )
}

export default SelectCard
