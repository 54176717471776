const initState = {
  users: null,
  loadingUsers: false,

  addUser: false,
  addUserId: null,
  addUserMessage: null,

  updateUser: false,
  updateUserId: null,
  updateUserMessage: null,

  deactiveUser: false,
  deactiveUserId: null,
  deactiveUserMessage: null,

  resetPassword: false,
  resetPasswordId: null,
  resetPasswordMessage: null,

  roles: null,
  loadingRoles: false,

  checks: null,
};

const AdminReducer = (state = initState, action) => {
  switch (action.type) {
    //fetch users
    case "USER_FETCH_LOADING":
      return {
        ...state,
        loadingUsers: true,
        users: null,
      };
    case "USER_FETCH_SUCCESS":
      return {
        ...state,
        loadingUsers: false,
        users: action.payload,
      };

    //add user
    case "ADD_USER_SUCCESS":
      return {
        ...state,
        addUser: true,
        addUserId: action.payload.id,
      };
    case "ADD_USER_FAILED":
      return {
        ...state,
        addUser: false,
        addUserMessage: action.payload,
      };
    //update user
    case "UPDATE_USER_SUCCESS":
      return {
        ...state,
        updateUser: true,
        updateUserId: action.payload.id,
      }
    case "UPDATE_USER_FAILED":
      return {
        ...state,
        updateUser: false,
        updateUserMessage: action.payload,
      }
    //deactive user
    case "DEACTIVATE_USER_SUCCESS":
      return {
        ...state,
        deactiveUser: true,
        deactiveUserId: action.payload.id,
      };
    case "DEACTIVATE_USER_FAILED":
      return {
        ...state,
        deactiveUser: false,
        deactiveUserMessage: action.payload,
      };
    //reset password
    case "RESET_PASSWORD_SUCCESS":
      return {
        ...state,
        resetPassword: true,
        resetPasswordId: action.payload.password,
        resetPasswordMessage: action.payload.message,
      };
    case "RESET_PASSWORD_FAILED":
      return {
        ...state,
        resetPassword: false,
        resetPasswordMessage: action.payload.message,
      }
    //fetch role
    case "ROLE_FETCH_LOADING":
      return {
        ...state,
        loadingRoles: true,
        roles: null,
      };
    case "ROLE_FETCH_SUCCESS":
      return {
        ...state,
        loadingRoles: false,
        roles: action.payload,
      };
    case "CHECK_USER":
      return {
        ...state,
        checks: action.payload,
      }
    case "INTERVIEWER_SYNCED":{
      return {
        ...state,
        addUser:false
      }
    }
    case  'RESET_ADD_SUCCESS':{
      return {
        ...state,
        addUser:false
      }
    }
    // case 'USER_FETCH_ERROR':
    //     return {
    //         ...state,
    //         loadingUsers:false,
    //         users:null
    //     }
    default:
      return state;
  }
};
export default AdminReducer;
