const createFormData = (file,field,body) => {
    const data = new FormData();
    // console.log("IN form data", file, body)
    data.append(field, file);
    // console.log("IN form data", data)
    Object.keys(body).forEach(key => {
      data.append(key, body[key]);
    });
    // console.log("data inside foreach",data);
  
    return data;
  };
  export default createFormData;