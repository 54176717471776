import React from 'react'

export default function NewOption({state, HandleChange, ind}) {
    return (
        <div className="col s12">
            <div className="input-field col s12">
                <select
                  className="browser-default"
                  type="text"
                  name="newOptionsData"
                  required
                  onChange={HandleChange}
                  value={state.newOptionsData[ind]?state.newOptionsData[ind].newOptionBodyType:""}
                  id={ind+"-newOptionBodyType"}
                >
                  <option value="" selected disabled defaultValue="">
                    Choose the option body type
                  </option>
                    <option value="text" key="1">
                      Text
                    </option>
                    <option value="image" key="2">
                      Image
                    </option>
                    <option value="audio" key="3">
                      Audio
                    </option>
                    <option value="video" key="4">
                      Video
                    </option>
                </select>
              </div>
              {
                  state.newOptionsData[ind]&&state.newOptionsData[ind].newOptionBodyType=="text"?
                  <div className="input-field col s12">
                    <input
                      type="text"
                      id={ind+"-option"}
                      name="newOptionsData"
                      onChange={HandleChange}
                      value={state.newOptionsData[ind].option}
                      required
                    />
                    <label>Option</label>
                  </div>
                  :null
              }
        </div>
    )
}
