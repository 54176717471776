import React, { PureComponent } from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import M from "materialize-css";
import Adduser from "../layout/ModalAdd";
import ResetPasswordModal from "../layout/ResetPasswordModal";
import ItemList from "../layout/ItemList";
import Navbar from "../layout/Navbar";
import {
  getUsers,
  addUser,
  getRoles,
  checkUser,
  updateUser,
  deactivateUser,
  resetPassword,
  ResetAddUser,
} from "../../store/actions/AdminAction";
import DeleteModal from "../layout/DeleteModal";

import "../stylesheets/admin.css";

class AdminDashboard extends PureComponent {
  state = {
    firstName: "",
    surName: null,
    role: "",
    email: "",
    password: null,
    contactNo: null,
    id: 0,
    check: false,
    addUser: false,
    selectedUser: "",
    selectedUserPassword: "",
    existsError: false,
    checked: {},
    photo:null,
    displayPhoto:null,
    query:null
  };

  componentDidMount = () => {
    var elems = document.querySelectorAll(".modal");
    var instances = M.Modal.init(elems, {
      preventScrolling: false,
      onOpenEnd:()=>{M.updateTextFields()},
      onCloseEnd: () => {
        this.setState({
          addUser: false,
          firstName: "",
          surName: "",
          role: null,
          email: "",
        });
      },
    });
    var elems = document.querySelectorAll(".chips");
    var instances = M.Chips.init(elems, {});
    var elems = document.querySelectorAll("input");
    var instances = M.updateTextFields(elems, {});
    var elems = document.querySelectorAll("select");
    var instances = M.FormSelect.init(elems, {});
    const user = this.props.auth.user;
    if (user) {
      this.props.GetUsers(this.props.auth.user.accessToken);
      this.props.GetRoles(this.props.auth.user.accessToken);
    }
    // console.log(user)
  };

  componentDidUpdate=()=>{
    if(this.props.addSuccess && this.props.auth.user){
      this.props.GetUsers(this.props.auth.user.accessToken);
      this.props.ResetAddUser()
    }
  }

  addUser = (e) => {
    this.setState({ addUser: true }, () => {});
  };

  handleChange = (e) => {
    this.setState({ ...this.state, [e.target.id]: e.target.value });
  };

  handleCheck = (e, id, index) => {
    this.setState({ check: !this.state.check });
    this.setState((previousState) => ({
      checked: {
        ...previousState.checked,
        [index]: !previousState.checked[index],
      },
    }));
    this.props.GetCheck(this.props.users, id);
  };
  OnSubmit = (e) => {
    e.preventDefault();
    var f = false;
    var mail = this.state.email;
    f = this.props.users.find(function (user) {
      return user.email == mail;
    });
    // console.log(f);
    if (f) {
      this.setState({ ...this.state, existsError: true });
    } else {
      const personData = {
        userName: this.state.firstName + " " + this.state.surName,
        roleId: this.state.role,
        email: this.state.email,
        password: this.state.password,
        contactNo: this.state.contactNo,
        photo: this.state.photo,
        // check: false,
      };
      this.setState({
        // users: [...this.state.users, personData],
        firstName: "",
        surName: "",
        role: null,
        email: "",
        id: 0,
        check: false,
        existsError: false,
        password: null,
        contactNo: null,
      });

      this.props.AddUsers(personData, this.props.auth.user.accessToken);
      this.props.GetUsers(this.props.auth.user.accessToken);

      var modal = document.getElementById("add-user-modal");
      var instance = M.Modal.getInstance(modal);
      instance.close();
    }
  };

  editUser = (e) => {
    e.preventDefault();
    this.setState({ selectedUser: e.target.id });
    const user = this.props.users.find(function (user) {
      return user.id == e.target.id;
    });
    console.log(user);
    this.setState({
      firstName: user.userName.split(" ").slice(0, -1).join(" "),
      surName: user.userName.split(" ").slice(-1).join(" "),
      role: user.Role.id,
      contactNo: user.contactNo,
      displayPhoto: user.photo?user.photo:null
    });
    var modal = document.getElementById("add-user-modal");
    var instance = M.Modal.getInstance(modal);
    instance.open();
  };

  onUpdate = (e) => {
    e.preventDefault();
    // var eUser = false;
    var id = this.state.selectedUser;
    // var index= this.state.users.findIndex((x) => x.id == id)
    const personData = {
      userName: this.state.firstName + " " + this.state.surName,
      roleId: this.state.role,
      // email: this.state.email,
      // password: this.state.password,
      contactNo: this.state.contactNo,
      photo:this.state.photo,
    };
    // var ModifiedUser = this.state.users.map((x) =>
    //   x.id == id ? personData : x
    // );
    // this.setState({ users: ModifiedUser });
    this.props.GetUpdate(personData, id, this.props.auth.user.accessToken);
    this.props.GetUsers(this.props.auth.user.accessToken);

    var modal = document.getElementById("add-user-modal");
    var instance = M.Modal.getInstance(modal);
    instance.close();
  };

  selectedUser = (e) => {
    e.preventDefault();
    // console.log(e.target.id)
    this.setState({ selectedUser: e.target.id });
    var modal = document.getElementById("delete-user-modal");
    var instance = M.Modal.getInstance(modal);
    instance.open();
  };

  deleteUser = (e) => {
    const idToRemove = this.state.selectedUser;
    const userActive = {
      isActive: false,
    };
    this.props.DeactivateUsers(
      userActive,
      idToRemove,
      this.props.auth.user.accessToken
    );
    this.props.GetUsers(this.props.auth.user.accessToken);

    var modal = document.getElementById("delete-user-modal");
    var instance = M.Modal.getInstance(modal);
    instance.close();
  };

  resetPassword = (e) => {
    e.preventDefault();
    this.props.ResetPassword(e.target.id, this.props.auth.user.accessToken);
    var modal = document.getElementById("reset-password-modal");
    var instance = M.Modal.getInstance(modal);
    instance.open();
  };

  HandlePhotoChange = (e) =>{
    this.setState({
      [e.target.id]:e.target.files[0]
    })
    const reader = new FileReader();
    reader.onload = (e => {
       this.setState({displayPhoto: e.target.result});          
    }).bind(this);
    reader.readAsDataURL(e.target.files[0]);
  }

  render() {
    const user = this.props.auth.user;
    const roles = this.props.roles;
    const { checked } = this.state;
    const checkedCount = Object.keys(checked).filter((key) => checked[key])
      .length;
    const disabled = checkedCount > 0;
    console.log("local state", this.state);
    console.log("local props", this.props);

    if (!user) return <Redirect to="/login" />;


    const query = this.state.query;
    const filteredUser = this.props.users? 
      query?
      this.props.users.filter((user) => {
          return user.userName.toLowerCase().includes(query.toLowerCase())
                ||user.Role.name.toLowerCase().includes(query.toLowerCase())
                ||user.email.toLowerCase().includes(query.toLowerCase())
      })
      :this.props.users
    : [];
    return (
      <div>
        <Navbar heading="Admin" user={user} />
        <Adduser
          HandleChange={this.handleChange}
          OnSubmit={this.OnSubmit}
          state={this.state}
          user={this.props.users}
          roles={roles}
          OnUpdate={this.onUpdate}
          PhotoChange={this.HandlePhotoChange}
        />
        <DeleteModal
          className="delete-modal"
          HandleDelete={this.deleteUser}
          users={this.state.users}
        />
        <ResetPasswordModal
          resetPasswordMessage={this.props.resetPasswordMessage}
          resetPasswordId={this.props.resetPasswordId}
        />
        <div className="row container" style={{ width: "90%" }}>
          <div className="row card">
            <div className="row list-item">
              <div className="col s2">
                <button
                  data-target="add-user-modal"
                  className="rounded-button modal-trigger"
                  onClick={this.addUser}
                >
                  <i className="material-icons left">add</i>Add User
                </button>
              </div>
              <div className="col s10 search-div">
                <div className="col s1">
                  <i className="material-icons right">search</i>
                </div>
                <div className="col s11 input-field search-box">
                  <input
                  className='serach-input'
                  id='query'
                  type='text'
                  onChange={this.handleChange}
                  />
                </div>
              </div>
            </div>
            <ItemList
              HandleCheck={this.handleCheck}
              checked={checked}
              disabled={disabled}
              users={this.state.check ? this.props.check : filteredUser}
              SelectedUser={this.selectedUser}
              EditUser={this.editUser}
              resetPassword={this.resetPassword}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    auth: state.auth,
    users: state.admin.users,
    roles: state.admin.roles,
    check: state.admin.checks,
    resetPasswordId: state.admin.resetPasswordId,
    resetPasswordMessage: state.admin.resetPasswordMessage,
    addSuccess: state.admin.addUser,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    GetUsers: (token) => {
      dispatch(getUsers(token));
    },
    AddUsers: (userInfo, token) => {
      dispatch(addUser(userInfo, token));
    },
    GetRoles: (token) => {
      dispatch(getRoles(token));
    },
    GetCheck: (user, id) => {
      dispatch(checkUser(user, id));
    },
    GetUpdate: (userInfo, userId, token) => {
      dispatch(updateUser(userInfo, userId, token));
    },
    DeactivateUsers: (userInfo, userId, token) => {
      dispatch(deactivateUser(userInfo, userId, token));
    },
    ResetPassword: (userId, token) => {
      dispatch(resetPassword(userId, token));
    },
    ResetAddUser:()=>{
      dispatch(ResetAddUser());
    }
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(AdminDashboard);
