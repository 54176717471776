import { combineReducers } from 'redux';
import authReducer from "./authReducer";
import AdminReducer from './AdminReducer';
import resourceReducer from "./resourceReducer";
import designerReducer from "./designerReducer";
import QualityReducer from "./QualityReducer";
import testReducer from "./TestReducer"
import alertReducer from './AlertReducer';
import TimeTableReducer from './TimeTableReducer';
import ProfessorReducer from './ProfessorReducer';
import HodReducer from './HodReducer'

const rootReducer = combineReducers({
    auth:authReducer,
    admin:AdminReducer,
    resource:resourceReducer,
    designer:designerReducer,
    quality: QualityReducer,
    test: testReducer,
    alert:alertReducer,
	TimeTable: TimeTableReducer,
    Professor: ProfessorReducer,
    Hod: HodReducer
});
export default rootReducer;