import React from "react";
import SecondaryLoader from './SecondaryLoader';

const ConfigureModal = ({generateRoutine,message,generateRoutineLoading,generateRoutineSuccess,flag}) => {
  console.log(message,generateRoutineLoading,generateRoutineSuccess)
  return (
    <div id="configure-modal" class="modal">
      <div className="row modal-content configure-modal">
        <div
          className="row modal-title configure-modal-title valign-wrapper"
          style={{ "background-color": "#bf750d", minHeight: 10 }}
        >
          <span className="configure-modal-name">Configure</span>
        </div>
        <div className="row">
          <div className="col s12">
            <form
              id="configure"
              //   onSubmit={}
              style={{ marginTop: 22 }}
            >
              <div
                className="row"
                style={{
                  height: 90,
                }}
              >
                <div className="col s12 m6 l8">
                  <input type="date" />
                </div>
                <div className="col s12 m6 l4">
                  <div class="switch" style={{marginTop: 15}}>
                    <label>
                      Off
                      <input type="checkbox" />
                      <span class="lever"></span>
                      On
                    </label>
                  </div>
                </div>
              </div>

              <div className="red-text center"></div>
            </form>
          </div>
          <div style={{marginLeft: 109}}>
            <button className="modal-close waves-effect waves btn" onClick={generateRoutine}>
              Generate Routine
            </button>
            {
              flag == true ?
              !generateRoutineLoading && generateRoutineSuccess
              ? 
              <div>
                <p style={{color:"limegreen",paddingLeft:"22%"}}>
                    <b>{message}</b>
                    <i class="material-icons Tiny" style={{color:"limegreen"}}>done_all</i>
                </p>
              </div>
              :
              <SecondaryLoader/>
              :null
            }
          </div>
        </div>
      </div>
      <div className="modal-footer">
        <button className="modal-close waves-effect waves btn-flat">
          CANCEL
        </button>
        <button
          type="submit"
          form="dashboard"
          className="waves-effect waves btn-flat"
        >
          SUBMIT
        </button>
      </div>
    </div>
  );
};

export default ConfigureModal;
