import React from 'react'

export default function LanguageModal({state, OnChange, FileChangeHandler, OnDownload, OnAdd, OnOtherChange}) {
    return (
        <div>
            <div id="language-modal" className="small-modal modal modal-fixed-footer">
                <div className='row modal-content'>
                    <div className="row modal-title valign-wrapper">
                        <span>Language</span>
                    </div>
                    <div className="row" style={{marginBottom:0}}> 
                        <div className="col s12">
                            <form onSubmit={OnAdd} id="addLanguage">
                                <div className="col s6">
                                    <label>
                                        <input className="with-gap" id="languageModal" name="liveoption" type="radio" value='upload' 
                                        checked={state.languageModal=='upload'? true : false}
                                        onChange={OnChange}/>
                                        <span>upload language file</span>
                                    </label>
                                </div>
                            </form>
                            <div className="col s6">
                                <label>
                                    <input className="with-gap" id="languageModal" name="liveoption" type="radio" value='download' 
                                    checked={state.languageModal=='download'? true : false}
                                    onChange={OnChange}/>
                                    <span>Download Excel</span>
                                </label>
                            </div>
                        </div>
                        <div className="row" style={{marginBottom:0}}>
                            {
                                state.languageModal == 'upload'?
                                    <div>
                                        <div className="col s12">
                                            <div className="col s3">
                                                <input placeholder="Code" required id="newLanguageCode" type="text" value={state.newLanguageCode} onChange={OnOtherChange} style={{margin:10}}/>
                                            </div>
                                            <div className="col s9">
                                                <input required id="languageFile" type="file" name="file" accept=".xlsx " onChange={FileChangeHandler} style={{margin:10}}/>
                                            </div>
                                        </div>
                                        <div className="col s12">
                                            <p>Language code should be same as the code in excel file</p>
                                        </div>
                                    </div>
                                :
                                    <p className="col s12 right right-align">
                                        <button className="btn-floating btn-large waves-effect waves-light" 
                                        onClick={OnDownload} style={{backgroundColor:'black', color:'white'}}>
                                            <i className="material-icons">file_download</i>
                                            {/* <span style={{padding:15}} className="right right-align">Performa</span> */}
                                        </button>
                                    </p>
                            }
                        </div>
                    </div>
                </div>
                <div className="modal-footer">
                    <button className="modal-close waves-effect waves btn-flat">CANCEL</button>
                    {state.languageModal == 'upload'?
                    <button type='submit' form="addLanguage" className="waves-effect waves btn-flat">upload</button>
                    :null}
                    {/* <a href="#!" className="modal-close waves-effect waves-green btn-flat">Cancel</a> */}
                </div>
            </div>
        </div>
    )
}


