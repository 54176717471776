import React from 'react'

export default function ExtraOperands({questions, HandleChange, state, deleteOp, id}) {
    return (
        <div>
            <p>
                <span><label>Operator</label></span>
                {/* <span style={{marginLeft:10}}><i className="material-icons tiny" id={'delete-'+id} onClick={deleteOp}>cancel</i></span> */}
            </p>
            <div className="col s12">
                <select className="browser-default logic-select" id={"operator-"+id}  onChange={HandleChange}>
                    <option disabled selected>Choose logic</option>
                    <option value="+">+</option>
                    <option value="-">-</option>
                    <option value='*'>*</option>
                    <option value='/'>/</option>
                </select>
            </div>  
            <div className="col s6">
            <select className="browser-default logic-select" id={"logicOperandSec-"+id} onChange={HandleChange}>
                <option disabled selected>Choose section</option>
                {
                Object.keys(questions.sections).map(secId =>
                    <option value={secId} >{"Sec. " + secId}</option>
                )
                }
            </select>
            </div>
            <div className="col s6">
                <span>
                    <select className="browser-default logic-select" id={"logicOperandQ-"+id} onChange={HandleChange}>
                    <option disabled selected>Choose Question</option>
                    {
                        state.extraOperand[id]&&state.extraOperand[id].logicOperandSec?
                        Object.keys(questions.sections[state.extraOperand[id].logicOperandSec].questions).map(qId =>
                            questions.sections[state.extraOperand[id].logicOperandSec]
                            .questions[qId].questionType=='NUMERIC'?
                            <option value={qId} >{"Q. " + qId}</option>
                            :null
                        )
                        :
                        null
                        }
                    </select>   
                </span>
            </div>
        </div>
    )
}
