import { backendUrl } from "../../config/api";

//fetch completed surveys
export const getSurveys = (status, token) => {
    return (dispatch) => {
      fetch(backendUrl + "/survey?status="+(status?status:"Live"), {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }).then((response) =>
        response.json().then((data) => {
          if (response.status == 200) {
            dispatch({
              type: "LIVE_SURVEY_FETCH_SUCCESS",
              payload: data.data,
            });
          } else if (response.status == 403) {
            dispatch({
              type: "AUTHENTICATION_ERROR",
            });
          } else {
            dispatch({
              type: "LIVE_SURVEY_FETCH_ERROR",
            });
          }
        })
      );
    };
  };


//fetch survey stat
export const getSurveyStat = (surveyId, token) => {
  return (dispatch) => {
    fetch(backendUrl + "/survey/" + surveyId + "/stat", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((response) =>
      response.json().then((data) => {
        if (response.status == 200) {
          dispatch({
            type: "SURVEY_STAT_FETCH_SUCCESS",
            payload: data.data,
          });
        } else if (response.status == 403) {
          dispatch({
            type: "AUTHENTICATION_ERROR",
          });
        } else {
          dispatch({
            type: "SURVEY_STAT_FETCH_ERROR",
          });
        }
      })
    );
  };
};

//Get Response for a survey
export const getResponse = (surveyInfo, surveyVersionId, token) => {
  return (dispatch) => {
    dispatch({
      type: "RESPONSE_SURVEY_LOADING",
    });
    fetch(backendUrl + "/survey/" + surveyVersionId + "/responses", {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(surveyInfo),
    }).then((response) =>
      response
        .json()
        .then((data) => {
          if (response.status === 200) {
            dispatch({
              type: "RESPONSE_SURVEY_SUCCESS",
              payload: data,
            });
          } else if (response.status === 403) {
            dispatch({
              type: "AUTHENTICATION_ERROR",
            });
          } else {
            dispatch({
              type: "RESPONSE_SURVEY_FAILED",
            });
          }
        })
        .catch((err) => {
          dispatch({
            type: "RESPONSE_SURVEY_FAILED",
          });
        })
    );
  };
};

//edit answer
export const saveAnswer = (responseVersion,responseVersionId, token)=>{
  return (dispatch) =>{
    fetch(backendUrl+"/response/"+responseVersionId+"/update", {
      method: "put",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(responseVersion)
    }).then((response) => 
      response.json().then((data) => {
        if(response.status === 200) {
          dispatch({
            type: "EDIT_ANSWER_SUCCESS",
            payload: data
          });
        } else if (response.status === 403) {
          dispatch({
            type: "AUTHENTICATION_ERROR",
          });
        } else {
          dispatch({
            type: "EDIT_ANSWER_FAILED",
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: "EDIT_ANSWER_FAILED",
        });
      })
    )
  }
}

export const changeFlag = (flagCheck,responseVersionId, token)=>{
  return (dispatch) =>{
    fetch(backendUrl+"/response/"+responseVersionId+"/update", {
      method: "put",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(flagCheck)
    }).then((response) => 
      response.json().then((data) => {
        if(response.status === 200) {
          dispatch({
            type: "CHANGE_FLAG_SUCCESS",
            payload: data
          });
        } else if (response.status === 403) {
          dispatch({
            type: "AUTHENTICATION_ERROR",
          });
        } else {
          dispatch({
            type: "CHANGE_FLAG_FAILED",
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: "CHANGE_FLAG_FAILED",
        });
      })
    )
  }
}

export const checkUser=(user,id) => {
  return(dispatch) => {
      // console.log(id)
      // console.log(user)
      var index = user.findIndex((x) => x.User.id === id);
      let newArray = [...user];
      newArray[index] = { ...newArray[index], check: newArray[index].check ? !newArray[index].check: true };
      dispatch({type:'CHECK_USER', payload:newArray})
  }
}

export const pushBack=(userId, resVerId, token) => {
  return (dispatch) => {
    fetch(backendUrl + "/user/" + userId + "/responseVersion/"+resVerId+"/push", {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      }
    }).then((response) =>
      response
        .json()
        .then((data) => {
          if (response.status === 200) {
            dispatch({
              type: "PUSH_BACK_SUCCESS",
              payload: data,
            });
          } else if (response.status === 403) {
            dispatch({
              type: "AUTHENTICATION_ERROR",
            });
          } else {
            dispatch({
              type: "PUSH_BACK_FAILED",
            });
          }
        })
        .catch((err) => {
          dispatch({
            type: "PUSH_BACK_FAILED",
          });
        })
    );
  }
}
//reject or approve
export const RejectOrApprove = (status, responseVersionId, token) => {
  return (dispatch) => {
    dispatch({type: "REJECT_APPROVE_LOADING"});
    fetch(backendUrl + "/response/" + responseVersionId + "/update", {
      method: "put",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(status),
    }).then((response) =>
      response
        .json()
        .then((data) => {
          if (response.status === 200) {
            dispatch({
              type: "REJECT_APPROVE_SUCCESS",
              payload: data,
            });
          } else if (response.status === 403) {
            dispatch({
              type: "AUTHENTICATION_ERROR",
            });
          } else {
            dispatch({
              type: "REJECT_APPROVE_FAILED",
            });
          }
        })
        .catch((err) => {
          dispatch({
            type: "REJECT_APPROVE_FAILED",
          });
        })
    );
  };
};

export const loadSurveyVersion = (sid, vid, token)=>{
  return (dispatch) =>{
      dispatch({type:'SURVEY_VERSION_CHANGE_LOADING'})         
      fetch(backendUrl
      +'/survey/'+sid+'/version/'+vid, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }).then(response => response.json().then(data=>{
          if (response.status == 200) {
          dispatch({
              type:'SURVEY_VERSION_CHANGE_SUCCESS', data:data.data
          });
       }else{
         dispatch({
           type: 'SURVEY_VERSION_CHANGE_ERROR',
         });
       }
      }))

  }
}

export const loadResponseVersion = (rid, vid, token)=>{
  return (dispatch) =>{
      dispatch({type:'RESPONSE_VERSION_CHANGE_LOADING'})         
      fetch(backendUrl
      +'/response/'+rid+'/version/'+vid, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }).then(response => response.json().then(data=>{
          if (response.status == 200) {
          dispatch({
              type:'RESPONSE_VERSION_CHANGE_SUCCESS', data:data.data
          });
       }else{
         dispatch({
           type: 'RESPONSE_VERSION_CHANGE_ERROR',
         });
       }
      }))

  }
}

export const getVersions = (sid, token)=>{
  return (dispatch) =>{
      dispatch({type:'VERSION_FETCH_LOADING'})         
      fetch(backendUrl
      +'/survey/'+sid+'/versions', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }).then(response => response.json().then(data=>{
          if (response.status == 200) {
          dispatch({
              type:'VERSION_FETCH_SUCCESS', data:data.data.SurveyVersions
          });
       }else{
         dispatch({
           type: 'VERSION_FETCH_ERROR',
         });
       }
      }))

  }
}

export const actionStateReset = ()=>{
  return (dispatch) =>{
      dispatch({type:'ACTION_STATE_RESET'})
  }
}

export const resetDownloadStatus = ()=>{
  return (dispatch) =>{
      dispatch({type:'DOWNLOAD_STATUS_RESET'})
  }
}

export const resetEditStatus = ()=>{
  return (dispatch) =>{
      dispatch({type:'EDIT_STATUS_RESET'})
  }
}
export const pushBackStatusReset = ()=>{
  return (dispatch) =>{
      dispatch({type:'PUSH_BACK_STATUS_RESET'})
  }
}

export const saveFilters = (filter)=>{
  return (dispatch) =>{
      dispatch({type:'FILTER_SAVE_SUCCESS', payload:filter})
  }
}

export const getResponseExcel = (rvid, token) => {
  return dispatch => {
    fetch(backendUrl +'/responseVersion/'+rvid+'/excel',{
      method: "post",
      headers:{
        'Authorization': `Bearer ${token}`
      }
    })
    .then(response =>
      response.blob().then(blob => {
        if (response.status === 500) {
          dispatch({
            type: "RESPONSE_EXCEL_ERROR",
          });
        } else if (response.status === 200) {
          dispatch({
            type: "RESPONSE_EXCEL_SUCCESS",
          });
          const data = window.URL.createObjectURL(blob);
          var link = document.createElement('a');
          link.href = data;
          link.download="RESPONSE_"+rvid+".xlsx";
          link.click();
          setTimeout(function(){
            // For Firefox it is necessary to delay revoking the ObjectURL
            window.URL.revokeObjectURL(data);
          }, 100);
        } 
        else if (response.status === 403) {
          dispatch({
            type: "AUTHENTICATION_ERROR"
          });
        }
         else {
          dispatch({
            type: "RESPONSE_EXCEL_ERROR"
          });
        }
      })
    );
  };
};

//Get Response for a survey
export const getTestResponse = (surveyInfo, surveyVersionId, token) => {
  return (dispatch) => {
    dispatch({
      type: "TEST_RESPONSE_SURVEY_LOADING",
    });
    fetch(backendUrl + "/surveyVersion/" + surveyVersionId + "/testResponse"+
    "?startDate="+surveyInfo.startDate+
    "&endDate="+surveyInfo.endDate
    , {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((response) =>
      response
        .json()
        .then((data) => {
          if (response.status === 200) {
            dispatch({
              type: "TEST_RESPONSE_SURVEY_SUCCESS",
              payload: data,
            });
          } else if (response.status === 403) {
            dispatch({
              type: "AUTHENTICATION_ERROR",
            });
          } else {
            dispatch({
              type: "TEST_RESPONSE_SURVEY_FAILED",
            });
          }
        })
        .catch((err) => {
          dispatch({
            type: "TEST_RESPONSE_SURVEY_FAILED",
          });
        })
    );
  };
};

export const getTestResponseExcel = (rid, token) => {
  return dispatch => {
    fetch(backendUrl +'/testResponse/'+rid+'/excel',{
      method: "post",
      headers:{
        'Authorization': `Bearer ${token}`
      }
    })
    .then(response =>
      response.blob().then(blob => {
        if (response.status === 500) {
          dispatch({
            type: "TEST_RESPONSE_EXCEL_ERROR",
          });
        } else if (response.status === 200) {
          dispatch({
            type: "TEST_RESPONSE_EXCEL_SUCCESS",
          });
          const data = window.URL.createObjectURL(blob);
          var link = document.createElement('a');
          link.href = data;
          link.download="TEST_RESPONSE_"+rid+".xlsx";
          link.click();
          setTimeout(function(){
            // For Firefox it is necessary to delay revoking the ObjectURL
            window.URL.revokeObjectURL(data);
          }, 100);
        } 
        else if (response.status === 403) {
          dispatch({
            type: "AUTHENTICATION_ERROR"
          });
        }
         else {
          dispatch({
            type: "TEST_RESPONSE_EXCEL_ERROR"
          });
        }
      })
    );
  };
};


export const calculateResult = (rid, token) => {
  return dispatch => {
    dispatch({
      type: "GENERATE_RESULT_LOADING",
    });
    fetch(backendUrl +'/responseVersion/'+rid+'/result',{
      method: "post",
      headers:{
        'Authorization': `Bearer ${token}`
      }
    })
    .then(response =>
      response.json().then(data => {
        if (response.status === 500) {
          dispatch({
            type: "GENERATE_RESULT_ERROR",
          });
        } else if (response.status === 200) {
          dispatch({
            type: "GENERATE_RESULT_SUCCESS",
          });
        } 
        else if (response.status === 403) {
          dispatch({
            type: "AUTHENTICATION_ERROR"
          });
        }
         else {
          dispatch({
            type: "GENERATE_RESULT_ERROR"
          });
        }
      })
    );
  };
};

