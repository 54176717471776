
const initState = {
    authError:null,
    authLoading:false,
    user:null,
    roles: null,
    loadingRoles: false,
}

const authReducer=(state=initState,action)=>{
    switch(action.type){
        case 'AUTH_LOADING':
            return {
                ...state,
                authError:null,
                authLoading:true,

            }
        case 'LOGIN_ERROR':
            return {
                ...state,
                authError:'Login Failed',
                authLoading:false,
            }
        case 'LOGIN_SUCCESS':
            
                return {
                    ...state,
                    authError:null,
                    authLoading:false,
                    user:action.user,

                }
        case 'SIGNOUT_SUCCESS':
                return initState;
        case 'AUTHENTICATION_ERROR':
                return initState
        case 'TOKEN_REFRESH_SUCCESS':
                return {
                    ...state,
                    user:{...state.user, accessToken:action.payload}
                }
        case 'TOKEN_REFRESH_ERROR':
                return initState
        case 'CHANGE_PASSWORD_SUCCESS':
                return {
                    ...state,
                    user:{...state.user, changePassword:true,changePasswordError:false}
                }
        case 'CHANGE_PASSWORD_WRONG_PASSWORD':
            return {
                ...state,
                user:{...state.user, changePasswordError:true}
            }
        //fetch role
        case "ROLE_FETCH_LOADING":
            return {
            ...state,
            loadingRoles: true,
            roles: null,
            };
        case "ROLE_FETCH_SUCCESS":
            return {
            ...state,
            loadingRoles: false,
            roles: action.payload,
            };
        case "CHECK_USER":
            return {
            ...state,
            checks: action.payload,
            }
        default:
            return state;
        
    }
}
export default authReducer;