import React from 'react'

export const PublishModal = () => {
    return (
        <div id="publish-modal" class="modal">
            <div className="row modal-content publish-modal">
                <div className="row modal-title publish-modal-title valign-wrapper"
                style={{ "background-color": "#5d1b8f", minHeight: 10 }}>
                    <span>Publish Notice</span>
                </div>
                <div className="row">
                    <div className="col s3 m3 l3">
                    <p>
                    <label>
                      <input class="with-gap" name="group3" type="radio"  />
                      <span>COVID</span>
                    </label>
                  </p>
                    </div>
                    <div className="col s3 m3 l3">
                    <p>
                    <label>
                      <input class="with-gap" name="group3" type="radio"  />
                      <span>Exam</span>
                    </label>
                  </p>
                    </div>
                    <div className="col s3 m3 l3">
                    <p>
                    <label>
                      <input class="with-gap" name="group3" type="radio"  />
                      <span>Result</span>
                    </label>
                  </p>
                    </div>
                    <div className="col s3 m3 l3">
                    <p>
                    <label>
                      <input class="with-gap" name="group3" type="radio"  />
                      <span>PTA</span>
                    </label>
                  </p>
                    </div>
                </div>
                <div className="row">
                    <form class="col s12">
                        <div class="row">
                        <div class="input-field col s12">
                            <textarea id="textarea1" class="materialize-textarea"></textarea>
                            <label for="textarea1">Notice</label>
                        </div>
                        </div>
                    </form>
                </div>
                <div className="modal-footer">
                    <button className="modal-close waves-effect waves btn-flat">
                    CANCEL
                    </button>
                    <button
                    type="submit"
                    form="dashboard"
                    className="waves-effect waves btn-flat"
                    >
                    SUBMIT
                    </button>
                </div>
            </div>
        </div>
    )
}
