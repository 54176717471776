import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import M from "materialize-css";
import Navbar from "../layout/Navbar";
import AttendanceRequestModal from "../layout/AttendanceRequestModal";

export class FacultyAttendance extends Component {
  state = {
    user: [
      {
        date: "Ram Sen",
        inTime: 101,
        outTime: 39,
        status: "late",
      },
      {
        date: "Ram Sen",
        inTime: 101,
        outTime: 39,
        status: "onTime",
      },
    ],
  };
  componentDidMount = () => {
    var elems = document.querySelectorAll(".modal");
    var instances = M.Modal.init(elems, {
      preventScrolling: true,
    });
  };
  render() {
    const user = this.props.auth.user;
    if (!user) return <Redirect to="/login" />;
    return (
      <div>
        <Navbar heading="Teacher" user={user} />
        <AttendanceRequestModal />
        <div
          className="row"
          style={{
            height: 90,
            backgroundColor: "#5d1b8f",
            borderBottomLeftRadius: 55,
            // borderBottomRightRadius: 55,
          }}
        >
          <div style={{ marginLeft: 90 }}>
            <div
              className="col s4 m4 l4"
              style={
                {
                  // "margin-top": 20,
                }
              }
            >
              <label for="birthday">From:</label>
              <input type="date" id="birthday" name="birthday" />
            </div>
            <div
              className="col s4 m4 l4"
              style={
                {
                  // "margin-top": 20,
                }
              }
            >
              <label for="birthday">To:</label>
              <input type="date" id="birthday" name="birthday" />
            </div>
          </div>
        </div>
        <div className="row" style={{ marginLeft: 170 }}>
          <div className="col s3 m3 l3">
            <b>Date</b>
          </div>
          <div className="col s2 m2 l2">
            <b>In Time</b>
          </div>
          <div className="col s2 m2 l2">
            <b>Out Time</b>
          </div>
          <div className="col s2 m2 l2">
            <b>Status</b>
          </div>
          <div className="col s2 m2 l2"></div>
        </div>
        <div>
          <ul
            className="collection"
            style={{
              backgroundColor: "rgb(255,255,255,0.5)",
              paddingTop: 15,
              paddingLeft: 70,
              // width:"60%",
              marginLeft: 90,
              marginRight: 90,
              boxShadow: "2px 20px 40px",
              height: "50vh",
              overflow: "auto",
            }}
          >
            {this.state.user.map((user) => (
              <div className="row">
                <div className="col s2 m2 l2">
                  <b>{user.date}</b>
                </div>
                <div
                  className="col s1 m1 l1 offset-s1 offset-m1 offset-l1"
                  style={{ paddingLeft: 48 }}
                >
                  <b>{user.inTime}</b>
                </div>
                <div
                  className="col s2 m2 l2 offset-s1 offset-m1 offset-l1"
                  style={{ paddingLeft: 74 }}
                >
                  <b>{user.outTime}</b>
                </div>
                <div className="col s1 m1 l1" style={{ paddingLeft: 79 }}>
                  {user.status == "onTime" ? (
                    <i class="material-icons" style={{ color: "limegreen" }}>
                      check_circle
                    </i>
                  ) : (
                    <i class="material-icons" style={{ color: "#c9c306" }}>
                      error
                    </i>
                  )}
                </div>
                {user.status == "late" ? (
                  <div className="col s1 m1 l1" style={{ paddingLeft: 135 }}>
                    <a
                      className="btn modal-trigger"
                      data-target="attendance-request-modal"
                    >
                      Request
                    </a>
                  </div>
                ) : null}
              </div>
            ))}
          </ul>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(FacultyAttendance);
