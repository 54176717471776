import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router";
import Navbar from "../layout/Navbar";
import "../stylesheets/common.css";

export class FacultyLeave extends Component {
  state = {
    leaveType: "",
    leaves:[
        {
            date:"20/01/2021",
            duration: 7,
            subject: "Brother's marriage",
            status:"pending"
        },
        {
            date:"12/08/2020",
            duration: 2,
            subject: "Tour with family",
            status: "cancel"
        },
        {
            date:"19/02/2020",
            duration: 1,
            subject: "Sick leave",
            status:"approved"
        },
    ]
  };

  handleChange=(e)=>{
    this.setState({leaveType: e.target.id})
  }

  render() {
    const user = this.props.auth.user;
    if (!user) return <Redirect to="/login" />;
    return (
      <div>
        <Navbar heading="Admin" user={user} />
        <div className="row">
            <div className="left-bar row left">
                <div className="col s12 m12 l12">
                <div className="row">
                <div className="col s3 m3 l3"><b>From</b></div>
                <div className="col s2 m2 l2"><b>Days</b></div>
                <div className="col s3 m3 l3"><b>Subject</b></div>
                <div className="col s2 m2 l2"><b>Status</b></div>
            </div>
            {
                this.state.leaves.map((leave)=>(
                    <div className="row">
                        <div className="col s3 m3 l3">{leave.date}</div>
                        <div className="col s2 m2 l2">{leave.duration}</div>
                        <div className="col s3 m3 l3">{leave.subject}</div>
                        <div className="col s2 m2 l2"
                        style={
                                leave.status == "pending" ? {color: "#ccc502"} :
                                leave.status == "approved" ? {color: "#0d751b"} : 
                                leave.status == "cancel" ? {color: "red"} : null
                              }
                        >{leave.status}</div>
                    </div>
                ))
            }
                </div>
            </div>
          <div className="col s8 m8 l8">
            <div className="container faculty_leave">
              <div className="facultyleave">
                <form>
                  <div className="row">
                    <div className="col s6 m6 l6">
                      <p>
                        <label>
                          <input class="with-gap" name="group1" id="fullday" type="radio" onChange={this.handleChange} />
                          <span>Full Day</span>
                        </label>
                      </p>
                    </div>
                    <div className="col s6 m6 l6">
                      <p>
                        <label>
                          <input class="with-gap" name="group1" id="halfday" type="radio" onChange={this.handleChange} />
                          <span>Half Day</span>
                        </label>
                      </p>
                    </div>
                  </div>
                  {this.state.leaveType == "halfday" ? (
                    <div>
                      <label for="birthday">Date:</label>
                      <input type="date" id="birthday" name="birthday" />
                    </div>
                  ) : (
                    <div className="row">
                      <div className="col s6 m6 l6">
                        <label for="birthday">From:</label>
                        <input type="date" id="birthday" name="birthday" />
                      </div>
                      <div className="col s6 m6 l6">
                        <label for="birthday">To:</label>
                        <input type="date" id="birthday" name="birthday" />
                      </div>
                    </div>
                  )}
                  <div className="row">
                    <form class="col s12 m12 l12">
                      <div class="row">
                        <div class="col s12 m12 l12">
                        Leave Subject:
                          <div class="input-field inline">
                            <input
                              id="email_inline"
                              type="email"
                              class="validate"
                            />
                          </div>
                        </div>
                        <div class="input-field col s12">
                          <textarea
                            id="textarea1"
                            className="textarea"
                            placeholder="Write your reason for Leave..."
                          ></textarea>
                        </div>
                      </div>
                    </form>
                  </div>
                  <div>
                    <a className="btn right">Request</a>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(FacultyLeave);
