import { backendUrl } from "../../config/api";
import createFormData from "../../utils/helper";
import getShuffelededQuestionnaire from "../../utils/questionRandomizer";

export const testSurvey = (sid,svid,token)=> {
    return (dispatch) => {
      dispatch({type: 'TEST_SURVEY_QUESTION_FETCH_LOADING'});
      fetch(backendUrl +"/surveyVersion/"+svid+"/test", {
        // headers: {
        //   Authorization: `Bearer ${token}`,
        // },
      }).then((response) =>
        response.json().then(async (data) => {
          if (response.status == 200) {
            console.log("data", data);
            var randomData = await getShuffelededQuestionnaire( data.data.questionnaire)
            data.data.questionnaire = randomData
            dispatch({
              type: "TEST_SURVEY_QUESTION_FETCH_SUCCESS",
              payload: data.data,
            });
          } else if (response.status == 403) {
            dispatch({
              type: "AUTHENTICATION_ERROR",
            });
          } else {
            dispatch({
              type: "TEST_SURVEY_QUESTION_FETCH_ERROR",
            });
          }
        })
      );
  };
  };

  export const saveAnswer = (answer, sid, qid)=>{
    return (dispatch) =>{
        dispatch({type:'SAVE_ANSWER', payload:answer, pos:{qid, sid}})
    }
  }

  export const prevQues = (question, sectionId, questionId, prevSec, prevQ) => {
    return (dispatch) => {
      const prev= {
        ...question.sections[sectionId].questions[questionId].questionFlow,
        prev: {sectionId: prevSec, questionId: prevQ}
      };
      dispatch({type:'PREV_QUES', payload:prev});
    };
  };

  export const getLanguages = (svid, token)=>{
    return (dispatch) =>{
        dispatch({type:'VERSION_LANGUAGES_LOADING'})         
        fetch(backendUrl
        +'/surveyVersion/'+svid+'/languages/test', {
          // headers: {
          //   Authorization: `Bearer ${token}`,
          // },
        }).then(response => response.json().then(data=>{
            if (response.status == 200) {
            dispatch({
                type:'VERSION_LANGUAGES_SUCCESS', data:data.data
            });
         }else{
           dispatch({
             type: 'VERSION_LANGUAGES_ERROR',
           });
         }
        }))
  
    }
  }

  export const uploadFile = (file)=> {
    return (dispatch) => {
      dispatch({type: 'LOADING_TEST_ANSWER_UPLOAD'});
      fetch(backendUrl + "/upload/test", {
        method: 'post',
        body:createFormData(file,"file", {}),
        // headers: {
        //   'Authorization': `Bearer ${token}`
        // },
      })
      .then(response => {
        response.json().then((data)=>{
            if (response.status === 200) {
            dispatch({
              type: 'UPLOAD_TEST_ANSWER_SUCCESSFUL',
              payload:data.url
            });
          }
          else if (response.status === 403 || response.status === 401) {
            dispatch({
              type: "AUTHENTICATION_ERROR"
            });
          }
          else {
            dispatch({
              type: 'UPLOAD_TEST_ANSWER_ERROR',
              data:data
            });
          }
        })
      }
    )
    .catch(response => {
      dispatch({
        type: 'UPLOAD_ERROR',
      });
    })
  };
  };

  export const submitAnswer = (response, svid) => {
    return (dispatch) => {
      fetch(backendUrl +"/surveyVersion/"+svid+"/response/test", {
        method: "post",
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(response)
      }).then(response =>
        response.json().then(data => {
         if (response.status === 200) {
            dispatch({
              type: "SUBMIT_TEST_SUCCESS",
              payload: data
            });
          } 
          else if (response.status === 403) {
            dispatch({
              type: "AUTHENTICATION_ERROR"
            });
          }
           else {
            dispatch({
              type: "SUBMIT_TEST_FAILED"
            });
          }
        }).catch((err)=>{
          dispatch({
            type: "SUBMIT_TEST_FAILED"
          });
        })
      );
    }
  }