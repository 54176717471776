import React from "react";

const StudentReportGeneration = () => {
  return (
    <div id="report-modal" class="modal">
      <div
        className="row modal-title configure-modal-title valign-wrapper"
        style={{ "background-color": "#d12ad4", minHeight: 10 }}
      >
        <span className="configure-modal-name">Generate Report</span>
      </div>
      <div className="row">
        <div className="col s12 m6 l4">
          <select class="browser-default">
            <option value="" disabled selected>
              Semester
            </option>
            <option value="1">Option 1</option>
            <option value="2">Option 2</option>
            <option value="3">Option 3</option>
          </select>
        </div>
        <div className="col s12 m6 l4">
          <select class="browser-default">
            <option value="" disabled selected>
              Section
            </option>
            <option value="1">Option 1</option>
            <option value="2">Option 2</option>
            <option value="3">Option 3</option>
          </select>
        </div>
        <div className="col s12 m6 l4">
          <select class="browser-default">
            <option value="" disabled selected>
              Student
            </option>
            <option value="1">Option 1</option>
            <option value="2">Option 2</option>
            <option value="3">Option 3</option>
          </select>
        </div>
      </div>
      <div style={{ marginLeft: 109 }}>
        <button className="modal-close waves-effect waves btn">
          Generate Report
        </button>
      </div>
      <div className="modal-footer">
        <button className="modal-close waves-effect waves btn-flat">
          CANCEL
        </button>
      </div>
    </div>
  );
};

export default StudentReportGeneration;
