import React from "react";

function SecondaryLoader() {
  return (
    <div className="secondaryLoader">
    <div class="dash uno"></div>
    <div class="dash dos"></div>
    <div class="dash tres"></div>
    <div class="dash cuatro"></div>
    </div>
  );
}

export default SecondaryLoader;
