import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import ReactPlayer from "react-player";
import M from "materialize-css";
import Navbar from "../layout/Navbar";
import QuestionListItem from "./QcQuestionList";
import SpecificQuestion from "./SpecificQuestionBlock";
import { getSurveys, RejectOrApprove, pushBack,saveAnswer, changeFlag, getResponseExcel, resetDownloadStatus, resetEditStatus, getResponse, actionStateReset, pushBackStatusReset, calculateResult } from "../../store/actions/QualityAction";
import DeleteModal from "../layout/DeleteModal";
import PushbackModal from "../layout/PushbackModal";
import "../stylesheets/qcResponse.css";
import EditQcAnswer from "../layout/EditQcAnswer";
import moment from 'moment'
import { resetAlert, setAlert } from "../../store/actions/MessageAction";
import {formatDate } from "../../utils/qcHelper";

export class SpecificResponse extends Component {
  state = {
    id: "",
    check: false,
    survey: {},
    questions: {},
    responses: {},
    selectedQ: null,
    selectedSec: null,
    checked: {},
    rejORapp: "",
    responseId: null,
    sectionId: null,
    questionId: null,
    responseVersion: {},
    reschk: false,
    responseVersionId: null,
    input: "",
    optionId: "",
    multiple:{},
    mix:{multiple:{}, single:{}},
    grid:{},
    ranks:{},
    flag: false,
    svPosition:null
  };

  componentDidMount = () => {
    var elems = document.querySelectorAll(".modal");
    var instances = M.Modal.init(elems, { preventScrolling: false, onOpenEnd:()=>{M.updateTextFields()}, onCloseEnd:()=>{this.props.ResetAlert()}});
    var elems = document.querySelectorAll('.tooltipped');
    var instances = M.Tooltip.init(elems, {});
    if(this.props.auth.user){
    this.props.GetSurveys("Live", this.props.auth.user.accessToken);
    var responses=  this.props.responses
    const survey = this.props.survey.find(
      (x) => x.id == parseInt(this.props.sid)
    );
    var svPosition=null
    if(survey)
    for(var j=0;j<survey.SurveyVersions.length;j++){
      if(Number(this.props.vid) == Number(survey.SurveyVersions[j].id)){
          svPosition=j
          break
        }
    }
    console.log("line 45", survey, this.props.vid)
    this.setState({ survey: survey, responseVersion: responses.ResponseVersions['0'], svPosition:svPosition});
    }
  };

  componentDidUpdate=()=>{
    if(this.props.actionSuccess){
      this.props.ResetActionStatus();
      console.log("CALLING UPDATE");
      if(this.props.filter){
        const surveyInfo = {
          startDate: formatDate(this.props.filter.startDate),
          endDate: formatDate(this.props.filter.endDate),
        };
        this.props.GetResponse(
          surveyInfo,
          this.props.filter.versionId,
          this.props.auth.user.accessToken
        );
      }
      this.props.history.push('/qc/'+this.props.auth.user.id+'/dashboard')
    }
    if(this.props.downloadSuccess){
      this.props.SetAlert('success', "Response downloaded");
      this.props.ResetDownloadStatus();
    }
    if(this.props.editError){
      this.props.SetAlert('error', "Response edit error");
      this.props.ResetEditStatus();
    }
    if(this.props.editSuccess){
      this.props.SetAlert('success', "Response edit success");
      this.props.ResetEditStatus();
      console.log("CALLING UPDATE");
      if(this.props.filter){
        const surveyInfo = {
          startDate: formatDate(this.props.filter.startDate),
          endDate: formatDate(this.props.filter.endDate),
        };
        this.props.GetResponse(
          surveyInfo,
          this.props.filter.versionId,
          this.props.auth.user.accessToken
        );
      }
    }
    if(this.props.pushBackSuccess){
      this.props.SetAlert('success', "Pushback success");
      this.props.ResetPushbackStatus();
      console.log("CALLING UPDATE");
      if(this.props.filter){
        const surveyInfo = {
          startDate: formatDate(this.props.filter.startDate),
          endDate: formatDate(this.props.filter.endDate),
        };
        this.props.GetResponse(
          surveyInfo,
          this.props.filter.versionId,
          this.props.auth.user.accessToken
        );
      }
      this.props.history.push('/qc/'+this.props.auth.user.id+'/dashboard')
    }
    if(this.props.pushBackError){
      this.props.SetAlert('error', "Error in pushback");
      this.props.ResetPushbackStatus();
    }

  }

  onQSelect = (questionsKey, sectionsKey) => {
    this.setState({
      ...this.state,
      selectedQ: questionsKey,
      selectedSec: sectionsKey,
    });
  };

  handleChange = (e) => {
    if(e.target.name == "multiple"){
      if(e.target.value in this.state.multiple){
          console.log("found",e.target.value,!(this.state.multiple[e.target.value]))
      }
      else{
          console.log("not found");
      }
    }
    if(e.target.name == "multiple")
    this.setState({ ...this.state, 
      multiple:{
        ...this.state.multiple,
        [e.target.value]: e.target.value in this.state.multiple?!(this.state.multiple[e.target.value]):true
      }
    })
    else if(String( e.target.name).includes("gridGroup")){
        var dataFromId = e.target.id.split('-');
        var groupId = dataFromId[1]
        var optionId = dataFromId[3]
        console.log("GRID TYPE CHANGE", groupId, optionId, e.target.value);

        this.setState({
            ...this.state,
            grid:{
                ...this.state.grid,
                [groupId]:{
                    [optionId]:e.target.value=="on"?true:false
                }
            }
        })
    }
    else if(e.target.name == "mix"){
        var dataFromId = e.target.id.split('-');
        var type = dataFromId[1]
        var optionId = dataFromId[2]
        console.log("MIX TYPE CHANGE", type, optionId, e.target.value);

        if(type == 'multiple')
        this.setState({
            ...this.state,
            mix:{
                ...this.state.mix,
                multiple:{
                    ...this.state.mix.multiple,
                    [optionId]:this.state.mix.multiple[optionId]?!this.state.mix.multiple[optionId]:true
                },
                single:{}
                
            }
        })
        else if(e.target.value)
        this.setState({
            ...this.state,
            mix:{
                multiple:{},
                single:{
                    [optionId]:this.state.mix[optionId]?!this.state.mix[optionId]:true
                }
            }
        })
    }
    else if(e.target.name =="rank"){
      var pos = e.target.id.split('-')[1]
        this.setState({ranks:{
          ...this.state.ranks,
          [pos]:e.target.value
        }})
    }
    else if(e.target.name =="single"){
      var pos = e.target.id.split('-')[1]
      console.log(pos, e.target.id);
      this.setState({optionId:pos})
    }
    else
        this.setState({[e.target.id]: e.target.value })
  };

  onEditClick = (e) => {
    e.preventDefault();
    var questions= this.state.survey.SurveyVersions["0"].questionnaire;
    var selectedSec= this.state.selectedSec;
    var selectedQ= this.state.selectedQ;
    var responseVersion= this.state.responseVersion;
    var data = []
    if(questions.sections[selectedSec].questions[selectedQ].questionType ==
      "MULTIPLE"){
          var keys = Object.keys(this.state.multiple)
          for(var i=0;i<keys.length;i++){
              if(this.state.multiple[keys[i]]){
                  data.push({optionId:keys[i]})
              }
          }
      }
    else if(questions.sections[selectedSec].questions[selectedQ].questionType ==
      "GRID"){
          var groups = Object.keys(this.state.grid)
          for(var i=0;i<groups.length;i++){
              var options = Object.keys(this.state.grid[groups[i]])
              for(var j=0;j<options.length;j++){
                  if(this.state.grid[groups[i]]&&this.state.grid[groups[i]][options[j]]){
                      data.push({groupId:groups[i], optionId:options[j]})
                  }
              }
          }
      }
    else if(questions.sections[selectedSec].questions[selectedQ].questionType ==
      "MIXED"){
          var keys = Object.keys(this.state.mix.single)
          var single=false
          for(var i=0;i<keys.length;i++){
              if(this.state.mix.single[keys[i]]){
                  data.push({optionId:keys[i], optionTypeGroup:'SINGLE'})
                  single=true
              }
          }
          if(!single){
              keys = Object.keys(this.state.mix.multiple)
              for(var i=0;i<keys.length;i++){
                  if(this.state.mix.multiple[keys[i]]){
                      data.push({optionId:keys[i], optionTypeGroup:'MULTIPLE'})
                  }
              }
          }
      }
    else if(questions.sections[selectedSec].questions[selectedQ].questionType ==
      "RANK"){
          var temp =Object.keys(this.state.ranks)
          for(var i=0;i<temp.length;i++){
            data.push({optionId:this.state.ranks[i]})
          }
      }
  const answer =
    questions.sections[selectedSec].questions[selectedQ].questionType ==
      "TEXT" ||
    questions.sections[selectedSec].questions[selectedQ].questionType ==
      "NUMERIC"
      ? 
      {
          answer: [
              {
                  input: this.state.input
              }
          ]
      }
      
      : questions.sections[selectedSec].questions[selectedQ].questionType ==
        "SINGLE"
      ?
      {
          answer: [
              {
                  optionId: this.state.optionId,
              }
          ]
      }
      : questions.sections[selectedSec].questions[selectedQ].questionType ==
      "MULTIPLE"
      ||questions.sections[selectedSec].questions[selectedQ].questionType ==
      "GRID"
      ||questions.sections[selectedSec].questions[selectedQ].questionType ==
      "MIXED"
      ||questions.sections[selectedSec].questions[selectedQ].questionType ==
      "RANK"
    ? 
      {
          answer:data
      }
      : null;
      responseVersion.response.sections[selectedSec].questions[selectedQ]= answer
        this.props.SaveAnswer(responseVersion, this.state.responseVersionId, this.props.auth.user.accessToken);
    var modal = document.getElementById("edit-answer-modal");
    var instance = M.Modal.getInstance(modal);
    instance.close();
  };

  flagStatus = (e) => {
    e.preventDefault();
    var selectedSec= this.state.selectedSec;
    var selectedQ= this.state.selectedQ;
    var responseVersion= this.state.responseVersion;
    var flag= this.state.flag;
    this.setState({flag: !flag},()=>{
      const flagCheck = {
        ...responseVersion.response.sections[selectedSec].questions[selectedQ],
        flag: this.state.flag
      }
    responseVersion.response.sections[selectedSec].questions[selectedQ]= flagCheck
      this.props.ChangeFlag(responseVersion, this.state.responseVersionId, this.props.auth.user.accessToken);
    })
    
  }


  showProps = (obj, objName) => {
    var result = ``;
    for (var i in obj) {
      // obj.hasOwnProperty() is used to filter out properties from the object's prototype chain
      if (obj.hasOwnProperty(i)) {
        result += `${objName}.${i} = ${obj[i]}\n`;
      }
    }
    return result;
  };

  onRejectApprove = (e) => {
    if (e.target.id == "reject") {
      this.setState({ rejORapp: e.target.id });
    } else if (e.target.id == "approve") {
      this.setState({ rejORapp: e.target.id });
    }
    var modal = document.getElementById("delete-user-modal");
    console.log(modal);
    var instance = M.Modal.getInstance(modal);
    console.log(instance);
    instance.open();
  };

  rejectORapprove = (id) => {
    if (this.state.rejORapp == "reject") {
      const status = {
        responseStatus: "Declined",
      };
      this.props.RejectOrApprove(status, id, this.props.auth.user.accessToken);
    } else if (this.state.rejORapp == "approve") {
      const status = {
        responseStatus: "Approved",
      };
      this.props.RejectOrApprove(status, id, this.props.auth.user.accessToken);
    }
    var modal = document.getElementById("delete-user-modal");
    var instance = M.Modal.getInstance(modal);
    instance.close();
  };

  OnOtherChange = (e) => {
    console.log(e.target.value)
    this.setState({ ...this.state, [e.target.id]: e.target.value });
    var responseVersion= this.props.responses.ResponseVersions.find(
      x => x.id == parseInt(e.target.value)
    )
    this.setState({responseVersion: responseVersion, responseVersionId: e.target.value, reschk: true})
  };

  onSubmit = (e, userId) => {
    e.preventDefault();
    this.props.PushBack( userId,this.state.responseVersionId,this.props.auth.user.accessToken);
    var modal = document.getElementById("pushback-modal");
    var instance = M.Modal.getInstance(modal);
    instance.close();
  }

  GetExcel=(e)=>{
    var user = this.props.auth.user
    if(user)
    this.props.GetResponseExcel(this.state.responseVersionId, user.accessToken)
  }

  CreateResult=(e)=>{
    var user = this.props.auth.user
    this.props.CalculateResult(this.state.responseVersionId, user.accessToken)
  }

  render() {
    const { questions, responses } = this.props;
    const { survey, id, users, selectedQ, selectedSec } = this.state;
    console.log("response", responses);
    console.log("survey", survey);
    console.log("state", this.state);
    const user = this.props.auth.user;
    if (!(user&&user.id)) return <Redirect to="/login" />;
    return (
      <div className="row">
        <Navbar heading="QC" user={user} />
        <PushbackModal responseVersionId={this.state.responseVersionId} onSubmit={this.onSubmit} surveyId={this.props.sid}/>
        <DeleteModal
          className="delete-modal"
          HandleDelete={(e) =>
            this.rejectORapprove(this.state.responseVersion.id)
          }
        />
        <EditQcAnswer
          className="edit-answer-modal"
          HandleChange={this.handleChange}
          onSubmit={this.onEditClick}
          state={this.state}
          // question={survey.SurveyVersions["0"].questionnaire}
          questions={this.state.questions}
          response={this.state.responses}
          selectedSec={selectedSec}
          selectedQ={selectedQ}
        />
        <div className="row body">
          <div className="row info-header" style={{ marginTop: 10 }}>
            <div className="col s12">
             <div className="col s2">
              Student:{this.state.reschk == true ? this.state.responseVersion.UserSurvey.User.userName : null}
            </div>
            <div className="col s1 offset-s1">
              email:{this.state.reschk == true ? this.state.responseVersion.UserSurvey.User.email : null}
            </div>
            <div className="col s2 offset-s1">
              Date: {this.state.reschk == true ? 
              moment(this.state.responseVersion.UserSurvey.createdAt)
              .format('MM-DD-YYYY, h:mm a') 
              : null}
            </div> 
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  paddingTop: 5,
                }}
              >
                <div className="col s2">
                  <a className="btn-floating btn-small green tooltipped" data-position="bottom" data-tooltip={this.state.responseVersion.location}
                  href={"https://www.google.com/maps/search/?api=1&query="+this.state.responseVersion.location} 
                  target="_blank" rel="noopener noreferrer" >
                    <i class="material-icons">location_on</i>
                  </a>
                </div>
                <div className="col s2">
                  <button className="btn-floating btn-small yellow darken-1 ">
                    <a href={this.state.responseVersion?this.state.responseVersion.videoRecording:null} 
                    download
                    rel="noopener noreferrer" target="_blank">
                      <i class="material-icons">play_circle_filled</i>
                    </a>
                  </button>
                </div>
                <div className="col s2">
                  <button className="btn-floating btn-small red ">
                    <a href={this.state.responseVersion?this.state.responseVersion.audioRecording:null} 
                    download
                    rel="noopener noreferrer" target="_blank">
                        <i class="material-icons">music_note</i>
                    </a>
                  </button>
                </div>
                <div className="col s1">
                  <button className="btn-floating btn-small blue" onClick={this.GetExcel}>
                    <i class="material-icons">file_download</i>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="col s12">   
            <div className="col s3">
              <div className="col s2">
                  <label>Versions</label>
              </div>
              <div className="col s7">
                  <select
                      required
                      // defaultValue={this.props.versionId}
                      onChange={this.OnOtherChange}
                      className='browser-default select-font'
                      id='selectedVersion'
                  >
                  <option value="" disabled selected>Version</option>
                      {
                        responses.ResponseVersions && responses.ResponseVersions.map((version,index) =>
                              <option /* selected={this.props.versionId==version.id} */ value={version.id}>{version.versionLabel}</option>
                          )
                      }
                  </select>
              </div>   
            </div>
            <div className="col s3">
              <span><label>Unique Id:</label></span><span>{this.state.responseVersion.uniqueId}</span>
            </div>
            <div className="col s3">
              <span><label>Marks:</label></span><span>{this.state.responseVersion.marks}</span>
            </div>
        </div>
          <div className="row " >
            <div className="col s5 question-list-complete">
              <div className="container" style={{ width: "90%" }}>
                 {survey.SurveyVersions &&
                 this.state.svPosition!=null?
                  Object.keys(
                    survey.SurveyVersions[this.state.svPosition].questionnaire.sections
                  ).map((sectionId, sectionsKey) =>
                    Object.keys(
                      survey.SurveyVersions[this.state.svPosition]
                      .questionnaire.sections[sectionId]
                      .questions
                    ).map((questionId, questionsKey) => (
                      <QuestionListItem
                        key={questionId}
                        id={questionId}
                        questionid={
                          sectionId + "." +questionId
                        }
                        q={survey.SurveyVersions[this.state.svPosition]
                          .questionnaire.sections[sectionId]
                          .questions[questionId].question}
                        type={survey.SurveyVersions[this.state.svPosition]
                          .questionnaire.sections[sectionId]
                          .questions[questionId].questionType}
                        qtype={survey.SurveyVersions[this.state.svPosition]
                          .questionnaire.sections[sectionId]
                          .questions[questionId].questionBodyType}
                        cssType="question-list"
                        onDetails={() =>
                          this.onQSelect(questionId, sectionId)
                        }
                      />
                    ))
                  )
                :null} 
              </div>
            </div>
            <div className="col s7">
              <div className="row">
                <div className="col s11">
                  <div className="container" style={{ width: "80%" }}>
                    {selectedQ != null && this.state.reschk == true&&this.state.svPosition!=null ? (
                      <SpecificQuestion
                        questions={
                          survey.SurveyVersions[this.state.svPosition].questionnaire.sections[
                            selectedSec
                          ].questions
                        }
                        response={this.state.responseVersion.response.sections[
                          selectedSec
                        ]?
                          this.state.responseVersion.response.sections[
                            selectedSec
                          ].questions
                          :null
                        }
                        selectedQ={selectedQ}
                      />
                    ) : null}
                  </div>
                </div>
                <div className="col s1">
                  <p className="row center">
                    {selectedQ != null ? (
                      <div>
                        <div className="row">
                          <button className='btn-floating btn-small' onClick={this.CreateResult}>
                            <i class="material-icons">play_arrow</i>
                          </button>
                        </div>
                        <div className="row">
                          <button
                            className="btn-floating btn-small blue modal-trigger"
                            data-target="edit-answer-modal"
                            onClick={() => {
                              this.setState({
                                questions:
                                  survey.SurveyVersions[this.state.svPosition].questionnaire
                                    .sections[selectedSec].questions,
                                responses:
                                  responses.ResponseVersions["0"].response
                                    .sections[selectedSec].questions,
                                // responseId:
                                //   responses.ResponseVersions["0"].response.id,
                                // sectionId:
                                //   responses.ResponseVersions["0"].response
                                //     .sections[selectedSec].id,
                                // questionId:
                                //   responses.ResponseVersions["0"].response
                                //     .sections[selectedSec].questions[selectedQ]
                                //     .id,
                              });
                            }}
                          >
                            <i class="material-icons">edit</i>
                          </button>
                        </div>
                        <div className="row">
                          <button className={this.state.responseVersion.response
                          &&this.state.responseVersion.response.sections
                          &&this.state.responseVersion.response.sections[selectedSec]
                          &&this.state.responseVersion.response.sections[
                            selectedSec
                          ].questions
                          &&this.state.responseVersion.response.sections[
                            selectedSec
                          ].questions[selectedQ]
                          &&this.state.responseVersion.response.sections[
                            selectedSec
                          ].questions[selectedQ].flag == true ? "btn-floating btn-small red" : "btn-floating btn-small blue" } onClick={this.flagStatus}>
                            <i class="material-icons">flag</i>
                          </button>
                        </div>
                      </div>
                    ) : null}
                  </p>
                </div>
              </div>
              {selectedQ != null ? (
                <div className="container" style={{ width: "95%" }}>
                  <div className="row center qc-action-block" style={
                    this.state.responseVersion.responseStatus === "Pushed"? {backgroundColor: "#FCF678", borderColor: "#FCF678" }
                  :this.state.responseVersion.responseStatus === "Approved" ? {backgroundColor: "#B8FC78", borderColor: "#B8FC78" }
                :this.state.responseVersion.responseStatus === "Declined" ? {backgroundColor: "#F2A696", borderColor: "#F2A696" } : null}>
                  {this.state.responseVersion.responseStatus === "Pushed" ?
              <div className="row" style={{opacity: 0.3, fontSize: 30}}>
              <strong>Retest</strong>
              </div>
              :
              this.state.responseVersion.responseStatus === "Approved" ?
              <div className="row" style={{opacity: 0.3, fontSize: 30}}>
              <strong>Passed</strong>
              </div>
              :
              this.state.responseVersion.responseStatus === "Declined" ?
              <div className="row" style={{opacity: 0.3, fontSize: 30}}>
              <strong>Failed</strong>
              </div>
              :
                    <div className="row">
                      <div className="col s4">
                        <button
                          id="pushBack"
                          className="btn-small waves-effect waves-light list-button modal-trigger"
                          data-target="pushback-modal"
                          onClick={(e) => {
                            this.setState({ id: e.target.id });
                          }}
                          disabled = {this.state.responseVersion.responseStatus === "Pushed" ||
                          this.state.responseVersion.responseStatus === "Approved" ||
                          this.state.responseVersion.responseStatus === "Declined" ? true :false}
                        >
                          Retest
                        </button>
                      </div>
                      <div className="col s4">
                        <button
                          id="reject"
                          className="btn-small waves-effect waves-light list-button modal-trigger"
                          data-target="delete-user-modal"
                          onClick={this.onRejectApprove}
                          disabled = {this.state.responseVersion.responseStatus === "Pushed" ||
                          this.state.responseVersion.responseStatus === "Approved" ||
                          this.state.responseVersion.responseStatus === "Declined" ? true :false}
                        >
                          Fail
                        </button>
                      </div>
                      <div className="col s4">
                        <button
                          id="approve"
                          className="btn-small waves-effect waves-light list-button modal-trigger"
                          data-target="delete-user-modal"
                          onClick={this.onRejectApprove}
                          disabled = {this.state.responseVersion.responseStatus === "Pushed" ||
                          this.state.responseVersion.responseStatus === "Approved" ||
                          this.state.responseVersion.responseStatus === "Declined" ? true :false}
                        >
                          Pass
                        </button>
                      </div>
                    </div>
                  }
                  </div>
                        
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  console.log(state, ownProps);
  return {
    auth: state.auth,
    survey: state.quality.surveys,
    previewAnswers: state.quality.savedAnswer,
    rid: ownProps.match.params.rid,
    sid: ownProps.match.params.sid,
    vid: ownProps.match.params.vid,
    responses: ownProps.location.state,
    actionSuccess: state.quality.rejectORapproveSuccess,
    downloadSuccess: state.quality.resDownloadSuccess,
    downloadError: state.quality.resDownloadError,
    editSuccess: state.quality.editSuccess,
    editError: state.quality.editError,
    pushBackSuccess: state.quality.pushBackSuccess,
    pushBackError: state.quality.pushBackError,
    filter: state.quality.filter,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    GetSurveys: (status, token) => {
      dispatch(getSurveys(status, token));
    },
    SaveAnswer: (responseVersion, sid, qid) => {
      dispatch(saveAnswer(responseVersion, sid, qid));
    },
    ChangeFlag: (flagCheck, sid, qid) => {
      dispatch(changeFlag(flagCheck, sid, qid));
    },
    RejectOrApprove: (status, responseVersionId, token) => {
      dispatch(RejectOrApprove(status, responseVersionId, token));
    },
    PushBack: (userId, resVerId, token) => {
      dispatch(pushBack(userId, resVerId, token));
    },
    GetResponseExcel: (resVerId, token) => {
      dispatch(getResponseExcel(resVerId, token));
    },
    ResetDownloadStatus:()=>{
      dispatch(resetDownloadStatus());
    },
    ResetEditStatus:()=>{
      dispatch(resetEditStatus());
    },
    SetAlert:(type, message)=>{
      dispatch(setAlert(type, message));
    },
    ResetAlert: ()=> {
        dispatch(resetAlert());
    },
    GetResponse: (surveyInfo, surveyVersionId, token) => {
      dispatch(getResponse(surveyInfo, surveyVersionId, token));
    },
    ResetActionStatus:()=>{
      dispatch(actionStateReset());
    },
    ResetPushbackStatus:()=>{
      dispatch(pushBackStatusReset());
    },
    CalculateResult:(id, token)=>{
      dispatch(calculateResult(id, token))
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SpecificResponse);

