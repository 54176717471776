import React from "react";

function StudentMarksCard({
  title,
  title1,
  title2,
  title3,
  title4,
  value,
  value1,
  value2,
  value3,
  value4,
}) {
  return (
    <div>
      <div className="row" style={{ margin: 40 }}>
        <div class="col s12 m3 l5">
          <div
            class="card horizontal"
            style={{
              width: 230,
              borderRadius: 10,
              height: 200,
              marginLeft: "-70px",
            }}
          >
            <div class="card-stacked">
              <div class="card-content">
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <p><b>{title}</b></p>
                  <i class="material-icons" style={{ color: "coral" }}>
                    mood
                  </i>
                </div>
                <p style={{ fontSize: 20 }}>
                  <strong>{value}</strong>
                </p>
                {/* <p style={{ fontSize: 30 }}>
                  <strong>{name}</strong>
                </p>
                <hr /> */}
                <div
                  style={{paddingTop:10, display: "flex", justifyContent: "space-between" }}
                >
                  <p>{title1}</p>
                  <p>{value1}</p>
                </div>
                <hr />
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <p>{title2}</p>
                  <p>{value2}</p>
                </div>
                <hr />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <div
        className="row"
        style={{ marginLeft: 10, marginRight: 60, marginTop: "-70px" }}
      >
        <div class="col s12 m3 l3" style={{ marginLeft: "-24%" }}>
          <div
            class="card horizontal"
            style={{
              width: 230,
              borderRadius: 10,
              height: 270,
              marginLeft: "-60px",
            }}
          >
            <div class="card-stacked">
              <div class="card-content">
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <p>{title1}</p>
                  <p>{value1}</p>
                </div>
                <hr />
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <p>{title2}</p>
                  <p>{value2}</p>
                </div>
                <hr />
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <p>{title3}</p>
                  <p>{value3}</p>
                </div>
                <hr />
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <p>{title4}</p>
                  <p>{value4}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </div>
  );
}

export default StudentMarksCard;
