  
    async function getShuffelededQuestionnaire(questionnaire) {
        try{
            var sectionObject = questionnaire.sections
            var sectionRandom = questionnaire.sectionRandom
            console.error("sectionRandom = ",sectionRandom, sectionObject)
            var shuffeledSections = await getShuffeledSection(sectionObject,sectionRandom)
            questionnaire.sections = shuffeledSections
            console.log("after shuffle", questionnaire.sections);
            for(let [sec,secData] of Object.entries(questionnaire.sections)){
                try{
                    if(secData.questionRandom){
                        var sectionKeys = Object.keys(questionnaire.sections)
                        var nextSectionFirstQuestion = "-1"
                        if(sectionKeys.indexOf(sec) > 0){
                          var currentSecIndex = sectionKeys.indexOf(sec)
                          var prevSec = sectionKeys[currentSecIndex - 1]
                          var prevSectionQuestionObjectKeys = Object.keys(questionnaire.sections[prevSec].questions)
                          var preSectionLastQuestion = prevSectionQuestionObjectKeys[prevSectionQuestionObjectKeys.length -1]
                          nextSectionFirstQuestion = questionnaire.sections[prevSec].questions[preSectionLastQuestion].questionFlow.next.questionId
                        }
                        var suffeledQuestions = await getSuffeledQuestions(secData.questions,sec,nextSectionFirstQuestion)
                        questionnaire.sections[sec].questions = suffeledQuestions
                    }
                    if(questionnaire.optionRandom){
                        for(let [ques,quesData] of Object.entries(questionnaire.sections[sec].questions)){
                            try{
                              var shuffeledOptions = await getSuffeledOptions(quesData.options)
                                questionnaire.sections[sec].questions[ques].options = shuffeledOptions
                            }
                            catch(error){
                                console.error("getShuffelededQuestionnaire:: error occured in question loop:: ",error)
                            }
                        }
                    }
                }
                catch(error){
                    console.error("getShuffelededQuestionnaire:: error occured in section loop:: ",error)
                }
            }
            return questionnaire
        }
        catch(error){
            console.error("getShuffelededQuestionnaire:: ",error)
            throw error
        }
    }
  
  
  
    async function shuffle(sourceArray) {
        for (var i = 0; i < sourceArray.length - 1; i++) {
            var j = i + Math.floor(Math.random() * (sourceArray.length - i));
    
            var temp = sourceArray[j];
            sourceArray[j] = sourceArray[i];
            sourceArray[i] = temp;
        }
        return sourceArray;
    }
  
  
    async function getLastQuestionID(sectionId,sectionObjects){
      var questionObjects = sectionObjects[sectionId].questions
        return Object.keys(questionObjects)[Object.keys(questionObjects).length - 1]
    }
  
  
    async function getFirstQuestionID(sectionId,sectionObjects){
      var questionObjects = sectionObjects[sectionId].questions
        return Object.keys(questionObjects)[0]
    }
  
  
    async function removeElment(arr,elm){
        return arr.filter(function(ele){
            return ele != elm
        })
    }
  
    async function getShuffeledSection(sectionObject,sectionRandom){
        try{
            if(sectionRandom&&sectionRandom.isRandom){
                var sectionIdArray = Object.keys(sectionObject)
                const randomSec = sectionRandom.random
                // const nonRandomSec = sectionRandom.notRandom
                var nonRandomSec = []
                for(var x=0;x<sectionIdArray.length;x++)
                {
                    if(!randomSec.includes(sectionIdArray[x])){
                        nonRandomSec.push(sectionIdArray[x])
                    }
                }
                console.error("randomSec = ",randomSec)
                console.error("nonRandomSec = ",nonRandomSec)
                var suffeledRandomSec = await shuffle(randomSec) 
                var newSectionObject = {}
                for(let [key,value] of Object.entries(sectionObject)){
                  if(nonRandomSec.includes(key)){
                      newSectionObject[key] = value
                  }
                  else{
                      var randSec = suffeledRandomSec[0]
                      // console.error("randSec = ",randSec)
                      newSectionObject[key] = sectionObject[randSec]
                      suffeledRandomSec = await removeElment(suffeledRandomSec,randSec)
                  }
                }
                var newSectionIdArray = Object.keys(newSectionObject)
                for(var k=0;k<newSectionIdArray.length;k++){
                    var nextSec = null
                    var nextQues = null
                    if(k < (newSectionIdArray.length -1)){
                        nextSec = newSectionIdArray[k + 1]
                        nextQues = await getFirstQuestionID(nextSec,newSectionObject)
                    }
                    var lastQues = await getLastQuestionID(newSectionIdArray[k],newSectionObject)
                    for(let [secQ,secQD] of Object.entries(newSectionObject[newSectionIdArray[k]].questions)){
                        if(Number(secQ) == Number(lastQues)){
                            newSectionObject[newSectionIdArray[k]].questions[secQ].questionFlow.next.sectionId = nextSec
                            newSectionObject[newSectionIdArray[k]].questions[secQ].questionFlow.next.questionId = nextQues
                        }
                        else{
                            newSectionObject[newSectionIdArray[k]].questions[secQ].questionFlow.next.sectionId = newSectionIdArray[k]
                        }
                    }
                }
                return newSectionObject
            }
            else{
                return sectionObject
            }
        }
        catch(error){
            console.error("getShuffeledSection:: ",error)
            throw error
        }
    }
  
  
    async function getSuffeledQuestions(questionObject,currentSection,sectionFirstQuestion){
        try{
            var objectLength = Object.keys(questionObject).length
            var lastKey = Object.keys(questionObject)[objectLength-1]
            var lastVal = questionObject[lastKey]
            var nextSection = lastVal.questionFlow.next.sectionId
            var nextSectionQuestion = lastVal.questionFlow.next.questionId
            
            var questionArray = []
            for(let [q,v] of Object.entries(questionObject)){
               questionArray.push(q)
            }
            var shuffeledQestionObject = await getSuffeledOptions(questionObject)
            var shuffeledQuestionArray = await shuffle(questionArray)
            if(shuffeledQuestionArray.includes(sectionFirstQuestion.toString())){
                shuffeledQuestionArray = await removeElment(shuffeledQuestionArray,sectionFirstQuestion)
            }
            var newobjectLength = Object.keys(shuffeledQestionObject).length
            var newlastKey = Object.keys(shuffeledQestionObject)[newobjectLength-1]
            for(let [q_no,q_info] of Object.entries(shuffeledQestionObject)){
              var curr_ques_index = shuffeledQuestionArray.indexOf(q_no)
                if(Number(q_no) == Number(newlastKey)){    
                   console.error("getSuffeledQuestions:: last question")
                   q_info.questionFlow.next.sectionId = nextSection
                   q_info.questionFlow.next.questionId = nextSectionQuestion
                }
                else{
                    q_info.questionFlow.next.sectionId = currentSection
                    for(var n=0;n<shuffeledQuestionArray.length;n++){
                        if(Number(q_no) != Number(shuffeledQuestionArray[n])){
                          var currentSectionQuestion = shuffeledQuestionArray[n]
                          shuffeledQuestionArray = await removeElment(shuffeledQuestionArray,currentSectionQuestion)
                            q_info.questionFlow.next.questionId = currentSectionQuestion
                            break
                        }
                    }
                }
            }
            return shuffeledQestionObject
        }
        catch(error){
            console.error("getSuffeledQuestions:: ",error)
            throw error
        }
    } 
  
  
    async function getSuffeledOptions(optionObject){
       try{
          var optionArray = []
          var optionKeyArray = []
            for(let [o,val] of Object.entries(optionObject)){
               optionArray.push(val)
               optionKeyArray.push(o)
            }
            var shuffeledOptionArray = await shuffle(optionArray)
            var shuffeledOptionObject = {}
            for(var i = 0;i<shuffeledOptionArray.length;i++){
                try{
                    var k = optionKeyArray[i]
                    var v = shuffeledOptionArray[i]
                    shuffeledOptionObject[k] = v
                }
                catch(error){
                    throw error
                }
            }
            return shuffeledOptionObject
       }
       catch(error){
           console.error("getSuffeledOptions:: ",error)
           throw error
       }
    } 
  
    export default getShuffelededQuestionnaire;