import React, { Component } from "react";
import $ from "jquery";
import { connect } from "react-redux";
import Navbar from "../layout/Navbar";
import { Redirect } from "react-router-dom";
import {
  getCourses,
  requestSubject,
} from "../../store/actions/ProfessorAction";

class SubjectChoose extends Component {
  state = {
    courseId: null,
    flag: true,
    courses: [],
    check: [],
    query: null,
  };

  componentDidMount() {
    this.props.GetCourse(this.props.auth.user.accessToken);
  }

  componentDidUpdate() {
    if (this.state.flag && this.props.courseFetchSuccess) {
      var user = this.props.auth.user;
      var prof = this.props.course;
      var arr = [];
      var info;
      for (var k = 0; k < prof.length; k++) {
        for (var i = 0; i < prof[k].ProfessorCourses.length; i++) {
          if (prof[k].ProfessorCourses[i].professorId === user.id) {
            info = {
              courseId: prof[k].ProfessorCourses[i].courseId,
              isApproved: prof[k].ProfessorCourses[i].isApproved,
            };
            arr.push(info);
          }
        }
        // console.log(arr)
      }
      this.setState({ courses: this.props.course, check: arr, flag: false });
    }
  }

  onSubmit = (e) => {
    e.preventDefault();
    const query = this.state.query;
    const filteredUser = this.props.course
      ? query
        ? this.props.course.filter((course) => {
            return (
              course.courseName.toLowerCase().includes(query.toLowerCase()) ||
              course.Semester.Department.name
                .toLowerCase()
                .includes(query.toLowerCase())
            );
          })
        : this.props.course
      : [];
    this.setState({ courses: filteredUser });
  };

  handleChange = (e) => {
    this.setState({ [e.target.id]: e.target.value, flag: true });
  };

  handleCheck = (e) => {
    $('input[type="checkbox"]').change(
      function () {
        var checkedValue = $("input:checkbox:checked")
          .map(function () {
            return this.id;
          })
          .get();
        this.setState({ courseId: checkedValue });
      }.bind(this)
    );
  };

  onClick = (e) => {
    var courses = this.state.courseId;
    var coursesIds = { courses };
    console.log(coursesIds);
    this.props.RequestCourse(coursesIds, this.props.auth.user.accessToken);
  };

  render() {
    const user = this.props.auth.user;
    const course = this.state.courses;
    const check = this.state.check;
    console.log(user);
    if (!user) return <Redirect to="/login" />;
    return (
      <div>
        <Navbar heading="Admin" user={user} />
        <div className="row list-item">
            <div className="col s6">
            <div className="col s1">
                <i className="material-icons right">search</i>
            </div>
            <div className="col s6 input-field search-box">
                <input
                className='serach-input'
                id='query'
                type='text'
                onChange={this.handleChange}
                />
            </div>
            </div>
            <div className="col s2" style={{marginLeft:"-283px"}}>
            <button className="btn submit" id="submit" onClick={this.onSubmit}>Submit</button>
            </div>
        </div>
        <form action="#">
          <div
            className="row list-item-header"
            style={{ width: "100%", marginLeft: 20 }}
          >
            <div className="col l2">
              <b>Department</b>
            </div>
            <div className="col l2">
              <b>Year</b>
            </div>
            <div className="col l3">
              <b>Semester</b>
            </div>
            <div className="col l2">
              <b>Course</b>
            </div>
            <div className="col l3">
              <b>Action</b>
            </div>
          </div>
          <div className="row" style={{ width: "100%", marginLeft: 20 }}>
            {this.props.courseFetchSuccess == true && this.state.flag == false
              ? course &&
                course.map((course, index) => (
                  <div className="row list-item">
                    <div className="col l2">
                      {course.Semester ? course.Semester.Department.name : null}
                    </div>
                    <div className="col l2">{course.Semester ? course.Semester.year : null}</div>
                    <div className="col l3">{course.Semester ? course.Semester.semester : null}</div>
                    <div className="col l2">{course.courseName}</div> 
                    <div
                      className="col l3"
                      /* style={{ display: "flex", justifyContent: "space-around" }} */
                    >
                      {/* <Form.Check
                  // custom
                  id={course.id}
                  className="custom-checkbox"
                  style={{ "font-size": "3rem" }}
                  aria-label="option 1"
                  onChange={this.handleCheck}
                  defaultChecked= {index < check.length && check[index.toString()].courseId == course.id ? check[index.toString()].isApproved : false}
                /> */}
                      <p>
                        <label>
                          <input
                            type="checkbox"
                            id={course.id}
                            className="custom-checkbox"
                            aria-label="option 1"
                            onChange={this.handleCheck}
                            defaultChecked={
                              index < check.length &&
                              check[index.toString()].courseId == course.id
                                ? check[index.toString()].isApproved
                                : false
                            }
                          />
                          <span></span>
                        </label>
                      </p>
                    </div>
                  </div>
                ))
              : null}
          </div>
        </form>
        <button
          className="btn submit"
          id="submit"
          style={{
            "margin-top": "2%",
            "margin-left": "90%",
            border: "1px solid",
          }}
          onClick={this.onClick}
        >
          Submit
        </button>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  console.log(state);
  return {
    auth: state.auth,
    course: state.Professor.course,
    semester: state.Professor.semester,
    semesterFetchSuccess: state.Professor.semesterFetchSuccess,
    department: state.Professor.department,
    courseFetchSuccess: state.Professor.courseFetchSuccess,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    GetCourse: (token) => {
      dispatch(getCourses(token));
    },
    RequestCourse: (body, token) => {
      dispatch(requestSubject(body, token));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SubjectChoose);
