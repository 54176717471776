import {backendUrl} from '../../config/api';


export const getProfessor = (deptId,token) => {
    return (dispatch) => {
        // dispatch({
        //     type: "PROFESSOR_FETCH_LOADING"
        //   });
      fetch(backendUrl +  "/dept/"+deptId+'/professors',{
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }).then((response) =>
        response.json().then((data) => {
          if (response.status == 200) {
            dispatch({
              type: "PROFESSOR_FETCH_SUCCESS",
              payload: data,
            });
          } else if (response.status == 403) {
            dispatch({
              type: "AUTHENTICATION_ERROR",
            });
          } else {
            dispatch({
              type: "PROFESSOR_FETCH_ERROR",
            });
          }
        })
      );
    };
  };

export const requestTime = (body,token) => {
  return(dispatch) => {
    fetch(backendUrl + "/preferredTime" , {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify(body),
    }).then((response) =>
      response
        .json()
        .then((data) => {
          if (response.status === 200) {
            dispatch({
              type: "RESPONSE_REQUEST_SUCCESS",
              payload: data,
            });
          } else if (response.status === 403) {
            dispatch({
              type: "AUTHENTICATION_ERROR",
            });
          } else {
            dispatch({
              type: "RESPONSE_REQUEST_FAILED",
            });
          }
        })
        .catch((err) => {
          dispatch({
            type: "RESPONSE_REQUEST_FAILED",
          });
        })
    );
  }
}

export const requestSubject = (body,token) => {
  return(dispatch) => {
    fetch(backendUrl + "/preferredCourse" , {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify(body),
    }).then((response) =>
      response
        .json()
        .then((data) => {
          if (response.status === 200) {
            dispatch({
              type: "COURSE_REQUEST_SUCCESS",
              payload: data,
            });
          } else if (response.status === 403) {
            dispatch({
              type: "AUTHENTICATION_ERROR",
            });
          } else {
            dispatch({
              type: "COURSE_REQUEST_FAILED",
            });
          }
        })
        .catch((err) => {
          dispatch({
            type: "COURSE_REQUEST_FAILED",
          });
        })
    );
  }
} 

export const getDepartment = (token) => {
  return (dispatch) => {
      // dispatch({
      //     type: "DEPARTMENT_FETCH_LOADING"
      //   });
    fetch(backendUrl + "/departments",{
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((response) =>
      response.json().then((data) => {
        if (response.status == 200) {
          dispatch({
            type: "DEPARTMENT_FETCH_SUCCESS",
            payload: data,
          });
        } else if (response.status == 403) {
          dispatch({
            type: "AUTHENTICATION_ERROR",
          });
        } else {
          dispatch({
            type: "DEPARTMENT_FETCH_ERROR",
          });
        }
      })
    );
  };
};

export const getSemester = (deptId,token) => {
  return (dispatch) => {
      // dispatch({
      //     type: "SEMESTER_FETCH_LOADING"
      //   });
    fetch(backendUrl + "/dept/"+deptId+"/semesters",{
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((response) =>
      response.json().then((data) => {
        if (response.status == 200) {
          dispatch({
            type: "SEMESTER_FETCH_SUCCESS",
            payload: data,
          });
        } else if (response.status == 403) {
          dispatch({
            type: "AUTHENTICATION_ERROR",
          });
        } else {
          dispatch({
            type: "SEMESTER_FETCH_ERROR",
          });
        }
      })
    );
  };
};

export const getCourses = (token) => {
  return (dispatch) => {
      // dispatch({
      //     type: "COURSE_FETCH_LOADING"
      //   });
    fetch(backendUrl +'/courses',{
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((response) =>
      response.json().then((data) => {
        if (response.status == 200) {
          dispatch({
            type: "COURSE_FETCH_SUCCESS",
            payload: data,
          });
        } else if (response.status == 403) {
          dispatch({
            type: "AUTHENTICATION_ERROR",
          });
        } else {
          dispatch({
            type: "COURSE_FETCH_ERROR",
          });
        }
      })
    );
  };
};

export const getAttendence = (section,course,date,token) => {
  return (dispatch) => {
      // dispatch({
      //     type: "COURSE_FETCH_LOADING"
      //   });
    fetch(backendUrl +'/section/'+section+'/course/'+course+"/attendance/"+date,{
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((response) =>
      response.json().then((data) => {
        if (response.status == 200) {
          dispatch({
            type: "ATTENDENCE_FETCH_SUCCESS",
            payload: data,
          });
        } else if (response.status == 403) {
          dispatch({
            type: "AUTHENTICATION_ERROR",
          });
        } else {
          dispatch({
            type: "ATTENDENCE_FETCH_ERROR",
          });
        }
      })
    );
  };
};

export const uploadExcel = (excelFile,section,course,token) => {
  return (dispatch) => {
      dispatch({type:'ADD_DATA_LOADING'});
      const data = new FormData(); 
      data.append('attendanceExcel', excelFile);
      console.log(data)
      fetch(backendUrl + "/attendance?sectionId="+section+"&courseId="+course, {
          method: "post",
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`
          },
          body: data
        }).then(response =>
          response
            .json()
            .then(data => {
                console.log('upload response', response);
              if (response.status === 201){
                console.log("Data", data);
                dispatch({ type: "ADD_DATA_SUCCESS", data });
              }
              else dispatch({ type: "ADD_DATA_ERROR" });
            })
            .catch(error => dispatch({ type: "ADD_DATA_ERROR" }))
        ) .catch(error => dispatch({ type: "ADD_DATA_ERROR" }));
  }
}

export const getStudent = (id,token) => {
  return (dispatch) => {
      // dispatch({
      //     type: "COURSE_FETCH_LOADING"
      //   });
    fetch(backendUrl +'/dept/'+id+'/student',{
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((response) =>
      response.json().then((data) => {
        if (response.status == 200) {
          dispatch({
            type: "STUDENT_FETCH_SUCCESS",
            payload: data.data,
          });
        } else if (response.status == 403) {
          dispatch({
            type: "AUTHENTICATION_ERROR",
          });
        } else {
          dispatch({
            type: "STUDENT_FETCH_ERROR",
          });
        }
      })
    );
  };
};

export const studentPerformance = (id,token) => {
  return (dispatch) => {
      dispatch({
          type: "STUDENT_PERFORMANCE_FETCH_LOADING"
        });
    fetch(backendUrl +'/student/'+id+'/performance',{
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((response) =>
      response.json().then((data) => {
        if (response.status == 200) {
          dispatch({
            type: "STUDENT_PERFORMANCE_FETCH_SUCCESS",
            payload: data.data,
          });
        } else if (response.status == 403) {
          dispatch({
            type: "AUTHENTICATION_ERROR",
          });
        } else {
          dispatch({
            type: "STUDENT_PERFORMANCE_FETCH_ERROR",
          });
        }
      })
    );
  };
};

export const professorCourseTaken = (id,token) => {
  return (dispatch) => {
      dispatch({
          type: "PROFESSOR_COURSE_FETCH_LOADING"
        });
    fetch(backendUrl +'/professor/'+id+'/courseTaken',{
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((response) =>
      response.json().then((data) => {
        if (response.status == 200) {
          dispatch({
            type: "PROFESSOR_COURSE_FETCH_SUCCESS",
            payload: data.data,
          });
        } else if (response.status == 403) {
          dispatch({
            type: "AUTHENTICATION_ERROR",
          });
        } else {
          dispatch({
            type: "PROFESSOR_COURSE_FETCH_ERROR",
          });
        }
      })
    );
  };
};

export const studentMarks = (pid,cid,token) => {
  return (dispatch) => {
      dispatch({
          type: "STUDENT_MARKS_FETCH_LOADING"
        });
    fetch(backendUrl +'/professor/'+pid+'/course/'+cid+'/presentStudent',{
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((response) =>
      response.json().then((data) => {
        if (response.status == 200) {
          dispatch({
            type: "STUDENT_MARKS_FETCH_SUCCESS",
            payload: data.data,
          });
        } else if (response.status == 403) {
          dispatch({
            type: "AUTHENTICATION_ERROR",
          });
        } else {
          dispatch({
            type: "STUDENT_MARKS_FETCH_ERROR",
          });
        }
      })
    );
  };
};

export const professorFeedback = (id,token) => {
  return (dispatch) => {
      dispatch({
          type: "PROFESSOR_FEEDBACK_FETCH_LOADING"
        });
    fetch(backendUrl +'/professor/'+id+'/feedback',{
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((response) =>
      response.json().then((data) => {
        if (response.status == 200) {
          dispatch({
            type: "PROFESSOR_FEEDBACK_FETCH_SUCCESS",
            payload: data.data,
          });
        } else if (response.status == 403) {
          dispatch({
            type: "AUTHENTICATION_ERROR",
          });
        } else {
          dispatch({
            type: "PROFESSOR_FEEDBACK_FETCH_ERROR",
          });
        }
      })
    );
  };
};

export const printRoutine = (filter, id,token) => {
  return (dispatch) => {
      dispatch({
          type: "PROFESSOR_ROUTINE_FETCH_LOADING"
        });
    fetch(backendUrl +'/routinePrint/'+filter+'/'+id,{
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((response) =>
      response.blob().then((blob) => {
        if (response.status == 200) {
          dispatch({
            type: "PROFESSOR_ROUTINE_FETCH_SUCCESS"
          });

          const data = window.URL.createObjectURL(blob);
          var link = document.createElement('a');
          link.href = data;
          link.download="routine.pdf";
          link.click();
          setTimeout(function(){
            // For Firefox it is necessary to delay revoking the ObjectURL
            window.URL.revokeObjectURL(data);
          }, 100);

        } else if (response.status == 403) {
          dispatch({
            type: "AUTHENTICATION_ERROR",
          });
        } else {
          dispatch({
            type: "PROFESSOR_ROUTINE_FETCH_ERROR",
          });
        }
      })
    );
  };
};