import {backendUrl} from '../../config/api';

export const getTimeSlots = (token) => {
    return (dispatch) => {
        dispatch({
            type: "TIMESLOTS_FETCH_LOADING"
          });
      fetch(backendUrl + "/timeslots",{
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }).then((response) =>
        response.json().then((data) => {
          if (response.status == 200) {
            dispatch({
              type: "TIMESLOTS_FETCH_SUCCESS",
              payload: data,
              payload_day: data.days,
              payload_slot: data.slots
            });
          } else if (response.status == 403) {
            dispatch({
              type: "AUTHENTICATION_ERROR",
            });
          } else {
            dispatch({
              type: "TIMESLOTS_FETCH_ERROR",
            });
          }
        })
      );
    };
  };

