import React from "react";
import StatItem from "./StatItem";
// import { useMediaQuery } from 'react-responsive'

const Stats = ({ items, button }) => {
//   const isTabletOrMobileDevice = useMediaQuery({
//     query: '(max-device-width: 1224px)'
//   })
//   const isTabletOrMobileDeviceMax = useMediaQuery({
//     query: '(max-device-width: 1366px)'
//   })
//   const isTabletOrMobileDeviceMin = useMediaQuery({
//     query: '(min-device-width: 1000px)'
//   })

//   const isLandscape = useMediaQuery({ query: '(orientation: landscape)' })
//   const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })
  return (
    <div className="row">
      {items &&
        items.map((item, i) => (
          <div className="col s12 m6 l3">
            <StatItem heading={item.heading} value={item.value} color={item.color}
            key={i}/>
          </div>
        ))}
    </div>
  );
};

export default Stats;
