import React from "react";

export default function Navbar() {
  return (
    <nav className="my-nav-style">
      <div className="nav-wrapper">
        <a href="#" className="brand-logo">
          <h5 className="nav-text">Ontest - Education </h5>
        </a>
        <ul id="nav-mobile" className="right hide-on-med-and-down">
          <li>
            <a
              className="nav-text"
              href="/login"
              style={{ fontWeight: "700" }}
            >
              Login
            </a>
          </li>
        </ul>
      </div>
    </nav>
  );
}
