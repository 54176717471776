import React from "react";
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd'
import Card from '../layout/Card';

const UpdatePrefarence = ({handleSave,professor,moveCard}) => {
  return (
    <div id="update-prefarence-modal" class="modal">
      <div className="row modal-content update-prefarence-modal">
        <div
          className="row modal-title update-prefarence-modal-title valign-wrapper"
          style={{ "background-color": "#2d49a6", minHeight: 10 }}
        >
          <span className="configure-modal-name">Update Preference</span>
        </div>
        <DndProvider backend={HTML5Backend}>
        <div className="row" /* style={{height:"50vh", overflow:"auto"}} */>
        {professor && professor.map((data, i) => (
          <Card
            key={data.id}
            index={i}
            id={data.id}
            text={data.userName}
            moveCard={moveCard}
          />
        ))}
      </div>
        </DndProvider>
      </div>
      <div className="modal-footer">
        <button className="modal-close waves-effect waves btn-flat">
          CANCEL
        </button>
        <button
          className="waves-effect waves btn-flat"
          id="Save" 
          onClick={handleSave}
        >
          SUBMIT
        </button>
      </div>
    </div>
  );
};

export default UpdatePrefarence;
