
import React, { Component } from "react";
import { connect } from "react-redux";
import M from 'materialize-css';

import { resetAlert, setAlert } from "../../store/actions/MessageAction";

class MessageShowModal extends Component {
    // componentDidMount = () => {  
    //   console.log("HERE");
    //   var elem = document.getElementById("message-modal");
    //   M.Modal.init(elem, {onOpenEnd:()=>{console.log("RESET"); this.props.ResetAlert()}});     
    // }

    componentDidUpdate=()=>{
      var elem = document.getElementById("message-modal");
      var instances = null
      if(elem)
       instances = M.Modal.getInstance(elem);
      //  console.log("message modal",this.props.message,instances );
      if(this.props.message && instances && !instances.isOpen){
        // console.log("info", instances.el, instances.options, instances.isOpen);
        instances.open();
      }
    }
    
    render() {
    const { auth, message, type} = this.props;
    const user = auth.user;
    return (
    <div>
        <div id="message-modal" className="modal">
        {
            type === "success" ?
            <div>
            <div class="section upper_section_success">
              <h5><i class="large material-icons #5ff13a" style={{color:"white"}}>check_circle</i></h5>
              <h4>SUCCESS</h4>
            </div>
            <div class="divider"></div>
            <div class="section lower_section">
              <h5>{message}</h5>
            </div>
            </div>
            : 
            type === "error"?
            <div>
            <div class="section upper_section_error">
              <h5><i class="large material-icons #d61616" style={{color:"white"}}>cancel</i></h5>
              <h4>OOPS!</h4>
            </div>
            <div class="divider"></div>
            <div class="section lower_section">
              <h5>{message}</h5>
            </div>
            </div>
            :null
        }
        </div>
    </div>        
      );
    }
}

const mapStateToProps = (state,ownProps) => {
  console.log("State in design", state)
  return {
      auth:state.auth,
      message:state.alert.message,
      type:state.alert.type,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    ResetAlert: ()=> {
        dispatch(resetAlert());
      },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(MessageShowModal);
