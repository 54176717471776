import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router";
import Navbar from "../layout/Navbar";

export class ReplaceTeacher extends Component {
  state = {
    professor: [
      {
        id: 1,
        name: "Professor_1",
        // status: "absent",
        subject: [
          { name: "DBMS0", slot: "9:00-10:00" },
          // {name:"DBMS1",slot:"1:00-2:00"},
          // {name:"DBMS2",slot:"11:00-12:00"},
        ],
      },
      {
        id: 1,
        name: "Professor_2",
        // status: "present",
        subject: [
          // {name:"Programming0",slot:"9:00-10:00"},
          // {name:"Programming1",slot:"1:00-2:00"},
          { name: "Programming2", slot: "11:00-12:00" },
        ],
      },
      {
        id: 1,
        name: "Professor_3",
        // status: "absent",
        subject: [
          // {name:"DS0",slot:"9:00-10:00"},
          { name: "DS1", slot: "1:00-2:00" },
          // {name:"DS2",slot:"11:00-12:00"},
        ],
      },
    ],
    status: "on",
    OtherProf: [
      { name: "Professor_4" },
      { name: "Professor_5" },
      { name: "Professor_6" },
    ],
  };

  handleChange=(e)=>{
      console.log(document.getElementById("status").value)
      if(document.getElementById("status").value=="off"){
      document.getElementById("status").value="on";
      console.log(document.getElementById("status").value)
      this.setState({status: "off"})
    }
   
     else if(document.getElementById("status").value=="on"){
      document.getElementById("status").value="off";
      console.log(document.getElementById("status").value)
      this.setState({status: "on"})
    }
  }
  render() {
    const user = this.props.auth.user;
      console.log(this.state.status)
      if (!user) return <Redirect to="/login" />;
    return (
      <div>
        <Navbar heading="Admin" user={user} />
        <div className="row" style={{ marginLeft: 170, marginTop:41 }}>
          <div className="col s6 m5 l3">
            <b>Teacher</b>
          </div>
          <div className="col s6 m5 l2">
            <b>Subject</b>
          </div>
          <div className="col s6 m5 l1">
            <b>Slot</b>
          </div>
          <div className="col s6 m2 l2">
            <b>Status</b>
          </div>
          <div className="col s6 m2 l4">
            <b>Available Professor</b>
          </div>
        </div>
        <div>
          <ul
            className="collection"
            style={{
              backgroundColor: "rgb(255,255,255,0.5)",
              paddingTop: 15,
              paddingLeft: 70,
              // width:"60%",
              marginLeft: 90,
              marginRight: 90,
              boxShadow: "2px 20px 40px",
              height: "64vh",
              overflow: "auto",
            }}
          >
            {
              this.state.professor.length == 0 ?
              <div className="row">
                  <div className="col s6 m5 l5 offset-l5" style={{marginTop:"10%"}}>
                    <b>No class found</b>
                  </div>
                </div>
              :
              this.state.professor.map((user) => (
                <div className="row">
                  <div className="col s3 m3 l3">
                    <b>{user.name}</b>
                  </div>
                  {
                    user.subject.map((subject)=>(
                      <>
                      <div className="col s2 m2 l2">
                        <b>{subject.name}</b>
                      </div>
                      <div className="col s2 m2 l2">
                        <b>{subject.slot}</b>
                      </div>
                      </>
                    ))
                  }
                  <div className="col s1 m1 l1">
                  <div class="switch">
                  <label>
                    <input type="checkbox" id="status" onChange={this.handleChange}/>
                    <span class="lever"></span>
                  </label>
                </div>
                  </div>
                  <div className="col s4 m4 l4">
                      <select class="browser-default" disabled={this.state.status == "on"?true:false}>
                      <option value="" disabled selected>Choose your option</option>
                      {
                        this.state.OtherProf.map((avail)=>(
                          <option>{avail.name}</option>
                        ))
                      }
                    </select>
                  </div>
                </div>
              ))
            }
          </ul>
        </div>
        <div className="right" style={{ paddingRight: 80, paddingTop: 30 }}>
          <button
            class="btn waves-effect waves-light"
            type="submit"
            name="action"
          >
            Submit
          </button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ReplaceTeacher);
