import React, { Component } from "react";
import { connect } from "react-redux";
import { getTimeSlots } from "../../store/actions/TimeTableAction";
import {filterRoutine} from "../../store/actions/HodAction"
import { Redirect } from "react-router-dom";
import Navbar from "../layout/Navbar";
import ClassInfo from "../layout/ClassInfo";
import { printRoutine } from "../../store/actions/ProfessorAction";
// import "../style.css";

export class ClassSchedule extends Component {
  state={
    info:[],
    flag:true
  }
  componentDidMount() {
    console.log("...loding");
    if(this.props.auth.user){
      this.props.GetTimeSlots(this.props.auth.user.accessToken);
      this.props.FilterRoutine("Teacher",this.props.auth.user.id,this.props.auth.user.accessToken)
    }
  }

  componentDidUpdate(){
    if(this.state.flag && this.props.filterRoutineSuccess && !this.props.filterRoutineLoading){
      var filterRoutine= this.props.filterRoutine;
      console.log("filterRoutine",filterRoutine)
      var arr=[];
      var classInfo;
      for(var i=0; i<filterRoutine.length;i++){
        console.log(filterRoutine[i].Routines)
        for(var k=0;k<filterRoutine[i].Routines.length;k++){
          classInfo={
            time:filterRoutine[i].Routines[k].TimeSlot.slot,
            slot:filterRoutine[i].Routines[k].TimeSlot.day,
            sub:filterRoutine[i].Routines[k].Course.courseName,
            var1:filterRoutine[i].Routines[k].SectionGroup.name,
            type:filterRoutine[i].Routines[k].Course.courseType,
            var2:filterRoutine[i].Routines[k].Room.slot
          }
          arr.push(classInfo)
        }
      }
      this.setState({info: arr,flag: false})
    }
  }
  PrintSchedule=()=>{
    this.props.PrintRoutine('Teacher', this.props.auth.user.id,this.props.auth.user.accessToken)
  }
  render() {
    const user = this.props.auth.user;
    // console.log(this.state.index)
    if (!user) return <Redirect to="/login" />;
    return (
      <div>
        <Navbar heading="Admin" user={user} />
        <div className="row">
        <div className="right">
          <a class="btn-floating waves-effect waves-light black" onClick={this.PrintSchedule} style={{marginTop: 10, marginRight: 10}}>
            <i class="material-icons medium">play_for_work</i>
          </a>
        </div>
      </div>
        <table class="color-table">
          <thead class="thead-dark">
            <tr>
              <th scope="col">Day</th>
              {this.props.slots &&
                this.props.slots.map((slot) => <th scope="col">{slot}</th>)}
            </tr>
          </thead>
          <tbody>
            {this.props.days &&
              this.props.days.map((day) => (
                <tr>
                  <th scope="row">{day}</th>
                  {this.props.slots &&
                    this.props.slots.map((slot) => (
                      <td>
                        <ClassInfo
                          slot={slot}
                          day={day}
                          classInfo={this.state.info}
                        />
                      </td>
                    ))}
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  console.log(state);
  return {
    auth: state.auth,
    days: state.TimeTable.days,
    slots: state.TimeTable.slot,
    filterRoutine: state.Hod.filterRoutine,
    filterRoutineSuccess: state.Hod.filterRoutineSuccess,
    filterRoutineLoading: state.Hod.filterRoutineLoading
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    GetTimeSlots: (token) => {
      dispatch(getTimeSlots(token));
    },
    FilterRoutine:(filter,id,token)=>{
      dispatch(filterRoutine(filter,id,token))
    },
    PrintRoutine:(filter, id, token) => {
      dispatch(printRoutine(filter,id,token))
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ClassSchedule);
