import React from 'react'

export default function EditableQuestion({selectedQ, selectedSec, selectedLang, questions, OnSelect, OnEdit, fileChange, HadleMandatory, state, upload}) {
    console.log("log", questions
        .sections[selectedSec]
        .questions[selectedQ]
        .mandatory);
    return (
        <div className="row">
            <p>
                <label>
                    <input
                    id={"mandatory"}
                    // value={optionId}
                    name="mandatory"
                    className="filled-in checkbox-blue"
                    type="checkbox"
                    checked={questions
                        .sections[selectedSec]
                        .questions[selectedQ]
                        .mandatory?true:false}
                    onChange={HadleMandatory}
                    />
                    <span>Mandatory</span>
                </label>
            </p>
            <div className="row editable-question">
                <div className="col s11"onClick={(e)=>OnSelect(e, selectedQ, 0, 'question')}>
                        <div>
                            <div className="col s1">
                                <p>Q.</p>
                            </div>
                            <div className="col s10">
                                <p>{questions.sections[selectedSec].questions[selectedQ].question
                                ?questions.sections[selectedSec].questions[selectedQ].question[selectedLang]
                                :null
                            }</p>
                            </div>
                        </div>
                    {
                        questions.sections[selectedSec].questions[selectedQ].questionBodyType=="image"
                        ||questions.sections[selectedSec].questions[selectedQ].questionBodyType=="video"
                        ||questions.sections[selectedSec].questions[selectedQ].questionBodyType=="audio"?
                            <div className="row" style={{margin:0}}>
                                <div className="col s9">
                                    <p>
                                    <input
                                        type='file' 
                                        id={"question-"+selectedQ}
                                        onChange={fileChange}
                                        placeholder="Upload image"
                                        style={{
                                            marginLeft:10,
                                            whiteSpace: 'nowrap',
                                            overflow: 'hidden',
                                            textOverflow:'ellipsis',
                                            width: '50%'
                                        }}
                                    />
                                    </p>
                                    
                                </div>
                                <div className="col s1">
                                    {
                                        state.designFiles["question-"+selectedQ]?
                                            state.designFiles["question-"+selectedQ].status =="loading"?
                                                <div class="preloader-wrapper small active">
                                                    <div class="spinner-layer spinner-blue-only">
                                                    <div class="circle-clipper left">
                                                        <div class="circle"></div>
                                                    </div><div class="gap-patch">
                                                        <div class="circle"></div>
                                                    </div><div class="circle-clipper right">
                                                        <div class="circle"></div>
                                                    </div>
                                                    </div>
                                                </div>
                                            :
                                                state.designFiles["question-"+selectedQ].status =="selected"?
                                                    <i className="material-icons">check_box</i>
                                                :
                                                    state.designFiles["question-"+selectedQ].status =="done"?
                                                        <i style={{color:'green'}} className="material-icons">check_box</i>
                                                    :
                                                        null
                                        :
                                            null
                                    }
                                    
                                </div>
                                {
                                    state.designFiles["question-"+selectedQ] &&
                                    state.designFiles["question-"+selectedQ].status =="selected"?
                                    <div className=" col s1 right right-align">
                                        <button className="btn-small btn-floating" onClick={upload}>
                                            <i className="material-icons">file_upload</i>
                                        </button>
                                    </div>
                                    :
                                    null
                                }
                            </div>
                            :
                            <></>
                    }
                </div>
                {
                    <div className="col s1" onClick={(e)=>OnEdit(e, 'question',{})}>
                        <span className="right right-align" style={{padding:10}} >
                            <i class="material-icons right">edit</i>
                        </span>
                    </div>
                }
               
                
            </div>
            <div className="row">
                {   
                    questions.sections[selectedSec].questions[selectedQ].questionType == 'SINGLE'?
                        <div className= "">
                            {
                            Object.keys(questions.sections[selectedSec].questions[selectedQ].options).map( (optionId, index) => 
                                <div className='row editable-question' key={index}>
                                    <>
                                    <div className="col s11">
                                        {questions.sections[selectedSec].questions[selectedQ].options[optionId].option?
                                        index+1 + ". " + questions.sections[selectedSec].questions[selectedQ].options[optionId].option[selectedLang]
                                        :null}
                                    </div>
                                    <div className="col s1">
                                        <i class="material-icons right" onClick={(e)=>OnEdit(e, 'option',{optionId})}>edit</i>
                                    </div>
                                    </>
                                {
                                    questions.sections[selectedSec].questions[selectedQ].options[optionId].optionType=='image'
                                    || questions.sections[selectedSec].questions[selectedQ].options[optionId].optionType=='video'
                                    ||questions.sections[selectedSec].questions[selectedQ].options[optionId].optionType=='audio'?
                                        <p>
                                            <span>{index+1}</span>
                                            <input
                                                type='file' 
                                                id={"option-"+optionId}
                                                onChange={fileChange}
                                                placeholder="Upload image"
                                                style={{marginLeft:10}}
                                            />
                                        </p>
                                    :
                                        null
                                        
                                }                                    
                                </div>
                            )
                            }
                            {/* <div className='row'>
                            Answer:
                            </div> */}
                        </div>
                    :
                        questions.sections[selectedSec].questions[selectedQ].questionType == 'MULTIPLE'?
                            <div className= "">
                                {
                                Object.keys(questions.sections[selectedSec].questions[selectedQ].options).map( (optionId, index) => 
                                    <div className="row editable-question">
                                        <div className='col s11 ' key={index}>
                                            <p>{ questions.sections[selectedSec].questions[selectedQ].options[optionId].option?
                                            optionId + ". " + questions.sections[selectedSec].questions[selectedQ].options[optionId].option[selectedLang]
                                            :null} 
                                        </p>
                                        </div>
                                        <div className='col s1 ' key={index}>
                                            <i class="material-icons right" onClick={(e)=>OnEdit(e, 'option',{optionId})}>edit</i>                                            
                                        </div>
                                        {
                                        questions.sections[selectedSec].questions[selectedQ].options[optionId].optionType=='image'
                                        ||questions.sections[selectedSec].questions[selectedQ].options[optionId].optionType=='video'
                                        ||questions.sections[selectedSec].questions[selectedQ].options[optionId].optionType=='audio'?
                                            <div className="row" style={{margin:0}}>
                                                <div className="col s1">
                                                    {/* <p>{optionId}</p> */}
                                                </div>
                                                <div className="col s9">
                                                    <p>
                                                    <input
                                                        type='file' 
                                                        id={"option-"+optionId}
                                                        onChange={fileChange}
                                                        placeholder="Upload file"
                                                        style={{
                                                            marginLeft:10,
                                                            whiteSpace: 'nowrap',
                                                            overflow: 'hidden',
                                                            textOverflow:'ellipsis',
                                                            width: '50%'
                                                        }}
                                                    />
                                                    </p>
                                                    
                                                </div>
                                                <div className="col s1">
                                                    {
                                                        state.designFiles["option-"+optionId]?
                                                            state.designFiles["option-"+optionId].status =="loading"?
                                                                <div class="preloader-wrapper small active">
                                                                    <div class="spinner-layer spinner-blue-only">
                                                                    <div class="circle-clipper left">
                                                                        <div class="circle"></div>
                                                                    </div><div class="gap-patch">
                                                                        <div class="circle"></div>
                                                                    </div><div class="circle-clipper right">
                                                                        <div class="circle"></div>
                                                                    </div>
                                                                    </div>
                                                                </div>
                                                            :
                                                                state.designFiles["option-"+optionId].status =="selected"?
                                                                    <i className="material-icons">check_box</i>
                                                                :
                                                                    state.designFiles["option-"+optionId].status =="done"?
                                                                        <i style={{color:'green'}} className="material-icons">check_box</i>
                                                                    :
                                                                        null
                                                        :
                                                            null
                                                    }
                                                    
                                                </div>
                                                {
                                                    state.designFiles["option-"+optionId] &&
                                                    state.designFiles["option-"+optionId].status =="selected"?
                                                    <div className=" col s1 right right-align">
                                                        <button className="btn-small btn-floating" onClick={upload}>
                                                            <i className="material-icons">file_upload</i>
                                                        </button>
                                                    </div>
                                                    :
                                                    null
                                                }
                                            </div>
                                            :
                                            <></>
                                        }
                                    </div>
                                )
                                }
                            </div>
                        :
                        questions.sections[selectedSec].questions[selectedQ].questionType == 'GRID'?
                            <div className=''>
                            {
                                Object.keys(questions.sections[selectedSec].questions[selectedQ].groups).map( (groupId, gIndex) =>
                                <>

                                    <div className='row edit-group editable-question' key={gIndex}>
                                        <div className="col s11">
                                            {questions.sections[selectedSec].questions[selectedQ].groups[groupId].group?
                                            groupId + ". " + questions.sections[selectedSec].questions[selectedQ].groups[groupId].group[selectedLang]
                                            :null
                                        }
                                        </div>
                                        <div className="col s1">
                                            <i class="material-icons right" onClick={(e)=>OnEdit(e, 'group',{groupId})}>edit</i>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col s11 offset-s1">
                                        {
                                            Object.keys(questions.sections[selectedSec].questions[selectedQ].options).map( (optionId, index) => 
                                            
                                            <div className='row  editable-question' key={index}>
                                                <div className='col s12 ' key={index}>
                                                    <div className="col s12">
                                                    { questions.sections[selectedSec].questions[selectedQ].options[optionId].option?
                                                    optionId + ". " + questions.sections[selectedSec].questions[selectedQ].options[optionId].option[selectedLang]
                                                    :null}
                                                    <i class="material-icons right" onClick={(e)=>OnEdit(e, 'option',{optionId})}>edit</i>
                                                    </div>
                                                </div>
                                                {
                                                questions.sections[selectedSec].questions[selectedQ].options[optionId].optionType=='image'?
                                                    <div className="row" style={{margin:0}}>
                                                        <div className="col s1">
                                                            {/* <p>{optionId}</p> */}
                                                        </div>
                                                        <div className="col s9">
                                                            <p>
                                                            <input
                                                                type='file' 
                                                                id={"option-"+optionId}
                                                                onChange={fileChange}
                                                                placeholder="Upload image"
                                                                style={{
                                                                    marginLeft:10,
                                                                    whiteSpace: 'nowrap',
                                                                    overflow: 'hidden',
                                                                    textOverflow:'ellipsis',
                                                                    width: '50%'
                                                                }}
                                                            />
                                                            </p>
                                                        </div>
                                                        <div className="col s1">
                                                            {
                                                                state.designFiles["option-"+optionId]?
                                                                    state.designFiles["option-"+optionId].status =="loading"?
                                                                        <div class="preloader-wrapper small active">
                                                                            <div class="spinner-layer spinner-blue-only">
                                                                            <div class="circle-clipper left">
                                                                                <div class="circle"></div>
                                                                            </div><div class="gap-patch">
                                                                                <div class="circle"></div>
                                                                            </div><div class="circle-clipper right">
                                                                                <div class="circle"></div>
                                                                            </div>
                                                                            </div>
                                                                        </div>
                                                                    :
                                                                        state.designFiles["option-"+optionId].status =="selected"?
                                                                            <i className="material-icons">check_box</i>
                                                                        :
                                                                            state.designFiles["option-"+optionId].status =="done"?
                                                                                <i style={{color:'green'}} className="material-icons">check_box</i>
                                                                            :
                                                                                null
                                                                :
                                                                    null
                                                            }
                                                            
                                                        </div>
                                                        {
                                                            state.designFiles["option-"+optionId] &&
                                                            state.designFiles["option-"+optionId].status =="selected"?
                                                            <div className=" col s1 right right-align">
                                                                <button className="btn-small btn-floating" onClick={upload}>
                                                                    <i className="material-icons">file_upload</i>
                                                                </button>
                                                            </div>
                                                            :
                                                            null
                                                        }
                                                    </div>
                                                    :
                                                    <></>
                                                }
                                            </div>
                                        )
                                        }
                                        </div>
                                    </div>
                                    
                                </>
                                )
                            }
                            </div>
                            :
                            questions.sections[selectedSec].questions[selectedQ].questionType == 'RANK'?
                            <div className=''>
                            {
                                Object.keys(questions.sections[selectedSec].questions[selectedQ].options).map( (optionId, index) => 
                                        <div className='row  editable-question' key={index}>
                                            <div className="col s12 ">
                                                {questions.sections[selectedSec].questions[selectedQ].options[optionId].option[selectedLang]?
                                                optionId + ". " + questions.sections[selectedSec].questions[selectedQ].options[optionId].option[selectedLang]
                                                :null}
                                                <i class="material-icons right" onClick={(e)=>OnEdit(e, 'option',{optionId})}>edit</i>
                                            </div>
                                        {
                                        questions.sections[selectedSec].questions[selectedQ].options[optionId].optionType=='image'?
                                            <div className="row" style={{margin:0}}>
                                                <div className="col s1">
                                                    {/* <p>{optionId}</p> */}
                                                </div>
                                                <div className="col s9">
                                                    <p>
                                                    <input
                                                        type='file' 
                                                        id={"option-"+optionId}
                                                        onChange={fileChange}
                                                        placeholder="Upload image"
                                                        style={{
                                                            marginLeft:10,
                                                            whiteSpace: 'nowrap',
                                                            overflow: 'hidden',
                                                            textOverflow:'ellipsis',
                                                            width: '50%'
                                                        }}
                                                    />
                                                    </p>
                                                    
                                                </div>
                                                <div className="col s1">
                                                    {
                                                        state.designFiles["option-"+optionId]?
                                                            state.designFiles["option-"+optionId].status =="loading"?
                                                                <div class="preloader-wrapper small active">
                                                                    <div class="spinner-layer spinner-blue-only">
                                                                    <div class="circle-clipper left">
                                                                        <div class="circle"></div>
                                                                    </div><div class="gap-patch">
                                                                        <div class="circle"></div>
                                                                    </div><div class="circle-clipper right">
                                                                        <div class="circle"></div>
                                                                    </div>
                                                                    </div>
                                                                </div>
                                                            :
                                                                state.designFiles["option-"+optionId].status =="selected"?
                                                                    <i className="material-icons">check_box</i>
                                                                :
                                                                    state.designFiles["option-"+optionId].status =="done"?
                                                                        <i style={{color:'green'}} className="material-icons">check_box</i>
                                                                    :
                                                                        null
                                                        :
                                                            null
                                                    }
                                                    
                                                </div>
                                                {
                                                    state.designFiles["option-"+optionId] &&
                                                    state.designFiles["option-"+optionId].status =="selected"?
                                                    <div className=" col s1 right right-align">
                                                        <button className="btn-small btn-floating" onClick={upload}>
                                                            <i className="material-icons">file_upload</i>
                                                        </button>
                                                    </div>
                                                    :
                                                    null
                                                }
                                            </div>
                                            :
                                            <></>
                                        }
                                    </div>
                                )
                                }
                            </div>
                            :
                                questions.sections[selectedSec].questions[selectedQ].questionType == 'MIXED'?
                                <div className=''>
                                {
                                    Object.keys(questions.sections[selectedSec].questions[selectedQ].options).map( (optionId, index) => 
                                            <div className='row editable-question' key={index}>
                                                <div className="col s12">
                                                    <div className="col s11">   
                                                        {questions.sections[selectedSec].questions[selectedQ].options[optionId].option?
                                                        optionId + ". " + questions.sections[selectedSec].questions[selectedQ].options[optionId].option[selectedLang]
                                                        :null}
                                                    </div>
                                                    <div className="col s1">
                                                        <i class="material-icons right" onClick={(e)=>OnEdit(e, 'option',{optionId})}>edit</i>
                                                    </div>
                                                </div>
                                                
                                            {
                                            questions.sections[selectedSec].questions[selectedQ].options[optionId].optionType=='image'?
                                                <div className="row" style={{margin:0}}>
                                                    <div className="col s1">
                                                        {/* <p>{optionId}</p> */}
                                                    </div>
                                                    <div className="col s9">
                                                        <p>
                                                        <input
                                                            type='file' 
                                                            id={"option-"+optionId}
                                                            onChange={fileChange}
                                                            placeholder="Upload image"
                                                            style={{
                                                                marginLeft:10,
                                                                whiteSpace: 'nowrap',
                                                                overflow: 'hidden',
                                                                textOverflow:'ellipsis',
                                                                width: '50%'
                                                            }}
                                                        />
                                                        </p>
                                                        
                                                    </div>
                                                    <div className="col s1">
                                                        {
                                                            state.designFiles["option-"+optionId]?
                                                                state.designFiles["option-"+optionId].status =="loading"?
                                                                    <div class="preloader-wrapper small active">
                                                                        <div class="spinner-layer spinner-blue-only">
                                                                        <div class="circle-clipper left">
                                                                            <div class="circle"></div>
                                                                        </div><div class="gap-patch">
                                                                            <div class="circle"></div>
                                                                        </div><div class="circle-clipper right">
                                                                            <div class="circle"></div>
                                                                        </div>
                                                                        </div>
                                                                    </div>
                                                                :
                                                                    state.designFiles["option-"+optionId].status =="selected"?
                                                                        <i className="material-icons">check_box</i>
                                                                    :
                                                                        state.designFiles["option-"+optionId].status =="done"?
                                                                            <i style={{color:'green'}} className="material-icons">check_box</i>
                                                                        :
                                                                            null
                                                            :
                                                                null
                                                        }
                                                        
                                                    </div>
                                                    {
                                                        state.designFiles["option-"+optionId] &&
                                                        state.designFiles["option-"+optionId].status =="selected"?
                                                        <div className=" col s1 right right-align">
                                                            <button className="btn-small btn-floating" onClick={upload}>
                                                                <i className="material-icons">file_upload</i>
                                                            </button>
                                                        </div>
                                                        :
                                                        null
                                                    }
                                                </div>
                                                :
                                                <></>
                                            }
                                        </div>
                                    )
                                    }
                                </div>
                                :
                                null
                }
                    
            </div>
        </div>
    )
}
