import React, { useState, useEffect } from "react";
import GridTable from "./GridTable";
import OptionViewer from "./OptionViewer";
import TextViewer from "./TextViewer";

export default function SpecificQuestion({
  selectedQ,
  selectedSec,
  selectedLang,
  questions,
  handleChange,
  languages,
  OnLanguageChange,
  HandleNextQuestionChange,
  HandlePrevQuestionChange,
  state,
  onFileUploader,
  uploadSuccess,
  uploadError,
  uploadLoading,
  uploadFile,
  onSubmit,
  image,
  mode,
  sid,
  sname,
  vid,
  css,
}) {
  console.log(questions, selectedQ, selectedSec, selectedLang);
  var nextSec = questions.sections[selectedSec].questions[selectedQ].questionFlow.next.sectionId;
  var nextQ = questions.sections[selectedSec].questions[selectedQ].questionFlow.next.questionId;
  console.log("$$$$",nextSec,nextQ,"$$$$")

  const [rankData, setRankData] = useState([]);
  const [selectedRank, setSelectedRank] = useState([]);
  // alert("i am in")
  const convertData = () => {
    console.log(
      "current data",
      questions.sections[selectedSec].questions[selectedQ].options
    );
    const keys = Object.keys(
      questions.sections[selectedSec].questions[selectedQ].options
    );
    console.log(
      "current data",
      questions.sections[selectedSec].questions[selectedQ].options
    );
    var newData = [];
    for (let i = 0; i < keys.length; i++) {
      const k =
        questions.sections[selectedSec].questions[selectedQ].options[keys[i]];
      // console.log("dat aload12",props.data.options[keys[i]]);
      k["optionId"] = keys[i];
      if(selectedRank.length==0||!selectedRank.find(data=>data.optionId==keys[i]))
      newData.push(k);
    }
    setRankData(newData);
  };

  useEffect(() => {
    console.log("rendering...");
    convertData();
  }, [
    questions.sections[selectedSec].questions[selectedQ].questionType == "RANK",
  ]);

  const rankHandler = (data, index) => {
    console.log("data & index", data, index);
    const remainRank = rankData.filter((rank, i) => {
      return data.optionId != rank.optionId;
    });
    console.log(remainRank);
    setRankData(remainRank);
    const selectRank = rankData.splice(index, 1);
    console.log("selected rank", selectRank);
    setSelectedRank([...selectedRank, ...selectRank]);
  };

  const removeData = (data, index) => {
    console.log("data & index", data, index);
    const remainSelectedData = selectedRank.filter((rank, i) => {
      return data.optionId != rank.optionId;
    });
    console.log(remainSelectedData);
    setSelectedRank(remainSelectedData);
    const selectRemoveRank = selectedRank.splice(index, 1);
    console.log(selectRemoveRank);
    setRankData([...rankData, ...selectRemoveRank]);
  };
  return (   
    <div
      className="col s12 mobile-screen"
      style={questions.sections[selectedSec].questions[selectedQ].pageStyle}
    >
    <div className="row" style={{ margin: 0 }}>
    <div className="col s6">
      <p>
        <img
          className=""
          src={image?image:require("../../image/logo.png")}
          alt="user"
          width="100%"
        />
      </p>
    </div>
    <div className="col s6">
      <p className="right right-align">
        <span>
          <i className="material-icons">language</i>
        </span>
        <span>
          <select
            // required
            defaultValue="default"
            onChange={OnLanguageChange}
            className="browser-default select-lanuage"
            id="selectedLanguage"
          >
            <option value="default" selected>
              default
            </option>
            {languages &&
              languages.map((lang) => (
                <option value={lang.code}>{lang.code}</option>
              ))}
          </select>
        </span>
      </p>
    </div>
    <div className="col s12" style={{fontSize:12, color:"grey"}}>
      <span>Test: {sname}  ID: {sid}  VID:{vid}</span> 
    </div>
  </div>
  <div
    className={""}
    style={
      questions.sections[selectedSec].questions[selectedQ].qStyle
        ? questions.sections[selectedSec].questions[selectedQ].qStyle
        : {}
    }
  >
    {questions.sections[selectedSec].questions[selectedQ]
      .questionBodyType == "text" ? 
      <>
        <TextViewer question={questions.sections[selectedSec].questions[selectedQ]} lang={selectedLang} type='question'/>
      </>
      : questions.sections[selectedSec].questions[selectedQ]
        .questionBodyType == "video" ? (
      <div>
        <video
          style={{ width: "90%", margin: 20 }}
          controls
          // autoPlay
          src={
            questions.sections[selectedSec].questions[selectedQ].question.filePath
          }
        />
        <TextViewer question={questions.sections[selectedSec].questions[selectedQ]} lang={selectedLang} type='question'/>
      </div>
    ) : questions.sections[selectedSec].questions[selectedQ]
        .questionBodyType == "image" ? (
      <div>
        <img
          style={{ width: "90%", margin: 20 }}
          controls
          // autoPlay
          src={
            questions.sections[selectedSec].questions[selectedQ].question.filePath
          }
        />
        <TextViewer question={questions.sections[selectedSec].questions[selectedQ]} lang={selectedLang} type='question'/>
      </div>
    ) : null}
  </div>

  <div className="">
    {questions.sections[selectedSec].questions[selectedQ].questionType ==
    "SINGLE" ? (
      <form>
        {Object.keys(
          questions.sections[selectedSec].questions[selectedQ].options
        ).map((optionId, index) =>
          !state.blockedOptions.find((id) => id == optionId) ? (
            <div
              className=""
              style={
                questions.sections[selectedSec].questions[selectedQ].opStyle
                  ? index % 2 == 0
                    ? questions.sections[selectedSec].questions[selectedQ]
                        .opStyle.even
                    : questions.sections[selectedSec].questions[selectedQ]
                        .opStyle.odd
                  : {}
              }
              key={index}
            >
              <label style={{ textDecorationColor: "black" }}>
                <input
                  className="with-gap"
                  name="single"
                  id="optionId"
                  value={optionId}
                  type="radio"
                  onChange={handleChange}
                    checked={state.optionId == optionId}
                />
                <span></span> 
              </label>
              <OptionViewer
                  option={
                    questions.sections[selectedSec].questions[selectedQ]
                      .options[optionId]
                  }
                  lang={selectedLang}
                />
            </div>
          ) : null
        )}
      </form>
    ) : questions.sections[selectedSec].questions[selectedQ].questionType ==
      "RANK" ? (
      <form>
        <div
          className=""
        >
          <div className="card rank-card">
            {rankData.map((optionId, index) => (
              <div
                className=""
                style={
                  questions.sections[selectedSec].questions[selectedQ]
                    .opStyle
                    ? index % 2 == 0
                      ? questions.sections[selectedSec].questions[selectedQ]
                          .opStyle.even
                      : questions.sections[selectedSec].questions[selectedQ]
                          .opStyle.odd
                    : {}
                }
                key={index}
              >
                <p>
                <OptionViewer
                  option={rankData[index]}
                  index={index}
                  data={optionId}
                  click={rankHandler}
                  lang={selectedLang}
                />
                </p>
                
              </div>
            ))}
          </div>
          <div className="card rank-card">
            {selectedRank &&
              selectedRank.map((optionId, index) => (
                <div
                  className=""
                  style={
                    questions.sections[selectedSec].questions[selectedQ]
                      .opStyle
                      ? index % 2 == 0
                        ? questions.sections[selectedSec].questions[
                            selectedQ
                          ].opStyle.even
                        : questions.sections[selectedSec].questions[
                            selectedQ
                          ].opStyle.odd
                      : {}
                  }
                  key={index}
                >
                  <p>
                    <OptionViewer
                    option={selectedRank && selectedRank[index]}
                    index={index}
                    data={optionId}
                    click={removeData}
                    lang={selectedLang}
                  />

                  </p>
                  
                </div>
              ))}
          </div>
        </div>
      </form>
    ) : questions.sections[selectedSec].questions[selectedQ].questionType ==
      "MULTIPLE" ? (
      <form>
        {Object.keys(
          questions.sections[selectedSec].questions[selectedQ].options
        ).map((optionId, index) =>
          !state.blockedOptions.find((id) => id == optionId) ? (
            <div
              className="valign-wrapper"
              style={
                questions.sections[selectedSec].questions[selectedQ].opStyle
                  ? index % 2 == 0
                    ? questions.sections[selectedSec].questions[selectedQ]
                        .opStyle.even
                    : questions.sections[selectedSec].questions[selectedQ]
                        .opStyle.odd
                  : {}
              }
              key={index}
            >
              <label>
                <input
                  id={"multiple-"+optionId}
                  value={optionId}
                  name="multiple"
                  type="checkbox"
                  checked={
                    state.multiple[optionId]
                      ? state.multiple[optionId]
                      : false
                  }
                  onChange={handleChange}
                />
                <span></span>
              </label>
              <OptionViewer
                  option={
                    questions.sections[selectedSec].questions[selectedQ]
                      .options[optionId]
                  }
                  lang={selectedLang}
                />
            </div>
          ) : null
        )}
      </form>
    ) : questions.sections[selectedSec].questions[selectedQ].questionType ==
      "TEXT" ? (
      <form>
        <div className="col s12">
          <input
            className="rounded-input"
            id="input"
            name="text"
            type="text"
            onChange={handleChange}
            value={state.input}
          />
        </div>
      </form>
    ) : questions.sections[selectedSec].questions[selectedQ].questionType ==
      "NUMERIC" ? (
      <form>
        <div className="col s12">
          <input
            className="rounded-input"
            id="input"
            name="numeric"
            type="number"
            onChange={handleChange}
            value={state.input}
          />
        </div>
      </form>
    ) : questions.sections[selectedSec].questions[selectedQ].questionType ==
      "GRID" ? (
        state.orientation == 'potrait'?
        <form>
          {Object.keys(
            questions.sections[selectedSec].questions[selectedQ].groups
          ).map((groupId, ind) => (
            <div>
              <p key={ind}>
                {
                  questions.sections[selectedSec].questions[selectedQ].groups[
                    groupId
                  ].group[selectedLang]
                }
              </p>
              {Object.keys(
                questions.sections[selectedSec].questions[selectedQ].options
              ).map((optionId, index) => (
                <div
                  className=""
                  style={
                    questions.sections[selectedSec].questions[selectedQ].opStyle
                      ? index % 2 == 0
                        ? {
                            ...questions.sections[selectedSec].questions[
                              selectedQ
                            ].opStyle.even,
                            marginLeft: 20,
                          }
                        : {
                            ...questions.sections[selectedSec].questions[
                              selectedQ
                            ].opStyle.odd,
                            marginLeft: 20,
                          }
                      : { marginLeft: 20 }
                  }
                  key={index}
                >
                  <label>
                    <input
                      className="with-gap browser-default"
                      type="radio"
                      id={"gr-"+ groupId+'-op-'+ optionId}
                      name={"gridGroup"+groupId}
                      checked={
                        state.grid[groupId]&&state.grid[groupId][optionId]? true : false
                      }
                      // value={optionId}
                      onChange={handleChange}
                    />
                    <span></span>
                  </label>
                    <OptionViewer
                      option={
                        questions.sections[selectedSec].questions[selectedQ]
                          .options[optionId]
                      }
                      lang={selectedLang}
                    />
                </div>
              ))}
            </div>
          ))}
        </form>
        :
        <GridTable 
        options={questions.sections[selectedSec].questions[selectedQ].options} 
        groups={questions.sections[selectedSec].questions[selectedQ].groups} 
        questions={questions} 
        selectedLang={selectedLang} 
        selectedSec={selectedSec}
        selectedQ={selectedQ}
        state={state}
        handleChange={handleChange}
        />

    ) : questions.sections[selectedSec].questions[selectedQ].questionType ==
      "VIDEO" ? (
      <form>
        <div className="col s12">
          <input
            required
            id="mediaFile"
            type="file"
            name="file"
            accept=".mp4,.mov,.wmv,.flv,.avi,.mpeg-4,.mkv "
            onChange={onFileUploader}
            style={{ margin: 10 }}
          />
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <button className="btn btn-small" onClick={uploadFile} disabled={uploadLoading} >
              upload
            </button>
            {uploadSuccess ? (
              <>
                <i class="material-icons" style={{ color: "green" }}>
                  check
                </i>
                <span style={{ color: "green" }}>added successfully</span>
              </>
            ) : uploadError ? (
              <>
                <i class="material-icons" style={{ color: "red" }}>
                  clear
                </i>
                <span style={{ color: "red" }}>oops!!something wrong</span>
              </>
            ) : null}
          </div>
        </div>
      </form>
    ) : questions.sections[selectedSec].questions[selectedQ].questionType ==
      "AUDIO" ? (
      <form>
        <div className="col s12">
          <input
            required
            id="mediaFile"
            type="file"
            name="file"
            accept=".mp3,.flac,.m4a,.wma,.aac"
            onChange={onFileUploader}
            style={{ margin: 10 }}
          />
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <button className="btn btn-small" onClick={uploadFile} disabled={uploadLoading} >
              upload
            </button>
            {uploadSuccess ? (
              <>
                <i class="material-icons" style={{ color: "green" }}>
                  check
                </i>
                <span style={{ color: "green" }}>added successfully</span>
              </>
            ) : uploadError ? (
              <>
                <i class="material-icons" style={{ color: "red" }}>
                  clear
                </i>
                <span style={{ color: "red" }}>oops!!something wrong</span>
              </>
            ) : null}
          </div>
        </div>
      </form>
    ) : questions.sections[selectedSec].questions[selectedQ].questionType ==
      "IMAGE" ? (
      <form>
        <div className="col s12">
          <input
            required
            id="mediaFile"
            type="file"
            name="file"
            accept=".jpeg,.jpg,.png "
            onChange={onFileUploader}
            style={{ margin: 10 }}
          />
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <button className="btn btn-small" onClick={uploadFile} disabled={uploadLoading} >
              upload
            </button>
            {uploadSuccess ? (
              <>
                <i class="material-icons" style={{ color: "green" }}>
                  check
                </i>
                <span style={{ color: "green" }}>added successfully</span>
              </>
            ) : uploadError ? (
              <>
                <i class="material-icons" style={{ color: "red" }}>
                  clear
                </i>
                <span style={{ color: "red" }}>oops!!something wrong</span>
              </>
            ) : null}
          </div>
        </div>
      </form>
    ) : 
    questions.sections[selectedSec].questions[selectedQ].questionType ==
      "MIXED" ? (
      <form>
        {Object.keys(
          questions.sections[selectedSec].questions[selectedQ].options
        ).map((optionId, index) =>
          !state.blockedOptions.find((id) => id == optionId) ? 
          questions.sections[selectedSec].questions[selectedQ].options[optionId].optionTypeGroup=="MULTIPLE" ? (
              <div
                className=""
                style={
                  questions.sections[selectedSec].questions[selectedQ].opStyle
                    ? index % 2 == 0
                      ? questions.sections[selectedSec].questions[selectedQ]
                          .opStyle.even
                      : questions.sections[selectedSec].questions[selectedQ]
                          .opStyle.odd
                    : {}
                }
                key={index}
              >
                <label>
                  <input
                    id={"mix-multiple-"+optionId}
                    name="mix"
                    type="checkbox"
                    checked={
                      state.mix.multiple[optionId]
                        ? state.mix.multiple[optionId]
                        : false
                    }
                    onChange={handleChange}
                    // value={optionId}
                  />
                 <span></span>
                </label>
                <OptionViewer
                    option={
                      questions.sections[selectedSec].questions[selectedQ]
                        .options[optionId]
                    }
                    lang={selectedLang}
                  />
              </div>
            ) : 

              <div
                className=""
                style={
                  questions.sections[selectedSec].questions[selectedQ].opStyle
                    ? index % 2 == 0
                      ? questions.sections[selectedSec].questions[selectedQ]
                          .opStyle.even
                      : questions.sections[selectedSec].questions[selectedQ]
                          .opStyle.odd
                    : {}
                }
                key={index}
              >
                <label>
                  <input
                    id={"mix-single-"+optionId}
                    name="mix"
                    type="checkbox"
                    checked={
                      state.mix.single[optionId]
                        ? state.mix.single[optionId]
                        : false
                    }
                    onChange={handleChange}
                  />
                  <span></span>
                </label>
                  <OptionViewer
                    option={
                      questions.sections[selectedSec].questions[selectedQ]
                        .options[optionId]
                    }
                    lang={selectedLang}
                  />
              </div>
              : 
          null
        )}
      </form>
      )
      :questions.sections[selectedSec].questions[selectedQ].questionType ==
      "DATETIME" ? (
        <form>
          <div className="col s12">
            <input
              className="rounded-input"
              id="input"
              name="datetime"
              type="datetime-local"
              onChange={handleChange}
              value={state.input}
            />
            <label for="datetime-input"></label>
          </div>
        </form>
      )
      :null
    }
  </div>
  <div className="col s12">
    {state.activateSubmit?
      <div className="center center-align">
        <div className="row" /* style={{display:"flex", flexDirection:"row", justifyContent:"space-between"}} */>
            <div
            className="btn-floating btn"
            onClick={() =>
              HandlePrevQuestionChange(questions, selectedQ, selectedSec)
            }
          >
            {/* <i class="material-icons" id="rightAlign" onClick={that.HandleOptionStyleChange}> */}
            <i class="material-icons" style={{ marginLeft: 7 }}>
              arrow_backward
            </i>
          </div>
          <div
            className="btn-floating btn"
            onClick={() =>
              HandleNextQuestionChange(
                questions,
                selectedQ,
                selectedSec,
                selectedRank
              )
            }
          >
            {/* <i class="material-icons" id="rightAlign" onClick={that.HandleOptionStyleChange}> */}
            <i class="material-icons">arrow_forward</i>
          </div>
        </div>
        <div style={{ margin: 10}}>
          <div className="btn" onClick={(e)=>
          mode=="preview"?
          HandleNextQuestionChange(
            questions,
            selectedQ,
            selectedSec,
            selectedRank
          )
          :
            onSubmit(
              e,
              questions,
              selectedQ,
              selectedSec,
              selectedRank)}>Submit</div>
        </div>
      </div>
      :
      <div className="center center-align">
      <div className="row" /* style={{display:"flex", justifyContent:"space-between"}} */>
      <div
      className="btn-floating btn"
      onClick={() =>
        HandlePrevQuestionChange(questions, selectedQ, selectedSec)
      }
    >
      {/* <i class="material-icons" id="rightAlign" onClick={that.HandleOptionStyleChange}> */}
      <i class="material-icons" style={{ marginLeft: 7 }}>
        arrow_backward
      </i>
    </div>
    <div
      className="btn-floating btn"
      onClick={() =>
        HandleNextQuestionChange(
          questions,
          selectedQ,
          selectedSec,
          selectedRank
        )
      }
    >
      {/* <i class="material-icons" id="rightAlign" onClick={that.HandleOptionStyleChange}> */}
      <i class="material-icons">arrow_forward</i>
    </div>
      </div>
      </div>
    }
  </div>
    </div>
  );
}
