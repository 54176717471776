import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Redirect } from "react-router-dom";
import Navbar from '../layout/Navbar'

export class AnnouncementFaculty extends Component {
    state={
        notice:[
            {
                id:1,
                notice: "Notice regarding appointment in Santhali Medium of Schools.(no:1731/N/S dt:24/02/2021)"
            },
            {
                id: 2,
                notice: "Notification regarding confirmation of services of new recruits who have been transferred after joining.(no:D.S.(Aca)/98/C/68 dt:09/02/2021)"
            },
            {
                id: 3,
                notice: "Notification regarding special issue of parsad barta 'DWISTAJANMA BARSHE ISWAR CHANDRA VIDYASAGAR: SRADHANJALI' (no:D.S.(Aca)/42/P/2(E) dt:18/01/2021)"
            }
        ]
    }
    render() {
        const user = this.props.auth.user;
        if (!user) return <Redirect to="/login" />;
        return (
          <div>
            <Navbar heading="Teacher" user={user} />
            <div className="container">
                <div className="row">
                    <div className="col s1 m1 l1" style={{marginTop: 31, paddingLeft: 127}}>
                        <i className="material-icons">search</i>
                    </div>
                    <div className="col s7 m7 l7">
                        <div class="input-field col s12 m12 l12">
                            <input id="password" type="password" class="validate" placeholder="Search here..."/>
                        </div>
                    </div>
                    <div className="col s2 m2 l2" style={{marginTop: 19, paddingLeft: 0}}>
                        <a className="btn">Search</a>
                    </div>
                </div>
                <div className="row">
                    <div className="col s1 m1 l1"><b>Sl No</b></div>
                    <div className="col s10 m10 l10"><b><center>Notice</center></b></div>
                    <div className="col s1 m1 l1"><b>Download</b></div>
                </div>
                <div>
                    {
                        this.state.notice.map((notice)=>(
                            <div  className="row">
                                <div className="col s1 m1 l1"><span>{notice.id}</span></div>
                                <div className="col s10 m10 l10"><span>{notice.notice}</span></div>
                                <div className="col s1 m1 l1"><i className="material-icons">download</i></div>
                            </div>
                        ))
                    }
                </div>
            </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
      auth: state.auth,
    };
  };

const mapDispatchToProps = {
    
}

export default connect(mapStateToProps, mapDispatchToProps)(AnnouncementFaculty)
