const initState = {
  surveys: null,
  surveyVersion: null,
  loadingSurveys: false,

  stat: null,
  loadingSurveyStat: false,

  surveyResponse: null,
  responseSurveyMessage: null,
  loadingResponse: false,

  rejectORapproveSuccess: false,
  rejectORapproveError: false,
  rejectORapproveLoading: false,
  rejectORapproveMessage: null,

  editAnswer: null,
  editAnswerMessage: null,

  loadingVersions:false,
  versions:null,

  checks: null,

  editSuccess:null,
  editError:null,
  response:null,

  flagSuccess: null,
  flagError: null,
  flag: null,

  pushBackSuccess: false,
  pushBackError: false,
  pushBackLoading: false,

  filter:null,

  loadingTestResponse: true,
  surveyTestResponse:null,
  createResultLoading:false,
  createResultSuccess:false,
  createResultError:false,
};

const QualityReducer = (state = initState, action) => {
    switch (action.type) {
      //fetch surveys
      case "LIVE_SURVEY_FETCH_LOADING":
        return {
          ...state,
          loadingSurveys: true,
          // surveys: null,
        };
      case "LIVE_SURVEY_FETCH_SUCCESS":
        return {
          ...state,
          loadingSurveys: false,
          surveys: action.payload,
          // surveyVersion: action.payload.map((data)=>({...data.SurveyVersions}))
        };
      case "SURVEY_STAT_FETCH_LOADING":
        return {
          ...state,
          loadingSurveyStat: true,
          stat: null,
        }
      case "SURVEY_STAT_FETCH_SUCCESS":
        return {
          ...state,
          loadingSurveyStat: false,
          stat: 
          action.payload.length == "0" ? 
          [
            {
              "responseCount" : "-",
              "qcApproved" : "-",
              "qcDecliened" : "-",
              "qcPushed" : "-",
              "interviewerCount" : "-"
            }
          ] 
          :
          action.payload 
        }
      case "RESPONSE_SURVEY_LOADING":
        return {
          ...state,
          loadingResponse: true,
          surveyResponse:null,
        }
      case "RESPONSE_SURVEY_SUCCESS":
        return {
          ...state,
          loadingResponse: false,
          surveyResponse: action.payload.data,
        }
      case "RESPONSE_SURVEY_FAILED":
        return {
          ...state,
          loadingResponse: false,
          responseSurveyMessage: action.payload
        }
      case "VERSION_FETCH_SUCCESS":
        return {
          ...state,
          loadingVersions:false,
          versions:action.data
        };
        case "CHECK_USER":
          return {
            ...state,
            checks: action.payload,
          }
    case 'EDIT_ANSWER_SUCCESS':
      return {
        ...state,
        editSuccess: true,
        editError: false,
        response: action.payload
      }
    case 'EDIT_ANSWER_FAILED':
      return {
        ...state,
        editSuccess: false,
        editError: true,
      }
    case 'CHANGE_FLAG_SUCCESS':
      return {
        ...state,
        flagSuccess: true,
        flagError: false,
        flag: action.payload
      }
    case 'CHANGE_FLAG_FAILED':
      return {
        ...state,
        flagSuccess: false,
        flagError: true,
      }
    case 'REJECT_APPROVE_SUCCESS':
      return {
        ...state,
        rejectORapproveSuccess: true,
        rejectORapproveError: false,
        rejectORapproveLoading: false,
      }
    case 'REJECT_APPROVE_FAILED':
      return {
        ...state,
        rejectORapproveSuccess: false,
        rejectORapproveError: true,
        rejectORapproveLoading: false,
      }
    case 'PUSH_BACK_SUCCESS':
      return {
        ...state,
        pushBackSuccess: true,
        pushBackError: false,
        pushBackLoading: false,
      }
    case 'PUSH_BACK_FAILED':
      return {
        ...state,
        pushBackSuccess: false,
        pushBackError: true,
        pushBackLoading: false,
      }
    case 'PUSH_BACK_STATUS_RESET':
      return {
        ...state,
        pushBackSuccess: false,
        pushBackError: false,
        pushBackLoading: false,
      }
    case 'ACTION_STATE_RESET':
      return {
        ...state,
        rejectORapproveSuccess: false,
        rejectORapproveError: false,
        rejectORapproveLoading: false,
      }
    case 'RESPONSE_EXCEL_SUCCESS':
      return {
        ...state,
        resDownloadSuccess: true,
        resDownloadError: false,
      }
    case 'RESPONSE_EXCEL_ERROR':
      return {
        ...state,
        resDownloadSuccess: false,
        resDownloadError: true,
      }
    case 'DOWNLOAD_STATUS_RESET':
      return {
        ...state,
        resDownloadSuccess: false,
        resDownloadError: false,
      }
    case 'EDIT_STATUS_RESET':
      return {
        ...state,
        editSuccess: false,
        editError: false,
      }
    case 'FILTER_SAVE_SUCCESS':
      return {
        ...state,
        filter:action.payload
      }
    case "TEST_RESPONSE_SURVEY_LOADING":
      return {
        ...state,
        loadingTestResponse: true,
        surveyTestResponse:null,
      }
    case "TEST_RESPONSE_SURVEY_SUCCESS":
      return {
        ...state,
        loadingTestResponse: false,
        surveyTestResponse: action.payload.data,
      }
    case "TEST_RESPONSE_SURVEY_FAILED":
      return {
        ...state,
        loadingTestResponse: false,
      }
    case "GENERATE_RESULT_LOADING":
      return {
        ...state,
        createResultLoading:true,
        createResultSuccess:false,
        createResultError:false,
      };
    case "GENERATE_RESULT_SUCCESS":
      return {
        ...state,
        createResultLoading:false,
        createResultSuccess:true,
        createResultError:false,
      };
    case "GENERATE_RESULT_ERROR":
      return {
        ...state,
        createResultLoading:false,
        createResultSuccess:false,
        createResultError:true,
      }
    default:
      return state;
  }
};
export default QualityReducer;
