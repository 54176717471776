import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router";
import Navbar from "../layout/Navbar";
import salary from "./salary-slip.pdf"

export class SalarySlip extends Component {
  render() {
    const user = this.props.auth.user;
    if (!user) return <Redirect to="/login" />;
    return (
      <div>
        <Navbar heading="Admin" user={user} />
        <div className="container">
          <iframe
            // src="https://www.niveshmarket.com/wp-content/uploads/2020/05/download-salary-slip-in-pdf-format.pdf"
            src={salary}
            style={{ width: "100%", height: "83vh", border: "none" }}
          ></iframe>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(SalarySlip);
