import React, { Component } from "react";
import { connect } from "react-redux";
import "../stylesheets/designer.css";
import SpecificQuestion from "./SpecificQuestion";
import {
  testSurvey,
  saveAnswer,
  prevQues,
  getLanguages,
  uploadFile,
  submitAnswer
} from "../../store/actions/TestAction";
import {displayLogicEvaluator, skipLogicEvaluator} from "../../utils/logicBuild";
import { resetAlert, setAlert } from "../../store/actions/MessageAction";
import M from 'materialize-css'

export class TestSurvey extends Component {
  state = {
    questions: {},
    orientation: "potrait",
    selectedQ: null,
    selectedSec: null,
    selectedLanguage: "default",
    blockedOptions:[],
    blockedGroups:[],
    input: "",
    optionId: "",
    multiple:{
      multipleId: null
    },
    grid:{},
    mix:{
        single:{},
        multiple:{}
    },
    url:null,
    uplodedFile:null,
    activateSubmit:false,
  };

  componentDidMount = () => {
    this.props.TestSurvey(
      this.props.sid,
      this.props.vid,
      // this.props.auth.user.accessToken
    );
    var elems = document.querySelectorAll(".modal");
    M.Modal.init(elems, {onOpenEnd:()=>{M.updateTextFields()}, onCloseEnd:()=>{this.props.ResetAlert()}});
    this.setState({questions: this.props.testQuestions})
  };

  componentDidUpdate = () => {
    if (this.state.selectedSec == null && this.state.selectedQ == null&&!this.state.activateSubmit) {
      this.props.testSurveyQuestion.questionnaire &&
        this.props.testSurveyQuestion.questionnaire.sections &&
        Object.keys(
          this.props.testSurveyQuestion.questionnaire.sections
        ).map((sectionId, sindex) =>
          Object.keys(
            this.props.testSurveyQuestion.questionnaire.sections[sectionId]
              .questions
          ).map((qid, qindex) =>
            sindex == 0 && qindex == 0
              ? this.setState({ selectedSec: sectionId, selectedQ: qid })
              : null
          )
        );
    }

    if(this.props.submitTestSuccess){
      this.props.SetAlert('success', "Response submitted successfully");
    }
    if(this.props.submitTestError){
      this.props.SetAlert('error', "Submit error... Try again")
    }   
    
  };

  HandleStatusChange = async (e) => {
    await this.setState({ ...this.state, [e.target.id]: e.target.value });
    //   var token = this.props.auth.user.accessToken;
    //   var surveyId = this.props.id;
    //   var versionId = this.props.versionId;
    //   this.props.SaveChanges(
    //     { surveyInfo: { status: this.state.surveyStatus } },
    //     surveyId,
    //     versionId,
    //     token
    //   );
  };

  handleChange = (e,groupId) => {
    if(e.target.name == "multiple"){
      if(e.target.value in this.state.multiple){
          console.log("found",e.target.value,!(this.state.multiple[e.target.value]))
      }
      else{
          console.log("not found");
      }
    }
    if(e.target.name == "multiple")
    this.setState({ ...this.state, 
      multiple:{
        ...this.state.multiple,
        [e.target.value]: e.target.value in this.state.multiple?!(this.state.multiple[e.target.value]):true
      }
    })
    else if(String( e.target.name).includes("gridGroup")){
        var dataFromId = e.target.id.split('-');
        var groupId = dataFromId[1]
        var optionId = dataFromId[3]
        console.log("GRID TYPE CHANGE", groupId, optionId, e.target.value);

        this.setState({
            ...this.state,
            grid:{
                ...this.state.grid,
                [groupId]:{
                    [optionId]:e.target.value=="on"?true:false
                }
            }
        })
    }
    else if(e.target.name == "mix"){
        var dataFromId = e.target.id.split('-');
        var type = dataFromId[1]
        var optionId = dataFromId[2]
        console.log("MIX TYPE CHANGE", type, optionId, e.target.value);

        if(type == 'multiple')
        this.setState({
            ...this.state,
            mix:{
                ...this.state.mix,
                multiple:{
                    ...this.state.mix.multiple,
                    [optionId]:this.state.mix.multiple[optionId]?!this.state.mix.multiple[optionId]:true
                },
                single:{}
                
            }
        })
        else if(e.target.value)
        this.setState({
            ...this.state,
            mix:{
                multiple:{},
                single:{
                    [optionId]:this.state.mix[optionId]?!this.state.mix[optionId]:true
                }
            }
        })
    }
    else
        this.setState({ ...this.state, [e.target.id]: e.target.value })
    console.log(this.state);
  };

  HandleNextQuestionChange = (questions, selectedQ, selectedSec, selectedRank) => {
    var data = []
    if(questions.sections[selectedSec].questions[selectedQ].questionType ==
      "MULTIPLE"){
          var keys = Object.keys(this.state.multiple)
          for(var i=0;i<keys.length;i++){
              if(this.state.multiple[keys[i]]){
                  data.push({optionId:keys[i]})
              }
          }
      }
    else if(questions.sections[selectedSec].questions[selectedQ].questionType ==
      "GRID"){
          var groups = Object.keys(this.state.grid)
          for(var i=0;i<groups.length;i++){
              var options = Object.keys(this.state.grid[groups[i]])
              for(var j=0;j<options.length;j++){
                  if(this.state.grid[groups[i]]&&this.state.grid[groups[j]][options[j]]){
                      data.push({groupId:groups[i], optionId:options[j]})
                  }
              }
          }
      }
    else if(questions.sections[selectedSec].questions[selectedQ].questionType ==
      "MIXED"){
          var keys = Object.keys(this.state.mix.single)
          var single=false
          for(var i=0;i<keys.length;i++){
              if(this.state.mix.single[keys[i]]){
                  data.push({optionId:keys[i], optionTypeGroup:'SINGLE'})
                  single=true
              }
          }
          if(!single){
              keys = Object.keys(this.state.mix.multiple)
              for(var i=0;i<keys.length;i++){
                  if(this.state.mix.multiple[keys[i]]){
                      data.push({optionId:keys[i], optionTypeGroup:'MULTIPLE'})
                  }
              }
          }
      }
    else if(questions.sections[selectedSec].questions[selectedQ].questionType ==
      "RANK"){
          data = selectedRank
      }
  const answer =
    questions.sections[selectedSec].questions[selectedQ].questionType ==
      "TEXT" ||
    questions.sections[selectedSec].questions[selectedQ].questionType ==
      "NUMERIC"
    ||
    questions.sections[selectedSec].questions[selectedQ].questionType ==
      "DATETIME"
      
      ? 
      {
          answer: [
              {
                  input: this.state.input
              }
          ]
      }
      
      : questions.sections[selectedSec].questions[selectedQ].questionType ==
        "SINGLE"
      ?
      {
          answer: [
              {
                  optionId: this.state.optionId,
              }
          ]
      }
      : questions.sections[selectedSec].questions[selectedQ].questionType ==
      "MULTIPLE"
      ||questions.sections[selectedSec].questions[selectedQ].questionType ==
      "GRID"
      ||questions.sections[selectedSec].questions[selectedQ].questionType ==
      "MIXED"
      ||questions.sections[selectedSec].questions[selectedQ].questionType ==
      "RANK"
    ? 
      {
          answer:data
      }
      :
      questions.sections[selectedSec].questions[selectedQ].questionType ==
      "IMAGE"
      ||questions.sections[selectedSec].questions[selectedQ].questionType ==
      "VIDEO"
      ||questions.sections[selectedSec].questions[selectedQ].questionType ==
      "AUDIO"?
      {
        answer:this.props.url
      }
      : null;

  if(this.state.selectedSec&&this.state.selectedQ)
  this.props.SaveAnswer(answer, this.state.selectedSec, this.state.selectedQ);

  var savedLogic = null
  var blockLogic = null
  var skip = null
  var block = null
  if(this.state.selectedSec&&
    this.state.selectedQ&&questions.sections[this.state.selectedSec].questions[this.state.selectedQ].logic){
      savedLogic = questions.sections[this.state.selectedSec].questions[this.state.selectedQ].logic.skip
  }
  savedLogic?
      skip = skipLogicEvaluator(this.props.savedAnswer, savedLogic)
  :
      console.log("NO SKIP LOGIC FOUND");
  
  var nextSec = null
  var nextQ = null
  if (skip && skip[1]){
      nextSec = skip[0].sectionId
      nextQ = skip[0].questionId
  }
  else if(this.state.selectedSec&&this.state.selectedQ){
      nextSec = questions.sections[this.state.selectedSec].questions[this.state.selectedQ].questionFlow.next.sectionId
      nextQ = questions.sections[this.state.selectedSec].questions[this.state.selectedQ].questionFlow.next.questionId
  }



  if(nextSec&& nextQ&&questions.sections[nextSec].questions[nextQ].logic){
      var blockLogic = questions.sections[nextSec].questions[nextQ].logic.display
      console.log("DISPLAY LOG  IC",nextSec, nextQ);
      console.log("DISPLAY LOGIC",questions.sections[nextSec].questions[nextQ].logic.display);
      block = displayLogicEvaluator(this.props.savedAnswer, blockLogic)
      console.log("DISPLAY LOGIC ANSWER",block);

  }

  if(block && block[1]){
      if(block[0].question){
          this.setState({
              selectedSec: questions.sections[nextSec].questions[nextQ].questionFlow.next.sectionId,
              selectedQ: questions.sections[nextSec].questions[nextQ].questionFlow.next.questionId
          })
          nextSec=questions.sections[nextSec].questions[nextQ].questionFlow.next.sectionId
          nextQ=questions.sections[nextSec].questions[nextQ].questionFlow.next.questionId
      }
      else{
          this.setState({
              ...this.state,
              blockedOptions:block[0].options,
              blockedGroups:block[0].groups
          })
      }
  }
  else{
      this.setState({
          ...this.state,
          blockedOptions:[],
          blockedGroups:[]
      })
  }
  this.setState({
    grid:{},
    mix:{multiple:{}, single:{}},
    multiple:{},
    input:null,
    optionId:null,
    uplodedFile:null,
  })

  if(!nextQ||!nextSec){
    this.setState({activateSubmit:true})
  }
  
  // console.log(nextSec, nextQ);
  if(nextSec &&nextQ &&this.props.savedAnswer.sections[nextSec]
      &&this.props.savedAnswer.sections[nextSec].questions
      &&this.props.savedAnswer.sections[nextSec].questions[nextQ]){

          if(this.props.testQuestions.sections[nextSec].questions[nextQ].questionType == "TEXT"
          ||this.props.testQuestions.sections[nextSec].questions[nextQ].questionType == "NUMERIC"
          ||this.props.testQuestions.sections[nextSec].questions[nextQ].questionType == "DATETIME"
          ){
              this.setState({
                  selectedSec: nextSec,
                  selectedQ: nextQ,
                  input:this.props.savedAnswer.sections[nextSec].questions[nextQ].answer[0].input
                });
          }
          else if(this.props.testQuestions.sections[nextSec].questions[nextQ].questionType == "SINGLE"){
              this.setState({
                      selectedSec:nextSec,
                      selectedQ:nextQ,
                      optionId:this.props.savedAnswer.sections[nextSec].questions[nextQ].answer[0].optionId
                });
          }
          else if(this.props.testQuestions.sections[nextSec].questions[nextQ].questionType == "MULTIPLE"){
              var m = {}
              var ans = this.props.savedAnswer.sections[nextSec].questions[nextQ].answer
              if(ans){
                  for (var i=0; i<ans.length;i++){
                      m[ans[i].optionId]=true
                  }
                  this.setState({
                      selectedSec:nextSec,
                      selectedQ:nextQ,
                      multiple:m
                  });
              }
          }
          else if(this.props.testQuestions.sections[nextSec].questions[nextQ].questionType == "GRID"){
              var m = {}
              var ans = this.props.savedAnswer.sections[nextSec].questions[nextQ].answer
              if(ans){
                  for (var i=0; i<ans.length;i++){
                      if(m[ans[i].groupId])
                          m[ans[i].groupId][ans[i].optionId]=true
                      else{
                          m[ans[i].groupId]={}
                          m[ans[i].groupId][ans[i].optionId]=true
                      }

                      
                  }
                  this.setState({
                      selectedSec:nextSec,
                      selectedQ:nextQ,
                      grid:m
                  });
              }
          }
          else if(this.props.testQuestions.sections[nextSec].questions[nextQ].questionType == "MIXED"){
              var m = {single:{}, multiple:{}}
              var ans = this.props.savedAnswer.sections[nextSec].questions[nextQ].answer
              if(ans){
                  for (var i=0; i<ans.length;i++){
                      if(ans[i].optionTypeGroup =='SINGLE'){
                          m.single[ans[i].optionId] = true
                      }
                      else{
                          m.multiple[ans[i].optionId] = true
                      }
                      
                  }
                  this.setState({
                      selectedSec:nextSec,
                      selectedQ:nextQ,
                      mix:m
                  });
              }
          }
          else if(this.props.testQuestions.sections[nextSec].questions[nextQ].questionType == "IMAGE"
            &&this.props.testQuestions.sections[nextSec].questions[nextQ].questionType == "VIDEO"
            &&this.props.testQuestions.sections[nextSec].questions[nextQ].questionType == "AUDIO"
            ){
              var m = {single:{}, multiple:{}}
              var ans = this.props.savedAnswer.sections[nextSec].questions[nextQ].answer
              
              this.setState({
                  selectedSec:nextSec,
                  selectedQ:nextQ,
                  url:ans,
              });
            }
          else{
              this.setState({
                  selectedSec:nextSec,
                  selectedQ:nextQ
                });
          }
  }
  else   
      this.setState({
          selectedSec:nextSec,
          selectedQ:nextQ
      });
  };

  HandlePrevQuestionChange = (questions,selectedQ, selectedSec) => {
    var prevData = this.props.prevElements.sections[selectedSec]&&
        this.props.prevElements.sections[selectedSec].questions[selectedQ]?
            this.props.prevElements.sections[selectedSec].questions[selectedQ]
        :
            this.props.prevData

        var prevSec = prevData.sectionId
        var prevQ = prevData.questionId
        // console.log("current sectionId", prevSec);
        // console.log("current questionId", prevQ);
        if(this.props.savedAnswer.sections[prevSec]
            &&this.props.savedAnswer.sections[prevSec].questions
            &&this.props.savedAnswer.sections[prevSec].questions[prevQ]){

                if(this.props.testQuestions.sections[prevSec].questions[prevQ].questionType == "TEXT"
                ||this.props.testQuestions.sections[prevSec].questions[prevQ].questionType == "NUMERIC"
                ||this.props.testQuestions.sections[prevSec].questions[prevQ].questionType == "DATETIME"
                ){
                    this.setState({
                        selectedSec: prevSec,
                        selectedQ: prevQ,
                        input:this.props.savedAnswer.sections[prevSec].questions[prevQ].answer[0].input
                      });
                }
                else if(this.props.testQuestions.sections[prevSec].questions[prevQ].questionType == "SINGLE"){
                    this.setState({
                        selectedSec: prevSec,
                        selectedQ: prevQ,
                        optionId:this.props.savedAnswer.sections[prevSec].questions[prevQ].answer[0].optionId
                      });
                }
                else if(this.props.testQuestions.sections[prevSec].questions[prevQ].questionType == "MULTIPLE"){
                    var m = {}
                    var ans = this.props.savedAnswer.sections[prevSec].questions[prevQ].answer
                    if(ans){
                        for (var i=0; i<ans.length;i++){
                            m[ans[i].optionId]=true
                        }
                        this.setState({
                            selectedSec:prevSec,
                            selectedQ:prevQ,
                            multiple:m
                        });
                    }
                }
                else if(this.props.testQuestions.sections[prevSec].questions[prevQ].questionType == "GRID"){
                    var m = {}
                    var ans = this.props.savedAnswer.sections[prevSec].questions[prevQ].answer
                    if(ans){
                        for (var i=0; i<ans.length;i++){
                            if(m[ans[i].groupId])
                                m[ans[i].groupId][ans[i].optionId]=true
                            else{
                                m[ans[i].groupId]={}
                                m[ans[i].groupId][ans[i].optionId]=true
                            }

                            
                        }
                        this.setState({
                            selectedSec:prevSec,
                            selectedQ:prevQ,
                            grid:m
                        });
                    }
                }
                else if(this.props.testQuestions.sections[prevSec].questions[prevQ].questionType == "MIXED"){
                    var m = {single:{}, multiple:{}}
                    var ans = this.props.savedAnswer.sections[prevSec].questions[prevQ].answer
                    if(ans){
                        for (var i=0; i<ans.length;i++){
                            if(ans[i].optionTypeGroup =='SINGLE'){
                                m.single[ans[i].optionId] = true
                            }
                            else{
                                m.multiple[ans[i].optionId] = true
                            }
                            
                        }
                        this.setState({
                            selectedSec:prevSec,
                            selectedQ:prevQ,
                            mix:m
                        });
                    }
                }
                else if(this.props.testQuestions.sections[prevSec].questions[prevQ].questionType == "IMAGE"
                &&this.props.testQuestions.sections[prevSec].questions[prevQ].questionType == "VIDEO"
                &&this.props.testQuestions.sections[prevSec].questions[prevQ].questionType == "AUDIO"
                ){
                  var m = {single:{}, multiple:{}}
                  var ans = this.props.savedAnswer.sections[prevSec].questions[prevQ].answer
                 
                  this.setState({
                      selectedSec:prevSec,
                      selectedQ:prevQ,
                      url:ans,
                  });
                }
                else{
                    this.setState({
                        selectedSec: prevSec,
                        selectedQ: prevQ,
                        });
                }
        }
        else   
            this.setState({
            selectedSec: prevSec,
            selectedQ: prevQ,
            });
  };

  onSubmit=(e)=>{
    e.preventDefault();
    const response ={
      response: this.props.savedAnswer
    }
    this.props.SubmitAnswer(response, this.props.vid,)
  }

  OnLanguageChange = (e) => {
    this.setState({ ...this.state, [e.target.id]: e.target.value });
};

  onFileUploader = (e) =>{
    // console.log(e.target.files)
    this.setState({uplodedFile: e.target.files[0]})
  }

  uploadFile=(e)=>{
    e.preventDefault();
    this.props.FileUploader(this.state.uplodedFile);
  }

  render() {
    console.log("######local props",this.props)
    console.log("######local state",this.state)
    return (
        <div className="container main_container_wrapper">
          {
            (this.state.selectedSec != null && this.state.selectedQ != null)
            ||(this.props.prevData.sectionId != null && this.props.prevData.questionId != null)?
          <div className="row">
            <div className="col s12 center center-align">
              <div className="col s6">
                <label>
                  <input
                    className="with-gap"
                    id="orientation"
                    name="orientation"
                    type="radio"
                    value="potrait"
                    checked={this.state.orientation == "potrait" ? true : false}
                    onChange={this.HandleStatusChange}
                  />
                  <span>Potrait</span>
                </label>
              </div>
              <div className="col s6">
                <label>
                  <input
                    className="with-gap"
                    id="orientation"
                    name="orientation"
                    type="radio"
                    value="landscape"
                    checked={
                      this.state.orientation == "landscape" ? true : false
                    }
                    onChange={this.HandleStatusChange}
                  />
                  <span>Landscape</span>
                </label>
              </div>
            </div>
          </div>
          :null}
          {
          (this.state.selectedSec != null && this.state.selectedQ != null)
          ||(this.props.prevData.sectionId != null && this.props.prevData.questionId != null)?
            this.state.orientation == "landscape" ? (
            <div
              className="row "
              style={{
                height: 340,
                width: 530,
                backgroundColor: "white",
                borderStyle: "solid",
                borderLeftWidth: 10,
                borderRightWidth: 10,
                borderRadius: 13,
                zoom: 1.5,
              }}
            >
                <SpecificQuestion
                  questions={this.props.testQuestions}
                  // questions={this.state.questions}
                  selectedQ={this.state.selectedQ?this.state.selectedQ:this.props.prevData.questionId}
                  selectedSec={this.state.selectedSec?this.state.selectedSec:this.props.prevData.sectionId}
                  selectedLang={this.state.selectedLanguage}
                  languages={this.props.languages}
                  state={this.state}
                  OnLanguageChange={this.OnLanguageChange}
                  handleChange={this.handleChange}
                  HandleNextQuestionChange={this.HandleNextQuestionChange}
                  HandlePrevQuestionChange={this.HandlePrevQuestionChange}
                  onFileUploader={this.onFileUploader}
                  uploadSuccess={this.props.uploadSuccess}
                  uploadError={this.props.uploadError}
                  uploadLoading={this.props.uploadLoading}
                  uploadFile={this.uploadFile}
                  onSubmit={this.onSubmit}
                  sname={this.props.testQuestions.name}
                  sid={this.props.sid}
                  vid={this.props.vid}
                  css="sp-question"
                />
            </div>
          ) : (
            <div
              className="row "
              style={{
                height: 530,
                width: 340,
                backgroundColor: "white",
                borderStyle: "solid",
                borderTopWidth: 30,
                borderBottomWidth: 40,
                borderTopWidth: 10,
                borderRadius: 13,
                marginBottom: 0,
                marginTop: 0,
                zoom:1.2
              }}
            >
                <SpecificQuestion
                  // questions={this.state.questions}
                  questions={this.props.testQuestions}
                  selectedQ={this.state.selectedQ?this.state.selectedQ:this.props.prevData.questionId}
                  selectedSec={this.state.selectedSec?this.state.selectedSec:this.props.prevData.sectionId}
                  selectedLang={this.state.selectedLanguage}
                  languages={this.props.languages}
                  state={this.state}
                  OnLanguageChange={this.OnLanguageChange}
                  handleChange={this.handleChange}
                  HandleNextQuestionChange={this.HandleNextQuestionChange}
                  HandlePrevQuestionChange={this.HandlePrevQuestionChange}
                  onFileUploader={this.onFileUploader}
                  uploadSuccess={this.props.uploadSuccess}
                  uploadError={this.props.uploadError}
                  uploadLoading={this.props.uploadLoading}
                  uploadFile={this.uploadFile}
                  onSubmit={this.onSubmit}
                  sname={this.props.testQuestions.name}
                  sid={this.props.sid}
                  vid={this.props.vid}
                  css="sp-question"
                />
            </div>
          ):
          null
          }
        </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  console.log("%c test", 'background: #222; color: #bada55', state,state.test.testQuestions)
  console.log("test state", state.test);
  return {
    auth: state.auth,
    testSurveyQuestion: state.test.testSurveyQuestion,
    testQuestions: state.test.testQuestions,
    // testQuestions: state.designer.questions,
    prev: state.test.prev,
    savedAnswer: state.test.savedAnswer,
    prevElements: state.test.prevElements,
    prevData: state.test.prevData,
    languages: state.test.versionLanguages,
    sid: ownProps.match.params.sid,
    vid: ownProps.match.params.vid,
    url: state.test.url,
    uploadLoading: state.test.uploadFileLoading,
    uploadSuccess: state.test.uploadFileSuccess,
    uploadError: state.test.uploadFileError,
    submitTestSuccess:state.test.submitTestSuccess,
    submitTestError:state.test.submitTestError,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    TestSurvey: (sid, svid/* , token */) => {
      dispatch(testSurvey(sid, svid/* , token */));
    },
    SaveAnswer: (answer, sid, qid) => {
      dispatch(saveAnswer(answer, sid, qid));
    },
    PrevQues: (question, sectionId, questionId, prevSec, prevQ) => {
      dispatch(prevQues(question, sectionId, questionId, prevSec, prevQ));
    },
    GetLanguages: (svid, token) =>{
      dispatch(getLanguages( svid, token));   
    },
    FileUploader: (file) =>{
      dispatch(uploadFile(file))
    },
    SubmitAnswer: (response, svid) =>{
      dispatch(submitAnswer(response, svid))
    },
    SetAlert:(type, message)=>{
      dispatch(setAlert(type, message));
    },
    ResetAlert: ()=> {
        dispatch(resetAlert());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TestSurvey);
