import React from 'react'
import BarChart from "./BarChart"

function TeacherStatCard({state,handleChange}) {
    return (
        <div>
        <div className="row" style={{ marginLeft: 60, marginRight: 60, marginTop: 60 }}>
        <div class="col s12 m3 l3">
          <div
            class="card horizontal"
            style={{ width: 500, borderRadius: 10, height: 200, overflow: "auto", marginLeft:"-70px" }}
          >
            <div class="card-stacked">
              <div class="card-content">
              <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                <p><b>Student Performance:</b></p>
                  <div style={{marginTop:"-10px"}}>
                  <select class="browser-default" id="Course" onChange={handleChange}>
                  <option value="" disabled selected>
                    Subject
                  </option>
                  {
                    state.profCourse && 
                      state.profCourse.map((course)=>(
                        <option value={course.courseId}>{course.Course.courseName+"("+course.Course.courseType+")"}</option>
                      ))
                  }
                </select>
                  </div>
                </div>
              <table className="striped">
              <thead>
                <tr>
                    <th>Year</th>
                    <th>Above 80%</th>
                    <th>Above 60%</th>
                    <th>Above 40%</th>
                    <th>Below 40%</th>
                </tr>
              </thead>
      
              <tbody>
              {
                state.studentMarksArr.map((data)=>(
                  <tr>
                  <td>{data.year}</td>
                  <td>{data.greaterThan80}</td>
                  <td>{data.greaterThan60}</td>
                  <td>{data.greaterThan40}</td>
                  <td>{data.belowThan40}</td>
                </tr>
                ))
              }
               
              </tbody>
            </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
      className="row"
      style={{ marginLeft: 10, marginRight: 60, marginTop: "-26px" }}
    >
      <div class="col s12 m3 l3" style={{marginLeft:"-24%"}}>
        <div
          class="card horizontal"
          style={{ width: 500, borderRadius: 10, height: 200, marginLeft:"-63px" }}
        >
          <div class="card-stacked">
            <div class="card-content">
            <p><b>Learning Effectiveness:</b></p>
            <BarChart
            data={[{name: '',color:'',
            Attendent: parseFloat(state.Attendent),
            notAttendent:  parseFloat(state.notAttendent)

          }]} 
            dimensions={[{name: 'name'}]} 
            measures={[{name: 'Attendent'}, {name: 'notAttendent'}]} 
            height='80' width='330'
            />
            </div>
          </div>
        </div>
      </div>
    </div>
        </div>
    )
}

export default TeacherStatCard
