
export const setAlert = (type, message)=>{
  return (dispatch) =>{
      dispatch({type:'SET_ALERT', payload:{type, message}})         
  }
}

export const resetAlert = ()=>{
    console.log("reset called");
  return (dispatch) =>{
      dispatch({type:'RESET_ALERT'})         
  }
}