import React  from 'react';
import '../stylesheets/modals.css';
import '../stylesheets/common.css';

const EditModal = ({HandleChange, OnSubmit, OnStyle, state}) => {
  return (
      <div className="">
        <div id="edit-modal" className="small-modal modal modal-fixed-footer">
            <div className='row modal-content'>
                <div className="row modal-title valign-wrapper">
                <span>Edit</span>
                </div>
                {/* <hr /> */}
                <div className="row"> 
                    <div className="col s12">
                        <form id="addUser" onSubmit={OnSubmit}>
                            <div className="input-field col s12">
                                <input
                                    type="text"
                                    id="editText"
                                    onChange={HandleChange}
                                    value={state.editText}
                                    placeholder="new data"
                                    required
                                />
                                <label>Edit text</label>  
                            </div>
                        </form>
                    </div>
                </div>
                <div className="row">
                    <div className="col s2">
                        <button className={!state.selectedTextBold.status?'btn btn-floating ':'btn btn-floating active'} 
                        id="selectedTextBold" onClick={OnStyle}>
                            <i class="material-icons" id="selectedTextBold">
                                format_bold
                            </i>
                        </button>
                    </div>
                    <div className="col s2">
                        <button className={!state.selectedTextItalic.status?"btn btn-floating ":'btn btn-floating active'}
                        id="selectedTextItalic" onClick={OnStyle}>
                            <i class="material-icons" id="selectedTextItalic">
                                format_italic
                            </i>
                        </button>
                    </div>
                    <div className="col s8 right right-align" >
                        {/* <button className='btn btn-floating'> */}
                            <i class="material-icons">format_color_text</i> 
                            <input id="selectedTextColor" type="color" onChange={OnStyle}/>
                        {/* </button> */}
                    </div>
                </div>
            </div>
            <div className="modal-footer">
                <button className="modal-close waves-effect waves btn-flat">CANCEL</button>
                <button type='submit' form="addUser" className="waves-effect waves btn-flat">Save</button>
                {/* <a href="#!" className="modal-close waves-effect waves-green btn-flat">Cancel</a> */}
            </div>
        </div>
      </div>
    
  );
};

export default EditModal;
