import React from "react";

const UploadAttendenceModal = ({handleUpload,onAttendanceSubmit}) => {
  return (
    <div id="attendence-modal" class="modal">
      <div className="row modal-content attendence-modal">
        <div
          className="row modal-title attendence-modal-title valign-wrapper"
          style={{ "background-color": "#bf750d", minHeight: 10 }}
        >
          <span className="attendence-modal-name">Upload Attendence</span>
        </div>
        <div className="row">
          <div className="col s12 m12 l12">
            <form>
              <div class="file-field input-field">
                <div class="btn-small">
                  <span>Select File</span>
                  <input 
                    type="file"
                    accept=".xls, .xlsx"
                    onChange={handleUpload}
                  />
                </div>
                <div class="file-path-wrapper">
                  <input class="file-path validate" type="text" />
                </div>
              </div>
            </form>
            <button className="right btn" onClick={onAttendanceSubmit}>Upload & Submit</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UploadAttendenceModal;
