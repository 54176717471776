import React from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import LandingPage from "../Landing/Home/LandingPage";

function Dashboard(props) {
  const user = props.auth.user;
  if (!user){
    return <LandingPage/>
  }
  else{
    switch(user.role) {
      // case 'Admin' : return <Redirect to={"/admin/" + user.id + "/dashboard"}/>;
      // case 'HOD' : return <Redirect to={"/admin/" + user.id + "/dashboard"}/>;
      // case 'Teacher' : return <Redirect to={"/admin/" + user.id + "/dashboard"}/>;
      case 'Admin' : return <Redirect to={"/hod/dashboard"}/>;
      case 'HOD' : return <Redirect to={"/hod/dashboard"}/>;
      case 'Teacher' : return <Redirect to={"/professor/dashboard"}/>;
      default: return <Redirect to="/login" />;
    }
  }
  
}

const mapStateToProps = (state) => {

  return {
    auth: state.auth,
  };
};
export default connect(mapStateToProps, null)(Dashboard);
