
const initState = {
    type:null,
    message:null,
}

const alertReducer=(state=initState,action)=>{
    switch(action.type){
        case 'SET_ALERT':
            return {
                ...state,
                type:action.payload.type,
                message:action.payload.message,
            }
        case 'RESET_ALERT':
            return initState
        default:
            return state;
        
    }
}
export default alertReducer;