import React from 'react'
import M from "materialize-css";

const Tab = ({image, name,color,onClick,classname,datatarget}) => {
  console.log(datatarget)
    return (
        <div>
      <div class="row">
      <div class="darken-1">
        <div
          data-target={ datatarget? datatarget: null}
          className={classname?classname:"btn-flat"}
          onClick={()=>onClick(name)}
          style={{ width: "96px", paddingLeft: 1 }}
        >
          <div
            class="section"
            style={{
              height: 67,
              "background-color":  `rgb(${Object.values(color).join(", ")})`,
              "text-align": "center",
              "box-shadow":
                "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
            }}
          >
            <h5>
              <img
                src={image}
                class="img-fluid"
                alt=""
                width="30"
                height="30"
                style={{marginTop:"-7px"}}
              />
            </h5>
          </div>
          <div class="divider"></div>
          <div
            class="section lower_section"
            style={{
              height:5,
              "background-color": "#eee",
              "box-shadow":
                "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
              textAlign: "center",
            }}
          >
            <h5 style={{ fontSize: "x-small", marginTop:"-15px" }}>
              <b>{name}</b>
            </h5>
          </div>
        </div>
      </div>
    </div>
    </div>
    )
}

export default Tab

