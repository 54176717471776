const initState = {
  testSurveyQuestion: [],
  testQuestions: [],
  loadingtestSurveyQuestion: false,

  // savedAnswer: [],
  prev: [],

  savedAnswer:{
    sections:{}
  },

  prevData:{
      sectionId:null,
      questionId:null
  },
  prevElements:{
      sections:{}
  },

  versionLanguages:null,
  loadingLanguages:false,

  uploadFileLoading:false,
  uploadFileSuccess:null,
  uploadFileError:null,
  url:null,

  testResponse: null,
  submitTestSuccess: null,
  submitTestError: null
};

const testReducer = (state = initState, action) => {
  console.log("test reducer", action.payload);
  switch (action.type) {
    case "TEST_SURVEY_QUESTION_FETCH_LOADING":
      return {
        ...state,
        loadingtestSurveyQuestion: true,
      };
    case "TEST_SURVEY_QUESTION_FETCH_SUCCESS":
      return {
        ...state,
        loadingtestSurveyQuestion: false,
        testSurveyQuestion: action.payload,
        testQuestions: action.payload.questionnaire,
      };
    case "TEST_SURVEY_QUESTION_FETCH_ERROR":
      return {
        ...state,
        loadingtestSurveyQuestion: false,
        testSurveyQuestion: ["ERROR !!!"],
      };
    // case "SAVE_ANSWER":
    //   return {
    //     ...state,
    //     savedAnswer: action.payload,
    //   };
      case 'SAVE_ANSWER':
        console.log("IN TEST reducer",action.payload, action.pos);
        var sid = action.pos.sid
        var qid = action.pos.qid
        var newData = state.savedAnswer
        if(state.savedAnswer.sections[sid]){
            if(state.savedAnswer.sections[sid].questions){
                newData.sections[sid].questions[qid] = action.payload
            }
            else{
                newData.sections[sid].questions = {}
                newData.sections[sid].questions[qid] = action.payload
            }
        }
        else{
            newData.sections[sid]= {}
            newData.sections[sid]["questions"] = {}
            newData.sections[sid].questions[qid] = action.payload
        }

        var newPrevData = state.prevElements
        if(state.prevElements.sections[sid]){
            if(state.prevElements.sections[sid].questions){
                newPrevData.sections[sid].questions[qid] = state.prevData
            }
            else{
                newPrevData.sections[sid].questions = {}
                newPrevData.sections[sid].questions[qid] = state.prevData
            }
        }
        else{
            newPrevData.sections[sid]= {}
            newPrevData.sections[sid]["questions"] = {}
            newPrevData.sections[sid].questions[qid] = state.prevData
        }
        return {
            ...state,
            prevData:{sectionId:sid, questionId:qid},
            prevElements:newPrevData,
            savedAnswer: newData,
            uploadFileLoading:false,
            uploadFileSuccess:false,
            uploadFileError:false,
        }
    case "PREV_QUES":
      return {
        ...state,
        prev: action.payload,
      };
    case "VERSION_LANGUAGES_SUCCESS":
      return {
        ...state,
        loadingLanguages: false,
        versionLanguages: action.data,
      };
    case "VERSION_FETCH_SUCCESS":
      return {
        ...state,
        loadingVersions: false,
        versions: action.data,
      };
      case 'LOADING_TEST_ANSWER_UPLOAD':
        return {
            ...state,
            uploadFileLoading:true,
            uploadFileSuccess:false,
            uploadFileError:false,
        }
    case 'UPLOAD_TEST_ANSWER_SUCCESSFUL':
        return {
            ...state,
            uploadFileLoading:false,
            uploadFileSuccess:true,
            uploadFileError:false,
            url:action.payload
        }
    case 'UPLOAD_TEST_ANSWER_ERROR':
        return {
            ...state,
            uploadFileLoading:false,
            uploadFileSuccess:false,
            uploadFileError:true,
            url:null
        }
    case "SUBMIT_TEST_SUCCESS" :
      return {
        ...state,
        submitTestSuccess: true,
        submitTestError: false,
        testResponse: action.payload,
        testSurveyQuestion: [],
        testQuestions: [],
        loadingtestSurveyQuestion: false,

        // savedAnswer: [],
        prev: [],

        savedAnswer:{
          sections:{}
        },

        prevData:{
            sectionId:null,
            questionId:null
        },
        prevElements:{
            sections:{}
        },

        versionLanguages:null,
        loadingLanguages:false,

        uploadFileLoading:false,
        uploadFileSuccess:null,
        uploadFileError:null,
        url:null,
      } 
    case "SUBMIT_TEST_FAILED" :
      return {
        ...state,
        submitTestSuccess: false,
        submitTestError: true,
        testResponse: null
      }
    default:
      return state;
  }
};
export default testReducer;
