import React, { Component } from "react";
import { connect } from "react-redux";
import {
  getCourses,
  getSemester,
  getSection,
} from "../../store/actions/HodAction";
import M from "materialize-css";
import { UploadModal } from "../layout/UploadModal";
import { Redirect } from "react-router-dom";
import Navbar from "../layout/Navbar";

export class LessonPlan extends Component {
  state = {
    flag: "",
    option: "",
    semId: "",
    flag: true,
  };
  componentDidMount() {
    var elems = document.querySelectorAll(".modal");
    var instances = M.Modal.init(elems, {
      preventScrolling: true,
    });
    var elems = document.querySelectorAll(".collapsible");
    var instances = M.Collapsible.init(elems, {});
    if (this.props.auth.user) {
      this.props.GetCourse(
        this.props.auth.user.deptId,
        this.props.auth.user.accessToken
      );
      this.props.GetSemester(
        this.props.auth.user.deptId,
        this.props.auth.user.accessToken
      );
    }
  }
  handleSemester = (e) => {
    this.setState({ semId: e.target.value }, () => {
      document.getElementById("section").selectedIndex = 0;
      this.props.GetSection(this.state.semId, this.props.auth.user.accessToken);
    });
  };

  handleChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };
  handleModal = (e) => {
    this.setState({ option: e.target.value });
  };
  render() {
    const user = this.props.auth.user;
    if (!user) return <Redirect to="/login" />;
    return (
      <div>
        <UploadModal state={this.state} handleChange={this.handleModal} />
        <Navbar heading="Teacher" user={user} />
        <div
          className="row"
          style={{
            height: 90,
            backgroundColor: "#3f8c23",
            borderBottomLeftRadius: 55,
            // borderBottomRightRadius: 55,
          }}
        >
          <div style={{ marginLeft: 90 }}>
            <div className="col s12 m3 l2">
              <input
                type="date"
                id="date"
                style={{
                  border: "1px solid white",
                  "margin-top": 20,
                  backgroundColor: "white",
                }}
                onChange={this.handleChange}
              />
            </div>
            <div
              className="col s12 m3 l2"
              style={{
                "margin-top": 20,
              }}
            >
              <select
                class="browser-default"
                id="Semester"
                onChange={this.handleSemester}
              >
                <option value="" disabled selected>
                  Select Semester
                </option>
                {this.props.semester &&
                  this.props.semester.map((sem) => (
                    <option value={sem.id}>{sem.semester}</option>
                  ))}
              </select>
            </div>
            <div
              className="col s12 m3 l2"
              style={{
                "margin-top": 20,
              }}
            >
              <select
                class="browser-default"
                id="section"
                onChange={this.handleChange}
              >
                <option value="" disabled selected>
                  Select Section
                </option>
                {this.props.section &&
                  this.props.section.map((sec) => (
                    <option value={sec.id}>{sec.name}</option>
                  ))}
              </select>
            </div>
            {/* <div
              className="col s12 m3 l2"
              style={{
                "margin-top": 20,
              }}
            >
              <select
                class="browser-default"
                id="course"
                onChange={this.handleChange}
              >
                <option value="" disabled selected>
                  Select Subject
                </option>
                {this.props.course &&
                  this.props.course.map((course) =>
                    course.Semesters.map((sem) =>
                      sem.Courses.map((course) => (
                        <option value={course.id}>{course.courseName}</option>
                      ))
                    )
                  )}
              </select>
            </div> */}
            <div
              className="col s12 m3 l1"
              style={{
                "margin-top": 23,
                paddingLeft: "25%",
              }}
            >
              <a
                class="btn-floating waves-effect waves-light btn modal-trigger"
                data-target="upload-modal"
                onClick={this.onModalOpen}
              >
                <i class="material-icons">cloud_upload</i>
              </a>
            </div>
          </div>
        </div>

        <div className="container">
          <ul class="collapsible">
            <li>
              <div class="collapsible-header">
                <i class="material-icons">book</i>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "50%",
                  }}
                >
                  <span>DS1</span>
                  <span>Array with Linked list</span>
                </div>
              </div>
              <div class="collapsible-body">
                <span>Lorem ipsum dolor sit amet.</span>
                <a className="btn-floating right btn-small">
                  <i className="material-icons">download</i>
                </a>
              </div>
            </li>
            <li>
              <div class="collapsible-header">
                <i class="material-icons">book</i>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "50%",
                  }}
                >
                  <span>Programming</span>
                  <span>Recursion & tower of hanoi</span>
                </div>
              </div>
              <div class="collapsible-body">
                <span>Lorem ipsum dolor sit amet.</span>
                <a className="btn-floating right btn-small">
                  <i className="material-icons">download</i>
                </a>
              </div>
            </li>
            <li>
              <div class="collapsible-header">
                <i class="material-icons">book</i>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "50%",
                  }}
                >
                  <span>Microprocessor</span>
                  <span>8085 Diagram & Registors</span>
                </div>
              </div>
              <div class="collapsible-body">
                <span>Lorem ipsum dolor sit amet.</span>
                <a className="btn-floating right btn-small">
                  <i className="material-icons">download</i>
                </a>
              </div>
            </li>
          </ul>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    semester: state.Hod.semester,
    section: state.Hod.section,
    course: state.Hod.course,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    GetSemester: (id, token) => {
      dispatch(getSemester(id, token));
    },
    GetCourse: (deptId, token) => {
      dispatch(getCourses(deptId, token));
    },
    GetSection: (id, token) => {
      dispatch(getSection(id, token));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LessonPlan);
