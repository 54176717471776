import React from 'react'

export default function StyleQuestion({that}) {
    return (
        <div className="center valign-wrapper style-menu">
            <div className="style-menu-item">
                <select
                    required
                    onChange={that.HandleQuestionStyleChange}
                    className='browser-default select-font'
                    id='fontFamily'
                >
                    <option value="" disabled selected>Choose text</option>
                    <option value="times">times</option>
                    <option value="courier">courier</option>
                    <option value="arial">arieal</option>
                </select>
            </div>
            <div className="style-menu-item">
                <input 
                type="number"
                className='select-font-size'
                id="fontSize"
                min="0"
                defaultValue="15"
                value={that.state.qStyle.fontSize}
                onChange={that.HandleQuestionStyleChange}
                />
            </div>
            <div className="style-menu-item">
                <i class="material-icons">format_color_fill</i> 
                <input id="backgroundColor" type="color" onChange={that.HandleQuestionStyleChange}/>
            </div>
            <div className="style-menu-item">
                <i class="material-icons">format_color_text</i> 
                <input id="color" type="color" onChange={that.HandleQuestionStyleChange}/>
            </div>
            <div className="style-menu-item">
                <i class="material-icons" id="fontWeight" onClick={that.HandleQuestionStyleChange}>
                    format_bold
                </i>
                <i class="material-icons"  id="fontStyle" onClick={that.HandleQuestionStyleChange}>
                    format_italic
                </i>
                <i class="material-icons"  id="textDecoration" onClick={that.HandleQuestionStyleChange}>
                    format_underline
                </i>
            </div>
            <div className="style-menu-item">
                <i class="material-icons" id="leftAlign" onClick={that.HandleQuestionStyleChange}>
                    format_align_left
                </i>
                <i class="material-icons" id="rightAlign" onClick={that.HandleQuestionStyleChange}>
                    format_align_right
                </i>
                <i class="material-icons" id="centerAlign" onClick={that.HandleQuestionStyleChange}>
                    format_align_center
                </i>
            </div>
            <div className="style-menu-item">
                <i class="material-icons">format_line_spacing</i> 
                <input id="marginBottom" type="number" onChange={that.HandleQuestionStyleChange}/>
            </div>
        </div>
    )
}
