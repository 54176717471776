import React from 'react'
import ExtraOperands from './ExtraOperands'

export default function ModalAddLogic({state, HandleChange, OnAdd, OnMoreOperand, OnAddLogic, HandleSelectChange, OnMerge, questions, OnLogicDelete, OnSave,HandleExtraOperandChange, DeleteExtraOperand}) {
  // console.log(questions);
  return (
    <div id="add-logic-modal" className="add-logic-modal modal modal-fixed-footer">
      <div className="row modal-content">
        <div className="row modal-title valign-wrapper">
          Logic
        </div>
        <div className="row">
          <div className="col s12 right right-align">
            <button className="btn btn-floating waves wave-effect" onClick={OnAddLogic}>
              <i className="material-icons">add</i>
            </button>
          </div>
          {
            state.add ?
          <div className="col s12">
            <div className="col s4">
                <label>
                    <input id="logic"className="with-gap" name="logic" type="radio" value='skip' 
                    checked={state.logic=='skip'? true : false}
                    onChange={HandleChange}
                    />
                    <span>Skip Logic</span>
                </label>
            </div>
            <div className="col s4">
                <label>
                    <input id="logic" className="with-gap" name="logic" type="radio" value='display' 
                    checked={state.logic=='display'? true : false}
                    onChange={HandleChange}
                    />
                    <span>Display Logic</span>
                </label>
            </div>
            <div className="col s4">
                <label>
                    <input id="logic" className="with-gap" value name="logic" type="radio" value='pipe' 
                    checked={state.logic=='pipe'? true : false}
                    onChange={HandleChange}
                    />
                    <span>Pipe Logic</span>
                </label>
            </div>
          </div>
          :
          null
        }
        </div>

        <div className="row">
          {
            state.add && (state.logic == 'skip'||state.logic == 'display')?
              <div className="col s12">
                <div className="col s4 logic-box">
                  <p>Operand</p>
                  <div className="col s6">
                    <select className="browser-default logic-select" id="logicOperandSec" onChange={HandleChange}>
                      <option disabled selected>Choose section</option>
                      {
                        Object.keys(questions.sections).map(secId =>
                          <option value={secId} >{"Sec. " + secId}</option>
                        )
                      }
                    </select>
                  </div>
                  <div className="col s6">
                    <span>
                    <select className="browser-default logic-select" id="logicOperandQ" onChange={HandleChange}>
                    <option disabled selected>Choose Question</option>
                    {
                      state.logicOperandSec?
                        Object.keys(questions.sections[state.logicOperandSec].questions).map(qId =>
                          <option value={qId} >{"Q. " + qId}</option>
                        )
                        :
                        null
                      }
                    </select>
                    </span>
                    <span style={{marginLeft:2}}>
                    {
                      state.logicOperandSec&&state.logicOperandQ&&
                      questions.sections[state.logicOperandSec].questions[state.logicOperandQ].questionType=='NUMERIC'?
                      <button className="extra-small" onClick={OnMoreOperand}>
                          <i style={{margin:0, marginTop:2, padding:0}} className="material-icons tiny">add</i>
                      </button>
                      :null
                    }
                    </span>
                  </div>
                  <div className="col s12">
                    {
                      state.extraOperand.map(data =>
                        <ExtraOperands
                        deleteOp={DeleteExtraOperand} 
                        state={state} 
                        HandleChange={HandleExtraOperandChange}
                        questions={questions}
                        id={data.id}/>
                      )
                    }
                  </div>
                </div>
                <div className="col s2 logic-box">
                  <p><span>Operator</span></p>
                  <div className="col s12">
                    <select className="browser-default logic-select" id="operator"  onChange={HandleChange}>
                      <option disabled selected>Choose logic</option>
                      {
                        state.logicOperandQ?
                          questions.sections[state.logicOperandSec].questions[state.logicOperandQ].questionType =="NUMERIC"?
                          <>
                            <option value="==">==</option>
                            <option value=">=">{">="}</option>
                            <option>{"<="}</option>
                            <option>{"!="}</option>
                          </>
                          :
                          <>
                            <option value="==">==</option>
                            <option value="!=">{"!="}</option>
                          </>
                        :
                        null

                      }
                    </select>
                  </div>            
                </div>
                <div className="col s2 logic-box">
                  <p><span>Answer</span></p>
                  <div className="col s12">
                  {
                    state.logicOperandQ?
                      questions.sections[state.logicOperandSec].questions[state.logicOperandQ].questionType =="NUMERIC"
                      ?
                        <input onChange={HandleChange} id="logicAnswer" value={state.logicAnswer} type="number"/>
                      :
                        questions.sections[state.logicOperandSec].questions[state.logicOperandQ].questionType =="TEXT"
                        ?
                          <input onChange={HandleChange} id="logicAnswer" value={state.logicAnswer} type="text"/>
                          :
                          questions.sections[state.logicOperandSec].questions[state.logicOperandQ].questionType =="SINGLE"?
                            <select onChange={HandleChange} id="logicAnswer"  className="browser-default logic-select"  onChange={HandleChange}>
                              {
                                <>
                                  <option disabled selected>options</option>
                                  {
                                    Object.keys(questions.sections[state.logicOperandSec].questions[state.logicOperandQ].options).map(op =>
                                      <option value={op}>Op. {op}</option>
                                      )
                                  }
                                </>
                              }
                            </select>
                          :
                            questions.sections[state.logicOperandSec].questions[state.logicOperandQ].questionType =="MULTIPLE"?
                              <select onChange={HandleChange} id="logicAnswer"  className="browser-default logic-select"  onChange={HandleChange}>
                                <option disabled selected>options</option>
                                {
                                  Object.keys(questions.sections[state.logicOperandSec].questions[state.logicOperandQ].options).map(op =>
                                    <option value={op}>Op. {op}</option>
                                  )
                                }
                              </select>
                              :
                              null
                        :
                          null
                    }
                  </div>
                </div>
                {
                  state.logic == "skip"?
                  <div className="col s4 logic-box">
                    <p>
                      <span>Go to Or,  </span> 
                      <span style={{marginLeft:20}}>
                        <label>
                            <input
                            id={"terminate"}
                            // value={optionId}
                            name="terminate"
                            className="filled-in checkbox-blue"
                            type="checkbox"
                            onChange={HandleChange}
                            checked={state.terminate}
                            />
                            <span> Terminate</span>
                        </label>
                      </span>
                    </p>
                    {
                      !state.terminate?
                    <>
                    <div className="col s6">
                      <select className="browser-default logic-select" id="logicResultSec" onChange={HandleChange}>
                        <option disabled selected>Choose section</option>
                        {
                          Object.keys(questions.sections).map(secId => 
                            <option value={secId} >{"Sec. " + secId}</option>
                            )
                        }
                      </select>
                    </div>
                    <div className="col s6">
                      <select className="browser-default logic-select" id="logicResultQ" onChange={HandleChange}>
                        <option disabled selected>Choose question</option>

                        {
                          state.logicResultSec?
                            Object.keys(questions.sections[state.logicResultSec].questions).map(qId =>
                              <option value={qId} >{"Q. " + qId}</option>
                            )
                            :
                            null
                          }
                      </select>
                    </div>
                    </>
                    :null
                    }
                  </div>
                  :
                    <div className="col s4 logic-box">
                      <p><span>Block Display</span></p>
                      <div className="col s3">
                        <select className="browser-default logic-select" id="logicResultSec" onChange={HandleChange}>
                          <option disabled selected>Choose section</option>
                          {
                            Object.keys(questions.sections).map(secId => 
                              <option value={secId} >{"Sec. " + secId}</option>
                              )
                          }
                        </select>
                      </div>
                      <div className="col s3">
                        <select className="browser-default logic-select" id="logicResultQ" onChange={HandleChange}>
                          <option disabled selected>Choose question</option>
                          {
                            state.logicResultSec?
                              Object.keys(questions.sections[state.logicResultSec].questions).map(qId =>
                                <option value={qId} >{"Q. " + qId}</option>
                              )
                              :
                              null
                            }
                        </select>
                      </div>
                      <div className="col s3">
                        <select className="browser-default logic-select" id="logicBlockType" onChange={HandleChange}>
                            <option disabled selected>Block</option>
                            <option value="question">Question</option>
                            <option value="option">option</option>
                          </select>
                      </div>
                      {
                        state.logicResultSec&&state.logicResultQ&&(
                        questions.sections[state.logicResultSec].questions[state.logicResultQ].questionType =="SINGLE"
                        || questions.sections[state.logicResultSec].questions[state.logicResultQ].questionType =="MULTIPLE")
                        ?
                        <div className="col s3">
                          <select onChange={HandleChange} id="logicBlockOption"  className="browser-default logic-select"  onChange={HandleChange}>
                            <option disabled selected>options</option>
                            {
                              Object.keys(questions.sections[state.logicResultSec].questions[state.logicResultQ].options).map(op =>
                                <option value={op}>Op. {op}</option>
                              )
                            }
                          </select>
                        </div>
                        :
                        null
                      }
                    </div>
                }             
              </div>
            :
            state.add?
              <div className="col s12">
                <div className="col s4 logic-box">
                  <p>Operand</p>
                  <div className="col s6">
                    <select className="browser-default logic-select">
                      <option disabled selected>Choose section</option>
                      <option>sec 1</option>
                      <option>sec 2</option>
                      <option>sec 3</option>
                      <option>sec 4</option>
                    </select>
                  </div>
                  <div className="col s6">
                    <select className="browser-default logic-select">
                      <option disabled selected>Choose question</option>
                      <option>Q 1</option>
                      <option>Q 2</option>
                      <option>Q 3</option>
                      <option>Q 4</option>
                    </select>
                  </div>
                </div>
                <div className="col s2 logic-box">
                  <p><span>Operator</span></p>
                  <div className="col s12">
                    <select className="browser-default logic-select">
                      <option disabled selected>Choose logic</option>
                      <option>==</option>
                      <option>{">="}</option>
                      <option>{"<="}</option>
                      <option>{"!="}</option>
                    </select>
                  </div>            
                </div>
                <div className="col s2 logic-box">
                  <p><span>Answer</span></p>
                  <div className="col s12">
                    <select className="browser-default logic-select">
                      <option disabled selected>options</option>
                      <option>Op 1</option>
                      <option>Op 2</option>
                      <option>Op 3</option>
                      <option>Op 4</option>
                    </select>
                  </div>
                </div>
                <div className="col s4 logic-box">
                  <p><span>Block Display</span></p>
                  <div className="col s3">
                    <select className="browser-default logic-select">
                      <option disabled selected>Choose section</option>
                      <option>sec 1</option>
                      <option>sec 2</option>
                      <option>sec 3</option>
                      <option>sec 4</option>
                    </select>
                  </div>
                  <div className="col s3">
                    <select className="browser-default logic-select">
                      <option disabled selected>Choose question</option>
                      <option>Q 1</option>
                      <option>Q 2</option>
                      <option>Q 3</option>
                      <option>Q 4</option>
                    </select>
                  </div>
                  <div className="col s3">
                    <select className="browser-default logic-select">
                        <option disabled selected>Block</option>
                        <option>Question</option>
                        <option>option</option>
                      </select>
                  </div>
                  <div className="col s3">
                    <select className="browser-default logic-select">
                      <option disabled selected>Choose question</option>
                      <option>Q 1</option>
                      <option>Q 2</option>
                      <option>Q 3</option>
                      <option>Q 4</option>
                    </select>
                  </div>
                </div>
              </div>
              :
              null
          }
          <div className="col s12 rigt right-align" style={{padding:10}}>
            {
              state.add?
                state.logics[state.logic].length == 0?
                  <button onClick={OnAdd} className="btn btn-small waves-effect waves ">Add</button>
                :
                  <>
                    <button onClick={OnAdd} id="and" className="btn btn-floating waves-effect waves"><i id="and" className="material-icons">expand_less</i></button> 
                    <button onClick={OnAdd} id="or" className="btn btn-floating waves-effect waves"><i id="or" className="material-icons">expand_more</i></button> 
                    <button onClick={OnAdd} id="na" className="btn btn-floating waves-effect waves">NA</button> 
                  </>
              :
              null
            }
            
          </div>
        </div>
        {
        state.logics.skip.length|state.logics.display.length|state.logics.pipe.length > 1?
          <div className="row logic-extra-action">
            <button  className="btn btn-small waves-effect waves " onClick={OnMerge}>group & merge</button>
          </div> 
        :
          null
        }

        <div className="row view-logic-all">
          <p><strong>Skips</strong></p>
          {
            state.logics.skip.map((logic, index) =>
            <div className="center center-align">
            {
              logic.type =="operator"?
              <div className="row view-logic-op" key={index}>
                <span key={index}>{logic.value}</span>
                <span className="right right align">
                  {/* <i id="opChange" className="material-icons">arrow_drop_down</i> */}
                </span>
              </div>
              :
              <div className=" row view-logic" key={index}>
                <span> 
                  <label>
                    <input id={"skip-"+index} type="checkbox" onChange={HandleSelectChange} />
                    <span></span>
                  </label>
                </span>
                <span key={"display-"+index}>{logic.value}</span>
                <span className="right right align" id={"skip-"+index} onClick={OnLogicDelete}>
                  <i id={"skip-"+index} className="material-icons">cancel</i>
                </span>
              </div>
            }
            </div>           
            )
          }
        </div>
        <div className="row view-logic-all">
          <p><strong>Displays</strong></p>
          {
            state.logics.display.map((logic, index) =>
            <div className="center center-align">
            {
              logic.type =="operator"?
              <div className="row view-logic-op" key={index}>
                <span key={"display-"+index}>{logic.value}</span>
                <span className="right right align">
                  {/* <i id="opChange" className="material-icons">arrow_drop_down</i> */}
                </span>
              </div>
              :
              <div className=" row view-logic" key={index}>
                <span> 
                  <label>
                    <input id={"display-"+index} type="checkbox" onChange={HandleSelectChange} />
                    <span></span>
                  </label>
                </span>
                <span key={index}>{logic.value}</span>
                <span className="right right align"id={"display-"+index} onClick={OnLogicDelete}>
                  <i id={"display-"+index} className="material-icons">cancel</i>
                </span>
              </div>
            }
            </div>           
            )
          }
        </div>
        <div className="row view-logic-all">
          <p><strong>Pipes</strong></p>
          {
            state.logics.pipe.map((logic, index) =>
            <div className="center center-align">
            {
              logic.type =="operator"?
              <div className="row view-logic-op" key={index}>
                <span key={"pipe-"+index}>{logic.value}</span>
                <span className="right right align">
                  {/* <i id="opChange" className="material-icons">arrow_drop_down</i> */}
                </span>
              </div>
              :
              <div className=" row view-logic" key={index}>
                <span> 
                  <label>
                    <input id={"pipe-"+index} type="checkbox" onChange={HandleSelectChange} />
                    <span></span>
                  </label>
                </span>
                <span key={index}>{logic.value}</span>
                <span className="right right align" id={"pipe-"+index} onClick={OnLogicDelete}>
                  <i id={"pipe-"+index} className="material-icons">cancel</i>
                </span>
              </div>
            }
            </div>           
            )
          }
        </div>
        
      </div>
      <div className="modal-footer">
          <button className="modal-close waves-effect waves btn-flat">
            Cancel
          </button>
          <button
            type="button"
            form="addUser"
            onClick={OnSave}
            className="waves-effect waves btn-flat"
          > 
            Add Logic
        </button>
      </div>
    </div>
  )
}
