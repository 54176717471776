import React, { Component } from 'react'
import { connect } from 'react-redux';
import { signIn } from '../../store/actions/authAction';
import { Redirect } from 'react-router-dom';
import '../stylesheets/common.css'

 class Login extends Component {
  state = {
    email: "",
    password: ""
  };
  handleChange = e => {
    this.setState({
      [e.target.id]: e.target.value
    });
  };

  handleSubmit = (e)=>{
    e.preventDefault();
    this.props.signIn(this.state);

  }
    render() {
      const { authError, user, authLoading } = this.props;
      if (user) return <Redirect to="/" />;
      if (authLoading) {
        return (
          <div className="progress">
            <div className="indeterminate"></div>
          </div>
        );
      }
      return (
      <div className="main_container_wrapper main-container ">
        <div className="row valign-wrapper">
          <div className="col s6">
            <img
              className="responsive-img"
              src={require("../../image/logo.png")}
              // style={{marginTop:10}}
            />
          </div>
          <div className="col s6">
            <form onSubmit={this.handleSubmit} style={{ marginTop: 30 }}>
              <div className="input-field col s12 login-inputs">
              <i className="material-icons prefix">person</i>
                <input
                  type="text"
                  id="email"
                  onChange={this.handleChange}
                  required
                />
                <label>email</label>
              </div>
              <div className="input-field col s12 login-inputs">
              <i className="material-icons prefix">lock</i>
                <input
                  type="password"
                  id="password"
                  onChange={this.handleChange}
                  required
                />
                <label>Password</label>
              </div>
              <div className="input-field col s12 login-inputs center-align">
                <button
                  className="btn btn-login waves-effect waves-light"
                  type="submit"
                >
                  Login
                </button>
                <div className="red-text center">
                  {authError ? <p>{authError}</p> : null}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      )
    }
}

const mapStateToProps = state => {
  return state.auth;
};
const mapDispatchToProps = dispatch => {
  return {
    signIn: credentials => {
      dispatch(signIn(credentials));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);