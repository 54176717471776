import React, { Component } from "react";
import { connect } from "react-redux";
import { backendUrl } from "../../config/api";

export const SupervisorList = ({ survey, surveyInterviewers, resetPassword, EditUser, DeleteUser }) => {
  const env = "server";
  return (
    <div>
      <form action="#">
        <div className="row list-item-header">
          <div className="col l1">Profile</div>
          <div className="col l3">Name</div>
          <div className="col l3">Email</div>
          <div className="col l2">Role</div>
          <div className="col l3">Action</div>
        </div>
        <div className="row">
          {survey &&
            surveyInterviewers.map((user) => (
              <div className="row list-item" key={user.User.id}>
                <div className="col l1">
                  <img
                    className="circle"
                    src={
                      user.User && user.User.photo &&user.photo!="null"? 
                        user.User.photo
                        : require("../../image/image_unavailable.jpg")
                    }
                    alt="user"
                    height="30"
                    width="30"
                    style={{ marginRight: 5 }}
                  />
                </div>
                <div className="col l3">
                  {user.User ? user.User.userName : null}
                </div>
                <div className="col l3">
                  {user.User ? user.User.email : null}
                </div>
                <div className="col l2">
                  {user.User.Role ? user.User.Role.name : null}
                </div>
                <div className="col l3">
                  <button
                    data-target="add-user-modal"
                    className="modal-trigger action-button"
                    id={"modify-"+user.User.id}
                    onClick={EditUser}
                  >
                    Modify User
                  </button>
                  <button
                    id={"resetPass-"+user.User.id}
                    className="modal-trigger action-button"
                    data-target="reset-password-modal"
                    onClick={resetPassword}
                  >
                    Reset Password
                  </button>
                  <button
                    data-target="delete-user-modal"
                    id={"revoke-"+user.User.id}
                    className=" modal-trigger action-button"
                    onClick={DeleteUser}
                  >
                    Revoke User
                  </button>
                </div>
              </div>
            ))}
        </div>
      </form>
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(SupervisorList);
