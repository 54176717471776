import React, { useEffect, useState } from "react";
import Navbar from "../Layouts/Navbar";
import M from "materialize-css";
import Footer from "../Layouts/Footer";
import "../style.css";

export default function LandingPage() {
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    const elems = document.querySelectorAll(".carousel.carousel-slider");
    M.Carousel.init(elems, {
      fullWidth: true,
      indicators: true,
      // onCycleTo: function (data) {
      //   let carouselInstance = M.Carousel.getInstance(elems[0]);
      //   let currentIndex = carouselInstance.center;
      //   setActiveIndex(currentIndex);
      // },
    });
    const instance = M.Carousel.getInstance(elems[0]);
    const autoplay = () => instance.next();
    // const interval = setInterval(autoplay, 5000);
    // return () => clearInterval(interval);
  }, []);

  const dots = [0, 1, 2].map((index) => (
    <li
      onClick={(event) => {
        const elems = document.querySelector(".carousel.carousel-slider");
        const instance = M.Carousel.getInstance(elems);
        instance.set(index);
        setActiveIndex(index);
      }}
      key={index}
      className={`dot ${index === activeIndex ? "active" : ""}`}
    />
  ));

  return (
    <div>
      <Navbar />
      <section>
        <div id="home-section-1" className="row">
          <div className="col s12">
            <h4
              style={{
                fontSize: "2.1rem",
                fontWeight: "600",
                textShadow: "2px 2px 4px rgba(0,0,0,0.2)",
              }}
            >
              University Management System
            </h4>
            <span style={{ color: "#124265", fontSize: "1.2rem" }}>
              Integrate Data across your Campus & Colleges
            </span>
          </div>
          <div className="col s6">
            <div className="col s12" style={{ textAlign: "right" }}>
              <img src={"/images/img1.png"} height="250px" />
            </div>
          </div>
          <div className="col s6">
            <div className="col s12" style={{ textAlign: "center" }}>
              <img src={"/images/img2.png"} height="250px" />
            </div>
          </div>
        </div>
      </section>
      <hr className="divider" />
      <section
        style={{ paddingTop: "0%", paddingBottom: "1%", textAlign: "center" }}
      >
        <h2 className="section-heading">Benefits</h2>
        <div id="home-section-2" className="row">
          <div className="col s4">
            <div className="col s12 center">
              <img src={"/images/benefit1.png"} height="160px" width="164px" />
            </div>
            <div className="col s12">
              <p className="center">
                By interlinking all departments, the system creates a unified
                platform for data management. This eliminates data silos,
                ensuring that information is easily accessible across
                departments. The system facilitates better communication among
                faculty, staff, students, and parents.
              </p>
            </div>
          </div>
          <div className="col s4">
            <div className="col s12 center">
              <img src={"/images/benefit2.png"} height="160px" width="164px" />
            </div>
            <div className="col s12 center">
              <p className="center">
                our educational institution management system is designed to
                equip key stakeholders—administrators, educators, students, and
                parents—with the right tools to excel in their respective roles.
                By equipping key stakeholders with the right tools, we're
                creating an ecosystem that supports dynamic learning, fosters
                collaboration, and drives institutional excellence.
              </p>
            </div>
          </div>
          <div className="col s4">
            <div className="col s12 center">
              <img src={"/images/benefit3.png"} height="160px" width="164px" />
            </div>
            <div className="col s12 center">
              <p className="center">
                Our system introduces a high degree of automation across various
                administrative and academic processes. By automating critical
                functions, our system frees up valuable time for educators and
                administrators, allowing them to focus more on strategic
                initiatives and less on manual tasks.
              </p>
            </div>
          </div>
        </div>
      </section>
      <hr className="divider" />
      <section style={{ paddingTop: "3%", paddingBottom: "0%" }}>
        <div id="home-section-3" className="row">
          <div className="carousel carousel-slider">
            <a className="carousel-item" href="#one!">
              <div className="col s6" id="background-image-div-admin"></div>
              <div className="col s6" style={{ paddingLeft: "7%" }}>
                <h5
                  style={{
                    fontWeight: "600",
                    textShadow: "1px 1px 2px rgba(0,0,0,0.2)",
                  }}
                >
                  Lets see Admins gets what
                </h5>
                <ul className="checkmark-list" style={{ marginTop: "7%" }}>
                  <li>
                    Save time.. lots of time (Let the software do the work).
                  </li>
                  <li>
                    Reports are generated by the click of a mouse (Save
                    efforts).
                  </li>
                  <li>Keep a track of stuff happening around.</li>
                  <li>Boost productivity & efficiency.</li>
                  <li>Eliminate errors.</li>
                  <li>Maintain Academic & Examination records centrally.</li>
                </ul>
              </div>
            </a>
            <a className="carousel-item" href="#two!">
              <div className="col s6" id="background-image-div-faculty"></div>
              <div className="col s6" style={{ paddingLeft: "7%" }}>
                <h5
                  style={{
                    fontWeight: "600",
                    textShadow: "1px 1px 2px rgba(0,0,0,0.2)",
                  }}
                >
                  Lets see Faculties gets what
                </h5>
                <ul className="checkmark-list" style={{ marginTop: "7%" }}>
                  <li>Save time (Let the software do the work).</li>
                  <li>
                    Grow on the learning curve of technology usage in education.
                  </li>
                  <li>
                    Reports are generated by the click of a mouse (Save
                    efforts).
                  </li>
                  <li>
                    Keep a track of student activities & Some Admin activities.
                  </li>
                  <li>Eliminate errors.</li>
                  <li>Send notifications via SMS and emails.</li>
                </ul>
              </div>
            </a>
            <a className="carousel-item" href="#three!">
              <div className="col s6" id="background-image-div-student"></div>
              <div className="col s6" style={{ paddingLeft: "7%" }}>
                <h5
                  style={{
                    fontWeight: "600",
                    textShadow: "1px 1px 2px rgba(0,0,0,0.2)",
                  }}
                >
                  Lets see Students gets what
                </h5>
                <ul className="checkmark-list" style={{ marginTop: "7%" }}>
                  <li>View Routines, Curriculums, Reports, activities etc.</li>
                  <li>
                    Stay up to date by receiving notifications from Faculties,
                    Admins etc.
                  </li>
                  <li>
                    Grow on the learning curve of tech supported education
                    system.
                  </li>
                  <li>
                    Eliminate Admission time rush via online application and
                    payment.
                  </li>
                  <li>Give anonymous feedback to class teachers.</li>
                </ul>
              </div>
            </a>
          </div>
          <ul className="custom-dots">{dots}</ul>
        </div>
      </section>
      <hr className="divider" />
      <section
        style={{ paddingTop: "1%", paddingBottom: "2.5%", textAlign: "center" }}
      >
        <h2 className="section-heading">Modes of deployment</h2>
        <div id="home-section-4" className="row">
          {/* <div className="col s12">
            <h5>Modes of deployment</h5>
          </div> */}
          <div className="col s12" style={{ textAlign: "center" }}>
            <img
              className="center"
              src="images/deploymet_modes.png"
              height="326px"
            />
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}
