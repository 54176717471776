const initState = {
  professorLoading: "",
  professorSuccess: "",
  professorError: "",
  professor: [],

  responseRequestSuccess: "",
  responseRequestError: "",
  responseRequest: [],

  courseFetchSuccess: "",
  courseFetchError: "",
  course: [],

  semesterFetchSuccess: "",
  semesterFetchError: "",
  semester: [],

  departmentFetchSuccess: "",
  departmentFetchError: "",
  department: [],

  courseRequestSuccess: "",
  courseRequestError: "",
  courseRequest: [],

  attendenceSuccess: "",
  attendenceError: "",
  attendence: [],

  studentPerformanceLoading:"",
  studentPerformanceSuccess:"",
  studentPerformanceError:"",
  studentPerformance:[],

  professorCourseLoading:"",
  professorCourseSuccess:"",
  professorCourseError:"",
  professorCourse:[],

  studentLoading: '',
  studentSuccess:'',
  studentError:'',
  student:[],

  studentMarksLoading: '',
  studentMarksSuccess:'',
  studentMarksError:'',
  studentMarks:[],

  professorFeedbackLoading: '',
  professorFeedbackSuccess:'',
  professorFeedbackError:'',
  professorFeedback:[],
};

const ProfessorReducer = (state = initState, action) => {
  switch (action.type) {
    // case "PROFESSOR_FETCH_LOADING":
    //     return{
    //         professorLoading: true,
    //         professorSuccess:false,
    //         professorError:false,
    //     }
    case "PROFESSOR_FETCH_SUCCESS":
      return {
          ...state,
        professorLoading: false,
        professorSuccess: true,
        professorError: false,
        professor: action.payload,
      };
    case "PROFESSOR_FETCH_ERROR":
      return {
        ...state,
        professorLoading: false,
        professorSuccess: false,
        professorError: true,
      };
    case "RESPONSE_REQUEST_SUCCESS":
      return {
        ...state,
        responseRequestSuccess: true,
        responseRequestError: false,
        responseRequest: action.payload,
      };
    case "RESPONSE_REQUEST_FAILED":
      return {
        ...state,
        responseRequestSuccess: false,
        responseRequestError: true,
      };
    case "COURSE_FETCH_SUCCESS":
      return {
        ...state,
        courseFetchSuccess: true,
        courseFetchError: false,
        course: action.payload.data,
      };
    case "COURSE_FETCH_ERROR":
      return {
        ...state,
        courseFetchSuccess: false,
        courseFetchError: true,
      };
    case "SEMESTER_FETCH_SUCCESS":
      return {
        ...state,
        semesterFetchSuccess: true,
        semesterFetchError: false,
        semester: action.payload.data,
      };
    case "SEMESTER_FETCH_ERROR":
      return {
        ...state,
        semesterFetchSuccess: false,
        semesterFetchError: true,
      };
    case "DEPARTMENT_FETCH_SUCCESS":
        return {
            ...state,
        departmentFetchSuccess: true,
        departmentFetchError: false,
        department: action.payload,
      };
    case "DEPARTMENT_FETCH_ERROR":
        return {
            ...state,
        departmentFetchSuccess: false,
        departmentFetchError: true,
      };
    case "COURSE_REQUEST_SUCCESS":
      return {
        ...state,
        courseRequestSuccess: true,
        courseRequestError: false,
        courseRequest: action.payload,
      };
    case "COURSE_REQUEST_FAILED":
      return {
        ...state,
        courseRequestSuccess: false,
        courseRequestError: true,
      };
      case "ATTENDENCE_FETCH_SUCCESS":
      return {
        ...state,
        attendenceSuccess: true,
        attendenceError: false,
        attendence: action.payload.data,
      };
    case "ATTENDENCE_FETCH_ERROR":
      return {
        ...state,
        attendenceSuccess: false,
        attendenceError: true,
      };
      case "STUDENT_PERFORMANCE_FETCH_LOADING":
      return {
        ...state,
        studentPerformanceLoading:true,
        studentPerformanceSuccess:false,
        studentPerformanceError:false
      };
      case "STUDENT_PERFORMANCE_FETCH_SUCCESS":
      return {
        ...state,
        studentPerformanceLoading: false,
        studentPerformanceSuccess:true,
        studentPerformanceError:false,
        studentPerformance:action.payload,
      };
    case "STUDENT_PERFORMANCE_FETCH_ERROR":
      return {
        ...state,
        studentPerformanceLoading:false,
        studentPerformanceSuccess:false,
        studentPerformanceError:true
      };
      case "PROFESSOR_COURSE_FETCH_LOADING":
        return {
          ...state,
          professorCourseLoading: true,
          professorCourseSuccess:false,
          professorCourseError:false,
        };
      case "PROFESSOR_COURSE_FETCH_SUCCESS":
      return {
        ...state,
        professorCourseLoading: false,
        professorCourseSuccess:true,
        professorCourseError:false,
        professorCourse:action.payload,
      };
    case "PROFESSOR_COURSE_FETCH_ERROR":
      return {
        ...state,
        professorCourseLoading:false,
        professorCourseSuccess:false,
        professorCourseError:true
      };
      case "STUDENT_FETCH_SUCCESS":
        return {
          ...state,
          studentLoading: false,
          studentSuccess:true,
          studentError:false,
          student:action.payload,
        };
      case "STUDENT_FETCH_ERROR":
        return {
          ...state,
          studentLoading:false,
          studentSuccess:false,
          studentError:true
        };
        case "STUDENT_MARKS_FETCH_LOADING":
          return {
            ...state,
            studentMarksLoading: true,
            studentMarksSuccess:false,
            studentMarksError:false,
          };
        case "STUDENT_MARKS_FETCH_SUCCESS":
          return {
            ...state,
            studentMarksLoading: false,
            studentMarksSuccess:true,
            studentMarksError:false,
            studentMarks:action.payload,
          };
        case "STUDENT_MARKS_FETCH_ERROR":
          return {
            ...state,
            studentMarksLoading:false,
            studentMarksSuccess:false,
            studentMarksError:true
          };
          case "PROFESSOR_FEEDBACK_FETCH_LOADING":
          return {
            ...state,
            professorFeedbackLoading: true,
            professorFeedbackSuccess:false,
            professorFeedbackError:false,
          };
          case "PROFESSOR_FEEDBACK_FETCH_SUCCESS":
          return {
            ...state,
            professorFeedbackLoading: false,
            professorFeedbackSuccess:true,
            professorFeedbackError:false,
            professorFeedback:action.payload,
          };
        case "PROFESSOR_FEEDBACK_FETCH_ERROR":
          return {
            ...state,
            professorFeedbackLoading:false,
            professorFeedbackSuccess:false,
            professorFeedbackError:true
          };
    default:
      return state;
  }
};
export default ProfessorReducer;
