import React, {Component} from "react";
import { connect } from "react-redux";
import M from 'materialize-css'
import { Redirect, Link} from "react-router-dom";
import Navbar from "../layout/Navbar"
import Stats from '../layout/Stats'

import {loadExistingSurvey} from '../../store/actions/designActions'


class LiveSurvey extends Component {
      state = {
          frequency:this.props.initData?this.props.initData.frequency:null,
          company:this.props.initData?this.props.initData.company:null,
          startDate:this.props.initData?this.props.initData.startDate:null,
          endDate:this.props.initData?this.props.initData.endDate:null,
          columns:[]
        }
      

      componentDidMount = () => {
        // console.log("nis", this.state)
        var elems = document.querySelectorAll('select');
        var instances = M.FormSelect.init(elems, {});
        console.log("here");
        if (this.props.surveys.length>0 && this.props.id){
          var surveyData = this.props.surveys.find((survey) => {return survey.id==this.props.id})
          // console.log("here", surveyData);
          this.props.LoadExistingSurvey(surveyData, surveyData.id)
        }
      }

      HandleChange=(e)=>{
        
      }
      onDateClose=()=>{
        this.setState({
            ...this.state,
            startDate:document.getElementById('startDate').value,
            endDate:document.getElementById('endDate').value
          })
        // console.log(this.state)
      }
      componentDidUpdate = () =>{
        
      }
    
    render(){
      const {auth, responses, CompanyName, loading, error} = this.props 
      if (!(auth.user&&auth.user.id)) return <Redirect to="/login" />;
      console.log(this.props);
    //   if (!(ChartData) || ChartData.length === 0) return <Redirect to="/" />;
      return (
        <div className='row '>
          <Navbar heading="Live Survey" user={auth.user}/>
          <div className='main_container_wrapper'>
            <div className="container" style={{width: "100%"}}>
              <div className="row card">
                <div className="row">
                  <Stats
                    items={[
                      { heading: "Total Interview", value: this.props.stat ? this.props.stat.numBorrowers : "23" },
                      { heading:"Total QC", value:this.props.stat ? this.props.stat.unpaidPrincipal.toFixed(2) : "9"},
                      { heading:"Approved QC", value:this.props.stat ? this.props.stat.defaultPrincipal != null && this.props.stat.defaultPrincipal !== "null" ?this.props.stat.defaultPrincipal.toFixed(2):0 : "7", color: "green"},
                      {heading:"Total Interviewer", value:this.props.stat&&this.props.stat.impactScore ? this.props.stat.impactScore.score.toFixed(2) : "10"} 
                    ]}
                  />
                </div>

                <div className="row col s12">
                  <Link to={"/survey/"+this.props.id+"/design"} className="col s12 btn">
                    Redesign
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }

};

const mapStateToProps = (state, ownProps) => {
  console.log("state", state);
  return {
    auth: state.auth,
    id: ownProps.match.params.id,
    surveys:state.designer.surveys?state.designer.surveys:[],
  };
};
const mapDispatchToProps = (dispatch)  => {
  return {
    LoadExistingSurvey: (data,  id) => {
      dispatch(loadExistingSurvey(data,id));
    },
  }
};



export default connect(mapStateToProps, mapDispatchToProps)(LiveSurvey);



