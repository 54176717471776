import {backendUrl} from '../../config/api';


export const getDeptProfessors = (deptId,token) => {
    return (dispatch) => {
        // dispatch({
        //     type: "DEPARTMENT_PROFESSOR_FETCH_LOADING"
        //   });
      fetch(backendUrl + "/dept/"+deptId+"/professors",{
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }).then((response) =>
        response.json().then((data) => {
          if (response.status == 200) {
            dispatch({
              type: "DEPARTMENT_PROFESSOR_FETCH_SUCCESS",
              payload: data.data,
            });
          } else if (response.status == 403) {
            dispatch({
              type: "AUTHENTICATION_ERROR",
            });
          } else {
            dispatch({
              type: "DEPARTMENT_PROFESSOR_FETCH_ERROR",
            });
          }
        })
      );
    };
  };

  export const getProfessors = (token) => {
    return (dispatch) => {
        // dispatch({
        //     type: "DEPARTMENT_PROFESSOR_FETCH_LOADING"
        //   });
        fetch(backendUrl + "/professors",{
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }).then((response) =>
        response.json().then((data) => {
          if (response.status == 200) {
            dispatch({
              type: "PROFESSOR_FETCH_SUCCESS",
              payload: data.data,
            });
          } else if (response.status == 403) {
            dispatch({
              type: "AUTHENTICATION_ERROR",
            });
          } else {
            dispatch({
              type: "PROFESSOR_FETCH_ERROR",
            });
          }
        })
      );
    };
  };
  export const addProfessor = (deptId,body,token) => {
    return dispatch => {
      dispatch({type: 'ADD_PROFESSOR_LOADING'});
      var url = backendUrl + "/addProfessor/"+deptId;
      fetch(url,{
        method: "post",
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(body)
      })
      .then(response =>
        response.json().then(data => {
          if (response.status === 200) {
            data['body'] = body
            // console.log("action", data)
            dispatch({
              type: "ADD_PROFESSOR_SUCCESS",
              payload: data
            });
          } 
          else if (response.status === 403 || response.status === 401) {
            dispatch({
              type: "AUTHENTICATION_ERROR"
            });
          }
          else {
            dispatch({
              type: "ADD_PROFESSOR_ERROR"
            });
          }
        })
      );
    };
  };

  export const getCourses = (deptId,token) => {
    return (dispatch) => {
        // dispatch({
        //     type: "COURSE_FETCH_LOADING"
        //   });
      // fetch(backendUrl + "/courses",{
        fetch(backendUrl + "/dept/"+deptId+"/courses",{
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }).then((response) =>
        response.json().then((data) => {
          if (response.status == 200) {
            dispatch({
              type: "COURSE_FETCH_SUCCESS",
              payload: data.data,
            });
          } else if (response.status == 403) {
            dispatch({
              type: "AUTHENTICATION_ERROR",
            });
          } else {
            dispatch({
              type: "COURSE_FETCH_ERROR",
            });
          }
        })
      );
    };
  };

  export const getSemester = (id,token) => {
    return (dispatch) => {
        // dispatch({
        //     type: "SEMESTER_FETCH_LOADING"
        //   });
      fetch(backendUrl + "/dept/"+id+"/semesters",{
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }).then((response) =>
        response.json().then((data) => {
          if (response.status == 200) {
            dispatch({
              type: "SEMESTER_FETCH_SUCCESS",
              payload: data.data,
            });
          } else if (response.status == 403) {
            dispatch({
              type: "AUTHENTICATION_ERROR",
            });
          } else {
            dispatch({
              type: "SEMESTER_FETCH_ERROR",
            });
          }
        })
      );
    };
  };

  export const getSection = (id,token) => {
    return (dispatch) => {
        // dispatch({
        //     type: "SEMESTER_FETCH_LOADING"
        //   });
      fetch(backendUrl + "/semester/"+id+"/sections",{
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }).then((response) =>
        response.json().then((data) => {
          if (response.status == 200) {
            dispatch({
              type: "SECTION_FETCH_SUCCESS",
              payload: data.data,
            });
          } else if (response.status == 403) {
            dispatch({
              type: "AUTHENTICATION_ERROR",
            });
          } else {
            dispatch({
              type: "SECTION_FETCH_ERROR",
            });
          }
        })
      );
    };
  };

  export const getRooms = (token) => {
    return (dispatch) => {
      fetch(backendUrl + "/rooms",{
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }).then((response) =>
        response.json().then((data) => {
          if (response.status == 200) {
            dispatch({
              type: "ROOM_FETCH_SUCCESS",
              payload: data.data,
            });
          } else if (response.status == 403) {
            dispatch({
              type: "AUTHENTICATION_ERROR",
            });
          } else {
            dispatch({
              type: "ROOM_FETCH_ERROR",
            });
          }
        })
      );
    };
  };

  export const approveCourse = (body,token) => {
    return dispatch => {
      dispatch({type: 'APPROVE_COURSE_LOADING'});
      var url = backendUrl + "/approveCourse";
      fetch(url,{
        method: "post",
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(body)
      })
      .then(response =>
        response.json().then(data => {
          if (response.status === 200) {
            data['body'] = body
            // console.log("action", data)
            dispatch({
              type: "APPROVE_COURSE_SUCCESS",
              payload: data
            });
          } 
          else if (response.status === 403 || response.status === 401) {
            dispatch({
              type: "AUTHENTICATION_ERROR"
            });
          }
          else {
            dispatch({
              type: "APPROVE_COURSE_ERROR"
            });
          }
        })
      );
    };
  };

  export const changePriority = (body,token) => {
    return dispatch => {
      dispatch({type: 'CHANGE_PRIORITY_LOADING'});
      var url = backendUrl + "/changePriority";
      fetch(url,{
        method: "post",
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(body)
      })
      .then(response =>
        response.json().then(data => {
          if (response.status === 200) {
            data['body'] = body
            // console.log("action", data)
            dispatch({
              type: "CHANGE_PRIORITY_SUCCESS",
              payload: data
            });
          } 
          else if (response.status === 403 || response.status === 401) {
            dispatch({
              type: "AUTHENTICATION_ERROR"
            });
          }
          else {
            dispatch({
              type: "CHANGE_PRIORITY_ERROR"
            });
          }
        })
      );
    };
  };

  export const filterRoutine = (filter,id, token) => {
    return dispatch => {
      dispatch({type: 'FILTER_ROUTINE_LOADING'});
      var url = backendUrl + "/routine/"+filter+"/"+id;
      fetch(url,{
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(response =>
        response.json().then(data => {
          if (response.status === 200) {
            dispatch({
              type: "FILTER_ROUTINE_SUCCESS",
              payload: data.data
            });
          } 
          else if (response.status === 403 || response.status === 401) {
            dispatch({
              type: "AUTHENTICATION_ERROR"
            });
          }
          else {
            dispatch({
              type: "FILTER_ROUTINE_ERROR"
            });
          }
        })
      );
    };
  };

  export const generateRoutine = (token) => {
    return (dispatch) => {
      dispatch({type: 'GENERATE_ROUTINE_LOADING'});
      fetch(backendUrl + "/generateRoutine",{
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }).then((response) =>
        response.json().then((message) => {
          if (response.status == 200) {
            dispatch({
              type: "GENERATE_ROUTINE_SUCCESS",
              payload: message,
            });
          } else if (response.status == 403) {
            dispatch({
              type: "AUTHENTICATION_ERROR",
            });
          } else {
            dispatch({
              type: "GENERATE_ROUTINE_ERROR",
            });
          }
        })
      );
    };
  };