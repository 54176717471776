import React from "react";

export default function Footer() {
  return (
    <footer className="page-footer footer">
      <div className="row">
        <div className="col s6 center">
          <h5>Address :</h5>
          <p>
            7th Floor, Monibhandar Building,
            <br /> Webel Bhavan Premises,
            <br />
            Sector V, GP Block,
            <br /> Kolkata 700091
          </p>
        </div>
        <div className="col s6 center" style={{ paddingTop: "4%" }}>
          <p style={{ fontSize: "1.1rem" }}>Incubated by :</p>
          <h5>NASSCOM</h5>
        </div>
      </div>
    </footer>
  );
}
