export default function ApplyStyle(question, lang, type){
    var htmlString =""
    if(type=='question'){
        if(question.qStyleExtra&&question.qStyleExtra.length>0){
            question.qStyleExtra.sort((a,b)=>{
                if(a.bold&&a.bold.status&&a.bold.start){
                    if(Number(a.bold.start)<Number(b.bold.start)){
                        return 1 
                    }
                    else{
                        return -1
                    }
                }
                else if(a.italic&&a.italic.status&&a.italic.start){
                    if(Number(a.italic.start)<Number(b.italic.start)){
                        return 1 
                    }
                    else{
                        return -1
                    }
                }
                else if(a.color&&a.color.status&&a.color.start){
                    if(Number(a.color.start)<Number(b.color.start)){
                        return 1 
                    }
                    else{
                        return -1
                    }
                }
                else{
                    return -1
                }
            })
            var maxLen = question.question[lang].length
            var min = question.question[lang].length
            var max = 0
            if(question.qStyleExtra[0]){
                if(question.qStyleExtra[0].bold.status&&question.qStyleExtra[0].bold.start<min){
                    min = question.qStyleExtra[0].bold.start
                }
                else if(question.qStyleExtra[0].italic.status&&question.qStyleExtra[0].italic.start<min){
                    min = question.qStyleExtra[0].italic.start
                }
                else if(question.qStyleExtra[0].color.status&&question.qStyleExtra[0].color.start<min){
                    min = question.qStyleExtra[0].color.start
                }

                if(question.qStyleExtra[0].bold.status&&question.qStyleExtra[0].bold.end>max){
                    max = question.qStyleExtra[0].bold.end
                }
                else if(question.qStyleExtra[0].italic.status&&question.qStyleExtra[0].italic.start>max){
                    max = question.qStyleExtra[0].italic.end
                }
                else if(question.qStyleExtra[0].color.status&&question.qStyleExtra[0].color.start>max){
                    max = question.qStyleExtra[0].color.end
                }
            }
            console.log("init min", min);
            htmlString += "<span>"+question.question[lang].slice(0, min)+"<span/>"
            question.qStyleExtra.map(style => {
                min = maxLen
                max=0
                if(style.bold.status&& style.bold.start<min){
                    min = style.bold.start
                }
                else if(style.italic.status&&style.italic.start<min){
                    min = style.italic.start
                }
                else if(style.color.status&&style.color.start<min){
                    min = style.color.start
                }
                if(style.bold.status&&style.bold.end>max){
                    max = style.bold.end
                }
                else if(style.italic.status&&style.italic.end>max){
                    max = style.italic.end
                }
                else if(style.color.status&&style.color.end>max){
                    max = style.color.end
                }

                htmlString += "<span style=\"color:"+(style.color&&style.color.status?style.color.color:'')
                +"; font-weight:"+ (style.bold&&style.bold.status?'bold':'normal')
                +"; font-style:"+ (style.italic&&style.italic.status?'italic':'normal')
                +";\">"+question.question[lang].slice(min, max+1)+"</span>"
                console.log("IN map:",min, max, question.question[lang].slice(min, max+1));
            })
            htmlString += "<span>"+question.question[lang].slice(max+1, maxLen)+"<span/>"
        }
        else
            htmlString = "<span>"+question.question[lang]+"</span>"
        
        if(question.mandatory){
            htmlString += "<span style=\"color:red;\"> ( * )</span>"
        }
        console.log("String", htmlString);
    }
    else if(type=='option'){
        if(question.opStyleExtra&&question.opStyleExtra.length>0){
            question.opStyleExtra.sort((a,b)=>{
                if(a.bold&&a.bold.status&&a.bold.start){
                    if(Number(a.bold.start)<Number(b.bold.start)){
                        return 1 
                    }
                    else{
                        return -1
                    }
                }
                else if(a.italic&&a.italic.status&&a.italic.start){
                    if(Number(a.italic.start)<Number(b.italic.start)){
                        return 1 
                    }
                    else{
                        return -1
                    }
                }
                else if(a.color&&a.color.status&&a.color.start){
                    if(Number(a.color.start)<Number(b.color.start)){
                        return 1 
                    }
                    else{
                        return -1
                    }
                }
                else{
                    return -1
                }
            })
            var maxLen = question.option[lang].length
            var min = question.option[lang].length
            var max = 0
            if(question.opStyleExtra[0]){
                if(question.opStyleExtra[0].bold.status&&question.opStyleExtra[0].bold.start<min){
                    min = question.opStyleExtra[0].bold.start
                }
                else if(question.opStyleExtra[0].italic.status&&question.opStyleExtra[0].italic.start<min){
                    min = question.opStyleExtra[0].italic.start
                }
                else if(question.opStyleExtra[0].color.status&&question.opStyleExtra[0].color.start<min){
                    min = question.opStyleExtra[0].color.start
                }
    
                if(question.opStyleExtra[0].bold.status&&question.opStyleExtra[0].bold.end>max){
                    max = question.opStyleExtra[0].bold.end
                }
                else if(question.opStyleExtra[0].italic.status&&question.opStyleExtra[0].italic.start>max){
                    max = question.opStyleExtra[0].italic.end
                }
                else if(question.opStyleExtra[0].color.status&&question.opStyleExtra[0].color.start>max){
                    max = question.opStyleExtra[0].color.end
                }
            }
            console.log("init min", min);
            htmlString += "<span>"+question.option[lang].slice(0, min)+"<span/>"
            question.opStyleExtra.map(style => {
                min = maxLen
                max=0
                if(style.bold.status&& style.bold.start<min){
                    min = style.bold.start
                }
                else if(style.italic.status&&style.italic.start<min){
                    min = style.italic.start
                }
                else if(style.color.status&&style.color.start<min){
                    min = style.color.start
                }
                if(style.bold.status&&style.bold.end>max){
                    max = style.bold.end
                }
                else if(style.italic.status&&style.italic.end>max){
                    max = style.italic.end
                }
                else if(style.color.status&&style.color.end>max){
                    max = style.color.end
                }
    
                htmlString += "<span style=\"color:"+(style.color&&style.color.status?style.color.color:'')
                +"; font-weight:"+ (style.bold&&style.bold.status?'bold':'normal')
                +"; font-style:"+ (style.italic&&style.italic.status?'italic':'normal')
                +";\">"+question.option[lang].slice(min, max+1)+"</span>"
                console.log("IN map:",min, max, question.option[lang].slice(min, max+1));
            })
            htmlString += "<span>"+question.option[lang].slice(max+1, maxLen)+"<span/>"
        }
        else
            htmlString = "<span>"+question.option[lang]+"</span>"
        console.log("String", htmlString);
    }
    return htmlString
}