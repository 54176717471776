import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router";
import Tab from "../layout/Tab";
import M from "materialize-css";
import Navbar from "../layout/Navbar";
import { UploadModal } from "../layout/UploadModal";
import "../stylesheets/common.css";
import { UploadDocument } from "../layout/UploadDocument";

class TeacherDashboard extends Component {
  state = {
    flag: "",
    option: "",
  };
  componentDidMount = () => {
    var elems = document.querySelectorAll(".modal");
    var instances = M.Modal.init(elems, {
      preventScrolling: true,
    });
  };
  handleChange = (e) => {
    this.setState({ option: e.target.value });
  };
  onClickGo = (name) => {
    this.setState({ flag: name });
  };
  onModalOpen = (name) => {
    if(name == "Lesson Plan"){
      var modal = document.getElementById("upload-modal");
      var instance = M.Modal.getInstance(modal);
      instance.open();
    }
    else if( name == "Upload Marks"){
      var modal = document.getElementById("upload-marks");
      var instance = M.Modal.getInstance(modal);
      instance.open();
    }
  };
  render() {
    const user = this.props.auth.user;
    console.log(user);
    if (!user) return <Redirect to="/login" />;
    if (this.state.flag == "Student Performance")
      return <Redirect to="/studentperfomance" />;
    if (this.state.flag == "Upload Attendence")
      return <Redirect to="/attendance" />;
    if (this.state.flag == "Your Performance")
      return <Redirect to="/YourPerfomance" />;
    if (this.state.flag == "Online Test")
      return <Redirect to={"/designer/" + user.id + "/dashboard"} />;
    if (this.state.flag == "Choose Time")
      return <Redirect to="/teacher/timetable" />;
    if (this.state.flag == "Class Schedule")
      return <Redirect to="/teacher/schedule" />;
    if (this.state.flag == "Choose Subject")
      return <Redirect to="/teacher/subject" />;
    if (this.state.flag == "Online Assesment")
      return <Redirect to={"/qc/" + user.id + "/dashboard"} />;
    if (this.state.flag == "Manage Test")
      return <Redirect to={"/usermanagement"} />;
    if (this.state.flag == "Leave") return <Redirect to={"/facultyleave"} />;
    if (this.state.flag == "View Result")
      return <Redirect to={"/viewResult"} />;
    if (this.state.flag == "Attendance")
      return <Redirect to={"/facultyAttendance"} />;
    if (this.state.flag == "Announce")
      return <Redirect to={"/faculty/announcement"} />;
    if (this.state.flag == "View Marks")
      return <Redirect to={"/marks"} />;
    if (this.state.flag == "Salary")
      return <Redirect to={"/salary"} />
    if (this.state.flag == "Lesson Plan")
      return <Redirect to={"/lessonplan"} />
    return (
      <div>
        <Navbar heading="Admin" user={user} />
        <UploadModal state={this.state} handleChange={this.handleChange} />
        <UploadDocument/>
        <div className="container" style={{ marginTop: 20 }}>
          <div className="row Academics&&Student">
            <div className="col s6 m6 l6">
              <div
                className="row academics"
                style={{ marginTop: 10, marginLeft: 49 }}
              >
                <fieldset style={{ height: 147 }}>
                  <legend>Academics</legend>
                  <div className="col s3 m3 l3">
                    <Tab
                      image="/assests/img/classroom.png"
                      color={{ r: "63", g: "140", b: "35" }}
                      name="Class Schedule"
                      onClick={this.onClickGo}
                    />
                  </div>
                  <div className="col s3 m3 l3">
                    <Tab
                      image="/assests/img/book.png"
                      color={{ r: "63", g: "140", b: "35" }}
                      name="Choose Subject"
                      onClick={this.onClickGo}
                    />
                  </div>
                  <div className="col s3 m3 l3">
                    <Tab
                      image="/assests/img/announcement.png"
                      color={{ r: "63", g: "140", b: "35" }}
                      name="Announce"
                      onClick={this.onClickGo}
                    />
                  </div>
                  <div className="col s3 m3 l3">
                    <Tab
                      image="/assests/img/lesson-plan.png"
                      color={{ r: "63", g: "140", b: "35" }}
                      // datatarget='"upload-modal"'
                      // classname="btn-flat modal-trigger"
                      name="Lesson Plan"
                      onClick={this.onClickGo}
                    />
                  </div>
                </fieldset>
              </div>
            </div>
            <div className="col s6 m6 l6">
              <div
                className="row student"
                style={{marginTop: 10, marginLeft: 49}}
              >
                <fieldset style={{ height: 147 }}>
                  <legend>Student</legend>
                  <div className="col s6 m6 l6">
                    <Tab
                      image="/assests/img/attendence.png"
                      color={{ r: "230", g: "81", b: "32" }}
                      name="Upload Attendence"
                      onClick={this.onClickGo}
                    />
                  </div>
                  <div className="col s6 m6 l6">
                    <Tab
                      image="/assests/img/student.png"
                      color={{ r: "230", g: "81", b: "32" }}
                      name="Student Performance"
                      onClick={this.onClickGo}
                    />
                  </div>
                </fieldset>
              </div>
            </div>
          </div>
          <div className="row online_test&&offline_test">
            <div className="col s6 m6 l6">
              <div
                className="row online_test"
                style={{  marginLeft: 49 }}
              >
                <fieldset style={{ height: 147 }}>
                  <legend>Online Test</legend>
                  <div className="col s4 m4 l4">
                    <Tab
                      image="/assests/img/geography.png"
                      color={{ r: "191", g: "191", b: "13" }}
                      name="Online Test"
                      onClick={this.onClickGo}
                    />
                  </div>
                  <div className="col s4 m4 l4">
                    <Tab
                      image="/assests/img/analytics.png"
                      color={{ r: "191", g: "191", b: "13" }}
                      name="Online Assesment"
                      onClick={this.onClickGo}
                    />
                  </div>
                  <div className="col s4 m4 l4">
                    <Tab
                      image="/assests/img/view.png"
                      color={{ r: "191", g: "191", b: "13" }}
                      name="View Result"
                      onClick={this.onClickGo}
                    />
                  </div>
                </fieldset>
              </div>
            </div>
            <div className="col s6 m6 l6">
            <div
                className="row offline_test"
                style={{  marginLeft: 49 }}
              >
                <fieldset style={{ height: 147 }}>
                  <legend>Offline Test</legend>
                  <div className="col s6 m6 l6">
                    <Tab
                      image="/assests/img/upload.png"
                      color={{ r: "16", g: "158", b: "163" }}
                      datatarget='"upload-marks"'
                      classname="btn-flat modal-trigger"
                      name="Upload Marks"
                      onClick={this.onModalOpen}
                    />
                  </div>
                  <div className="col s6 m6 l6">
                    <Tab
                      image="/assests/img/marks.png"
                      color={{ r: "16", g: "158", b: "163" }}
                      name="View Marks"
                      onClick={this.onClickGo}
                    />
                  </div>
                </fieldset>
              </div>
            </div>
          </div>
          <div className="row personal_data">
          <div className="col s6 m6 l6">
          <div
                className="row personal_info"
                style={{  marginLeft: 49 }}
              >
                <fieldset style={{ height: 147 }}>
                  <legend>Personal Data</legend>
                  <div className="col s3 m3 l3">
                    <Tab
                      image="/assests/img/your.png"
                      color={{ r: "93", g: "27", b: "143" }}
                      name="Your Performance"
                      onClick={this.onClickGo}
                    />
                  </div>
                  <div className="col s3 m3 l3">
                    <Tab
                      image="/assests/img/leave.png"
                      color={{ r: "93", g: "27", b: "143" }}
                      name="Leave"
                      onClick={this.onClickGo}
                    />
                  </div>
                  <div className="col s3 m3 l3">
                    <Tab
                      image="/assests/img/sal.png"
                      color={{ r: "93", g: "27", b: "143" }}
                      name="Salary"
                      onClick={this.onClickGo}
                    />
                  </div>
                  <div className="col s3 m3 l3">
                    <Tab
                      image="/assests/img/teacher-attend.png"
                      color={{ r: "93", g: "27", b: "143" }}
                      name="Attendance"
                      onClick={this.onClickGo}
                    />
                  </div>
                </fieldset>
              </div>
          </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(TeacherDashboard);
