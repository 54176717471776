import React, { Component } from 'react';
import 'materialize-css/dist/css/materialize.min.css';
import M from 'materialize-css'; 
import {Link, NavLink, withRouter} from 'react-router-dom';
import { signOut, changePassword } from '../../store/actions/authAction';
import { connect } from 'react-redux';
import { backendUrl, env } from '../../config/api';
import ChangePassword from '../auth/ChangePassword'
import Navlinks from './Navlinks';
import { onHomeClick } from '../../store/actions/resourceActions';


class NavBar extends Component {
  state={
    password:"",
    newPassword:""
  }

  componentDidMount() {

      var elems = document.querySelectorAll('.dropdown-trigger');
      M.Dropdown.init(elems, {coverTrigger:false,constrainWidth:false});
      var elems_sn = document.querySelectorAll('.sidenav');
      M.Sidenav.init(elems_sn, {});

  }

  handleChange = (e) => {
    this.setState({ ...this.state, [e.target.id]: e.target.value },() => {console.log(this.state)});
  };

  onSubmit = (e) => {
    e.preventDefault();
    console.log(this.state)
    const personData = {
      password: this.state.password,
      newPassword: this.state.newPassword,
    };

    this.props.ChangePassword(personData, this.props.auth.user.id, this.props.auth.user.accessToken)
  }

  OnSignOut = () => {
    // console.log("state", this.state)
    // console.log("props", this.props)
    // var token= this.props.user.accessToken
    this.props.SignOut(this.props.auth.user.accessToken);
  }


  render(){
    const { heading, user} = this.props;
    return (
      <div >
      <ChangePassword state={this.state} HandleChange={this.handleChange} OnSubmit={this.onSubmit}/>
        <ul id="userName" className="dropdown-content white darken-3" >
          <li><Link className="ChangePassword black-text modal-trigger" data-target="change-password-modal">Change Password</Link></li>
          <li><a href='#' className=" black-text" onClick={this.OnSignOut}>Logout</a></li>
        </ul>
      
        <div className="navbar-fixed">
          <nav style={{backgroundColor:'black'}} role="navigation">
            <div className="nav-wrapper">
              <ul  className="left hide-on-med-and-down">
                <li>
                  <Link onClick={this.props.onHomeClick} className="home" to="/" style={{fontSize:28}}>
                    {/* {heading} */}
                    <img className="" src={require('../../image/logo.png')} alt="user" height='30' width='120' style={{marginTop: 5}}/>
                  </Link>
                </li> 
                {/* <Navlinks user={user}/> */}
              </ul>
              <ul className="right hide-on-med-and-down" style={{paddingRight: 10}}>
               
                <li><Link className="dropdown-trigger white-text" style={{fontSize: '16px'}} to="#" data-target="userName"><i className="material-icons right" style={{color:"white"}}>arrow_drop_down</i>{user?user.name:"no_name"}</Link></li>  
                <li><img className="circle" src={user&&user.photo? user.photo : require('../../image/image_unavailable.jpg')} alt="user" height='50' width='50' style={{marginTop: 5}}/></li>
              </ul>
              <a href="#" data-target="nav-mobile" className="sidenav-trigger"><i className="material-icons">menu</i></a>
            </div>
          </nav>
        </div>

        <ul id="nav-mobile" className="sidenav">
          {
            user?
              <div className="center center-align">
                <li><img className="circle" src={user&&user.photo? user.photo : require('../../image/image_unavailable.jpg')}  alt="user" style={{marginTop: 20, height:200, width:200}}/></li>
              </div>
            :
              null
          }
          <li><Link className="home" to="/" >Modulas Survey</Link></li>
          <li><Link className="ChangePassword black-text" to={"/changepassword"}>Change Password</Link></li>
          <li><a href='#' className=" black-text" onClick={this.OnSignOut}>Logout</a></li>      
        </ul>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  // console.log("state", state);
  return {
    auth: state.auth,
    users: state.admin.users,
    // roles: state.admin.roles,
    // check: state.admin.checks,
  };
};
const mapDispatchToProps = dispatch => {
  return {
    SignOut: (token) => {
      // console.log("user in signout", uid)
      dispatch(signOut(token));      
    },
    ChangePassword: (credential, id, token) => {
      dispatch(changePassword(credential, id,token))
    },
    onHomeClick:()=>{
      dispatch(onHomeClick())
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NavBar);