import React from "react";
import "../stylesheets/common.css";

const TestSurveyModal = ({ HandleTestSurvey, url }) => {
  return (
    <div id="test-survey-modal" className="modal">
      <div className="modal-content test_survey">
        <h5>
          To test the survey You just copy the url and paste it on address tab
        </h5>
        <p>
          <b>{url}</b>
        </p>
        <h5>
          or else you can click{" "}
          <a class="test_survey_btn" onClick={HandleTestSurvey}>
            here
          </a>{" "}
          to test the survey
        </h5>
        {/* <button className="btn card-button test_survey_btn" onClick={HandleTestSurvey}>Test Survey</button> */}
      </div>
    </div>
  );
};

export default TestSurveyModal;
