import React, { Component } from "react";
import { connect } from "react-redux";
import { getTimeSlots } from "../../store/actions/TimeTableAction";
import { Redirect } from "react-router-dom";
import Navbar from "../layout/Navbar";
import ClassInfo from "../layout/ClassInfo";
import {filterRoutine, getRooms, getDeptProfessors, getSemester,getSection} from "../../store/actions/HodAction"
import { printRoutine } from "../../store/actions/ProfessorAction";
// import "../style.css";

export class ClassScheduleHod extends Component {
  state={
    semId:'',
    filter:"",
    info:[],
    flag:false,
    exId:null
  }

  componentDidMount() {
    console.log("...loding");
    if(this.props.auth.user){
    this.props.GetTimeSlots(this.props.auth.user.accessToken);
    this.props.GetDeptProfessor(this.props.auth.user.deptId,this.props.auth.user.accessToken)
    this.props.GetRooms(this.props.auth.user.accessToken)
    this.props.GetSemester(this.props.auth.user.deptId,this.props.auth.user.accessToken)
    }
  }

  componentDidUpdate(){
    console.log(this.props.filterRoutine)
    if(this.state.filter== "teacher"){
      if(this.state.flag && this.props.filterRoutineSuccess && !this.props.filterRoutineLoading){
        var filterRoutine= this.props.filterRoutine;
        console.log("filterRoutine",filterRoutine)
        var arr=[];
        var classInfo;
        for(var i=0; i<filterRoutine.length;i++){
          console.log(filterRoutine[i].Routines)
          for(var k=0;k<filterRoutine[i].Routines.length;k++){
            classInfo={
              time:filterRoutine[i].Routines[k].TimeSlot.slot,
              slot:filterRoutine[i].Routines[k].TimeSlot.day,
              sub:filterRoutine[i].Routines[k].Course.courseName,
              var1:filterRoutine[i].Routines[k].SectionGroup.name,
              type:filterRoutine[i].Routines[k].Course.courseType,
              var2:filterRoutine[i].Routines[k].Room.slot
            }
            arr.push(classInfo)
          }
        }
        this.setState({info: arr,flag: false})
      }
    }
    if(this.state.filter== "room"){
      if(this.state.flag && this.props.filterRoutineSuccess && !this.props.filterRoutineLoading){
        var filterRoutine= this.props.filterRoutine;
        console.log("filterRoutine",filterRoutine)
        var arr=[];
        var classInfo;
        for(var i=0; i<filterRoutine.length;i++){
          console.log(filterRoutine[i].Routines)
          for(var k=0;k<filterRoutine[i].Routines.length;k++){
            classInfo={
              time:filterRoutine[i].Routines[k].TimeSlot.slot,
              slot:filterRoutine[i].Routines[k].TimeSlot.day,
              sub:filterRoutine[i].Routines[k].Course.courseName,
              var1:filterRoutine[i].Routines[k].SectionGroup.name,
              type:filterRoutine[i].Routines[k].Course.courseType,
              var2:filterRoutine[i].Routines[k].User.userName
            }
            arr.push(classInfo)
          }
        }
        this.setState({info: arr,flag: false})
      }
    }
    if(this.state.filter== "section"){
      if(this.state.flag && this.props.filterRoutineSuccess && !this.props.filterRoutineLoading){
        var filterRoutine= this.props.filterRoutine;
        console.log("filterRoutine",filterRoutine)
        var arr=[];
        var classInfo;
        for(var i=0; i<filterRoutine.length;i++){
          console.log(filterRoutine[i].Routines)
          for(var k=0;k<filterRoutine[i].Routines.length;k++){
            classInfo={
              time:filterRoutine[i].Routines[k].TimeSlot.slot,
              slot:filterRoutine[i].Routines[k].TimeSlot.day,
              sub:filterRoutine[i].Routines[k].Course.courseName,
              var1:filterRoutine[i].Routines[k].Room.slot,
              type:filterRoutine[i].Routines[k].Course.courseType,
              var2:filterRoutine[i].Routines[k].User.userName
            }
            arr.push(classInfo)
          }
        }
        this.setState({info: arr,flag: false})
      }
    }
  }

  handleFilter=(e)=>{
    this.setState({filter: e.target.value, exId:null},()=>{
      if(this.state.filter=="teacher"){
        document.getElementById("Teacher").selectedIndex = 0;
      }
      if(this.state.filter=="section"){
        document.getElementById("Section").selectedIndex = 0;
      }
      if(this.state.filter=="room"){
        document.getElementById("Room").selectedIndex = 0;
      }
    })
  }

  handleSemester=(e)=>{
    this.setState({semId: e.target.value},()=>{
      document.getElementById("Section").selectedIndex = 0;
      this.props.GetSection(this.state.semId,this.props.auth.user.accessToken)
    })
  }

  handleChange=(e)=>{
    var filter=e.target.id;
    var id=e.target.value;
    this.setState({flag: true, exId:id})
    this.props.FilterRoutine(filter,id,this.props.auth.user.accessToken);
  }

  PrintSchedule=()=>{
    if(this.state.filter == "teacher"){
      this.props.PrintRoutine('Teacher', this.state.exId,this.props.auth.user.accessToken)
    }
    else if(this.state.filter == 'section'){
      this.props.PrintRoutine('Section', this.state.exId,this.props.auth.user.accessToken)

    }
    else if(this.state.filter == 'room'){
      this.props.PrintRoutine('Room', this.state.exId,this.props.auth.user.accessToken)
    }
  }

  render() {
    const user = this.props.auth.user;
    console.log(this.state.info)
    // console.log(this.state.index)
    if (!user) return <Redirect to="/login" />;
    return (
      <div>
        <Navbar heading="Admin" user={user} />
        <div className="row">
          <div style={{ marginLeft: 70 }}>
          <div
              className="col s12 m2 l2"
              style={{
                "margin-top": 10,
              }}
            >
              <select class="browser-default" id="filter" onChange={this.handleFilter}>
                <option value="" disabled selected>
                  Select Filter
                </option>
                <option value="teacher">Professor</option>
                <option value="section">Section</option>
                <option value="room">Room</option>
              </select>
            </div>
            {
              this.state.filter == "teacher"?
              <div
              className="col s12 m2 l2"
              style={{
                "margin-top": 10,
              }}
            >
              <select class="browser-default" id="Teacher" onChange={this.handleChange}>
                <option value="" disabled selected>
                  Select Teacher
                </option>
                {
                  this.props.departmentProfessors &&
                    this.props.departmentProfessors.map((prof)=>(
                      <option value={prof.id}>{prof.userName}</option>
                    ))
                }
              </select>
            </div>
            :
            this.state.filter == "section"?
              <>
            <div
              className="col s12 m2 l2"
              style={{
                "margin-top": 10,
              }}
            >
              <select class="browser-default" id="Semester" onChange={this.handleSemester}>
                <option value="" disabled selected>
                  Select Semester
                </option>
                {
                  this.props.semester &&
                    this.props.semester.map((sem)=>(
                      <option value={sem.id}>{sem.semester}</option>
                    ))
                }
              </select>
            </div>
            <div
              className="col s12 m2 l2"
              style={{
                "margin-top": 10,
              }}
            >
              <select class="browser-default" id="Section" onChange={this.handleChange}>
                <option value="" disabled selected>
                  Select Section
                </option>
                {
                  this.props.section &&
                    this.props.section.map((sec)=>(
                      <option value={sec.id}>{sec.name}</option>
                    ))
                }
              </select>
            </div>
              </>
              :
              this.state.filter == "room"?
              <div
              className="col s12 m2 l2"
              style={{
                "margin-top": 10,
              }}
            >
              <select class="browser-default" id="Room" onChange={this.handleChange}>
                <option value="" disabled selected>
                  Select Room
                </option>
                {
                  this.props.rooms &&
                    this.props.rooms.map((room)=>(
                      <option value={room.id}>{room.slot+" ("+room.type+")"}</option>
                    ))
                }
              </select>
            </div>
            :
            null
            }
          </div>
          <div className="right">
            <button 
            disabled={!this.state.exId} 
            onClick={this.PrintSchedule} 
            class="btn-floating waves-effect waves-light black" 
            style={{marginTop: 10, marginRight: 10}}>
              <i class="material-icons">file_download</i>
            </button>
          </div>
        </div>
        <table class="color-table">
          <thead class="thead-dark">
            <tr>
              <th scope="col">Day</th>
              {this.props.slots &&
                this.props.slots.map((slot) => <th scope="col">{slot}</th>)}
            </tr>
          </thead>
          <tbody>
            {this.props.days &&
              this.props.days.map((day) => (
                <tr>
                  <th scope="row">{day}</th>
                  {this.props.slots &&
                    this.props.slots.map((slot) => (
                      <td>
                      {
                        this.props.filterRoutine.length > 0 
                        ?
                        <ClassInfo
                          slot={slot}
                          day={day}
                          classInfo={this.state.info}
                        />
                        :
                        null
                      }
                      </td>
                    ))}
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  console.log(state);
  return {
    auth: state.auth,
    days: state.TimeTable.days,
    slots: state.TimeTable.slot,
    departmentProfessors: state.Hod.departmentProfessor,
    departmentProfessorSuccess: state.Hod.departmentProfessorSuccess,
    filterRoutine: state.Hod.filterRoutine,
    filterRoutineSuccess: state.Hod.filterRoutineSuccess,
    filterRoutineLoading: state.Hod.filterRoutineLoading,
    rooms: state.Hod.room,
    semester: state.Hod.semester,
    section: state.Hod.section
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    GetTimeSlots: (token) => {
      dispatch(getTimeSlots(token));
    },
    GetDeptProfessor: (deptId,token)=>{
      dispatch(getDeptProfessors(deptId,token))
    },
    FilterRoutine:(filter,id,token)=>{
      dispatch(filterRoutine(filter,id,token))
    },
    GetRooms:(token)=>{
      dispatch(getRooms(token))
    },
    GetSemester:(id,token)=>{
      dispatch(getSemester(id,token))
    },
    GetSection:(id,token)=>{
      dispatch(getSection(id,token))
    },
    PrintRoutine:(filter, id, token) => {
      dispatch(printRoutine(filter,id,token))
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ClassScheduleHod);
