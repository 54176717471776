import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router";
import Tab from "../layout/Tab";
import Navbar from "../layout/Navbar";
import M from "materialize-css";
import ConfigureModal from "../layout/ConfigureModal";
import {
  changePriority,
  getDeptProfessors,
  getProfessors,
  generateRoutine,
} from "../../store/actions/HodAction";
import UpdatePrefarence from "../layout/UpdatePrefarence";
import StudentReportGeneration from "../layout/StudentReportGeneration";
import { UploadDocument } from "../layout/UploadDocument";
const update = require("immutability-helper");

class HodDashboard extends Component {
  state = {
    users: [],
    addSuccess: true,
    professor: [],
    flag: "",
    generateRoutineflag: false,
  };
  componentDidMount = () => {
    var elems = document.querySelectorAll(".modal");
    var instances = M.Modal.init(elems, {
      preventScrolling: true,
    });
    if(this.props.auth.user)
    this.props.GetDeptProfessor(this.props.auth.user.deptId,this.props.auth.user.accessToken)
  };

  componentDidUpdate() {
    if (this.state.addSuccess && this.props.departmentProfessorSuccess) {
      console.log("from did update", this.props.departmentProfessors);
      this.setState({ professor: this.props.departmentProfessors });
      this.setState({ addSuccess: false });
    }
    if (this.state.generateRoutineflag) {
      console.log("in....");
      if (
        this.props.generateRoutineSuccess &&
        !this.props.generateRoutineLoading
      ) {
        var modal = document.getElementById("configure-modal");
        var instance = M.Modal.getInstance(modal);
        instance.open();
        // this.setState({generateRoutineflag: false})
      } else {
        var modal = document.getElementById("configure-modal");
        var instance = M.Modal.getInstance(modal);
        instance.open();
      }
    }
  }
  onClickGo = (name) => {
    this.setState({ flag: name });
  };
  moveCard = (dragIndex, hoverIndex) => {
    console.log("dragIndex :", dragIndex);
    console.log("hoverIndex: ", hoverIndex);
    const { professor } = this.state;
    const dragCard = professor[dragIndex];
    this.setState(
      update(this.state, {
        professor: {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragCard],
          ],
        },
      })
    );
    // this.setState({flag: true})
    // console.log(this.state.professor)
  };
  handleSavePriority = (e) => {
    var prof = this.state.professor;
    var newPriority = [];
    for (var i = 0; i < prof.length; i++) {
      newPriority.push(prof[i].id);
    }
    var professor = {
      professors: newPriority,
    };
    this.props.ChangePriority(professor, this.props.auth.user.accessToken);
  };
  onModalOpen = (name) => {
    if (name == "Configure") {
      var modal = document.getElementById("configure-modal");
      var instance = M.Modal.getInstance(modal);
      instance.open();
    } else if (name == "Update Faculty") {
      var modal = document.getElementById("update-prefarence-modal");
      var instance = M.Modal.getInstance(modal);
      instance.open();
    } else if (name == "Report Generation") {
      var modal = document.getElementById("report-modal");
      var instance = M.Modal.getInstance(modal);
      instance.open();
    } else if (name == "Payroll") {
      var modal = document.getElementById("upload-marks");
      var instance = M.Modal.getInstance(modal);
      instance.open();
    }
  };

  generateRoutine = () => {
    this.setState({ generateRoutineflag: true });
    this.props.GenerateRoutine(this.props.auth.user.accessToken);
  };

  render() {
    const user = this.props.auth.user;
    console.log(user);
    if (!user) return <Redirect to="/login" />;
    if (this.state.flag == "Faculty Performance")
      return <Redirect to="/teacherperfomance" />;
    if (
      this.state.flag == "Faculty" ||
      this.state.flag == "Users"
    )
      return <Redirect to={"/admin/" + user.id + "/dashboard"} />;
    if (this.state.flag == "Student")
      return <Redirect to={"/addStudent"} />;
    if (this.state.flag == "View") return <Redirect to="/hod/schedule" />;
    if (this.state.flag == "Course Allocation")
      return <Redirect to="/course/allocate" />;
    if (this.state.flag == "Daily Replacement")
      return <Redirect to="/replace/teacher" />;
    if (this.state.flag == "Student Attendance")
      return <Redirect to="/attendance" />;
    if (this.state.flag == "Faculty Attendance")
      return <Redirect to="/attendance/hod" />
    if (this.state.flag == "Announce")
      return <Redirect to="/announcement" />
      if (this.state.flag == "Online Report")
      return <Redirect to={"/viewResult"} />;
    if (this.state.flag == "Offline Report")
      return <Redirect to={"/marks"} />;
    if (this.state.flag == "HR")
      return <Redirect to={"/leaveApproval"} />;
    return (
      <div>
        <Navbar heading="Admin" user={user} />
        <ConfigureModal
          generateRoutineSuccess={this.props.generateRoutineSuccess}
          generateRoutineLoading={this.props.generateRoutineLoading}
          message={this.props.generateRoutine}
          generateRoutine={this.generateRoutine}
          flag={this.state.generateRoutineflag}
        />
        <UpdatePrefarence
          handleSave={this.handleSavePriority}
          professor={this.state.professor}
          moveCard={this.moveCard}
        />
        <StudentReportGeneration />
        <UploadDocument/>
        <div className="container" style={{ marginTop: 20 }}>
          <div className="row admission&&manage">
            <div className="col s6 m6 l6">
              <div
                className="row admission"
                style={{ marginTop: 10, marginLeft: 49 }}
              >
                {/* <div className="col s2 m2 l2 offset-s2 offset-m2 offset-l2">
      <span>Admission</span>
    </div> */}
                <fieldset style={{ height: 147 }}>
                  <legend>Admission</legend>
                  <div className="col s6 m6 l6">
                    <Tab
                      image="/assests/img/add student.png"
                      color={{ r: "93", g: "27", b: "143" }}
                      name="Student"
                      onClick={this.onClickGo}
                    />
                  </div>
                  <div className="col s6 m6 l6">
                    <Tab
                      image="/assests/img/add faculty.png"
                      color={{ r: "93", g: "27", b: "143" }}
                      name="Faculty"
                      onClick={this.onClickGo}
                    />
                  </div>
                </fieldset>
              </div>
            </div>
            <div className="col s6 m6 l6">
              <div
                className="row manage"
                style={{ marginTop: 10, marginLeft: 49 }}
              >
                {/* <div className="col s2 m2 l2 offset-s2 offset-m2 offset-l2">
      <span>Manage</span>
    </div> */}
                <fieldset style={{ height: 147 }}>
                  <legend>Manage</legend>
                  <div className="col s6 m6 l6">
                    <Tab
                      image="/assests/img/edit-user.png"
                      color={{ r: "63", g: "140", b: "35" }}
                      name="Users"
                      onClick={this.onClickGo}
                    />
                  </div>
                  <div className="col s6 m6 l6">
                    <Tab
                      image="/assests/img/announcement.png"
                      color={{ r: "63", g: "140", b: "35" }}
                      name="Announce"
                      onClick={this.onClickGo}
                    />
                  </div>
                </fieldset>
              </div>
            </div>
          </div>
          <div className="row exam&&attendance">
            <div className="col s6 m6 l6">
              <div
                className="row exam"
                style={{  marginLeft: 49 }}
              >
                {/* <div className="col s2 m2 l2 offset-s2 offset-m2 offset-l2">
            <span>Examination</span>
          </div> */}
                <fieldset style={{ height: 147 }}>
                  <legend>Examination</legend>
                  <div className="col s6 m6 l6">
                    <Tab
                      image="/assests/img/marks.png"
                      color={{ r: "191", g: "191", b: "13" }}
                      name="Online Report"
                      onClick={this.onClickGo}
                    />
                  </div>
                  <div className="col s6 m6 l6">
                    <Tab
                      image="/assests/img/offline mark.png"
                      color={{ r: "191", g: "191", b: "13" }}
                      name="Offline Report"
                      onClick={this.onClickGo}
                    />
                  </div>
                </fieldset>
              </div>
            </div>
            <div className="col s6 m6 l6">
              <div
                className="row attendence"
                style={{  marginLeft: 49 }}
              >
                {/* <div className="col s2 m2 l2 offset-s2 offset-m2 offset-l2">
      <span>Attendence</span>
    </div> */}
                <fieldset style={{ height: 147 }}>
                  <legend>Attendance</legend>
                  <div className="col s6 m6 l6">
                    <Tab
                      image="/assests/img/attendence.png"
                      color={{ r: "230", g: "81", b: "32" }}
                      name="Student Attendance"
                      onClick={this.onClickGo}
                    />
                  </div>
                  <div className="col s6 m6 l6">
                    <Tab
                      image="/assests/img/manage.png"
                      color={{ r: "230", g: "81", b: "32" }}
                      name="Faculty Attendance"
                      onClick={this.onClickGo}
                    />
                  </div>
                </fieldset>
              </div>
            </div>
          </div>
          <div className="row routine&&additional">
            <div className="col s6 m6 l6">
              <div
                className="row routine"
                style={{  marginLeft: 49 }}
              >
                {/* <div className="col s2 m2 l2 offset-s2 offset-m2 offset-l2">
            <span>Routine</span>
          </div> */}
                <fieldset style={{ height: 147 }}>
                  <legend>Routine</legend>
                  <div className="col s4 m4 l4">
                    <Tab
                      image="/assests/img/changeuser.png"
                      color={{ r: "16", g: "158", b: "163" }}
                      name="Daily Replacement"
                      onClick={this.onClickGo}
                    />
                  </div>
                  <div className="col s4 m4 l4">
                    <Tab
                      image="/assests/img/course alloc.png"
                      color={{ r: "16", g: "158", b: "163" }}
                      name="Course Allocation"
                      onClick={this.onClickGo}
                    />
                  </div>
                  <div className="col s4 m4 l4">
                    <Tab
                      image="/assests/img/view.png"
                      color={{ r: "16", g: "158", b: "163" }}
                      name="View"
                      onClick={this.onClickGo}
                    />
                  </div>
                </fieldset>
              </div>
            </div>
            <div className="col s6 m6 l6">
              <div
                className="row additional"
                style={{  marginLeft: 49 }}
              >
                {/* <div className="col s2 m2 l2 offset-s2 offset-m2 offset-l2">
      <span>Additional</span>
    </div> */}
                <fieldset style={{ height: 147 }}>
                  <legend>Additional</legend>
                  {/* <div className="col s4 m4 l4">
                    <Tab
                      image="/assests/img/configure.png"
                      color={{ r: "209", g: "42", b: "212" }}
                      name="Settings"
                      // onClick={this.onClickGo}
                    />
                  </div> */}
                  <div className="col s6 m6 l6">
                    <Tab
                      image="/assests/img/hr.png"
                      color={{ r: "209", g: "42", b: "212" }}
                      name="HR"
                      onClick={this.onClickGo}
                    />
                  </div>
                  <div className="col s6 m6 l6">
                    <Tab
                      image="/assests/img/update.png"
                      color={{ r: "209", g: "42", b: "212" }}
                      datatarget='"upload-marks"'
                      classname="btn-flat modal-trigger"
                      name="Payroll"
                      onClick={this.onModalOpen}
                    />
                  </div>
                </fieldset>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    departmentProfessors: state.Hod.departmentProfessor,
    addProfessorSuccess: state.Hod.addProfessorSuccess,
    departmentProfessorSuccess: state.Hod.departmentProfessorSuccess,
    generateRoutineLoading: state.Hod.generateRoutineLoading,
    generateRoutineSuccess: state.Hod.generateRoutineSuccess,
    generateRoutine: state.Hod.generateRoutine,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    GetProfessors: (deptId, token) => {
      dispatch(getProfessors(deptId, token));
    },
    ChangePriority: (body, token) => {
      dispatch(changePriority(body, token));
    },
    GetDeptProfessor: (deptId, token) => {
      dispatch(getDeptProfessors(deptId, token));
    },
    GenerateRoutine: (token) => {
      dispatch(generateRoutine(token));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(HodDashboard);
