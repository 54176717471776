import React from "react";

export const UploadDocument = () => {
  return (
    <div id="upload-marks" class="modal">
      <div class="row modal-content">
        <div className="row modal-title ">
          <span>Upload</span>
        </div>
        <div>
          <form action="#">
            <div class="file-field input-field">
              <div class="btn">
                <span>File</span>
                <input type="file" />
              </div>
              <div class="file-path-wrapper">
                <input class="file-path validate" type="text" />
              </div>
            </div>
          </form>
        </div>
      </div>
      <div className="modal-footer">
        <button className="modal-close waves-effect waves btn-flat">
          CANCEL
        </button>
        <button
          type="submit"
          form="addUser"
          className="waves-effect waves btn-flat"
        >
          submit
        </button>
      </div>
    </div>
  );
};
