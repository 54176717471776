import React, { Component } from "react";
import "./App.css";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import { connect } from "react-redux";

import Login from "./components/auth/Login"
import Dashboard from "./components/dashboard/Dashboard";
import AdminDashboard from "./components/dashboard/AdminDashBoard";
import DesignerDashboard from "./components/dashboard/DesignerDashBoard";
import SupervisorDashboard from "./components/dashboard/SupervisorDashBoard";
import TeacherDashboard from "./components/dashboard/TeacherDashboard";
import HodDashboard from "./components/dashboard/HodDashboard";

import StudentPerfomance from "./components/Teacher/StudentPerfomance";
import TimeTable from './components/Teacher/TimeTable';
import ClassSchedule from './components/Teacher/ClassSchedule'; 
import YourPerfomance from "./components/Teacher/YourPerformance";
import SubjectChoose from "./components/Teacher/SubjectChoose";
import TeacherPerfomance from "./components/Hod/TeacherPerformance";
import ClassScheduleHod from "./components/Hod/ClassScheduleHod";
import FacultyLeave from "./components/Teacher/FacultyLeave";
import ViewResult from "./components/Teacher/ViewResult";
import AddStudentForm from "./components/Hod/AddStudentForm";
import OfflineResult from "./components/Teacher/OfflineResult";
import LeaveApproval from "./components/Hod/LeaveApproval";
import LessonPlan from "./components/Teacher/LessonPlan";
import Announcement from "./components/Hod/Announcement";
import SalarySlip from "./components/Teacher/SalarySlip";
import AnnouncementFaculty from "./components/Teacher/AnnouncementFaculty";
import FacultyAttendance from "./components/Teacher/FacultyAttendance";
import FacultyAttendanceHod from "./components/Hod/FacultyAttendanceHod";
import CourseAllocation from "./components/Hod/CourseAllocation";
import ReplaceTeacher from "./components/Hod/ReplaceTeacher";
import UploadAttendence from "./components/Teacher/UploadAttendence"
import QualityControl from "./components/dashboard/QualityControl";
import SpecificResponse from './components/qc/SpecificResponse';
import DesignSurvey from "./components/designer/DesignSurvey";
import LiveSurvey from "./components/designer/LiveSurvey";
import ArchivedSurvey from "./components/designer/ArchivedSurvey";
import TestSurvey from "./components/designer/TestSurvey";
import MessageShowModal from "./components/layout/MessageShowModal";
import SpecificTestResponse from "./components/qc/SpecificTestResponse";
import { refreshToken } from "./store/actions/authAction";
import { refreshInterval } from "./config/constant";


class App extends Component {
  componentDidUpdate() {
    const { auth, RefreshToken } = this.props;
    if (auth && auth.user && RefreshToken) {
      if (this.refreshTimer) clearInterval(this.refreshTimer);
      this.refreshTimer = setInterval(() => {
        RefreshToken(auth.user.accessToken, auth.user.refreshAccessToken);
      }, refreshInterval);
    }
  }
  componentWillUnmount() {
    if (this.refreshTimer) clearInterval(this.refreshTimer);
  }

  render() {
    return (
      <div className="fullContainer" style={{  }}>
        <MessageShowModal/>
        <BrowserRouter>
          <Switch>
            <Route path="/login" component={Login} />
            <Route exact path="/" component={Dashboard} />
            <Route path="/admin/:id/dashboard" component={AdminDashboard} />
            <Route path="/designer/:id/dashboard" component={DesignerDashboard} />
            <Route path="/professor/dashboard" component={TeacherDashboard} />
            <Route path="/hod/dashboard" component={HodDashboard} />
            <Route path="/studentperfomance" component={StudentPerfomance} />
            <Route path="/YourPerfomance" component={YourPerfomance} />
            <Route path="/teacherperfomance" component={TeacherPerfomance} />
            <Route path="/facultyleave" component={FacultyLeave} />
            <Route path="/viewResult" component={ViewResult} />
            <Route path="/marks" component={OfflineResult} />
            <Route path="/leaveApproval" component={LeaveApproval} />
            <Route path="/announcement" component={Announcement} />
            <Route path="/lessonplan" component={LessonPlan} />
            <Route path="/salary" component={SalarySlip} />
            <Route path="/addStudent" component={AddStudentForm} />
            <Route path="/faculty/announcement" component={AnnouncementFaculty} />
            <Route path="/facultyAttendance" component={FacultyAttendance} />
            <Route path="/attendance/hod" component={FacultyAttendanceHod} />
            <Route path="/teacher/schedule" component={ClassSchedule} />
            <Route path="/replace/teacher" component={ReplaceTeacher} />
            <Route path="/course/allocate" component={CourseAllocation} />
            <Route path="/teacher/subject" component={SubjectChoose} />
            <Route path="/hod/schedule" component={ClassScheduleHod} />
            <Route path="/teacher/timetable" component={TimeTable} />
            <Route path="/attendance" component={UploadAttendence} />
            <Route path="/qc/:id/dashboard" component={QualityControl} />
            <Route path="/usermanagement" component={SupervisorDashboard} />
            <Route path="/survey/:sid/version/:vid/response/:rid" component={SpecificResponse} />
            <Route path="/survey/:sid/version/:vid/test" component={TestSurvey} />
            <Route path="/survey/:id/design" component={DesignSurvey} />
            <Route path="/livesurvey/:id/stat" component={LiveSurvey} />
            <Route path="/archivedsurvey/:id/stat" component={ArchivedSurvey} />
            <Route path="/survey/:sid/version/:vid/testResponse/:rid" component={SpecificTestResponse} />
          </Switch>
        </BrowserRouter>
        <div className="footer-copyright center">
          <div className="container">
            {/* © 2020 Vixplor Analytics Private Limited */}
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    RefreshToken: (token, rToken) => {
      dispatch(refreshToken(token, rToken));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(App);
