import React from "react";
import "../stylesheets/qcList.css";
import moment from 'moment'

export default function TestResponseListItem({
  id,
  dateTime,
  status,
  onDetails,
  cssType,
}) {
  return (
      <tr className={"row valign-wrapper " + cssType}>
        <td style={{ width: "7%", marginLeft:9 }}>
          <span>{id}</span>
        </td>

        <td style={{ width: "70%" }}>
          <span>{cssType=='response-list'?moment(dateTime).format('MM-DD-YYYY, h:mm a'):dateTime }</span>
        </td>

        <td style={{ width: "30%" }}>
          <span>{status}</span>
        </td>

        {cssType != "response-list-header" ? (
          <td style={{ width: "18%", paddingTop: 5, display:"flex", justifyContent:"space-between" }}>
            <button className='btn-floating btn-small' onClick={onDetails}>
              <i className="material-icons Small ">
                chevron_right
              </i>
            </button>  
          </td>
          
        ) : (
          <td style={{ width: "22%" }}>
            <span></span>
          </td>
        )}
      </tr>
  );
}
