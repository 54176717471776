const initState = {
    timeSlotLoading: false,
    timeSlotSuccess:false,
    timeSlotError:false,
    timeSlot:[],
    days:[],
    slot:[]
  };

  const TimeTableReducer = (state= initState, action) => {
    switch (action.type) {
        case "TIMESLOTS_FETCH_LOADING":
            return{
                timeSlotLoading: true,
                timeSlotSuccess:false,
                timeSlotError:false,
            }
        case "TIMESLOTS_FETCH_SUCCESS":
            return{
                timeSlotLoading: false,
                timeSlotSuccess:true,
                timeSlotError:false,
                timeSlot:action.payload.data,
                days: action.payload_day,
                slot: action.payload_slot
            }
        case "TIMESLOTS_FETCH_ERROR":
            return{
                timeSlotLoading: false,
                timeSlotSuccess:false,
                timeSlotError:true,
            }
        default:
            return state;
    }
  }
  export default TimeTableReducer;