import React from 'react'

const TeacherAboutCard = ({user, state}) => {
    return (
        <div>
        <div className="row" style={{ marginTop: 60 }}>
        <div class="col s12 m3 l3">
          <div
            class="card horizontal"
            style={{ width: 300, height:415, borderRadius: 10, marginLeft:"-70px" }}
          >
            <div class="card-stacked">
              <div class="card-content" style={{height:"1%"}}>
            <div>
              <p><b>Teacher's Designation:</b></p>
              <ul>
              <li>
              <p>{state.designation}</p>
              </li>
              </ul>
            </div>
            <hr />
            
            <div style={{overflow:"auto",height:"21%"}}>
            <p><b>Subject Taught:</b></p>
            <ul>
            <li>
            {
              state.profCourse && 
                state.profCourse.map((course)=>(
                  <p>{course.Course.courseName+"("+course.Course.courseType+")"}</p>
                ))
            }
            </li>
            </ul>
            </div>
            <hr />
            
            <div>
              <p><b>Research Papers:</b></p>
              <ul>
              <li>
              <p>Conference: 5</p>
              </li>
              </ul>
              <ul>
              <li>
              <p>Journal: 4</p>
              </li>
              </ul>
            </div>
              </div>
            </div>
          </div>
        </div>
      </div>
        </div>
    )
}

export default TeacherAboutCard
