import React from 'react';
import StylePage from './StylePage';
import StyleQuestion from "./StyleQuestion";
import StyleOption from "./StyleOption";

export default function Style({that}) {
    return (
        <div className="row">
            <p className="center center-align grey-text">
                <strong>
                    {
                        that.state.menuType == 0 ?
                            "Page Style"
                        :
                            that.state.menuType == 1 ?
                                "Question Style"
                            :
                                that.state.menuType == 2 ?
                                    "Option Style"
                                :
                                    null
                    }
                </strong>
            </p>
            <div className="col s1" style={{padding:0}}>
                <i class="material-icons" id="leftClick" onClick={that.changeMenu}>
                    chevron_left
                </i>
            </div>
            <div className="col s10"  style={{padding:0}}>
                {
                    that.state.menuType == 0 ?
                        <StylePage that={that}/>
                    :
                        that.state.menuType == 1 ?
                            <StyleQuestion that={that}/>
                        :
                            that.state.menuType == 2 ?
                                <StyleOption that={that}/>
                            :
                                null
                }
            </div>
                
            <div className="col s1 right right-align" style={{padding:0}}>
                <i class="material-icons" id="leftClick" onClick={that.changeMenu}>
                    chevron_right
                </i>
            </div>
        </div>
    )
}
