import React, { Component } from "react";
import { connect } from "react-redux";
import Navbar from "../layout/Navbar";
import M from "materialize-css";
import { Redirect } from "react-router-dom";
import {
  getCourses,
  getSemester,
  getSection,
} from "../../store/actions/HodAction";
import { getAttendence, uploadExcel } from "../../store/actions/ProfessorAction";
import UploadAttendenceModal from "../layout/UploadAttendenceModal";

export class UploadAttendence extends Component {
  state = {
    user: [],
    semId: "",
    flag: true,
    excelFile: null
  };

  componentDidMount() {
    var elems = document.querySelectorAll(".modal");
    var instances = M.Modal.init(elems, {
      preventScrolling: true,
    });
    this.props.GetCourse(
      this.props.auth.user.deptId,
      this.props.auth.user.accessToken
    );
    this.props.GetSemester(
      this.props.auth.user.deptId,
      this.props.auth.user.accessToken
    );
  }

  componentDidUpdate() {
    if (this.state.flag && this.props.attendenceSuccess) {
      var student = this.props.attendence;
      var arr = [];
      var isPresent;
      var info;
      for (var i = 0; i < student.length; i++) {
        for(var j=0;j<student[i].Attendances.length;j++){
          if(student[i].Attendances[j].isPresent != false){
            isPresent=true;
          }else{
            isPresent= false
          }
          info = {
            isPresent:isPresent
          };
        }
        info = {
          ...info,
          name: student[i].userName,
          roll: student[i].rollNo,
        };
        arr.push(info);
      }
      this.setState({ user: arr, flag: false });
    }
  }

  handleSemester = (e) => {
    this.setState({ semId: e.target.value }, () => {
      document.getElementById("section").selectedIndex = 0;
      this.props.GetSection(this.state.semId, this.props.auth.user.accessToken);
    });
  };

  handleChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };

  formatDate = (input) => {
    var datePart = input.match(/\d+/g),
      year = datePart[0].substring(0),
      month = datePart[1],
      day = datePart[2];
    return day + "-" + month + "-" + year;
  };

  handleView = (e) => {
    var section = this.state.section;
    var date = this.formatDate(this.state.date);
    var course = this.state.course;
    console.log(section, course, date);
    this.props.GetAttendence(
      section,
      course,
      date,
      this.props.auth.user.accessToken
    );
  };

  handleUpload=(e)=>{
    this.setState({ excelFile: e.target.files[0] })
  }

  onAttendanceSubmit = (e) => {
    var section = this.state.section;
    var excelFile = this.state.excelFile;
    var course = this.state.course;
    console.log(excelFile,section,course)
    var modal = document.getElementById("attendence-modal");
    var instance = M.Modal.getInstance(modal);
    instance.close();
    this.props.UploadExcel(excelFile,section,course,this.props.auth.user.accessToken)
};

  render() {
    const user = this.props.auth.user;
    if (!user) return <Redirect to="/login" />;
    console.log(this.state.user)
    return (
      <div>
        <Navbar heading="Teacher" user={user} />
        <UploadAttendenceModal 
          handleUpload={this.handleUpload}
          onAttendanceSubmit={this.onAttendanceSubmit}
        />
        <div
          className="row"
          style={{
            height: 90,
            backgroundColor: "#e65120",
            borderBottomLeftRadius: 55,
            // borderBottomRightRadius: 55,
          }}
        >
          <div style={{ marginLeft: 90 }}>
            <div className="col s12 m3 l2">
              <input
                type="date"
                id="date"
                style={{
                  border: "1px solid white",
                  "margin-top": 20,
                  backgroundColor: "white",
                }}
                onChange={this.handleChange}
              />
            </div>
            <div
              className="col s12 m3 l2"
              style={{
                "margin-top": 20,
              }}
            >
              <select
                class="browser-default"
                id="Semester"
                onChange={this.handleSemester}
              >
                <option value="" disabled selected>
                  Select Semester
                </option>
                {this.props.semester &&
                  this.props.semester.map((sem) => (
                    <option value={sem.id}>{sem.semester}</option>
                  ))}
              </select>
            </div>
            <div
              className="col s12 m3 l2"
              style={{
                "margin-top": 20,
              }}
            >
              <select
                class="browser-default"
                id="section"
                onChange={this.handleChange}
              >
                <option value="" disabled selected>
                  Select Section
                </option>
                {this.props.section &&
                  this.props.section.map((sec) => (
                    <option value={sec.id}>{sec.name}</option>
                  ))}
              </select>
            </div>
            <div
              className="col s12 m3 l2"
              style={{
                "margin-top": 20,
              }}
            >
              <select
                class="browser-default"
                id="course"
                onChange={this.handleChange}
              >
                <option value="" disabled selected>
                  Select Subject
                </option>
                {this.props.course &&
                  this.props.course.map((course) =>
                    course.Semesters.map((sem) =>
                      sem.Courses.map((course) => (
                        <option value={course.id}>{course.courseName}</option>
                      ))
                    )
                  )}
              </select>
            </div>
            <div
              className="col s12 m3 l1"
              style={{
                "margin-top": 23,
                paddingLeft: "25%",
              }}
            >
              <a 
                class="btn-floating waves-effect waves-light btn modal-trigger"
                data-target="attendence-modal"
                onClick={this.onModalOpen}
              >
                <i class="material-icons">cloud_upload</i>
              </a>
            </div>
          </div>
        </div>
        <div className="row" style={{ marginLeft: 170 }}>
          <div className="col s6 m5 l4">
            <b>Student</b>
          </div>
          <div className="col s6 m5 l5">
            <b>Roll</b>
          </div>
          <div className="col s6 m2 l2">
            <b>Status</b>
          </div>
        </div>
        <div>
          <ul
            className="collection"
            style={{
              backgroundColor: "rgb(255,255,255,0.5)",
              paddingTop: 15,
              paddingLeft: 70,
              // width:"60%",
              marginLeft: 90,
              marginRight: 90,
              boxShadow: "2px 20px 40px",
              height: "50vh",
              overflow: "auto",
            }}
          >
            {
              this.props.attendenceSuccess &&
                this.props.attendence['0'].Attendances.length == 0 ?
              <div className="row">
                  <div className="col s6 m5 l5 offset-l5" style={{marginTop:"10%"}}>
                    <b>No class found</b>
                  </div>
                </div>
              :
              this.state.user.map((user) => (
                <div className="row">
                  <div className="col s6 m5 l5">
                    <b>{user.name}</b>
                  </div>
                  <div className="col s6 m5 l5">
                    <b>{user.roll}</b>
                  </div>
                  <div className="col s6 m2 l2">
                    {
                      user.isPresent == true 
                      ? <i class="material-icons" style={{color:"limegreen"}}>check_circle</i>
                      :<i class="material-icons" style={{color:"red"}}>cancel</i>
                    }
                  </div>
                </div>
              ))
            }
          </ul>
        </div>
        <div className="right" style={{ paddingRight: 80, paddingTop: 30 }}>
          <button
            class="btn waves-effect waves-light"
            type="submit"
            onClick={this.handleView}
            name="action"
          >
            View
            <i class="material-icons right" style={{ color: "white" }}>
              visibility
            </i>
          </button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    semester: state.Hod.semester,
    section: state.Hod.section,
    course: state.Hod.course,
    attendence: state.Professor.attendence,
    attendenceSuccess: state.Professor.attendenceSuccess,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    GetSemester: (id, token) => {
      dispatch(getSemester(id, token));
    },
    GetCourse: (deptId, token) => {
      dispatch(getCourses(deptId, token));
    },
    GetSection: (id, token) => {
      dispatch(getSection(id, token));
    },
    GetAttendence: (section, course, date, token) => {
      dispatch(getAttendence(section, course, date, token));
    },
    UploadExcel: (excelFile,section,course,token) => {
      dispatch(uploadExcel(excelFile,section,course,token))
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UploadAttendence);
