const initState = {
    interviewers:null,
    loadingInterviewers:false,
    loadingSurveyInterviewers:false,
    surveyInterviewers:null,
    surveys:null,
    loadSurveyError:false,
    assignUserLoading:false,
    assignSuccess:false,
    deassignUserLoading:false,
    deassignSuccess:false
}

const resourceReducer=(state=initState,action)=>{
    switch(action.type){
        case 'INTERVIEWER_FETCH_LOADING':
            return {
                ...state,
                loadingInterviewers:true,                
                interviewers:null
            }
        case 'INTERVIEWER_FETCH_SUCCESS':
            return {
                ...state,
                loadingInterviewers:false,                
                interviewers:action.data
            }
        case 'INTERVIEWER_FETCH_ERROR':       
            return {
                ...state,
                loadingInterviewers:false,                
                interviewers:null
            }
        case 'SURVEY_INTERVIEWER_FETCH_LOADING':
            return {
                ...state,
                loadingSurveyInterviewers:true,                
                surveyInterviewers:null
            }
        case 'SURVEY_INTERVIEWER_FETCH_SUCCESS':
            return {
                ...state,
                loadingSurveyInterviewers:false,                
                surveyInterviewers:action.data
            }
        case 'SURVEY_INTERVIEWER_FETCH_ERROR':       
            return {
                ...state,
                loadingSurveyInterviewers:false,                
                surveyInterviewers:null
            }
        case 'FETCH_SURVEYS_LOADING':
            return {
                ...state,
                loadingSurvey:true,
                loadSurveyError:false,               
                surveys:null
            }
        case 'FETCH_SURVEYS_SUCCESS':       
            return {
                ...state,
                loadingSurvey:false,
                loadSurveyError:false,              
                surveys:action.data
            }
        case 'FETCH_SURVEYS_ERROR':       
            return {
                ...state,
                loadingSurvey:false,   
                loadSurveyError:true,             
                surveys:null
            }
        case 'INTERVIEWER_CHECKED':
            return {
                ... state,
                interviewers:action.data
            }
        case 'ASSIGN_INTERVIEWER_LOADING':
            return {
                ...state,
                assignUserLoading:true,
                assignSuccess:false
            }
        case 'ASSIGN_INTERVIEWER_SUCCESS':
            return {
                ...state,
                assignUserLoading:false,
                assignSuccess:true
            }
        case 'ASSIGN_INTERVIEWER_ERROR':
            return {
                ...state,
                assignUserLoading:false,
                assignSuccess:false
            }
        case 'DEASSIGN_INTERVIEWER_LOADING':
            return {
                ...state,
                deassignUserLoading:true,
                deassignSuccess:false
            }
        case 'DEASSIGN_INTERVIEWER_SUCCESS':
            return {
                ...state,
                deassignUserLoading:false,
                deassignSuccess:true
            }
        case 'DEASSIGN_INTERVIEWER_ERROR':
            return {
                ...state,
                deassignUserLoading:false,
                deassignSuccess:false
            }
        case 'MODIFY_SUPERVISOR_LOADING':
            return {
                ...state,
                assignSupervisorLoading:true,
                assignSupervisorSuccess:false,
                assignSupervisorError:false,
            }
        case 'DEASSIGN_INTERVIEWER_SUCCESS':
            return {
                ...state,
                assignSupervisorLoading:false,
                assignSupervisorSuccess:true,
                assignSupervisorError:false,
            }
        case 'DEASSIGN_INTERVIEWER_ERROR':
            return {
                ...state,
                assignSupervisorLoading:false,
                assignSupervisorSuccess:false,
                assignSupervisorError:true,
            }
        case "INTERVIEWER_SYNCED":{
            return {
                ...state,
                assignSuccess:false
            }
        }
        default:
            return state;
        
    }
}
export default resourceReducer;