import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router";
import SelectCard from "../layout/SelectCard";
import StudentMarksCard from "../layout/StudentMarksCard";
import ComparisionCard from "../layout/ComparisionCard"
import StatisticCard from "../layout/StatisticCard";
import Navbar from "../layout/Navbar"
import { getStudent, studentPerformance } from "../../store/actions/ProfessorAction";

export class StudentPerfomance extends Component {
  state = {
    flag:false,
    student:[],
    studentInfo:{},
    rank: 17,
    percentile: 23,
    title:"Total Marks",
    title1:'percentile',
    title2:'rank',
    marks: null,
    Semester1: 68,
    Semester2: 64,
    Semester3:67,
    sem:["1","2","3","4","5","6","7","8"],
    student: ["Student 1","Student 2","Student 3"],
  };

  componentDidMount(){
    this.props.GetStudent(this.props.auth.user.deptId, this.props.auth.user.accessToken)
  }

  componentDidUpdate(){
    var arr=[];
    if(this.state.flag && this.props.studentPerformanceSuccess && !this.props.studentPerformanceLoading){
      this.setState({student: this.props.studentPerformance, flag: false})
      for(var i=0; i<this.props.studentPerformance.length;i++){
        arr.push(this.props.studentPerformance[i].marksInfo.marks)
      }
      const sum = arr.reduce((a, b) => a + b, 0);
      this.setState({marks: sum})
    }
  }
  handleChange=(e)=>{
    console.log(e.target)
    var s_id= e.target.value;
    var studentInfo = this.props.student.find(o => o.id == s_id);
    this.setState({studentInfo:studentInfo,flag: true})
    this.props.StudentPerformance(s_id,this.props.auth.user.accessToken)
  }
  render() {
    const user = this.props.auth.user;
    console.log(this.state.student, this.state.studentInfo)
    if (!user) return <Redirect to="/login" />;
    return (
      <div style={{ display: "flex", flexDirection: "column" }}>
      <Navbar heading="Admin" user={user} />
        <div
          style={{
            height: 130,
            backgroundColor: "#bf750d",
            borderBottomLeftRadius: 55,
            // borderBottomRightRadius: 55,
          }}
        >
          <div className="row" style={{display:"flex",justifyContent:"space-around", marginTop: "1%", marginLeft: "-2%" }}>
            {/* <SelectCard name="Select Year" /> */}
            <p className="col s12 m6 l6" style={{fontSize: 30, paddingLeft:"4%", color:"whitesmoke" }}>
            <strong>{this.state.studentInfo.userName}</strong>
          </p>
            <SelectCard name="Select Student" value={this.props.student} handleChange={this.handleChange}/>
          </div>
        </div>
        <div className="row"/* style={{ display: "flex", justifyContent: "space-around" }} */>
          <div className="col">
          <StudentMarksCard
            title={this.state.title}
            title1={this.state.title1}
            title2={this.state.title2}
            value={this.state.marks}
            value1={this.state.percentile}
            value2={this.state.rank}
          />
          </div>
          {/* <div className="col" style={{height:150}}>
          <ComparisionCard state={this.state}/>
          </div> */}
          <div className="col" style={{height:150}}>
          <StatisticCard state={this.state} score={this.state.student}/>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {

  return {
    auth: state.auth,
    student: state.Professor.student,
    studentPerformanceSuccess: state.Professor.studentPerformanceSuccess,
    studentPerformance: state.Professor.studentPerformance,
    studentPerformanceLoading: state.Professor.studentPerformanceLoading
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    GetStudent: (id, token) => {
      dispatch(getStudent(id, token));
    },
    StudentPerformance: (id,token) => {
      dispatch(studentPerformance(id,token))
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(StudentPerfomance);
