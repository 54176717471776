import React, { Component } from 'react'
import { Link, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import M from 'materialize-css'

import  { getInterviewers, addInterviewers, syncInterviewer, checkExistingUser, assignInterviewers} from '../../store/actions/resourceActions';

export class ModalView extends Component {

    state = {
        interviewers:[]
    }
    componentDidMount(){
        var elems = document.querySelectorAll('.collapsible');
        var instances = M.Collapsible.init(elems, {});
        // this.setState
        var user = this.props.auth.user
        if (user)
        this.props.GetInterviewers(user.accessToken);
    }
    componentDidUpdate(){
        if(this.props.newAdd){
            var user = this.props.auth.user
            if (user){
                this.props.GetInterviewers(user.accessToken);
                this.props.InterviewersSynced();
            }
        }
    }
    onSelect=(e)=>{
        console.log(e.target.id);
        this.props.CheckExistingUser(e.target.id, this.props.interviewers);
    }
    onSubmit = (e) =>{
        e.preventDefault();
        var checkIds = []
        var data = this.props.interviewers
        for (var i=0;i<data.length;i++){
            if(data[i].checked){
                checkIds.push({id:data[i].id})
            }
        }
        this.props.AssignInterviewers(checkIds, this.props.selected, this.props.auth.user.accessToken);
    }

    render() {
        const {interviewers} = this.props
        return (
            <div id="view-modal" className="modal modal-fixed-footer">
                <div className='row modal-content'>
                {this.props.id != 'pushBack'?
                    <div className="row modal-title valign-wrapper">
                        <span>Check and add </span>
                    </div>
                    :
                    <div className="row modal-title valign-wrapper">
                        <span>Push Back TO </span>
                    </div>
                }
                    <form id="addExistingUser" onSubmit={this.onSubmit} className="row" action="#" style={{margin:20}}>
                        <ul class="collapsible">
                        {
                            interviewers.map(i => 
                                <li key={i.id}>
                                    <div class="collapsible-header">
                                        <div className="col s6">
                                            <label>
                                                <input type="checkbox" id={i.id} onChange={this.onSelect} />
                                                <span>{i.id}</span>
                                            </label>
                                        </div>
                                        <div className="col s6">
                                            {i.userName}
                                        </div>
                                    </div>
                                    <div class="collapsible-body">
                                        {
                                            i.UserSurveys.map(s => 
                                                <div className="row">
                                                    <div className="col s6">
                                                        {s.surveyId}
                                                    </div>
                                                    <div className="col s6">
                                                        {s.Survey.name}
                                                    </div>   
                                                </div>
                                            )
                                        }
                                    </div>
                                </li>
                                
                            )
                        }
                        </ul>
                    </form>
                    
                </div>
                <div className="modal-footer">
                    <button className="modal-close waves-effect waves btn-flat">CANCEL</button>
                    {this.props.id !='pushBack'?
                    <button type='submit' form="addExistingUser" className="waves-effect waves btn-flat">ADD NEW USER</button>
                    :
                    <button type='submit' form="addUser" className="waves-effect waves btn-flat">PUSH BACK</button>
                    }
                    {/* <a href="#!" className="modal-close waves-effect waves-green btn-flat">Cancel</a> */}
                </div>
                
            </div>
        )
    }
}

const mapStateToProps = (state,ownProps) => {
    // console.log("State in add existing", state)
    return {
        auth: state.auth,
        surveys:state.resource.surveyInterviewers?state.resource.surveyInterviewers:[],
        interviewers:state.resource.interviewers?state.resource.interviewers:[],
        newAdd:state.admin.addUser,
        newAssign:state.resource.assignSuccess
    }
  };
  const mapDispatchToProps = (dispatch) => {
    return {
        GetInterviewers:(token) => {
            // console.log("here");
            dispatch(getInterviewers(token));
        },
        AssignInterviewers:(checkedIds,surveyId, token) => { //2nd argument not need for actual integration
            // console.log("assign", checkedIds, surveyId);
            dispatch(assignInterviewers(checkedIds, surveyId, token));
        },
        InterviewersSynced:() => {
            dispatch(syncInterviewer());
        },
        CheckExistingUser:(id, users)=>{
            dispatch( checkExistingUser(id, users));
        }
    };
  };
  
export default connect(mapStateToProps, mapDispatchToProps)(ModalView);
  
