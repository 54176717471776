import React from "react";

export const UploadModal = ({ state, handleChange }) => {
  return (
    <div id="upload-modal" class="modal">
      <div
        className="row modal-title configure-modal-title valign-wrapper"
        style={{ "background-color": "#109ea3", minHeight: 10 }}
      >
        <span className="configure-modal-name">Upload Materials</span>
      </div>
      <div className="row">
        <div className="col s12 m12 l12">
          <form>
            <div class="file-field input-field">
              <div class="btn-small">
                <span>Select File</span>
                <input type="file" accept=".xls, .xlsx" />
              </div>
              <div class="file-path-wrapper">
                <input class="file-path validate" type="text" />
              </div>
            </div>
          </form>
          <button className="right btn">Upload & Submit</button>
        </div>
      </div>
      <div style={{ margin: 9 }}>
        <select class="browser-default" onChange={handleChange}>
          <option value="" disabled selected>
            Choose Option
          </option>
          <option value="1">Book</option>
          <option value="2">PPT</option>
        </select>
      </div>
      <div style={{ margin: 9 }}>
        {state.option == "1" ? (
          <ul>
            <li>
            <a  href="https://tanthiamhuat.files.wordpress.com/2018/03/deeplearningwithpython.pdf">
                Deep Learning with Python
            </a>
            </li>
            <li >
            <a href="http://www.ddegjust.ac.in/studymaterial/pgdca/ms-03.pdf">
            Digital Electronics
            </a>
            </li>
          </ul>
        ) : state.option == "2" ? (
          <p>No ppt Found</p>
        ):null}
      </div>
      <div className="modal-footer">
        <button className="modal-close waves-effect waves btn-flat">
          CANCEL
        </button>
      </div>
    </div>
  );
};
