import React from 'react'

export default function StylePage({that}) {
    return (
        <div className="style-menu center valign-wrapper">
            <div className="style-menu-item">
                <i class="material-icons">colorize</i> 
                <input id="backgroundColor" type="color" onChange={that.HandlePageStyleChange}/>
                <i class="small material-icons" id="contentCopy" onClick={that.HandlePageStyleChange} style={{marginTop:10}}>content_copy</i>
            </div>
            {/* <div className="style-menu-item">
                <select
                    required
                    onChange={that.HandlePageStyleChange}
                    className='browser-default select-font'
                    id='buttonStyle'
                >
                    <option value="" disabled selected>Button style</option>
                    <option value="default">default</option>
                    <option value="round">round</option>
                    <option value="borderedRound">round border</option>
                </select>
            </div> */}
            {/* <div className="style-menu-item">
                <i class="material-icons">format_color_fill</i> 
                <input id="buttonColor" type="color" onChange={that.HandlePageStyleChange}/>
            </div>
            <div className="style-menu-item">
                <i class="material-icons">format_color_text</i> 
                <input id="color" type="color" onChange={that.HandlePageStyleChange}/>
            </div> */}
            <div className="style-menu-item">
                <input
                    type='file' 
                    id={"surveyImage"}
                    onChange={that.SurveyImageChange}
                    placeholder="Upload image"
                    style={{marginLeft:10}}
                />
                <button className="btn-small btn-floating" onClick={that.UploadSurveyImage}>
                    <i className="material-icons">file_upload</i>
                </button>
            </div>
            {/* <div className="style-menu-item">
                
            </div> */}
            {/* <div className="style-menu-item">
                <i class="material-icons">space_bar</i> 
                <input id="marginBottom" type="number" onChange={that.HandlePageStyleChange}/>
            </div> */}
            {/* <div className="style-menu-item" onClick>
               
            </div> */}
        </div>
    )
}
