const initState = {
    ProfessorLoading:'',
    ProfessorSuccess:'',
    ProfessorError:'',
    Professor:[],

    departmentProfessorLoading: '',
    departmentProfessorSuccess:'',
    departmentProfessorError:'',
    departmentProfessor:[],

    addProfessorLoading:'',
    addProfessorSuccess:'',
    addProfessorError:'',
    addProfessor:[],

    semesterLoading:'',
    semesterSuccess:'',
    semesterError:'',
    semester:[],

    sectionLoading:'',
    sectionSuccess:'',
    sectionError:'',
    section:[],

    courseLoading:'',
    courseSuccess:'',
    courseError:'',
    course:[],

    approveCourseLoading:'',
    approveCourseSuccess:'',
    approveCourseError:'',
    approveCourse:[],

    changePriorityLoading:'',
    changePrioritySuccess:'',
    changePriorityError:'',
    changePriority:[],

    filterRoutineLoading:'',
    filterRoutineSuccess:'',
    filterRoutineError:'',
    filterRoutine:[],

    generateRoutineLoading:'',
    generateRoutineSuccess:'',
    generateRoutineError:'',
    generateRoutine:'',

    roomLoading:'',
    roomSuccess:'',
    roomError:'',
    room:[],
  };

  const HodReducer = (state= initState, action) => {
      switch (action.type) {
        // case "DEPARTMENT_PROFESSOR_FETCH_LOADING":
        //     return{
        //         departmentProfessorLoading: true,
        //         departmentProfessorSuccess:false,
        //         departmentProfessorError:false,
        //     }
        case "DEPARTMENT_PROFESSOR_FETCH_SUCCESS":
            return{
                ...state,
                departmentProfessorLoading: false,
                departmentProfessorSuccess:true,
                departmentProfessorError:false,
                departmentProfessor:action.payload
            }
        case "DEPARTMENT_PROFESSOR_FETCH_ERROR":
            return{
                ...state,
                departmentProfessorLoading: false,
                departmentProfessorSuccess:false,
                departmentProfessorError:true,
                }
        case "PROFESSOR_FETCH_SUCCESS":
            return{
                ...state,
                ProfessorLoading: false,
                ProfessorSuccess:true,
                ProfessorError:false,
                Professor:action.payload
            }
        case "PROFESSOR_FETCH_ERROR":
            return{
                ...state,
                ProfessorLoading: false,
                ProfessorSuccess:false,
                ProfessorError:true,
                }
        // case "ADD_PROFESSOR_LOADING":
        //     return {
        //         addProfessorLoading: true,
        //         addProfessorSuccess: false,
        //         addProfessorError: false
        //     }
        // case "ADD_PROFESSOR_SUCCESS":
        //     return {
        //         addProfessorLoading: false,
        //         addProfessorSuccess: true,
        //         addProfessorError:false,
        //         addProfessor: action.payload
        //     }
        // case "ADD_PROFESSOR_ERROR":
        //     return {
        //         addProfessorLoading: false,
        //         addProfessorSuccess: false,
        //         addProfessorError: true
        //     }
        case "SEMESTER_FETCH_SUCCESS":
            return {
                ...state,
                semesterLoading: false,
                semesterSuccess: true,
                semesterError:false,
                semester: action.payload
            }
        case "SEMESTER_FETCH_ERROR":
            return {
                ...state,
                semesterLoading: false,
                semesterSuccess: false,
                semesterError: true
            }
        case "SECTION_FETCH_SUCCESS":
            return {
                ...state,
                sectionLoading: false,
                sectionSuccess: true,
                sectionError:false,
                section: action.payload
            }
        case "SECTION_FETCH_ERROR":
            return {
                ...state,
                sectionLoading: false,
                sectionSuccess: false,
                sectionError: true
            }
        case "COURSE_FETCH_SUCCESS":
            return {
                ...state,
                courseLoading: false,
                courseSuccess: true,
                courseError:false,
                course: action.payload
            }
        case "COURSE_FETCH_ERROR":
            return {
                ...state,
                courseLoading: false,
                courseSuccess: false,
                courseError: true
            }
        case "APPROVE_COURSE_LOADING":
            return {
                ...state,
                approveCourseLoading:true,
                approveCourseSuccess:false,
                approveCourseError:false
            }
        case "APPROVE_COURSE_SUCCESS":
            return {
                ...state,
                approveCourseLoading:false,
                approveCourseSuccess:true,
                approveCourseError:false,
                approveCourse:action.payload,
            }
        case "APPROVE_COURSE_ERROR":
            return {
                ...state,
                approveCourseLoading:false,
                approveCourseSuccess:false,
                approveCourseError:true
            }
        case "CHANGE_PRIORITY_SUCCESS":
            return {
                ...state,
                changePriorityLoading:false,
                changePrioritySuccess:true,
                changePriorityError:false,
                changePriority:action.payload,
            }
        case "CHANGE_PRIORITY_ERROR":
            return {
                ...state,
                changePriorityLoading:false,
                changePrioritySuccess:false,
                changePriorityError:true
            }
        case "FILTER_ROUTINE_LOADING":
            return {
                ...state,
                filterRoutineLoading:true,
                filterRoutineSuccess:false,
                filterRoutineError:false
            }
        case "FILTER_ROUTINE_SUCCESS":
            return {
                ...state,
                filterRoutineLoading:false,
                filterRoutineSuccess:true,
                filterRoutineError:false,
                filterRoutine:action.payload,
            }
        case "FILTER_ROUTINE_ERROR":
            return {
                ...state,
                filterRoutineLoading:false,
                filterRoutineSuccess:false,
                filterRoutineError:true
            }
        case "ROOM_FETCH_SUCCESS":
            return {
                ...state,
                roomLoading:false,
                roomSuccess:true,
                roomError:false,
                room:action.payload,
            }
        case "ROOM_FETCH_ERROR":
            return {
                ...state,
                roomLoading:false,
                roomSuccess:false,
                roomError:true
            }
        case "GENERATE_ROUTINE_LOADING":
            return {
                ...state,
                generateRoutineLoading:true,
                generateRoutineSuccess:false,
                generateRoutineError:false
            }
        case "GENERATE_ROUTINE_SUCCESS":
            return {
                ...state,
                generateRoutineLoading:false,
                generateRoutineSuccess:true,
                generateRoutineError:false,
                generateRoutine:action.payload.message,
            }
        case "GENERATE_ROUTINE_ERROR":
            return {
                ...state,
                generateRoutineLoading:false,
                generateRoutineSuccess:false,
                generateRoutineError:true
            }
        default:
            return state;
      }
  }

  export default HodReducer