import React from "react";
import "../stylesheets/modals.css";
import "../stylesheets/common.css";

const AddUser = ({ HandleChange, OnSubmit, state, OnUpdate, roles, PhotoChange, point, supervisors }) => {
  console.log(supervisors)
  console.log(state)
  return (
    <div id="add-user-modal" className="modal modal-fixed-footer">
      <div className="row modal-content">
        <div className="row modal-title valign-wrapper">
          {state.addUser ? <span>Add new user</span> : <span>Edit User</span>}
        </div>

        <div className="row">
          <div className="col s12">
            <form id="addUser" onSubmit={state.addUser ? OnSubmit : OnUpdate}>
              <div className="input-field file-field col s12 center">
                <img
                  className="circle image-modify"
                  type="image"
                  id="photo"
                  height="100"
                  width="100"
                  style={{ marginRight: 5 }}
                  src={
                    state.displayPhoto?state.displayPhoto:
                    require("../../image/image_unavailable.jpg")}
                  // onChange={HandleChange}
                  // required
                />
                <input id="photo" type="file" name="photo"  accept="image/*" onChange={PhotoChange} />
              </div>
              {/* <div className="file-field input-field col s12">
                  <div className="btn waves-effect waves-light center center-align col s12 m12 l9">
                      <span>Upload Photo</span>
                      <input type="file" name="photo"  accept="image/*" onChange={PhotoChange} />
                  </div>   
              </div>  */}
              <div className="input-field col s12">
                <input
                  type="text"
                  id="firstName"
                  className="validate"
                  onChange={HandleChange}
                  value={state.firstName}
                  required
                />
                <label className="active" for="firstName">
                  First name
                </label>
              </div>
              <div className="input-field col s12">
                <input
                  type="text"
                  id="surName"
                  onChange={HandleChange}
                  value={state.surName}
                  required
                />
                <label>Surname</label>
              </div>
              <div className="input-field col s12">
                <input
                  type="number"
                  id="contactNo"
                  onChange={HandleChange}
                  value={state.contactNo}
                  required
                />
                <label>Phone Number</label>
              </div>
              {state.addUser ? (
                <div>
                  <div className="input-field col s12">
                    <input
                      type="text"
                      id="email"
                      onChange={HandleChange}
                      value={state.email}
                      required
                    />
                    <label>Email</label>
                  </div>
                  <div className="input-field col s12">
                    <input
                      type="password"
                      id="password"
                      onChange={HandleChange}
                      value={state.password}
                      required
                    />
                    <label>Password</label>
                  </div>
                </div>
              ) : null}
              <div className="col s12">
                <label style={{color:'black'}}>Role</label>
              </div>
              <div className="input-field col s12">
                <select
                  className="browser-default"
                  type="text"
                  required
                  onChange={HandleChange}
                  value={state.role}
                  id="role"
                >
                  <option value="" disabled defaultValue="">
                    Choose the role
                  </option>
                  {roles &&
                    roles.map((role, index) => (
                      <option value={role.Role.id} key={index}>
                        {role.Role.name}
                      </option>
                    ))}
                </select>
              </div>
              {
                !state.addUser&&point=="um"&& state.role =="5" && supervisors&&supervisors.length>0?
                <>
                <div className="col s12">
                  <label style={{color:'black'}}>Supervisor</label>
                </div>
                <div className="input-field col s12">
                  <select
                    className="browser-default"
                    type="text"
                    required
                    onChange={HandleChange}
                    value={state.supervisor}
                    id="supervisor"
                  >
                    <option value="" disabled defaultValue="" selected>
                      Choose the supervisor
                    </option>
                    {supervisors.map((sup, index) => (
                        <option value={sup.User.id} key={index}>
                          {sup.User.userName}
                        </option>
                      ))}
                  </select>
                </div>
                </>
                :null
              }

              <div className="red-text center">
                {state.existsError ? <p>Email exists</p> : null}
              </div>
            </form>
          </div>
        </div>
      </div>
      <div className="modal-footer">
        <button className="modal-close waves-effect waves btn-flat">
          CANCEL
        </button>
        {state.addUser ? (
          <button
            type="submit"
            form="addUser"
            className="waves-effect waves btn-flat"
          >
            ADD NEW USER
          </button>
        ) : (
          <button
            type="submit"
            form="addUser"
            className="waves-effect waves btn-flat"
          >
            EDIT USER
          </button>
        )}
      </div>
    </div>
  );
};

export default AddUser;
