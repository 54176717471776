import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import M from "materialize-css";
import QuestionListItem from "./QcQuestionList";
import SpecificQuestion from "./SpecificQuestionBlock";
import { getSurveys, getTestResponseExcel, resetDownloadStatus, getResponse} from "../../store/actions/QualityAction";
import "../stylesheets/qcResponse.css";
import moment from 'moment'
import { resetAlert, setAlert } from "../../store/actions/MessageAction";
import Navbar from "../layout/Navbar";

export class SpecificTestResponse extends Component {
  state = {
    id: "",
    check: false,
    survey: {},
    questions: {},
    responses: {},
    selectedQ: null,
    selectedSec: null,
    checked: {},
    responseId: null,
    sectionId: null,
    questionId: null,
    responseVersion: {},
    reschk: false,
    responseVersionId: null,
    svPosition:null
  };

  componentDidMount = () => {
    var elems = document.querySelectorAll(".modal");
    var instances = M.Modal.init(elems, { preventScrolling: false, onOpenEnd:()=>{M.updateTextFields()}, onCloseEnd:()=>{this.props.ResetAlert()}});
    var elems = document.querySelectorAll('.tooltipped');
    var instances = M.Tooltip.init(elems, {});
    if(this.props.auth.user){
      var responses=  this.props.responses
      const survey = this.props.survey.find(
        (x) => x.id == parseInt(this.props.sid)
      );

      const svPosition = survey.SurveyVersions.findIndex(sv => sv.id==this.props.vid)

    
      this.setState({ survey: survey, responseVersion: responses, svPosition:svPosition});
    }
  };

  componentDidUpdate=()=>{

  }

  onQSelect = (questionsKey, sectionsKey) => {
    this.setState({
      ...this.state,
      selectedQ: questionsKey,
      selectedSec: sectionsKey,
    });
  };


  showProps = (obj, objName) => {
    var result = ``;
    for (var i in obj) {
      // obj.hasOwnProperty() is used to filter out properties from the object's prototype chain
      if (obj.hasOwnProperty(i)) {
        result += `${objName}.${i} = ${obj[i]}\n`;
      }
    }
    return result;
  };

  GetExcel=(e)=>{
    var user = this.props.auth.user
    if(user)
    this.props.GetResponseExcel(this.props.rid, user.accessToken)
  }

  render() {
    const { questions, responses } = this.props;
    const { survey, id, users, selectedQ, selectedSec } = this.state;
    console.log("response", responses);
    console.log("survey", survey);
    console.log("state", this.state);
    const user = this.props.auth.user;
    if (!user) return <Redirect to="/login" />;
    return (
      <div className="row">
        <Navbar heading="QC" user={user} />
        <div className="row body">
          <div className="row info-header" style={{ marginTop: 10 }}>
            <div className="col s12">
            <div className="col s3">
              Date: {
              moment(this.state.responseVersion.createdAt)
              .format('MM-DD-YYYY, h:mm a')}
            </div> 
            <div className="col s1">
              Id: {this.state.responseVersion.id}
            </div> 
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  paddingTop: 5,
                }}
              >
                <div className="col s2">
                  <a className="btn-floating btn-small green tooltipped" data-position="bottom" data-tooltip={this.state.responseVersion.location}
                  href={"https://www.google.com/maps/search/?api=1&query="+this.state.responseVersion.location} 
                  target="_blank" rel="noopener noreferrer" >
                    <i class="material-icons">location_on</i>
                  </a>
                </div>
                <div className="col s2">
                  <button className="btn-floating btn-small yellow darken-1 ">
                    <a href={this.state.responseVersion?this.state.responseVersion.videoRecording:null} 
                    download
                    rel="noopener noreferrer" target="_blank">
                      <i class="material-icons">play_circle_filled</i>
                    </a>
                  </button>
                </div>
                <div className="col s2">
                  <button className="btn-floating btn-small red ">
                    <a href={this.state.responseVersion?this.state.responseVersion.audioRecording:null} 
                    download
                    rel="noopener noreferrer" target="_blank">
                        <i class="material-icons">music_note</i>
                    </a>
                  </button>
                </div>
                <div className="col s1">
                  <button className="btn-floating btn-small blue" onClick={this.GetExcel}>
                    <i class="material-icons">file_download</i>
                  </button>
                </div>
              </div>
            </div>
          </div>   
        </div>
        <div className="row " >
          <div className="col s5 question-list-complete">
            <div className="container" style={{ width: "90%" }}>
                {survey.SurveyVersions &&
                this.state.svPosition!=null?
                  Object.keys(
                    survey.SurveyVersions[this.state.svPosition].questionnaire.sections
                  ).map((sectionId, sectionsKey) =>
                    Object.keys(
                      survey.SurveyVersions[this.state.svPosition]
                      .questionnaire.sections[sectionId]
                      .questions
                    ).map((questionId, questionsKey) => (
                      <QuestionListItem
                        key={questionId}
                        id={questionId}
                        questionid={
                          sectionId + "." +questionId
                        }
                        q={survey.SurveyVersions[this.state.svPosition]
                          .questionnaire.sections[sectionId]
                          .questions[questionId].question}
                        type={survey.SurveyVersions[this.state.svPosition]
                          .questionnaire.sections[sectionId]
                          .questions[questionId].questionType}
                        qtype={survey.SurveyVersions[this.state.svPosition]
                          .questionnaire.sections[sectionId]
                          .questions[questionId].questionBodyType}
                        cssType="question-list"
                        onDetails={() =>
                          this.onQSelect(questionId, sectionId)
                        }
                      />
                    ))
                  )
                  :null
                } 
            </div>
          </div>
          <div className="col s7">
            <div className="row">
              <div className="col s12">
                <div className="container" style={{ width: "80%" }}>
                  {selectedQ != null? (
                    <SpecificQuestion
                      questions={
                        survey.SurveyVersions[this.state.svPosition].questionnaire.sections[
                          selectedSec
                        ].questions
                      }
                      response={this.state.responseVersion.response.sections[
                        selectedSec
                      ]?
                        this.state.responseVersion.response.sections[
                          selectedSec
                        ].questions
                        :null
                      }
                      selectedQ={selectedQ}
                    />
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  console.log(state, ownProps);
  return {
    auth: state.auth,
    survey: state.quality.surveys,
    previewAnswers: state.quality.savedAnswer,
    rid: ownProps.match.params.rid,
    sid: ownProps.match.params.sid,
    vid: ownProps.match.params.vid,
    responses: ownProps.location.state,
    downloadSuccess: state.quality.resDownloadSuccess,
    downloadError: state.quality.resDownloadError,
    filter: state.quality.filter,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    GetSurveys: (token) => {
      dispatch(getSurveys(token));
    },
    GetResponseExcel: (resId, token) => {
      dispatch(getTestResponseExcel(resId, token));
    },
    ResetDownloadStatus:()=>{
      dispatch(resetDownloadStatus());
    },
    SetAlert:(type, message)=>{
      dispatch(setAlert(type, message));
    },
    ResetAlert: ()=> {
        dispatch(resetAlert());
    },
    GetResponse: (surveyInfo, surveyVersionId, token) => {
      dispatch(getResponse(surveyInfo, surveyVersionId, token));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SpecificTestResponse);

