import React from 'react'

const ClassInfo = ({slot,day,classInfo}) => {
    return (
        <div>
            {
                classInfo && classInfo.map((f)=>(
                    slot == f.time && day == f.slot?
                    <div>
                        <p>{f.sub}/{f.type}</p>
                        <p>{f.var1}/{f.var2}</p>
                    </div>
                    :null
                ))
            }
        </div>
    )
}

export default ClassInfo
