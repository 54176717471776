import React from 'react'

export default function StyleOption({that}) {
    return (
        <div className="center valign-wrapper style-menu">
            <div className="style-menu-item">
                <span>
                    <select
                        required
                        onChange={that.HandleOptionStyleChange}
                        className='browser-default select-font'
                        id='fontFamily'
                    >
                        <option value="" disabled selected>Choose text</option>
                        <option value="times">times</option>
                        <option value="courier">courier</option>
                        <option value="arial">arieal</option>
                    </select>
                </span>  
            </div>
            <div className="style-menu-item">
                <input 
                className='select-font-size'
                type="number"
                id="fontSize"
                min="0"
                defaultValue="15"
                value={that.state.opStyle.fontSize}
                onChange={that.HandleOptionStyleChange}
                />
            </div>
            <div className="style-menu-item">
                <i class="material-icons">format_color_fill</i> 
                <input id="backgroundColorEven" type="color" onChange={that.HandleOptionStyleChange}/>
            </div>
            <div className="style-menu-item">
                <i class="material-icons">format_color_fill</i> 
                <input id="backgroundColorOdd" type="color" onChange={that.HandleOptionStyleChange}/>
            </div>
            <div className="style-menu-item">
                <i class="material-icons">format_color_text</i> 
                <input id="color" type="color" onChange={that.HandleOptionStyleChange}/>
            </div>
            <div className="style-menu-item">
                <i class="material-icons" id="fontWeight" onClick={that.HandleOptionStyleChange}>
                    format_bold
                </i>
                <i class="material-icons"  id="fontStyle" onClick={that.HandleOptionStyleChange}>
                    format_italic
                </i>
                <i class="material-icons"  id="textDecoration" onClick={that.HandleOptionStyleChange}>
                    format_underline
                </i>
            </div>
            <div className="style-menu-item">
                <i class="material-icons" id="leftAlign" onClick={that.HandleOptionStyleChange}>
                    format_align_left
                </i>
                <i class="material-icons" id="rightAlign" onClick={that.HandleOptionStyleChange}>
                    format_align_right
                </i>
                <i class="material-icons" id="centerAlign" onClick={that.HandleOptionStyleChange}>
                    format_align_center
                </i>
            </div>
            <div className="style-menu-item">
                <span className="two-icon"><i class="material-icons">format_line_spacing</i> </span>
                <input className="select-font-size" id="marginBottom" type="number" onChange={that.HandleOptionStyleChange}/>
            </div>
        </div>
    )
}
