import React, { Component } from "react";
import { connect } from "react-redux";
import M from "materialize-css";
import { Redirect } from "react-router-dom";
import Select from 'react-select';
// import { Multiselect } from "multiselect-react-dropdown";
import Navbar from "../layout/Navbar";
import Stats from "../layout/Stats";
import ResponseListItem from "../qc/QcResponseList";
import ResponseListItemExtra from "../qc/QcResponseListExtra";
import DeleteModal from "../layout/DeleteModal";
import {
  getSurveys,
  getSurveyStat,
  getResponse,
  getVersions,
  RejectOrApprove,
  actionStateReset,
  saveFilters,
  getTestResponse,
  calculateResult
} from "../../store/actions/QualityAction";
import {
  getSurveyInterviewers,
  getInterviewers,
} from "../../store/actions/resourceActions";

import {formatDate } from "../../utils/qcHelper";

import "../stylesheets/qc.css";
import TestResponseListItem from "../qc/QcResponseTestList";

class QualityControl extends Component {
  state = {
    frequency: this.props.initData ? this.props.initData.frequency : null,
    company: this.props.initData ? this.props.initData.company : null,
    startDate: this.props.initData ? this.props.initData.startDate : null,
    endDate: this.props.initData ? this.props.initData.endDate : null,
    columns: [],
    survey: {},
    surveyID: null,
    versionId: null,
    resVersionId: null,
    rejORapp: null,
    dropdownNotUpdated:true,
    dropdownOptions:[],
    view:null,
    name:null,
    filterSet:false,
    responseType:"Live",
    surveyStatus:"Live",

    surveySupervisors:[],
    supvervisorUpdated:true,
    seletedSupervisor:null,

    selectedSec:null,
    selectedQ:null,
    surveyPosition:null,
    surveyVersionPosition:null,
    resultSync:false,
  };

  componentDidMount = () => {
    // console.log("nis", this.state)
    var elems = document.querySelectorAll(".modal");
    var instances = M.Modal.init(elems, { preventScrolling: false, onOpenEnd:()=>{M.updateTextFields()}, });
    var elems = document.querySelectorAll("select");
    var instances = M.FormSelect.init(elems, {});
    var elems = document.querySelectorAll(".datepicker");
    M.Datepicker.init(elems, {
      minDate: new Date(2010, 0, 1),
      maxDate: new Date(Date.now()),
      autoClose: true,
      onClose: () => {
        this.onDateClose();
      },
    });
    this.updateDimensions();
    window.addEventListener("resize", this.updateDimensions.bind(this));
    if(this.props.auth.user){
    this.props.GetSurveys(this.state.surveyStatus, this.props.auth.user.accessToken);
    // this.props.GetSurveyInterviewers();
    }
    // this.props.GetSurveyStat(this.props.survey)
  };

  
  componentDidUpdate = () => {
    if(this.props.survey && this.state.surveyID&& this.state.versionId && this.state.dropdownNotUpdated){
      console.log("In update");
      var dropdownOptions = []
      var survey = this.props.survey.find(survey => survey.id == this.state.surveyID)
      var sPosition = this.props.survey.findIndex(survey => survey.id == this.state.surveyID)
      var svPosition = survey.SurveyVersions.findIndex(version => version.id == this.state.versionId)
      console.log("here", sPosition, svPosition);
      var sections = survey.SurveyVersions[svPosition].questionnaire.sections
      var secKeys = Object.keys(sections)
      dropdownOptions.push({id:'responseStatus',value:'responseStatus', label:"Response status", type:'survey'})
      // dropdownOptions.push({id:'surveyCreatedAt', value:'surveyCreatedAt', label:"survey create date", type:'survey'})
      dropdownOptions.push({id:'uniqueId', value:'uniqueId', label:"Unique Id", type:'survey'})
      dropdownOptions.push({id:'createdAt', value:'responseCreatedAt', label:"Create date", type:'survey'})
      for (var i=0;i<secKeys.length;i++){
        var questions = sections[secKeys[i]].questions
        var qKeys = Object.keys(questions)
        for(var j=0;j<qKeys.length;j++){
          dropdownOptions.push({
            id:secKeys[i]+'.'+qKeys[j],
            value:secKeys[i]+'.'+qKeys[j],
            label:"Sec"+secKeys[i]+'.Q'+qKeys[j]+'.', 
            type:'response'
          })
        }
        console.log("In i loop", dropdownOptions);
      }
      this.setState({
        surveyVersionPosition:svPosition, 
        surveyPosition:sPosition,
        dropdownNotUpdated:false,
        dropdownOptions:dropdownOptions
      })

    }

    if(this.props.actionSuccess&&this.state.surveyID){
      console.log("CALLING UPDATE");
      const surveyInfo = {
        startDate: formatDate(this.state.startDate),
        endDate: formatDate(this.state.endDate),
      };
      this.props.GetResponse(
        surveyInfo,
        this.state.versionId,
        this.props.auth.user.accessToken
      );
      this.props.ActionStateReset();
    }

    if(this.props.filter&&!this.state.filterSet){
      this.setState({...this.props.filter, filterSet:true})
    }

    if(this.props.surveyInterviewers&&this.props.surveyInterviewers.length>0&&!this.state.supvervisorUpdated){
      var supervisors = []
      supervisors = this.props.surveyInterviewers.filter(user =>{
        return user.User.Role.name.toLowerCase() == "supervisor"
      })

      supervisors = supervisors.map(user => {
        return {value:user.User.id, label: user.User.userName}
      })

      this.setState({surveySupervisors:supervisors, supvervisorUpdated:true})

    }

    if(this.props.createResultSuccess &&this.state.resultSync){
      const surveyInfo = {
        startDate: formatDate(this.state.startDate),
        endDate: formatDate(this.state.endDate),
      };
      if(this.state.responseType == "Live"){
        this.props.GetResponse(
          surveyInfo,
          this.state.versionId,
          this.props.auth.user.accessToken
        );
      }
      else{
        this.props.GetTestResponse(
          surveyInfo,
          this.state.versionId,
          this.props.auth.user.accessToken
        );
      }

      this.setState({resultSync:false})
    }
    
  };

  HandleChange = (selectedOptions) => {
    if(selectedOptions)
    this.setState({
      ...this.state,
      columns:selectedOptions,
    })
  };
  HandleSupervisorChange = (selectedOptions) => {
    if(selectedOptions)
    this.setState({
      ...this.state,
      seletedSupervisor:selectedOptions,
    })
  };

  handleSurvey = (e) => {
    var sid =  e.target.value
    this.setState({ surveyID: sid });
    const survey = this.props.survey.find(function (sur) {
      return sur.id ==  sid;
    });
    console.log("sid", sid)
    // survey.SurveyVersions.map((version) => {
    //   this.setState({ survey: version, versionId: version.id });
    // });
    this.setState({supvervisorUpdated:false})
    this.props.GetSurveyStat(sid, this.props.auth.user.accessToken);
    this.props.GetVersions(sid, this.props.auth.user.accessToken);
    this.props.GetSurveyInterviewers(sid, this.props.auth.user.accessToken);
    
  };
  onDateClose = () => {
    this.setState({
      ...this.state,
      startDate: document.getElementById("startDate").value,
      endDate: document.getElementById("endDate").value,
    });
    // console.log(this.state)
  };
  handleBack = () => {
    this.props.initResponse();
    this.props.history.push("/");
  };

  onNISChangeHandler = (event) => {
    // console.log(event.target.id, event.target.value)
    this.setState({
      [event.target.id]: event.target.value,
    });
  };

  formatDateTime = (data) => {
    const dateTime = new Date().toISOString(data);
    return dateTime;
  };
  onSubmit = (e) => {
    e.preventDefault();
    const surveyInfo = {
      startDate: formatDate(this.state.startDate),
      endDate: formatDate(this.state.endDate),
    };
    if(this.state.responseType == "Live"){
      this.props.GetResponse(
        surveyInfo,
        this.state.versionId,
        this.props.auth.user.accessToken
      );
    }
    else{
      this.props.GetTestResponse(
        surveyInfo,
        this.state.versionId,
        this.props.auth.user.accessToken
      );
    }

    // this.props.GetNewsNIS(this.state, this.props.auth.accessToken)
  };

  updateDimensions() {
    // console.log("WIDTH", window.innerWidth)
    if (window.innerWidth <= 500) {
      this.setState({
        ...this.state,
        width: window.innerWidth - 60,
        height: 250,
        tickRotation: true,
      });
    } else if (window.innerWidth < 1400 && window.innerWidth > 1000) {
      this.setState({ ...this.state, width: 700, height: 350 });
    } else {
      this.setState({
        ...this.state,
        width: 1050,
        height: 300,
        tickRotation: false,
      });
    }
    // console.log("here", this.state);
  }
  OnClick = (d, selected) => {
    // console.log("CLICKED", d);
    var data = {
      date: d,
      relevanceThreshold: this.state.relevanceThreshold,
      noveltyThreshold: this.state.noveltyThreshold,
      frequency: this.state.frequency,
      company: this.state.company,
    };
    //   this.props.GetNISTopNews(data, selected,  this.props.auth.accessToken)
  };

  onRejectApprove = (type,resVerId) => {
    if (type == "reject") {
      this.setState({ rejORapp: type, resVersionId: resVerId });
    } else if (type == "approve") {
      this.setState({ rejORapp: type, resVersionId: resVerId });
    }
    var modal = document.getElementById("delete-user-modal");
    var instance = M.Modal.getInstance(modal);
    instance.open();
  };

  rejectORapprove = () => {
    if (this.state.rejORapp == "reject") {
      const status = {
        responseStatus: "Declined",
      };
      this.props.RejectOrApprove(status, this.state.resVersionId, this.props.auth.user.accessToken);
    } else if (this.state.rejORapp == "approve") {
      const status = {
        responseStatus: "Approved",
      };
      this.props.RejectOrApprove(status, this.state.resVersionId, this.props.auth.user.accessToken);
    }
    var modal = document.getElementById("delete-user-modal");
    var instance = M.Modal.getInstance(modal);
    instance.close();
  };

  /**
   * Remove event listener
   */
  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions.bind(this));
  }

  HandleOtherChange = (e) => {
    if(e.target.id=='versionId'){
      this.setState({[e.target.id]: e.target.value },(e)=>{
        if(this.props.survey && this.state.surveyID&& this.state.versionId){
          console.log("In update");
          var dropdownOptions = []
          var survey = this.props.survey.find(survey => survey.id == this.state.surveyID)
          var sPosition = this.props.survey.findIndex(survey => survey.id == this.state.surveyID)
          var svPosition = survey.SurveyVersions.findIndex(version => version.id == this.state.versionId)
          console.log("here", sPosition, svPosition);
          var sections = survey.SurveyVersions[svPosition].questionnaire.sections
          var secKeys = Object.keys(sections)
          dropdownOptions.push({id:'responseStatus',value:'responseStatus', label:"Response status", type:'survey'})
          // dropdownOptions.push({id:'surveyCreatedAt', value:'surveyCreatedAt', label:"survey create date", type:'survey'})
          dropdownOptions.push({id:'uniqueId', value:'uniqueId', label:"Unique Id", type:'survey'})
          dropdownOptions.push({id:'createdAt', value:'responseCreatedAt', label:"Create date", type:'survey'})
          for (var i=0;i<secKeys.length;i++){
            var questions = sections[secKeys[i]].questions
            var qKeys = Object.keys(questions)
            for(var j=0;j<qKeys.length;j++){
              dropdownOptions.push({
                id:secKeys[i]+'.'+qKeys[j],
                value:secKeys[i]+'.'+qKeys[j],
                label:"Sec"+secKeys[i]+'.Q'+qKeys[j]+'.', 
                type:'response'
              })
            }
            console.log("In i loop", dropdownOptions);
          }
          this.setState({
            surveyVersionPosition:svPosition, 
            surveyPosition:sPosition,
            dropdownNotUpdated:false,
            dropdownOptions:dropdownOptions
          })
        }
      })
    }
    else{
      this.setState({[e.target.id]: e.target.value })
    }
  }

  HandleStatusChange=(e)=>{
    this.setState({[e.target.name]: e.target.value, surveyID:"" }, ()=>{
      this.props.GetSurveys(this.state.surveyStatus, this.props.auth.user.accessToken);
    });
    
  }

  CreateResult=(id)=>{
    var user = this.props.auth.user
    this.props.CalculateResult(id, user.accessToken)
    this.setState({resultSync:true})
  }
  

  render() {
    const {
      auth,
      responses,
      loading,
      error,
      survey,
      testResponses
    } = this.props;
    console.log("loacl state", this.state);
    console.log("loacl props", this.props);
    if (!(auth.user && auth.user.id)) return <Redirect to="/login" />;
    //   if (!(ChartData) || ChartData.length === 0) return <Redirect to="/" />;

    const query = this.state.name;
    const filteredResponses = responses
      ? 
        this.state.view=='interviewer'&& this.state.name?
        responses.filter((res) => {
            return res.ResponseVersions[0].UserSurvey.User.userName.toLowerCase().includes(query.toLowerCase())
                  ||res.ResponseVersions[0].UserSurvey.userId == query;
          })
        :
        this.state.view=='supervisor'&& this.state.seletedSupervisor?
        responses.filter((res) => {
            return res.ResponseVersions[0].UserSurvey.userId == this.state.seletedSupervisor.value
            ||res.ResponseVersions[0].UserSurvey.supervisorId == this.state.seletedSupervisor.value ;
          })
        :
        this.state.view=='center'&&this.state.selectedSec&&this.state.selectedQ&& this.state.name?
        responses.filter((res) => {
          return res.ResponseVersions[0].response.sections[this.state.selectedSec].questions[this.state.selectedQ].answer[0].input.toLowerCase().includes(query.toLowerCase())
        })
        :
        responses
      : [];

    return (
      <>
        <DeleteModal
          className="delete-modal"
          HandleDelete={(e) =>
            this.rejectORapprove()
          }
        />
        <Navbar heading="QC" user={auth.user} />
        <div className="row full-portal">
          <div className="col s12 l2 m5 left-bar-qc">
            <div className="row center-align">
              <p style={{marginBottom:5}}>
                <strong> Modify Parameters</strong>
                <hr />
              </p>
              <form id="response" onSubmit={this.onSubmit}>
                <div className="ModifyParamm">
                  <div className="col s12">
                    <span>Response Type</span>
                  </div>
                  <div class="input-field col s12">
                    <div className="col s6">
                      <label>
                        <input
                          className="with-gap"
                          name="responseType"
                          id="liveResponse"
                          value="Live"
                          type="radio"
                          onChange={this.HandleStatusChange}
                          checked={this.state.responseType=="Live"}
                        />
                        <span>App</span> 
                      </label>
                    </div>
                    <div className="col s6">
                      <label>
                        <input
                          className="with-gap"
                          name="responseType"
                          id="testResponse"
                          value="Test"
                          type="radio"
                          onChange={this.HandleStatusChange}
                          checked={this.state.responseType=="Test"}
                        />
                        <span>Web</span> 
                      </label>
                    </div>
                  </div>
                  <div className="col s12">
                    <span>Test Status</span>
                  </div>
                  <div class="input-field col s12">
                    <div className="col s6">
                      <label>
                        <input
                          className="with-gap"
                          name="surveyStatus"
                          id="liveSurvey"
                          value="Live"
                          type="radio"
                          onChange={this.HandleStatusChange}
                          checked={this.state.surveyStatus=="Live"}
                        />
                        <span>App</span> 
                      </label>
                    </div>
                    <div className="col s6">
                      <label>
                        <input
                          className="with-gap"
                          name="surveyStatus"
                          id="testSurvey"
                          value="Test"
                          type="radio"
                          onChange={this.HandleStatusChange}
                          checked={this.state.surveyStatus=="Test"}
                        />
                        <span>Web</span> 
                      </label>
                    </div>
                  </div>
                  
                  <div class="input-field col s12">
                    <select
                      type="text"
                      onChange={this.handleSurvey}
                      id="survey"
                      defaultValue=""
                      value={this.state.surveyID}
                      className="browser-default"
                    >
                      <option value="" disabled selected>
                        Choose a test
                      </option>
                      {survey &&
                        survey.map((survey, index) => (
                          <option id={survey.id} key={index} value={survey.id}>
                            {survey.name}
                          </option>
                        ))}
                    </select>
                  </div>
                  <div class="input-field col s12">
                    <input
                      type="text"
                      className="datepicker validate"
                      id="startDate"
                      value={this.state.startDate}
                      required
                      placeholder="Start Date"
                    />
                  </div>
                  <div class="input-field col s12">
                    <input
                      type="text"
                      className="datepicker validate "
                      id="endDate"
                      value={this.state.endDate}
                      required
                      placeholder="End Date"
                    />
                  </div>
                  <div className="row left-align"></div>
                  <div class="input-field col s12">
                    <select
                      required
                      onChange={this.HandleOtherChange}
                      className='browser-default'
                      id='versionId'
                    >
                      <option value="" disabled selected>
                        Choose test versions
                      </option>
                      {
                        this.props.versions.map((version,index)=>
                              <option selected={this.props.versionId==version.id} value={version.id}>{version.versionLabel}</option>
                              )
                      }
                    </select>
                  </div>
                  {
                    responses&&responses.length>0
                    ||testResponses&&testResponses.length>0?
                    <div>
                      <div class="input-field col s12">
                        <span>Choose extra columns</span>
                        <Select
                        value={this.state.columns}
                        onChange={this.HandleChange}
                        options={this.state.dropdownOptions}
                        placeholder="Choose extra columns"
                        isMulti={true}
                        />

                      </div>
                    </div>
                    :null
                  }
                </div>
              </form>
              <div className="center center-align">
                {loading ? (
                  <div className="preloader-wrapper small active">
                    <div className="spinner-layer spinner-green-only">
                      <div className="circle-clipper left">
                        <div className="circle"></div>
                      </div>
                      <div className="gap-patch">
                        <div className="circle"></div>
                      </div>
                      <div className="circle-clipper right">
                        <div className="circle"></div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="col s12 l12 m12 center center-align">
                    <button
                      type="submit"
                      form="response"
                      className="btn qc-submit"
                    >
                      SUBMIT
                    </button>
                  </div>
                )}
                <div className="red-text">
                  {error ? <p>Data not available</p> : null}
                </div>
              </div>
            </div>
          </div>

          <div className="col s12 l10 m7">
            <div className="container" style={{ width: "95%" }}>
              <div className="row stats">
                {this.props.stat &&
                  this.props.stat.map((stat) => (
                    <Stats
                      items={[
                        {
                          heading: "Total Response",
                          value: stat.responseCount ,
                        },
                        {
                          heading: "Total Checked",
                          value:
                            stat.qcApproved + stat.qcDecliened + stat.qcPushed,
                        },
                        {
                          heading: "Total passed",
                          value: stat.qcApproved,
                          color: "green",
                        },
                        {
                          heading: "Total Student",
                          value: stat.interviewerCount,
                        },
                      ]}
                    />
                  ))
                }
              </div>
              {
                this.state.surveyStatus=="Live"&&this.state.responseType=="Live"?
                responses?
                responses.length == 0?
                <div className="row">
                  <div className="col s12 center center-align">
                    <h3 className="light-h3">No response found</h3>
                  </div>
                </div>
                :
                <div className="row response-complete flex-container">
                <div className="col s7">
                  <table style={{ width: "100%" }}>
                    <tbody>
                    <ResponseListItem
                      id="id"
                      displayId="id"
                      interviwerName="Student name"
                      // interviewerId="Interviewer Id"
                      dateTime="Test time"
                      phone="Phone"
                      status="Test status"
                      marks="Marks"
                      // onDetails = {}
                      cssType="response-list-header"
                    />
                    {filteredResponses &&
                      filteredResponses.map((response) => (
                        response.ResponseVersions.map((responseVersion, index)=>
                        <ResponseListItem
                          id={responseVersion.id}
                          displayId={response.id+"."+responseVersion.versionLabel+"."}
                          key={responseVersion.id}
                          interviwerName={responseVersion.UserSurvey.User.userName}
                          // interviewerId={response.interviewerId}
                          pushed={response.pushedFlag}
                          dateTime={response.createdAt}
                          survey={this.state.survey}
                          phone={responseVersion.UserSurvey.User.contactNo}
                          // image = {response.angentName}
                          status={responseVersion.responseStatus}
                          marks={responseVersion.marks}
                          getMarks={this.CreateResult}
                          // formatTimestamp={this.}
                          onDetails={() =>{
                            this.props.history.push(
                              "/survey/" +
                                response.SurveyVersion.surveyId+
                              "/version/" +
                                response.SurveyVersion.id+
                                "/response/" +
                                responseVersion.id,
                              response
                            )
                            this.props.SaveFilters(this.state)
                          }
                        }
                          onReject={this.onRejectApprove}
                          onApprove={this.onRejectApprove}
                          cssType="response-list"
                        />
                        )
                      ))}
                    </tbody>
                  </table>
                </div>
                <div className="col s5">
                  <div
                    style={{
                      width: "100%",
                      overflow: "auto",
                      overflowX: "scroll",
                    }}
                  >
                    <table>
                      <ResponseListItemExtra
                        data={filteredResponses? filteredResponses[0]:[]}
                        cssType="response-list-header"
                        columns={this.state.columns}
                      />
                      {filteredResponses &&
                        filteredResponses.map((response) => (
                          response.ResponseVersions.map((responseVersion, index)=>
                          // response.responseStatus == "Pending"?
                          <ResponseListItemExtra
                            data={responseVersion}
                            cssType="response-list"
                            columns={this.state.columns}
                          />
                          // :
                          // null
                          )
                        ))}
                    </table>
                  </div>
                </div>
              </div>
              :
              <div className="row">
                <div className="col s12 center center-align">
                  <h3 className="light-h3">Select Parameters correctly to get response</h3>
                </div>
              </div>
              :
              this.state.responseType=="Test"?
              testResponses?
              testResponses.length == 0?
                  <div className="row">
                    <div className="col s12 center center-align">
                      <h3 className="light-h3">No response found</h3>
                    </div>
                  </div>
                  :
                  <div className="row response-complete flex-container">
                    <div className="col s4">
                      <table style={{ width: "100%" }}>
                        <TestResponseListItem
                          id="id"
                          dateTime="Survey time"
                          status="Response status"
                          cssType="response-list-header"
                        />
                        {testResponses.map((response) => (
                            <TestResponseListItem
                              id={response.id}
                              dateTime={response.createdAt}
                              status={response.responseStatus}
                              onDetails={() =>{
                                console.log("called");
                                this.props.history.push(
                                    "/survey/"+this.state.surveyID+
                                    "/version/"+this.state.versionId+
                                    "/testResponse/" + response.id,
                                  response
                                )
                                this.props.SaveFilters(this.state)
                              }}
                              cssType="response-list"
                            />
                        ))}
                      </table>
                    </div>
                    <div className="col s8">
                      <div
                        style={{
                          width: "100%",
                          overflow: "auto",
                          overflowX: "scroll",
                        }}
                      >
                        <table>
                          <ResponseListItemExtra
                            data={testResponses? testResponses[0]:[]}
                            cssType="response-list-header"
                            columns={this.state.columns}
                          />
                          {testResponses &&
                            testResponses.map((response) => (
                              <ResponseListItemExtra
                                data={response}
                                cssType="response-list"
                                columns={this.state.columns}
                              />                            
                            ))}
                        </table>
                      </div>
                    </div>
                  </div>
                :
                <div className="row">
                  <div className="col s12 center center-align">
                    <h3 className="light-h3">Select Parameters correctly to get response</h3>
                  </div>
                </div>
              :
              <div className="row">
                <div className="col s12 center center-align">
                  <h3 className="light-h3">Select Parameters correctly to get response</h3>
                </div>
              </div>
              }
              
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  console.log("State in QC", state.quality)
  return {
    auth: state.auth,
    survey: state.quality.surveys,
    stat: state.quality.stat,
    responses: state.quality.surveyResponse,
    id: ownProps.match.params.id,
    surveyInterviewers: state.resource.surveyInterviewers
      ? state.resource.surveyInterviewers
      : [],
    interviewers: state.resource.interviewers
      ? state.resource.interviewers
      : [],
    versions: state.quality.versions?state.quality.versions:[],
    actionSuccess: state.quality.rejectORapproveSuccess,
    actionError: state.quality.rejectORapproveError,
    filter: state.quality.filter,
    testResponses: state.quality.surveyTestResponse,
    createResultLoading:state.quality.createResultLoading,
    createResultSuccess:state.quality.createResultSuccess,
    createResultError:state.quality.createResultError,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    //fetch surveys
    GetSurveys: (status, token) => {
      dispatch(getSurveys(status, token));
    },
    //fetch survey stat
    GetSurveyStat: (surveyId, token) => {
      dispatch(getSurveyStat(surveyId, token));
    },
    //get survey response
    GetResponse: (surveyInfo, surveyVersionId, token) => {
      dispatch(getResponse(surveyInfo, surveyVersionId, token));
    },
    GetTestResponse: (surveyInfo, surveyVersionId, token) => {
      dispatch(getTestResponse(surveyInfo, surveyVersionId, token));
    },
    GetSurveyInterviewers: (sid, token) => {
      dispatch(getSurveyInterviewers(sid, token));
    },
    GetVersions: (sid, token) =>{
      dispatch(getVersions( sid, token));   
  },
  RejectOrApprove: (status, responseVersionId, token) => {
    dispatch(RejectOrApprove(status, responseVersionId, token));
  },
  ActionStateReset:()=>{
    dispatch(actionStateReset());
  },
  SaveFilters:(filter)=>{
    dispatch(saveFilters(filter));
  },
  CalculateResult:(id, token)=>{
    dispatch(calculateResult(id, token))
  }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(QualityControl);
