import React, { Component } from 'react'
import { connect } from "react-redux";
import M from 'materialize-css'
import  { getSurveyInterviewers } from '../../store/actions/resourceActions';
import { checkUser,pushBack } from "../../store/actions/QualityAction";

class PushbackModal extends Component {
    state={
        check: false,
        checked: {},
        userId: null
    }
    componentDidMount(){
        var elems = document.querySelectorAll('.collapsible');
        var instances = M.Collapsible.init(elems, {});
        // this.setState
        var user = this.props.auth.user
        if (user)
        this.props.GetSurveyInterviewers(this.props.surveyId, user.accessToken);
    }

    onSelect = (e,id, index) => {
        console.log(index)
        this.setState({ check: !this.state.check, userId: id });
    this.setState((previousState) => ({
      checked: {
        ...previousState.checked,
        [index]: !previousState.checked[index],
      },
    }));
    this.props.GetCheck(this.props.interviewers, id);
        console.log(this.state.checked)
    }

    // onSubmit = (e) => {
    //   e.preventDefault();
    //   this.props.PushBack( this.state.userId,this.props.responseVersionId,this.props.auth.user.accessToken)
    // }
    render() {
        const { checked } = this.state;
        const checkedCount = Object.keys(checked).filter((key) => checked[key])
         .length;
        const disabled = checkedCount > 0;
        // console.log("interviwers",this.props.interviewers)
        return (
            <div id="pushback-modal" className="modal modal-fixed-footer">
      <div className="row modal-content">
        <div className="row modal-title valign-wrapper">
          <span>Push Back TO </span>
        </div>
        <form
          id="pushBack"
          onSubmit={(e)=>this.props.onSubmit(e, this.state.userId)}
          className="row"
          // action="#"
          style={{ margin: 20 }}
        >
            {this.props.interviewers && this.props.interviewers.map((i,index) => (
              <div className="row list-item" key={i.id}>
              <div className="col l10">
                {i.User.userName}
              </div>
              <div className="col l2 center">
                <label>
                  <input
                    key={index}
                    type="checkbox"
                    id={i.User.id}
                    onChange={(e) => this.onSelect(e, i.User.id, index)}
                    checked={checked[index] || false}
                    disabled={!checked[index] && disabled}
                  />
                  <span></span>
                </label>
              </div>
                </div>
            ))}
                  </form>
      </div>
      <div className="modal-footer">
        <button className="modal-close waves-effect waves btn-flat">
          CANCEL
        </button>
        <button
          type="submit"
          form="pushBack"
          className="waves-effect waves btn-flat"
        >
          PUSH BACK
        </button>
      </div>
    </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
  // console.log("State in add existing", state)
  return {
    auth: state.auth,
    surveys: state.resource.surveyInterviewers
      ? state.resource.surveyInterviewers
      : [],
    interviewers: state.resource.surveyInterviewers
    ? state.resource.surveyInterviewers
    : [],
    check: state.quality.checks,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    GetSurveyInterviewers: (surveyId, token) => {
      dispatch(getSurveyInterviewers(surveyId, token));
    },
    GetCheck: (user, id) => {
      dispatch(checkUser(user, id));
    },
    PushBack: (userId, resVerId, token) => {
      dispatch(pushBack(userId, resVerId, token));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(PushbackModal);
