import React from 'react'
import OptionViewer from './OptionViewer'

export default function GridTable({options, groups, questions, selectedLang, selectedSec, selectedQ, state, handleChange}) {
    return (
        <div>
            <form>
            <table className="pdTable" style={{marginLeft:0, fontSize:14}}>
                <tbody>
                    <tr>
                        <td>
                            
                        </td>
                        {   
                            Object.keys( options).map((optionId, index) => 
                                <td key={optionId}
                                style={
                                    questions.sections[selectedSec].questions[selectedQ].opStyle
                                      ? index % 2 == 0
                                        ? {
                                            ...questions.sections[selectedSec].questions[
                                              selectedQ
                                            ].opStyle.even,
                                            marginLeft: 20,
                                          }
                                        : {
                                            ...questions.sections[selectedSec].questions[
                                              selectedQ
                                            ].opStyle.odd,
                                            marginLeft: 20,
                                          }
                                      : { marginLeft: 20 }
                                  }
                                  >
                                <OptionViewer
                                option={
                                    questions.sections[selectedSec].questions[selectedQ]
                                    .options[optionId]
                                }
                                lang={selectedLang}/>
                                </td>
                            )
                        }
                    </tr>
                    {
                        Object.keys( groups).map(groupId => 
                            <tr>
                                <td>
                                    {questions.sections[selectedSec]
                                    .questions[selectedQ]
                                    .groups[groupId]
                                    .group[selectedLang]
                                    }
                                </td>{
                                    Object.keys( options).map((optionId, index) => 
                                    <td key={optionId}
                                    style={
                                        questions.sections[selectedSec].questions[selectedQ].opStyle
                                          ? index % 2 == 0
                                            ? {
                                                ...questions.sections[selectedSec].questions[
                                                  selectedQ
                                                ].opStyle.even,
                                                marginLeft: 20,
                                              }
                                            : {
                                                ...questions.sections[selectedSec].questions[
                                                  selectedQ
                                                ].opStyle.odd,
                                                marginLeft: 20,
                                              }
                                          : { marginLeft: 20 }
                                      }
                                    >
                                        <label>
                                            <input
                                            className="with-gap browser-default"
                                            type="radio"
                                            id={"gr-"+ groupId+'-op-'+ optionId}
                                            name={"gridGroup"+groupId}
                                            checked={
                                                state.grid[groupId]&&state.grid[groupId][optionId]? true : false
                                            }
                                            // value={optionId}
                                            onChange={handleChange}
                                            />
                                            <span></span>
                                        </label>
                                    </td>
                                )}
                            </tr>
                        )
                    }
                    
                </tbody>
                
            </table>
            </form>
        </div>
    )
}
