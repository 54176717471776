import React from "react";
import "../stylesheets/qcList.css";

export default function ResponseListItemExtra({ data, cssType, columns }) {
  return (
    <>
      {
      columns.length > 0 ? (
        <tr className={"row valign-wrapper " + cssType}>
        {
          columns.map(c=>
            cssType == "response-list" ?
              <td>
                {
                  c.type == "response"&&data.response?
                  data.response.sections[c.value.split('.')[0]]
                  &&data.response.sections[c.value.split('.')[0]].questions
                  &&data.response.sections[c.value.split('.')[0]].questions[c.value.split('.')[1]]
                  &&data.response.sections[c.value.split('.')[0]].questions[c.value.split('.')[1]].answer
                  &&data.response.sections[c.value.split('.')[0]].questions[c.value.split('.')[1]].answer.length>1?
                    <span>
                    {
 
                    Array.isArray( data.response.sections[c.value.split('.')[0]].questions[c.value.split('.')[1]].answer)?
                      data.response.sections[c.value.split('.')[0]].questions[c.value.split('.')[1]].answer.map(answer =>
                        
                            answer.optionId && answer.groupId?
                              " Gr. "+answer.groupId+"Op. "+ answer.optionId
                            :
                            " Op. "+ answer.optionId
                      )
                      : data.response.sections[c.value.split('.')[0]].questions[c.value.split('.')[1]].answer
                    }
                    </span>
                    :
                    <span>
                      {
                      data.response.sections[c.value.split('.')[0]]
                      &&data.response.sections[c.value.split('.')[0]]
                      &&data.response.sections[c.value.split('.')[0]].questions
                      &&data.response.sections[c.value.split('.')[0]].questions[c.value.split('.')[1]]
                      &&data.response.sections[c.value.split('.')[0]].questions[c.value.split('.')[1]].answer
                      &&data.response.sections[c.value.split('.')[0]].questions[c.value.split('.')[1]].answer[0]
                      &&data.response.sections[c.value.split('.')[0]].questions[c.value.split('.')[1]].answer[0].input?
                      data.response.sections[c.value.split('.')[0]].questions[c.value.split('.')[1]].answer[0].input
                      :
                      data.response.sections[c.value.split('.')[0]]
                      &&data.response.sections[c.value.split('.')[0]].questions[c.value.split('.')[1]]
                      &&data.response.sections[c.value.split('.')[0]].questions[c.value.split('.')[1]]
                      &&data.response.sections[c.value.split('.')[0]].questions[c.value.split('.')[1]].answer
                      &&data.response.sections[c.value.split('.')[0]].questions[c.value.split('.')[1]].answer
                      &&data.response.sections[c.value.split('.')[0]].questions[c.value.split('.')[1]].answer[0].optionId?
                      "Op."+data.response.sections[c.value.split('.')[0]].questions[c.value.split('.')[1]].answer[0].optionId
                      :
                      null
                      }
                    </span>
                  :
                      <span>
                        {data[c.id]}
                      </span>
                }
              </td>
            :
            <td
            style={{
              width:"20%",
              marginLeft: 10,
              overflowX: "hidden",
            }}>
              <span>{c.label}</span>
            </td>
          )
        }
        </tr>
      ) : null}
    </>
  );
}
