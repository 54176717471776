const initState = {
    questions:[],
    questionsLoading:false,
    uploadMasterLoading:false,
    uploadMasterSuccess:false,
    uploadMasterError:false,
    surveyId:null,
    versionId:null,
    versionInfo:null,

    loadingSurveys: false,
    surveys: null,
    surveyInfo:null,

    versionLanguages:null,
    loadingLanguages:false,
    loadingVersions:false,
    versions:null,

    versionLoading:false,
    versionLoadSuccess:false,
    versionLoadError:false,

    savedAnswer:{
        sections:{}
    },

    prevData:{
        sectionId:null,
        questionId:null
    },
    prevElements:{
        sections:{}
    },

    logicSave:false,
    uploading:false,
    uploadError:false,
    uploadElementId:null,
    uploadSuccess:false,

    surveyImageLoading:false,
    surveyImageLoadSuccess:false,
    surveyImageLoadError:false,

    addLanguageSuccess:false,
    addLanguageError:false,
    addLanguageLoading:false,

    saveSurveyLoading:false,
    saveSurveySuccess:false,
    saveSurveyError:false,

    addQuestionLoading:true,
    addQuestionSuccess:false,
    addQuestionError:false,

    changeMandatorySuccess:false,
    changeMandatoryLoading:false,
    changeMandatoryError:false,

    deleteQSuccess:false,
    deleteQError:false,
    deleteQLoading:false,
}

const designerReducer=(state=initState,action)=>{
    switch(action.type){
        case 'STYLE_SAVE_LOADING':
            return {
                ...state,
                questionsLoading:true,                
                questions:null
            }
        case 'STYLE_SAVE_SUCCESS':
            return {
                ...state,
                questionsLoading:false,                
                questions:action.data
            }
        case 'STYLE_SAVE_ERROR':       
            return {
                ...state,
                questionsLoading:false,                
                questions:null
            }
        case 'LOADING_UPLOAD':
            return {
                ...state,
                uploadMasterLoading:true,
                uploadMasterSuccess:false,
                uploadMasterError:false,
                surveyId:null
            }
        case 'UPLOAD_SUCCESSFUL':
            return {
                ...state,
                uploadMasterLoading:false,
                uploadMasterSuccess:true,
                uploadMasterError:false,
                questions:action.data.version.questionnaire,
                surveyInfo:{...action.data, sections:[]},
                surveyId:action.id,
                versionId:action.data.version.id,
                versionInfo:action.data.version,
            }
        case 'UPLOAD_ERROR':
            return {
                ...state,
                uploadMasterLoading:true,
                uploadMasterSuccess:false,
                uploadMasterError:action.data,
                surveyId:null
            }
        case 'LOAD_SURVEY_SUCCESS':
            return {
                ...state,
                questions:action.data.SurveyVersions[0].questionnaire,
                versionInfo:{...action.data.SurveyVersions[0],questionnaire:null },
                surveyId:action.id,
                versionId:action.versionId,
                surveyInfo:{...action.data, SurveyVersions:[]},
                versionLoadSuccess:true,
            }
        case "SURVEY_FETCH_LOADING":
            return {
                ...state,
                loadingSurveys: true,
                // surveys: null,
            };
        case "SURVEY_FETCH_SUCCESS":
            return {
                ...state,
                loadingSurveys: false,
                surveys: action.payload,
            };
        case "VERSION_LANGUAGES_LOADING":
            return {
                ...state,
                loadingLanguages:true
                // surveys: null,
            };
        case "VERSION_LANGUAGES_SUCCESS":
            return {
                ...state,
                loadingLanguages:false,
                versionLanguages:action.data
            };
        case "VERSION_FETCH_SUCCESS":
            return {
                ...state,
                loadingVersions:false,
                versions:action.data
            };

        case 'LOADING_NEW_VERSION':
            return {
                ...state,
                uploadMasterLoading:true,
                uploadMasterSuccess:false,
                uploadMasterError:false,
                surveyId:null,
                versionLoadError:false,
                versionLoadSuccess:false,
            }
        case 'NEW_VERSION_LOAD_SUCCESSFUL':
            return {
                ...state,
                uploadMasterLoading:false,
                uploadMasterSuccess:true,
                uploadMasterError:false,
                questions:action.data.questionnaire,
                versionInfo:{...action.data, questionnaire:null},
                surveyInfo:action.survey,
                surveyId:action.survey.id,
                versionId: action.data.id,
                versionLoadError:false,
                versionLoadSuccess:true,
            }
        case 'NEW_VERSION_LOAD_ERROR':
            return {
                ...state,
                uploadMasterLoading:true,
                uploadMasterSuccess:false,
                uploadMasterError:true,
                surveyId:null,
                versionId:null,
                versionLoadError:true,
                versionLoadSuccess:false,
            }
        case 'VERSION_CHANGE_SUCCESS':
            return {
                ...state,
                versionLoading:false,
                questions:action.data.questionnaire,
                versionInfo:{...action.data, questionnaire:null},
                surveyInfo:action.data.Survey,
                surveyId:action.data.Survey.id,
                versionId: action.data.id,
                versionLoadError:false,
                versionLoadSuccess:true,
            }
        case 'VERSION_CHANGE_LOADING':
            return {
                ...state,
                versionLoading:true,
                versionLoadError:false,
                versionLoadSuccess:false,
                // surveyId:null,
                // versionId:null
            }
        case 'VERSION_CHANGE_ERROR':
            return {
                ...state,
                versionLoading:false,
                versionLoadError:true,
                versionLoadSuccess:false,
                versionLoadError:true,
                versionLoadSuccess:false,
                // surveyId:null,
                // versionId:null
            }
        case 'PREVIEW_DATA_SAVE':
            console.log("IN DESIGNER reducer",action.payload);
            var sid = action.pos.sid
            var qid = action.pos.qid
            var newData = state.savedAnswer
            if(state.savedAnswer.sections[sid]){
                if(state.savedAnswer.sections[sid].questions){
                    newData.sections[sid].questions[qid] = action.payload
                }
                else{
                    newData.sections[sid].questions = {}
                    newData.sections[sid].questions[qid] = action.payload
                }
            }
            else{
                newData.sections[sid]= {}
                newData.sections[sid]["questions"] = {}
                newData.sections[sid].questions[qid] = action.payload
            }

            var newPrevData = state.prevElements
            if(state.prevElements.sections[sid]){
                if(state.prevElements.sections[sid].questions){
                    newPrevData.sections[sid].questions[qid] = state.prevData
                }
                else{
                    newPrevData.sections[sid].questions = {}
                    newPrevData.sections[sid].questions[qid] = state.prevData
                }
            }
            else{
                newPrevData.sections[sid]= {}
                newPrevData.sections[sid]["questions"] = {}
                newPrevData.sections[sid].questions[qid] = state.prevData
            }
            return {
                ...state,
                prevData:{sectionId:sid, questionId:qid},
                prevElements:newPrevData,
                savedAnswer: newData,
            }
        
        case 'LOGIC_SAVE':
            var newQuestions = state.questions
            newQuestions.sections[action.pos.sid].questions[action.pos.qid]["logic"] = action.payload 
            return {
                ...state,
                questions:newQuestions,
                logicSaveSuccess:true
            }
        
        case 'LOGIC_SAVE_RESET':
            return {
                ...state,
                logicSaveSuccess:false
            }
        
        case 'DESIGN_UPLOAD_SUCCESSFUL':
            return {
                ...state,
                uploading:false,
                uploadError:false,
                uploadSuccess:true,
                uploadElementId:action.id,
            }
        case 'DESIGN_UPLOAD_LOADING':
            return {
                ...state,
                uploading:true,
                uploadError:false,
                uploadSuccess:false,
                uploadElementId:action.id,
            }
        case 'DESIGN_UPLOAD_ERROR':
            return {
                ...state,
                uploading:false,
                uploadElementId:action.id,
                uploadError:true,
                uploadSuccess:false
            }
        case 'UPLOAD_SEQUENCE_COMPLETE':
            return {
                ...state,
                uploading:false,
                uploadElementId:null,
                uploadError:false,
                uploadSuccess:false
            }
        case 'LOADING_SURVEY_IMAGE':
            return {
            ...state,
            surveyImageLoading:true,
            surveyImageLoadSuccess:false,
            surveyImageLoadError:false,
        }
        case 'SURVEY_IMAGE_ADD_SUCCESS':
            return {
            ...state,
            surveyImageLoading:false,
            surveyImageLoadSuccess:true,
            surveyImageLoadError:false,
        }
        case 'SURVEY_IMAGE_ADD_ERROR':
            return {
            ...state,
            surveyImageLoading:false,
            surveyImageLoadSuccess:false,
            surveyImageLoadError:true,
        }
        case 'ADD_LANGUAGE_LOADING':
            return {
            ...state,
            addLanguageSuccess:false,
            addLanguageError:false,
            addLanguageLoading:true,
        }
        case 'ADD_LANGUAGE_SUCCESS':
            return {
            ...state,
            addLanguageSuccess:true,
            addLanguageError:false,
            addLanguageLoading:false,
        }
        case 'ADD_LANGUAGE_ERROR':
            return {
            ...state,
            addLanguageSuccess:false,
            addLanguageError:true,
            addLanguageLoading:false,
        }
        case 'SAVE_SURVEY_SUCCESS':
            return {
                ...state,
                saveSurveyLoading:false,
                saveSurveySuccess:true,
                saveSurveyError:false,
            }
        case 'SAVE_SURVEY_LOADING':
            return {
                ...state,
                saveSurveyLoading:true,
                saveSurveySuccess:false,
                saveSurveyError:false,
            }
        case 'SAVE_SURVEY_ERROR':
            return {
                ...state,
                saveSurveyLoading:false,
                saveSurveySuccess:false,
                saveSurveyError:true,
            }
        case 'SURVEY_SAVE_STATUS_RESET':
            return {
                ...state,
                saveSurveyLoading:false,
                saveSurveySuccess:false,
                saveSurveyError:false,
            }
        case 'ADD_QUESTION_LOADING':
            return {
                ...state,
                addQuestionLoading:true,
                addQuestionSuccess:false,
                addQuestionError:false,

            }
        case 'ADD_QUESTION_SUCCESS':
            return {
                ...state,
                addQuestionLoading:false,
                addQuestionSuccess:true,
                addQuestionError:false,

            }
        case 'ADD_QUESTION_ERROR':
            return {
                ...state,
                addQuestionLoading:false,
                addQuestionSuccess:false,
                addQuestionError:true,

            }
        case 'RESET_QUESTION_ADD_STATUS':
            return {
                ...state,
                addQuestionLoading:false,
                addQuestionSuccess:false,
                addQuestionError:false,

            }
        case 'CHANGE_MANDATORY_SUCCESS':
            return {
                ...state,
                changeMandatorySuccess:true,
                changeMandatoryLoading:false,
                changeMandatoryError:false,
                questions:action.payload
            }
        case 'CHANGE_MANDATORY_LOADING':
            return {
                ...state,
                changeMandatorySuccess:false,
                changeMandatoryLoading:true,
                changeMandatoryError:false,
            }
        case 'CHANGE_MANDATORY_ERROR':
            return {
                ...state,
                changeMandatorySuccess:false,
                changeMandatoryLoading:false,
                changeMandatoryError:true,
            }
        case 'CHANGE_MANDATORY_ERROR':
            return {
                ...state,
                changeMandatorySuccess:false,
                changeMandatoryLoading:false,
                changeMandatoryError:true,
            }
        case 'DELETE_QUESTION_SUCCESS':
            return {
                ...state,
                deleteQSuccess:true,
                deleteQError:false,
                deleteQLoading:false,
            }
        case 'DELETE_QUESTION_ERROR':
            return {
                ...state,
                deleteQSuccess:false,
                deleteQError:true,
                deleteQLoading:false,
            }
        case 'DELETE_QUESTION_LOADING':
            return {
                ...state,
                deleteQSuccess:false,
                deleteQError:false,
                deleteQLoading:true,
            }
        case 'DELETE_QUESTION_STATUS_RESET':
            return {
                ...state,
                deleteQSuccess:false,
                deleteQError:false,
                deleteQLoading:false,
            }
        case 'HOME_CLICK':
            return initState
        default:
            return state;
        
    }
}
export default designerReducer;