import { backendUrl } from "../../config/api";
import createFormData from "../../utils/helper";

export const saveStyle = (selectedSec, selectedQ, state, questions)=>{
    return (dispatch) =>{
        dispatch({type:'STYLE_SAVE_LOADING'})
        console.log("here1", selectedSec, selectedQ, state);

        questions.sections[selectedSec].questions[selectedQ]['pageStyle'] = state.pageStyle
        questions.sections[selectedSec].questions[selectedQ]['qStyle'] = state.qStyle 
        questions.sections[selectedSec].questions[selectedQ]['buttonStyle'] = state.buttonStyle 
        var evenstyle = {}
        Object.assign(evenstyle, state.opStyle);
        evenstyle['backgroundColor'] = state.opStyle.backgroundColorEven
        var oddstyle = {}
        Object.assign(oddstyle, state.opStyle);
        oddstyle['backgroundColor'] = state.opStyle.backgroundColorOdd
        questions.sections[selectedSec].questions[selectedQ]['opStyle']=
          questions.sections[selectedSec].questions[selectedQ]['opStyle']?
            {...questions.sections[selectedSec].questions[selectedQ]['opStyle']}
          :
            {}
        questions.sections[selectedSec].questions[selectedQ]['opStyle']['even'] = evenstyle
        questions.sections[selectedSec].questions[selectedQ]['opStyle']['odd'] = oddstyle

        dispatch({type:'STYLE_SAVE_SUCCESS', data:questions})
         
        // fetch(backendUrl
        // +'/login', {
        //   method: 'post',
        //   headers: {
        //     Accept: 'application/json',
        //     'Content-Type': 'application/json',
        //   },
        //   body: JSON.stringify({
        //     email: credential.email,
        //     password: credential.password,
        //   }),
        // }).then(response => response.json().then(data=>{
        //     if (response.status == 200) {
        //     dispatch({
        //         type:'LOGIN_SUCCESS',
        //         user: {...data.data, type: data.type, accessToken:data.accessToken, refreshAccessToken:data.refreshAccessToken},
        //     });
        //  }else{
        //    dispatch({
        //      type: 'LOGIN_ERROR',
        //    });
        //  }
        // }))

    }
}
export const save = (state, qs)=>{
    return (dispatch) =>{
        dispatch({type:'STYLE_SAVE_LOADING'})
        console.log("here1", state);


        if (state.editMetaData.type == 'question'){
            qs.sections[state.selectedSec].questions[state.selectedQ].question[state.selectedLanguage] = state.editText
            if(state.selectedTextBold.status||state.selectedTextItalic.status||state.selectedTextColor.status){
              qs.sections[state.selectedSec].questions[state.selectedQ].qStyleExtra = [] 
              qs.sections[state.selectedSec].questions[state.selectedQ].qStyleExtra
              .push({
                bold:state.selectedTextBold,
                italic:state.selectedTextItalic,
                color:state.selectedTextColor
              })
            }
        }
        if (state.editMetaData.type == 'option'){
            qs.sections[state.selectedSec].questions[state.selectedQ]
            .options[state.editMetaData.optionId].option[state.selectedLanguage] = state.editText

            if(state.selectedTextBold.status||state.selectedTextItalic.status||state.selectedTextColor.status){
              qs.sections[state.selectedSec].questions[state.selectedQ].options[state.editMetaData.optionId].opStyleExtra = [] 
              qs.sections[state.selectedSec].questions[state.selectedQ].options[state.editMetaData.optionId].opStyleExtra
              .push({
                bold:state.selectedTextBold,
                italic:state.selectedTextItalic,
                color:state.selectedTextColor
              })
            }
        }
        if (state.editMetaData.type == 'group'){
            qs.sections[state.selectedSec].questions[state.selectedQ].groups[state.editMetaData.groupId].group[state.selectedLanguage] = state.editText
        }
        dispatch({type:'STYLE_SAVE_SUCCESS', data:qs})
         
        // fetch(backendUrl
        // +'/login', {
        //   method: 'post',
        //   headers: {
        //     Accept: 'application/json',
        //     'Content-Type': 'application/json',
        //   },
        //   body: JSON.stringify({
        //     email: credential.email,
        //     password: credential.password,
        //   }),
        // }).then(response => response.json().then(data=>{
        //     if (response.status == 200) {
        //     dispatch({
        //         type:'LOGIN_SUCCESS',
        //         user: {...data.data, type: data.type, accessToken:data.accessToken, refreshAccessToken:data.refreshAccessToken},
        //     });
        //  }else{
        //    dispatch({
        //      type: 'LOGIN_ERROR',
        //    });
        //  }
        // }))

    }
}

export const write = (data)=>{
    return (dispatch) =>{
        dispatch({type:'STYLE_SAVE_LOADING'})         
        fetch(backendUrl
        +'/write', {
          method: 'post',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: data
        }).then(response => response.json().then(data=>{
            if (response.status == 200) {
            dispatch({
                type:'WRITE_SUCCESS'
            });
         }else{
           dispatch({
             type: 'LOGIN_ERROR',
           });
         }
        }))

    }
}


export const writeSurveyChanges = (data,sid, svid, token)=>{
    return (dispatch) =>{
        dispatch({type:'SAVE_SURVEY_LOADING'})         
        fetch(backendUrl
        +'/survey/'+sid+'/version/'+svid, {
          method: 'put',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(data)
        }).then(response => response.json().then(data=>{
            if (response.status == 200) {
            dispatch({
                type:'SAVE_SURVEY_SUCCESS'
            });
         }else{
           dispatch({
             type: 'SAVE_SURVEY_ERROR',
           });
         }
        }))

    }
}



export const uploadFile = (file,surveyName, description,course, token)=> {
    return (dispatch) => {
      dispatch({type: 'LOADING_UPLOAD'});
      fetch(backendUrl + "/survey/create?courseId="+course, {
        method: 'post',
        body:createFormData(file,"masterExcel", {name:surveyName, description:description?description:"NA"}),
        headers: {
          'Authorization': `Bearer ${token}`
        },
      })
      .then(response => {
        response.json().then((data)=>{
          console.log("upload data", data.version);
            if (response.status === 200) {
            dispatch({
              type: 'UPLOAD_SUCCESSFUL',
              data:data.version,
              id:data.id,
            });
          }
          else if (response.status === 403 || response.status === 401) {
            dispatch({
              type: "AUTHENTICATION_ERROR"
            });
          }
          else {
            dispatch({
              type: 'UPLOAD_ERROR',
              data:data.message
            });
          }
        })
      }
    )
    .catch(response => {
      dispatch({
        type: 'UPLOAD_ERROR',
      });
    })
  };
  };


export const uploadNewVersion = (id, data, file, excel,course, token)=> {
    return (dispatch) => {
      console.log(data, file);
      dispatch({type: 'LOADING_NEW_VERSION'});
      fetch(backendUrl + "/survey/"+id+"/createVersion?excel="+excel+"&courseId="+course, {
        method: 'post',
        body: excel?
                createFormData(file,"masterExcel", {})
              :
                JSON.stringify({questionnaire:data}),
          headers: excel?
            {
              'Authorization': `Bearer ${token}`
            }
          :
            {
              Accept: 'application/json',
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`
            }
      
      })
      .then(response => {
        response.json().then((data)=>{
            if (response.status === 200) {
            dispatch({
              type: 'NEW_VERSION_LOAD_SUCCESSFUL',
              data:data.data,
              survey:data.survey
            });
          }
          else if (response.status === 403 || response.status === 401) {
            dispatch({
              type: "AUTHENTICATION_ERROR"
            });
          }
          else {
            dispatch({
              type: 'NEW_VERSION_LOAD_ERROR',
              data:data
            });
          }
        })
      }
    )
    .catch(response => {
      dispatch({
        type: 'VERSION_LOAD_ERROR',
      });
    })
  };
  };


export const loadExistingSurvey = (surveyData, id)=> {
    return (dispatch) => {
      // dispatch({type: 'LOADING_SURVEY'});
      dispatch({type: 'LOAD_SURVEY_SUCCESS', data:surveyData, id:id, versionId:surveyData.SurveyVersions[0].id});
    };
};

//fetch completed surveys
export const getSurveys = (status, token) => {
  return (dispatch) => {
    dispatch({type: "SURVEY_FETCH_LOADING"});

    fetch(backendUrl + "/survey?status="+status, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((response) =>
      response.json().then((data) => {
        if (response.status == 200) {
          dispatch({
            type: "SURVEY_FETCH_SUCCESS",
            payload: data.data,
          });
        } else if (response.status == 403) {
          dispatch({
            type: "AUTHENTICATION_ERROR",
          });
        } else {
          dispatch({
            type: "SURVEY_FETCH_ERROR",
          });
        }
      })
    );
  };
};

export const getLanguages = (svid, token)=>{
  return (dispatch) =>{
      dispatch({type:'VERSION_LANGUAGES_LOADING'})         
      fetch(backendUrl
      +'/surveyVersion/'+svid+'/languages', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }).then(response => response.json().then(data=>{
          if (response.status == 200) {
          dispatch({
              type:'VERSION_LANGUAGES_SUCCESS', data:data.data
          });
       }else{
         dispatch({
           type: 'VERSION_LANGUAGES_ERROR',
         });
       }
      }))

  }
}
export const getVersions = (sid, token)=>{
  return (dispatch) =>{
      dispatch({type:'VERSION_FETCH_LOADING'})         
      fetch(backendUrl
      +'/survey/'+sid+'/versions', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }).then(response => response.json().then(data=>{
          if (response.status == 200) {
          dispatch({
              type:'VERSION_FETCH_SUCCESS', data:data.data.SurveyVersions
          });
       }else{
         dispatch({
           type: 'VERSION_FETCH_ERROR',
         });
       }
      }))

  }
}

export const getLanguageForm = (svid, token) => {
  return dispatch => {
    fetch(backendUrl +'/surveyVersion/'+svid+'/languageExcel',{
      headers:{
        'Authorization': `Bearer ${token}`
      }
    })
    .then(response =>
      response.blob().then(blob => {
        if (response.status === 500) {
          dispatch({
            type: "LANGUAGE_PERFORMA_ERROR",
          });
        } else if (response.status === 200) {
          dispatch({
            type: "LANGUAGE_PERFORMA_SUCCESS",
          });
          const data = window.URL.createObjectURL(blob);
          var link = document.createElement('a');
          link.href = data;
          link.download=svid + "_language.xlsx";
          link.click();
          setTimeout(function(){
            // For Firefox it is necessary to delay revoking the ObjectURL
            window.URL.revokeObjectURL(data);
          }, 100);
        } 
        else if (response.status === 403) {
          dispatch({
            type: "AUTHENTICATION_ERROR"
          });
        }
         else {
          dispatch({
            type: "LANGUAGE_PERFORMA_ERROR"
          });
        }
      })
    );
  };
};


export const uploadLanguageFile = (file,svid, code,  token)=> {
  console.log("code", code);
  return (dispatch) => {
    dispatch({type: 'ADD_LANGUAGE_LOADING'});
    fetch(backendUrl + "/surveyVersion/"+svid+"/addLanguage", {
      method: 'post',
      body:createFormData(file,"languageExcel",{code:code}),
      headers: {
        'Authorization': `Bearer ${token}`
      },
    })
    .then(response => {
      response.json().then((data)=>{
          if (response.status === 200) {
          dispatch({
            type: 'ADD_LANGUAGE_SUCCESS'
          });
        }
        else if (response.status === 403 || response.status === 401) {
          dispatch({
            type: "AUTHENTICATION_ERROR"
          });
        }
        else {
          dispatch({
            type: 'ADD_LANGUAGE_ERROR',
          });
        }
      })
    }
  )
  .catch(response => {
    dispatch({
      type: 'UPLOAD_ERROR',
    });
  })
};
};

export const loadVersion = (sid, vid, token)=>{
  return (dispatch) =>{
      dispatch({type:'VERSION_CHANGE_LOADING'})         
      fetch(backendUrl
      +'/survey/'+sid+'/version/'+vid, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }).then(response => response.json().then(data=>{
          if (response.status == 200) {
          dispatch({
              type:'VERSION_CHANGE_SUCCESS', data:data.data
          });
       }else{
         dispatch({
           type: 'VERSION_CHANGE_ERROR',
         });
       }
      }))

  }
}

export const uploadAnswerFile = (file, token)=> {
  return (dispatch) => {
    dispatch({type: 'LOADING_ANSWER_UPLOAD'});
    fetch(backendUrl + "/upload", {
      method: 'post',
      body:createFormData(file,"file", {}),
      headers: {
        'Authorization': `Bearer ${token}`
      },
    })
    .then(response => {
      response.json().then((data)=>{
          if (response.status === 200) {
          dispatch({
            type: 'ANSWER_UPLOAD_SUCCESSFUL',
            payload:data.url
          });
        }
        else if (response.status === 403 || response.status === 401) {
          dispatch({
            type: "AUTHENTICATION_ERROR"
          });
        }
        else {
          dispatch({
            type: 'ANSWER_UPLOAD_ERROR',
            data:data
          });
        }
      })
    }
  )
  .catch(response => {
    dispatch({
      type: 'UPLOAD_ERROR',
    });
  })
};
};

export const saveAnswer = (answer, sid, qid)=>{
  return (dispatch) =>{
      dispatch({type:'PREVIEW_DATA_SAVE', payload:answer, pos:{qid, sid}})
  }
}

export const saveLogic = (sid, qid, logics)=>{
  return (dispatch) =>{
      dispatch({type:'LOGIC_SAVE', payload:logics, pos:{qid, sid}})
  }
}
export const logicSaveReset = ()=>{
  return (dispatch) =>{
      dispatch({type:'LOGIC_SAVE_RESET'})
  }
}

export const uploadDesignFile = (file, fullId, sectionId, questionId, surveyVersionId, token)=> {
  var type = fullId.split('-')[0]//fullId style is option-1, question-1 ...
  var id = fullId.split('-')[1]

  return (dispatch) => {
    dispatch({type: 'DESIGN_UPLOAD_LOADING', id:fullId});
    fetch(backendUrl + "/upload?type=design", {
      method: 'post',
      body:createFormData(file,"file", {type, id, sectionId, questionId,surveyVersionId}),
      headers: {
        'Authorization': `Bearer ${token}`
      },
    })
    .then(response => {
      response.json().then((data)=>{
          if (response.status === 200) {
          dispatch({
            type: 'DESIGN_UPLOAD_SUCCESSFUL',
            payload:data.url,
            id:fullId
          });
        }
        else if (response.status === 403 || response.status === 401) {
          dispatch({
            type: "AUTHENTICATION_ERROR",
          });
        }
        else {
          dispatch({
            type: 'DESIGN_UPLOAD_ERROR',
            id:fullId
          });
        }
      })
    }
  )
  .catch(response => {
    dispatch({
      type: 'DESIGN_UPLOAD_ERROR',
      id:fullId
    });
  })
};
};

export const uploadSequenceComplete = ()=> {
  return (dispatch) => {
    dispatch({type: 'UPLOAD_SEQUENCE_COMPLETE'});
  }
};

export const uploadSurveyImage = (id, file, token)=> {
  return (dispatch) => {
    dispatch({type: 'LOADING_SURVEY_IMAGE'});
    fetch(backendUrl + "/survey/"+id+"/image", {
      method: 'post',
      body: createFormData(file,"file", {}),
      headers: {
        'Authorization': `Bearer ${token}`
      }
    
    })
    .then(response => {
      response.json().then((data)=>{
          if (response.status === 200) {
          dispatch({
            type: 'SURVEY_IMAGE_ADD_SUCCESS',
          });
        }
        else if (response.status === 403 || response.status === 401) {
          dispatch({
            type: "AUTHENTICATION_ERROR"
          });
        }
        else {
          dispatch({
            type: 'SURVEY_IMAGE_ADD_ERROR',
          });
        }
      })
    }
  )
  .catch(response => {
    dispatch({
      type: 'VERSION_LOAD_ERROR',
    });
  })
};
};

export const resetSurveySaveStatus = ()=> {
  return (dispatch) => {
    dispatch({type: 'SURVEY_SAVE_STATUS_RESET'});
  }
};

export const copyStyle = (state, questions)=> {
  return (dispatch) => {
    dispatch({type:'STYLE_SAVE_LOADING'})
    console.log("here1", selectedSec, selectedQ, state);
    var secIds = Object.keys(questions.sections)
    var selectedSec = null
    var selectedQ = null
    var qIds = null
    for(var i=0;i<secIds.length;i++){
      selectedSec = secIds[i]
      qIds = Object.keys(questions.sections[selectedSec].questions)
      for(var j=0;j<qIds.length;j++){
        selectedQ = qIds[j]
        console.log("COPY", selectedSec, selectedQ);
        questions.sections[selectedSec].questions[selectedQ]['pageStyle'] = state.pageStyle
        questions.sections[selectedSec].questions[selectedQ]['qStyle'] = state.qStyle 
        questions.sections[selectedSec].questions[selectedQ]['buttonStyle'] = state.buttonStyle 
        var evenstyle = {}
        Object.assign(evenstyle, state.opStyle);
        evenstyle['backgroundColor'] = state.opStyle.backgroundColorEven
        var oddstyle = {}
        Object.assign(oddstyle, state.opStyle);
        oddstyle['backgroundColor'] = state.opStyle.backgroundColorOdd
        questions.sections[selectedSec].questions[selectedQ]['opStyle']=
          questions.sections[selectedSec].questions[selectedQ]['opStyle']?
            {...questions.sections[selectedSec].questions[selectedQ]['opStyle']}
          :
            {}
        questions.sections[selectedSec].questions[selectedQ]['opStyle']['even'] = evenstyle
        questions.sections[selectedSec].questions[selectedQ]['opStyle']['odd'] = oddstyle
      }
    }

    dispatch({type:'STYLE_SAVE_SUCCESS', data:questions})
  }
};

export const addNewQuestion=(versionId, newQuestionData, token)=>{
  return (dispatch) => {
    dispatch({type: 'LOADING_ADD_QUESTION'});
    fetch(backendUrl + "/surveyVersion/"+versionId+'/addQuestion', {
      method: 'post',
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(newQuestionData),
    
    })
    .then(response => {
      response.json().then((data)=>{
          if (response.status === 200) {
          dispatch({
            type: 'ADD_QUESTION_SUCCESS',
          });
        }
        else if (response.status === 403 || response.status === 401) {
          dispatch({
            type: "AUTHENTICATION_ERROR"
          });
        }
        else {
          dispatch({
            type: 'ADD_QUESTION_ERROR',
          });
        }
      })
    }
    )
    .catch(response => {
      dispatch({
        type: 'ADD_QUESTION_ERROR',
      });
    })
  };
}

export const resetQuestionAddStatus=()=>{
  return (dispatch) => {
    dispatch({type: 'RESET_QUESTION_ADD_STATUS'});
  }
}

export const changeMandatory=(status,qs, selectedSec, selectedQ, sid, vid, token )=>{
  return (dispatch) => {
    dispatch({type: 'CHANGE_MANDATORY_LOADING'});
    qs.sections[selectedSec].questions[selectedQ]['mandatory'] = status
    fetch(backendUrl
      +'/survey/'+sid+'/version/'+vid, {
        method: 'put',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(qs)
      }).then(response => response.json().then(data=>{
          if (response.status == 200) {
          dispatch({
            type: 'CHANGE_MANDATORY_SUCCESS', payload:qs
          });
       }else{
         dispatch({
           type: 'CHANGE_MANDATORY_ERROR',
         });
       }
      }))
  }
}

export const deleteQuestion=(svid, selectedSec, selectedQ, token )=>{
  return (dispatch) => {
    fetch(backendUrl
      +'/surveyVersion/'+svid+'/deleteQuestion', {
      method: 'post',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({sectionId:selectedSec, questionId:selectedQ})
    }).then(response => response.json().then(data=>{
        if (response.status == 200) {
        dispatch({
          type: 'DELETE_QUESTION_SUCCESS'
        });
      }else{
        dispatch({
          type: 'DELETE_QUESTION_ERROR',
        });
      }
    }))
    .catch(err => {
      console.error("error", err);
      dispatch({
        type: 'DELETE_QUESTION_ERROR',
      });
    })
  }
}

export const resetQuestionDeleteStatus=()=>{
  return (dispatch) => {
    dispatch({type: 'DELETE_QUESTION_STATUS_RESET'});
  }
}

export const applyRandom=(type, data)=>{
  return (dispatch) => {
    dispatch({type:'STYLE_SAVE_LOADING'});
    dispatch({type:'STYLE_SAVE_SUCCESS', data});
  }
}

export const changeQType=(data)=>{
  return (dispatch) => {
    dispatch({type:'STYLE_SAVE_LOADING'});
    dispatch({type:'STYLE_SAVE_SUCCESS', data});
  }
}

export const addNewOption=(data)=>{
  return (dispatch) => {
    dispatch({type:'STYLE_SAVE_LOADING'});
    dispatch({type:'STYLE_SAVE_SUCCESS', data});
  }
}

