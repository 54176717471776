import React from "react";
import "../stylesheets/common.css"

const ResetPasswordModal = ({resetPasswordId, resetPasswordMessage}) => {
  // console.log(users)
  return (
    <div id="reset-password-modal" className="modal">
        <div className="modal-content reset_pass">
            <h5>{resetPasswordMessage}</h5>
            <p>Your New Password is:&nbsp;<b>{resetPasswordId}</b></p>
        </div>
    </div>
  );
};

export default ResetPasswordModal;
