import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import Navbar from "../layout/Navbar";

export class ViewResult extends Component {
    state={
        user:[
            {
                name: "Ram Sen",
                roll: 101,
                mark: 39,
                status: "Pass"
            },
            {
                name: "Tulsi Gupta ",
                roll: 102,
                mark: 49,
                status: "Pass"
            },
            {
                name: "Biplob Saha",
                roll: 103,
                mark: 29,
                status: "Fail"
            },
        ]
    }
  render() {
    const user = this.props.auth.user;
    if (!user) return <Redirect to="/login" />;
    return (
      <div>
      <Navbar heading="Teacher" user={user}/>
        <div
          className="row"
          style={{
            height: 90,
            backgroundColor: "#bfbf0d",
            borderBottomLeftRadius: 55,
            // borderBottomRightRadius: 55,
          }}
        >
          <div style={{ marginLeft: 90 }}>
            <div className="col s4 m4 l4" style={{
                "margin-top": 20,
              }}>
              <select class="browser-default">
                <option value="" disabled selected>
                  Select Subject
                </option>
                <option value="1">Option 1</option>
                <option value="2">Option 2</option>
                <option value="3">Option 3</option>
              </select>
            </div>
            <div
              className="col s4 m4 l4"
              style={{
                "margin-top": 20,
              }}
            >
              <select class="browser-default" id="Semester">
                <option value="" disabled selected>
                  Select Test
                </option>
                <option value="1">Option 1</option>
                <option value="2">Option 2</option>
                <option value="3">Option 3</option>
              </select>
            </div>
            <div
              className="col s2 m2 l2"
              style={{
                "margin-top": 23,
                paddingLeft: "25%",
              }}
            >
              <a 
                class="btn-floating waves-effect waves-light btn"
              >
                <i class="material-icons">cloud_download</i>
              </a>
            </div>
          </div>
        </div>
        <div className="row" style={{ marginLeft: 170 }}>
          <div className="col s3 m3 l3">
            <b>Student</b>
          </div>
          <div className="col s3 m3 l3">
            <b>Roll</b>
          </div>
          <div className="col s3 m3 l3">
            <b>Mark</b>
          </div>
          <div className="col s3 m3 l3">
            <b>Status</b>
          </div>
        </div>
        <div>
          <ul
            className="collection"
            style={{
              backgroundColor: "rgb(255,255,255,0.5)",
              paddingTop: 15,
              paddingLeft: 70,
              // width:"60%",
              marginLeft: 90,
              marginRight: 90,
              boxShadow: "2px 20px 40px",
              height: "50vh",
              overflow: "auto",
            }}
          >
            {
              
              this.state.user.map((user) => (
                <div className="row">
                  <div className="col s3 m3 l3">
                    <b>{user.name}</b>
                  </div>
                  <div className="col s4 m4 l4">
                    <b>{user.roll}</b>
                  </div>
                  <div className="col s2 m2 l2">
                    <b>{user.mark}</b>
                  </div>
                  <div className="col s1 m1 l1 offset-s1 offset-m1 offset-l1">
                    {
                      user.status == "Pass" 
                      ? <i class="material-icons" style={{color:"limegreen"}}>check_circle</i>
                      :<i class="material-icons" style={{color:"red"}}>cancel</i>
                    }
                  </div>
                </div>
              ))
            }
          </ul>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
    return {
      auth: state.auth,
    };
  };

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ViewResult);
