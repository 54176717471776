import React from "react";
import ReactPlayer from "react-player";

export default function SpecificQuestion({
  selectedQ,
  questions,
  css,
  response,
}) {
  return (
    <div className={"col s12 " + css ? css : ""}>
      {!css ? (
        <div className="row">
          <div className="col s4">
            <span>Start: 12/10/2019 10:15:25</span>
          </div>
          <div className="col s4">
            <span>End: 12/10/2019 10:15:25</span>
          </div>
          <div className="col s4">
            <span>Duration: 10 min</span>
          </div>
        </div>
      ) : null}
      <div className="single-question col s12">
        <div className={"row question"}>
          {questions[selectedQ]&&questions[selectedQ].questionBodyType == "text" ? (
            <span>{questions[selectedQ].question.default}</span>
          ) : 
          questions[selectedQ]&&questions[selectedQ].questionBodyType == "image" ?
            <>
              <p>{questions[selectedQ].question.default}</p>
              <img style={{maxHeight:'10%', maxWidth:'80%'}}
              controls 
              // autoPlay
              src={questions[selectedQ].question.filePath} />
            </>
          :
          (
            <>
              <p>{questions[selectedQ].question.default}</p>
              <ReactPlayer
                controls="true"
                width="525px"
                url={" " + questions[selectedQ].question.filePath}
              />
            </>
          )}
        </div>
        <div className="row">
          {questions[selectedQ].questionType === "TEXT" ||
          questions[selectedQ].questionType === "DATETIME" ||
          questions[selectedQ].questionType === "NUMERIC" ? (
            <>
            <p>
              <span>Answer: </span>
              {response&&response[selectedQ]&&response[selectedQ].answer &&
                response[selectedQ].answer.map((answer, index) =>
                  answer.input ? (
                    <span>{answer.input}</span>
                  ) : (
                    <span>NOT ANSWRED</span>
                  )
                )}
            </p>
            <p>
              <span>Correct Answer: </span>
              {questions[selectedQ].answer &&
                questions[selectedQ].answer.map((answer, index) =>
                  answer.input ? (
                    <span>{answer.input}</span>
                  ) : (
                    <span>NOT ANSWRED</span>
                  )
                )}
            </p>
            </>
          ) : questions[selectedQ].questionType === "SINGLE" ||
            questions[selectedQ].questionType === "MULTIPLE" ||
            questions[selectedQ].questionType === "MIXED" ? (
            <div className="">
              {questions[selectedQ].options &&
                Object.values(questions[selectedQ].options).map(
                  (option, index) => (
                    <div className="" key={index}>
                      {
                         option.optionType=='text' ?
                          <p>{index + 1 + ". " + option.option.default}</p>
                         :
                         option.optionType=='image' ?
                             <>
                                 <p>{index+1+'. '+option.option.default}</p>
                                 <p style={{display:'inline'}}>
                                     <img style={{maxHeight:'10%', maxWidth:'80%'}}
                                     controls 
                                     // autoPlay
                                     src={option.option.filePath} />
                                 </p>
                             </>
                         :
                             option.optionType=='audio' ?
                                <>
                                  <p>{index+1+'. '+option.option.default}</p>
                                  <ReactPlayer
                                    controls="true"
                                    width="525px"
                                    url={" " + option.option.filePath}
                                  />
                                </>
                                 
                             :
                                 option.optionType=='video' ?
                                 <>
                                     <p>{index+1+'. '+option.option.default}</p>
                                     <p style={{display:'inline'}}>
                                         <video style={{maxHeight:'10%', maxWidth:'80%'}}
                                             controls 
                                             // autoPlay
                                             src={option.option.filePath} />
                                     </p>
                                 </>
                                 :
                                     null
                      }
                    </div>
                  )
                )}
              <>
              <p>
                <span>Answer: </span>
                {response&&response[selectedQ]&&response[selectedQ].answer &&
                  response[selectedQ].answer.map((answer, index) =>
                    answer.optionId ? (
                      <span>{answer.optionId + ","}</span>
                    ) : (
                      <span>NOT ANSWRED</span>
                    )
                  )}
              </p>
              <p>
                <span>Correct Answer: </span>
                {questions[selectedQ].answer &&
                  questions[selectedQ].answer.map((answer, index) =>
                    answer.optionId ? (
                      <span>{answer.optionId + ","}</span>
                    ) : (
                      <span>NOT ANSWRED</span>
                    )
                  )}
              </p>
              </>
            </div>
          ) : questions[selectedQ].questionType === "RANK" ? (
            <div className="">
              {response&&response[selectedQ]&&response[selectedQ]&&questions[selectedQ].options &&
                Object.values(questions[selectedQ].options).map(
                  (option, index) => (
                    <div className="" key={index}>
                      <p>{index + 1 + ". " + option.option.default}</p>
                    </div>
                  )
                )}
              <>
              <p>
                <span>Answer: </span>
                {response&&response[selectedQ]&&response[selectedQ].answer &&
                  response[selectedQ].answer.map((answer, index) =>
                    answer.optionId ? (
                      <span>{answer.optionId + "->"}</span>
                    ) : (
                      <span>NOT ANSWRED</span>
                    )
                  )}
              </p>
              <p>
                <span>Correct Answer: </span>
                {questions[selectedQ].answer &&
                  questions[selectedQ].answer.map((answer, index) =>
                    answer.optionId ? (
                      <span>{answer.optionId + "->"}</span>
                    ) : (
                      <span>NOT ANSWRED</span>
                    )
                  )}
              </p>
              </>
            </div>
          ) : questions[selectedQ].questionType === "GRID" ? (
            <div className="">
              {questions[selectedQ].groups &&
                Object.values(questions[selectedQ].groups).map(
                  (groups, index) => (
                    <div className="" key={index}>
                      <p>
                        {groups.group.default}

                        {questions[selectedQ].options &&
                          Object.values(questions[selectedQ].options).map(
                            (option, index) => (
                              <div className="" key={index}>
                                <p>{index + 1 + ". " + option.option.default}</p>
                              </div>
                            )
                          )}
                      </p>
                    </div>
                  )
                )}
              <>
              <p>
                <span>Answer: </span>
                {response&&response[selectedQ]&&response[selectedQ].answer &&
                  response[selectedQ].answer.map((answer, index) =>
                    answer.optionId ? (
                      <span>{answer.optionId + ","}</span>
                    ) : (
                      <span>NOT ANSWRED</span>
                    )
                  )}
              </p>
              <p>
                <span>Correct Answer: </span>
                {questions[selectedQ].answer &&
                  questions[selectedQ].answer.map((answer, index) =>
                    answer.optionId ? (
                      <span>{answer.groupId+'.'+answer.optionId + ", "}</span>
                    ) : (
                      <span>NOT ANSWRED</span>
                    )
                  )}
              </p>
              </>
            </div>
          ) : questions[selectedQ]&&questions[selectedQ].questionType === "MEDIA" ? (
            <p>
              <span>Answer:</span>
              {
                (response[selectedQ].answer = {} ? (
                  <span>NOT ANSWRED</span>
                ) : (
                  <ReactPlayer
                    controls="true"
                    width="525px"
                    url={" " + response[selectedQ + 1].answer}
                  />
                ))
              }
            </p>
          ) : null}
        </div>
      </div>
    </div>
  );
}
