import React from 'react'
import TextViewer from './TextViewer'

export default function OptionViewer({option, lang, index, click, data}) {
    // console.log(option)
    return (
        <>
            {
                option.optionType=='text' ?
                    <span onClick={()=>click? click(data,index) : null}><TextViewer question={option} lang={lang} type='option' /> </span>
                :
                    option.optionType=='image' ?
                        <p>
                            <span></span>
                            <p className='center-object'>
                                <img className='img-vid-positioner'
                                src={option.option?option.option.filePath:''} />
                            </p>
                            <p>{option.option?
                                <TextViewer question={option} lang={lang} type='option' /> 
                                :null
                                }
                            </p>
                        </p>
                    :
                        option.optionType=='audio' ?
                            option.option
                        :
                            option.optionType=='video' ?
                            <p>
                                <span></span>
                                <p className='center-object'>
                                    <video className='img-vid-positioner'
                                        controls 
                                        // autoPlay
                                        src={option.option?option.option.filePath:null} />
                                </p>
                                <p>{option.option?
                                    <TextViewer question={option} lang={lang} type='option' /> 
                                    :null
                                    }
                                </p>
                            </p>
                            :
                                null
            }
        </>
    )
}
