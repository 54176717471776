import React from "react";
import { backendUrl } from "../../config/api";

const ItemList = ({ users, HandleCheck, SelectedUser, EditUser, disabled, checked, resetPassword }) => {
  const env = "server";
  return (
    <div>
      <form action="#">
        <div className="row list-item-header">
          <div className="col l1 center">
            {/* <label>
              <input type="checkbox" />
              <span></span>
            </label> */}
          </div>
          <div className="col l3">Name</div>
          <div className="col l3">Email</div>
          <div className="col l2">Role</div>
          <div className="col l3">Action</div>
        </div>
        <div className="row">
          {users && users.map((user,index) => (
            <div className="row list-item" key={user.id}>
              <div className="col l1 center">
                <label>
                  <input
                    key={index}
                    type="checkbox"
                    id={user.id}
                    onChange={(e) => HandleCheck(e, user.id, index)}
                    checked={checked[index] || false}
                    disabled={!checked[index] && disabled}
                  />
                  <span></span>
                </label>
              </div>
              <div className="col l1">
                <img
                  className="circle"
                  src={
                    user && user.photo!="null"&&user.photo?
                        user && user.photo
                      : require("../../image/image_unavailable.jpg")
                  }
                  alt="user"
                  height="30"
                  width="30"
                  style={{ marginRight: 5 }}
                />
              </div>
              <div className="col l2">
                {user.userName}
              </div>
              <div className="col l3">{user.email}</div>
              <div className="col l3">{user.Role.name}</div>
              {user.check ? (
                <div className="col l2">
                {/*  <button
                    data-target="delete-user-modal"
                    id={user.id}
                    className="action-button red-text"
                    onClick={SelectedUser}
                  >
                    Delete Account
                  </button> 
                
              
                */}
                  <button 
                    data-target="delete-user-modal"
                    id={user.id}
                    className="action-button"
                    onClick={SelectedUser}
                  >
                    Deactivate Account
                  </button>
                </div>
              ) : (
                <div className="col l2">
                  <button
                    data-target="edit-user-modal"
                    className="modal-trigger action-button"
                    id={user.id}
                    onClick={EditUser}
                  >
                    Modify User
                  </button>
                  <button 
                    id={user.id}
                    className="modal-trigger action-button" 
                    data-target="reset-password-modal"
                    onClick={resetPassword}>Reset Password</button>
                </div>
              )}
            </div>
          ))}
        </div>
      </form>
    </div>
  );
};

export default ItemList;
