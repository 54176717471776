import React from 'react'
import '../stylesheets/qcList.css';

const QuestionListItem = ({questionid,sid, qid, q, type, onDetails, cssType, designer, typeChange}) => {
    // console.log(q,qtype);
    return (
        <div className={"valign-wrapper " + cssType} onClick={()=>{onDetails(sid, qid)}}>
            <div className="col s1">
                <span>{questionid}</span>
            </div>
            
            <div className="col s8">
                 <span>{
                   q?q.default:null
                }</span>
            </div>

            <div className={"col s2"+designer&&(type== 'SINGLE'|| type == 'MULTIPLE')? "input-field":''}>
            {
                designer&&
                (type== 'SINGLE'
                || type == 'MULTIPLE')?
                    <select onChange={(e)=>typeChange(sid,qid, e.target.value)} className="browser-default select-font" value={type}>
                        <option value='SINGLE'>SINGLE</option>
                        <option value='MULTIPLE'>MULTIPLE</option>
                    </select>
                :<span>{type}</span>

                }
            </div>

            {
                cssType != "response-list-header"?
                    // <button className="waves-effect waves-light btn-small response-button right col s2" id={id} onClick={onDetails}>
                    //     Details
                    // </button>
                    <div className="col s1">
                        <i class="material-icons">navigate_next</i>
                    </div>
                :
                    <div className="col s2">
                        <span>Action</span>
                    </div>
            }
            
        </div>
    )
}

export default QuestionListItem