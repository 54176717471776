import React, { Component, version } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import NavBar from "../layout/Navbar";
import TestSurveyModal from "../layout/TestSurveyModal";
import M from "materialize-css";
import moment from "moment";
// import fs from '';

import QuestionListItem from "../qc/QcQuestionList";
import SpecificQuestion from "./SpecificQuestion";
import EditableQuestion from "./EditableQuestion";
import Style from "./Style";


import {saveStyle, loadVersion, save, 
    writeSurveyChanges, getLanguages, 
    getLanguageForm, uploadLanguageFile, 
    getVersions, saveAnswer, saveLogic, 
    logicSaveReset, uploadDesignFile, 
    uploadSequenceComplete, uploadSurveyImage, 
    resetSurveySaveStatus, copyStyle, addNewQuestion, 
    resetQuestionAddStatus, changeMandatory, 
    deleteQuestion, resetQuestionDeleteStatus, 
    applyRandom, changeQType, addNewOption} from '../../store/actions/designActions'
import EditModal from '../layout/EditModal';
import ModalAddLogic from '../layout/ModalAddLogic';
import LanguageModal from '../layout/LanguageModal';
import {buildLogic, displayLogicEvaluator, skipLogicEvaluator} from "../../utils/logicBuild";
import { resetAlert, setAlert } from "../../store/actions/MessageAction";
import DeleteModal from "../layout/DeleteModal";
import AddQuestion from "./AddQuestion";


class DesignSurvey extends Component {
    state = {
        menuType:0,
        surveyStatus:'Design',
        selectedQ:null,
        selectedSec:null,
        selectedType:'question',
        editText:null,
        orientation:'potrait',
        selectedVersion:null,

        logic:'skip',
        logics:{skip:[], display:[], pipe:[]},
        repeat:false,
        add:false,

        languageFile:null,
        selectedLanguage:"default",
        
        qStyle :{
            fontWeight:null,
            fontStyle:null,
            fontFamily: null,
            textDecoration: null,
            fontSize:null,
            backgroundColor:null,
            color:null,
            textAlign:'left',
            marginBottom:null
        },
        opStyle :{
            fontWeight:null,
            fontStyle:null,
            fontFamily: null,
            textDecoration: null,
            fontSize:null,
            backgroundColorEven:null,
            backgroundColorOdd:null,
            color:null,
            textAlign:'left',
            marginBottom:null
        },
        pageStyle :{
            backgroundColor:null,
            height:'100%'
        },
        buttonStyle:{
            button:'default',
            backgroundColor:null,
            color:null
        },

        languageModal:"download",
        newLanguageCode:null,

        logicOperandSec:null,
        logicOperandQ:null,
        operator:null,
        logicResultSec:null,
        logicResultQ:null,
        logicAnswer:null,
        logicBlockType:null,
        logicBlockOption:null,

        blockedOptions:[],
        blockedGroups:[],

        input: "",
        optionId: "",
        multiple:{
        multipleId: null
        },
        grid:{},
        mix:{
            single:{},
            multiple:{}
        },

        designFiles:{},
        versionStatus:'Design',
        statusReflected:false,
        versionLoadReflected:false,

        surveyImage:null,
        imageUploadReflected:true,
        editMetaData:null,
        languageAddReflected:true,

        tempStatus:null,
        dummyUpload:false,
        dummyFile:false,

        newQuestionType:"",
        newQuestionBodyType:"",
        newQuestionText:null,
        newOptionsData:[],
        newGroupsData:[],
        newSection: false,

        selectedTextBold:{status:false, start:null, end:null},
        selectedTextItalic:{status:false, start:null, end:null},
        selectedTextColor:{status:false, color:null, start:null, end:null},

        terminate:false,
        deleteQ:false,

        extraOperand:[],
        newOption:null,
    }

    componentDidMount=()=> {
        var elems = document.querySelectorAll("select");
        M.FormSelect.init(elems, {});
        elems = document.querySelectorAll(".modal");
        M.Modal.init(elems, {onOpenEnd:()=>{M.updateTextFields()}, onCloseEnd:()=>{this.setState({extraOperand:[]});this.props.ResetAlert()}});
        if (this.props.surveyInfo)
        this.setState({
            ...this.state,
            surveyStatus: this.props.surveyInfo.status,
        });
        var user = this.props.auth.user
        if (user)
        {
            this.props.GetLanguages(
            this.props.versionId,
            user.accessToken
            );
            this.props.GetVersions(this.props.id, user.accessToken)
        }
    }
    
    componentDidUpdate=()=>{
        var modal = document.getElementById("add-logic-modal")

        var instance = null
        if(modal)
            instance = M.Modal.getInstance(modal);

        if(instance&&this.props.logicSaveSuccess&&instance.isOpen){
            instance.close()
            this.props.LogicSaveReset()
            this.setState({
                ...this.state,
                logicOperandSec:null,
                logicOperandQ:null,
                operator:null,
                logicResultSec:null,
                logicResultQ:null,
                logicAnswer:null,
                logicBlockType:null,
                logicBlockOption:null,
                logics:{skip:[], display:[], pipe:[]},
                repeat:false,
                add:false,
            })
        }

        if(this.props.uploadSuccess){
            this.setState({...this.state, 
                designFiles:{
                    ...this.state.designFiles, 
                    [this.props.uploadId]:{
                        ...this.state.designFiles[this.props.uploadId],
                        status:"done"
                    }
                }
            })
            this.commonUploader()
        }

        if(this.props.uploading&&this.state.designFiles[this.props.uploadId].status!="loading"){
            console.log("set loading", this.props.uploadId);
            this.setState({...this.state, 
                designFiles:{
                    ...this.state.designFiles, 
                    [this.props.uploadId]:{
                        ...this.state.designFiles[this.props.uploadId],
                        status:"loading"
                    }
                }
            })
        }

        if (this.props.versionInfo && !(this.state.statusReflected)){
            this.setState({
                ...this.state,
                versionStatus:this.props.versionInfo.status,
                surveyStatus:this.props.surveyInfo.status,
                statusReflected:true,
            })
        }

        if (this.props.versionLoad && !(this.state.versionLoadReflected)){
            this.setState({
                ...this.state,
                versionStatus:this.props.versionInfo.status,
                surveyStatus:this.props.surveyInfo.status,
                statusReflected:true,
                versionLoadReflected:true
            })
        }

        if (this.props.imageLoadSuccess && !(this.state.imageUploadReflected)){
            this.setState({
                imageUploadReflected:true,
            })
            this.props.LoadVersion(this.props.id, this.props.versionId, this.props.auth.user.accessToken)
        }
        if (this.props.addLanguageSuccess && !(this.state.languageAddReflected)){
            this.setState({
                languageAddReflected:true,
            })
            var modal = document.getElementById("language-modal")

            var instance = null
            if(modal)
                instance = M.Modal.getInstance(modal);

            if(instance&&instance.isOpen){
                instance.close()
            }
            this.props.LoadVersion(this.props.id, this.props.versionId, this.props.auth.user.accessToken)
            this.props.GetLanguages(
                this.props.versionId,
                this.props.auth.user.accessToken
                );
        }

        if(this.props.saveSurveySuccess){
            this.props.SetAlert('success', "Survey changes saved")
            this.props.LoadVersion(this.props.id, this.props.versionId, this.props.auth.user.accessToken)
            this.props.ResetSurveySaveStatus();
        }
        if(this.props.saveSurveyError){
            this.props.SetAlert('error', "Error in survey save")
            this.props.ResetSurveySaveStatus();
        }

        if(this.props.addQuestionSuccess){
            // this.props.SetAlert('success', "Question Added")
            var elem = document.getElementById('add-question-modal')
            var instance = M.Modal.getInstance(elem);
            if(instance.isOpen){
                instance.close();
            }

            this.props.LoadVersion(this.props.id, this.props.versionId, this.props.auth.user.accessToken)
            // this.props.ResetQuestionAddStatus();
        }
        if(this.props.addQuestionError){
            this.props.SetAlert('error', "Error in add question")
            this.props.ResetQuestionAddStatus();
        }
        
        if(this.props.deleteQSuccess){
            this.props.SetAlert('success', "Question Deleted")
            this.setState({selectedSec:null, selectedQ:null,deleteQ:false})
            this.props.LoadVersion(this.props.id, this.props.versionId, this.props.auth.user.accessToken)
            this.props.ResetQuestionDeleteStatus();
        }
        if(this.props.deleteQError){
            this.props.SetAlert('error', "Error in question delete")
            this.props.ResetQuestionDeleteStatus();
        }
    }

    changeMenu = (e) => {
        var val = this.state.menuType
        if(e.target.id == 'leftClick'){
            val = ((Number(this.state.menuType) - 1) +3) % 3 ;
            this.setState({...this.state, menuType:val})
        }
        else{
            val = ((Number(this.state.menuType) - 1) +3) % 3 ;
            this.setState({...this.state, menuType:val})
        }
    }

    onQSelect = (sid, qid) =>{        
        // console.log("here",e.target);
        // var sid = (e.target.id).split(".")[0]
        // var qid = (e.target.id).split(".")[1]
        this.setState({
            ...this.state, selectedSec:Number(sid), selectedQ:Number(qid),selectedForMod:sid+"."+qid
        })
        // console.log("here",this.state);

    }

    onClick = (e, qid, eid, type) => {
        console.log(e.target, qid, eid);
        this.setState({...this.state, selectedForMod:qid, selectedForModOp:eid, selectedType:type})
    }

    HandleQuestionStyleChange =(e) => {
        console.log(e.target.id, e.target.value);
        var style = {
            fontWeight:'bold',
            fontStyle:'italic',
            textDecoration:'underline',
        }
        var align = {
            leftAlign:'left',
            rightAlign:'right',
            centerAlign:'center',
        }
    }

    changeMenu = (e) => {
        var val = this.state.menuType;
        if (e.target.id == "leftClick") {
        val = (Number(this.state.menuType) - 1 + 3) % 3;
        this.setState({ ...this.state, menuType: val });
        } else {
        val = (Number(this.state.menuType) - 1 + 3) % 3;
        this.setState({ ...this.state, menuType: val });
        }
    };

    HandleQuestionStyleChange = (e) => {
        console.log(e.target.id, e.target.value);
        var style = {
        fontWeight: "bold",
        fontStyle: "italic",
        textDecoration: "underline",
        };
        var align = {
        leftAlign: "left",
        rightAlign: "right",
        centerAlign: "center",
        };

        if (e.target.id == "fontSize" || e.target.id == "marginBottom")
        this.setState({
            ...this.state,
            qStyle: { ...this.state.qStyle, [e.target.id]: Number(e.target.value) },
        });
        else if (
        e.target.id == "fontWeight" ||
        e.target.id == "fontStyle" ||
        e.target.id == "textDecoration"
        )
        this.setState({
            ...this.state,
            qStyle: { ...this.state.qStyle, [e.target.id]: style[e.target.id] },
        });
        else if (e.target.id == "fontFamily")
        this.setState({
            ...this.state,
            qStyle: { ...this.state.qStyle, [e.target.id]: e.target.value },
        });
        else if (
        e.target.id == "leftAlign" ||
        e.target.id == "rightAlign" ||
        e.target.id == "centerAlign"
        )
        this.setState({
            ...this.state,
            qStyle: { ...this.state.qStyle, textAlign: align[e.target.id] },
        });
        else
        this.setState({
            ...this.state,
            qStyle: { ...this.state.qStyle, [e.target.id]: e.target.value },
        });
    };

    CompleteStatusChange=()=>{
        var value = this.state.tempStatus
        this.setState({ ...this.state, surveyStatus: value, tempStatus:null});
        var token = this.props.auth.user.accessToken;
        var surveyId = this.props.id;
        var versionId = this.props.versionId;
        
        var elem = document.getElementById("delete-user-modal")
        if(elem){
            var instance = M.Modal.getInstance(elem);
            if(instance && instance.isOpen){
                instance.close();
            }
        }
        this.props.SaveChanges(
            { surveyInfo: { status: value }, surveyVersionInfo:{status:value} },
            surveyId,
            versionId,
            token
        );
        this.props.LoadVersion(this.props.id, this.state.selectedVersion, token)
    }

    HandleStatusChange = async (e) => {
        console.log(
        "%c status according to the dropdown",
        "background: #222; color: #bada55",
        e.target.value
        );
        if (e.target.value == "Test") {
            var elem = document.getElementById("test-survey-modal");
            var instances = M.Modal.getInstance(elem);
            instances.open();
            this.setState({ ...this.state, [e.target.id]: e.target.value });
            if(this.props.auth.user)
            this.props.SaveChanges(
                { surveyInfo: { status: e.target.value }, surveyVersionInfo:{status:e.target.value} },
                this.props.id,
                this.props.versionId,
                this.props.auth.user.accessToken
            );
        } else {
            this.setState({tempStatus:e.target.value})
            var elem = document.getElementById("delete-user-modal");
            var instances = M.Modal.getInstance(elem);
            instances.open();
        }
    };

    
    
    HandleVersionStatusChange=(e)=>{
        console.log(
            "%c status according to the dropdown",
            "background: #222; color: #bada55",
            e.target.value
            );
        if (e.target.value == "Test") {
            if (this.state.surveyStatus !="Test"&&this.state.surveyStatus !="Live"){
                console.log("WRONG");
                this.props.SetAlert('error', "can not set survey version "+e.target.value +" when survey is in "+this.state.surveyStatus)
            }
            else{
                console.log("right");
                var elem = document.getElementById("test-survey-modal");
                var instances = M.Modal.getInstance(elem);
                instances.open();
                this.setState({ ...this.state, [e.target.id]: e.target.value });
                
                var token = this.props.auth.user.accessToken;
                var surveyId = this.props.id;
                var versionId = this.props.versionId;
                this.props.SaveChanges(
                    { surveyVersionInfo: { status: e.target.value } },
                    surveyId,
                    versionId,
                    token
                );
            }
        } else {
            if ((this.state.surveyStatus !="Live" &&e.target.value=="Live")||
            (this.state.surveyStatus !="Live" &&e.target.value=="Completed")){
                this.props.SetAlert('error', "can not set survey version "+e.target.value +" when survey is in "+this.state.surveyStatus)
            }
            else{
                this.setState({ ...this.state, [e.target.id]: e.target.value });
                var token = this.props.auth.user.accessToken;
                var surveyId = this.props.id;
                var versionId = this.props.versionId;
                this.props.SaveChanges(
                    { surveyVersionInfo: { status: e.target.value } },
                    surveyId,
                    versionId,
                    token
                    );
            }
        }

    }

    HandleTestSurvey = (e) => {
       this.props.history.push("/survey/" +
        this.props.surveyInfo.id +
        "/version/" +
        this.props.versionId +
        "/test");
        // win.focus();
    };
    HandleOptionStyleChange = (e) => {
        console.log(e.target.id, e.target.value);
        var style = {
        fontWeight: "bold",
        fontStyle: "italic",
        textDecoration: "underline",
        };
        var align = {
        leftAlign: "left",
        rightAlign: "right",
        centerAlign: "center",
        };

        if (e.target.id == "fontSize" || e.target.id == "marginBottom")
        this.setState({
            ...this.state,
            opStyle: {
            ...this.state.opStyle,
            [e.target.id]: Number(e.target.value),
            },
        });
        else if (
        e.target.id == "fontWeight" ||
        e.target.id == "fontStyle" ||
        e.target.id == "textDecoration"
        )
        this.setState({
            ...this.state,
            opStyle: { ...this.state.opStyle, [e.target.id]: style[e.target.id] },
        });
        else if (
        e.target.id == "leftAlign" ||
        e.target.id == "rightAlign" ||
        e.target.id == "centerAlign"
        )
        this.setState({
            ...this.state,
            opStyle: { ...this.state.opStyle, textAlign: align[e.target.id] },
        });
        else if (e.target.id == "fontFamily")
        this.setState({
            ...this.state,
            opStyle: { ...this.state.opStyle, [e.target.id]: e.target.value },
        });
        else
        this.setState({
            ...this.state,
            opStyle: { ...this.state.opStyle, [e.target.id]: e.target.value },
        });
    };

    HandlePageStyleChange = (e) => {
        console.log(e.target.id, e.target.value);
        var style = {
            buttonStyle: "button",
            buttonColor: "backgroundColor",
            color: "color",
        }

        // if(e.target.id=="fontSize")
        //     this.setState({...this.state, qStyle:{...this.state.qStyle, [e.target.id]:Number(e.target.value)}})
        if (
        e.target.id == "buttonStyle" ||
        e.target.id == "buttonColor" ||
        e.target.id == "color"
        ) {
        this.setState({
            ...this.state,
            buttonStyle: {
            ...this.state.buttonStyle,
            [style[e.target.id]]: e.target.value,
            },
        });
        }

        // if(e.target.id=="fontSize")
        //     this.setState({...this.state, opStyle:{...this.state.opStyle, [e.target.id]:Number(e.target.value)}})
        // if(e.target.id == "fontWeight" || e.target.id == "fontStyle"|| e.target.id == "textDecoration")
        //     this.setState({...this.state, opStyle:{...this.state.opStyle, [e.target.id]: style[e.target.id]}})
        // else if(e.target.id == "fontFamily")
        //     this.setState({...this.state, opStyle:{...this.state.opStyle,[e.target.id]:e.target.value}})
        else if(e.target.id == "contentCopy"){
            this.props.CopyStyle(this.state, this.props.questions)
        }
        else
        this.setState({
            ...this.state,
            pageStyle: { ...this.state.pageStyle, [e.target.id]: e.target.value },
        });
    };

    OnStyleSave = () => {
      console.log(this.state.selectedSec,
        this.state.selectedQ,
        this.state,
        this.props.questions)
        this.props.SaveStyle(
        this.state.selectedSec,
        this.state.selectedQ,
        this.state,
        this.props.questions
        );
    };


    OnDefaultTheme = (e) => {
        // let data = JSON.stringify(this.props.questions);
        // this.props.Write(data);
    };

    OnAdd = (e) => {
        console.log("added", this.state, e.target);
        var q = this.props.questions.sections[this.state.logicOperandSec].questions[this.state.logicOperandQ]
        if (this.state.logic == "skip") {
            if (e.target.id == "and")
                this.setState({
                ...this.state,
                repeat: false,
                add: false,
                logics: {
                    ...this.state.logics,
                    skip: [
                    ...this.state.logics.skip,
                    { type: "operator", value: "AND" },
                    buildLogic(q, this.state, "skip"),
                    ],
                },
                });
            else if (e.target.id == "or")
                this.setState({
                ...this.state,
                repeat: false,
                add: false,
                logics: {
                    ...this.state.logics,
                    skip: [
                    ...this.state.logics.skip,
                    { type: "operator", value: "OR" },
                    buildLogic(q, this.state, "skip"),
                    ],
                },
                });
            else
                this.setState({
                ...this.state,
                repeat: false,
                add: false,
                logics: {
                    ...this.state.logics,
                    skip: [
                    ...this.state.logics.skip,
                    buildLogic(q, this.state, "skip"),
                    ],
                },
                });
        } 
        else if (this.state.logic == "display") {
            if (e.target.id == "and")
                this.setState({
                ...this.state,
                repeat: false,
                add: false,
                logics: {
                    ...this.state.logics,
                    display: [
                    ...this.state.logics.display,
                    { type: "operator", value: "AND" },
                    buildLogic(q, this.state, "display"),
                    ],
                },
                });
            else if (e.target.id == "or")
                this.setState({
                ...this.state,
                repeat: false,
                add: false,
                logics: {
                        ...this.state.logics,
                        display: [
                            ...this.state.logics.display,
                            { type: "operator", value: "OR" },
                            buildLogic(q, this.state, "display"),
                        ],
                    },
                });
            else
                this.setState({
                    ...this.state,
                    repeat: false,
                    add: false,
                    logics: {
                        ...this.state.logics,
                        display: [
                        ...this.state.logics.display,
                        buildLogic(q, this.state, "display"),
                        ],
                    },
                });
        } 
        else if (this.state.logic == "pipe") {
            if (e.target.id == "and")
                this.setState({
                ...this.state,
                repeat: false,
                add: false,
                logics: {
                    ...this.state.logics,
                    pipe: [
                    ...this.state.logics.pipe,
                    { type: "operator", value: "AND" },
                    buildLogic(q, this.state, "pipe"),
                    ],
                },
                });
            else if (e.target.id == "or")
                this.setState({
                ...this.state,
                repeat: false,
                add: false,
                logics: {
                    ...this.state.logics,
                    pipe: [
                    ...this.state.logics.pipe,
                    { type: "operator", value: "OR" },
                    buildLogic(q, this.state, "pipe"),
                    ],
                },
                });
            else
                this.setState({
                ...this.state,
                repeat: false,
                add: false,
                logics: {
                    ...this.state.logics,
                    pipe: [
                    ...this.state.logics.pipe,
                    buildLogic(q, this.state, "pipe"),
                    ],
                },
                });
        }
    };

    OnMoreOperand = (e) => {

        this.setState({extraOperand:[
            ...this.state.extraOperand,
            {id:this.state.extraOperand.length, operator:null, logicOperandSec:null, logicOperandQ:null}
        ] });
    };

    HandleExtraOperandChange=(e)=>{
        var type = e.target.id.split('-')[0]
        var index = e.target.id.split('-')[1]
        var newData = this.state.extraOperand
        newData[index][type]=e.target.value
        this.setState({
            extraOperand:newData
        })
    }

    DeleteExtraOperand=(e)=>{
        var type = e.target.id.split('-')[0]
        var index = e.target.id.split('-')[1]
        var newData = this.state.extraOperand.filter(data=>data.id!=index)
        this.setState({
            extraOperand:newData
        })
    }
    OnNewLogic = (e) => {
        // console.log("click", this.state, e.target);
        this.setState({ ...this.state, add: !this.state.add });
    };

    HandleLogicSelect=(e)=>{
        var l = this.state.logics
        var id = Number( e.target.id.split("-")[1])
        var type = e.target.id.split("-")[0]
        console.log("logics", e.target.id, e.target.checked, id, type);
        if(type == "skip"){
            console.log("logics", l.skip[id]);
            l.skip[id].checked = e.target.checked 
            this.setState({...this.state, logics:l})
        }
        else if(type == "display"){
            l.display[id].checked = e.target.checked 
            this.setState({...this.state, logics:l})
        }
        if(type == "pipe"){
            l.pipe[id].checked = e.target.checked 
            this.setState({...this.state, logics:l})
        }
        
    }

    OnMerge=(e)=>{
        var newLogics = []
        var mergedLogic = ""
        var mergedObject = null
        var ommit = []
        for (var i=0;i<this.state.logics.skip.length; i++){
            var l = this.state.logics.skip[i]
            if (l.checked){
                
                if(i>0 && this.state.logics.skip[i-1].type == "operator")
                {
                    mergedLogic +=l.value
                    if (mergedObject){
                        mergedObject.mainValue = mergedObject.mainValue.concat(this.state.logics.skip[i-1])
                        mergedObject.mainValue = mergedObject.mainValue.concat(l.mainValue)
                        ommit.push(i)
                        ommit.push(i-1)
                    }
                    else{
                        mergedObject=l
                        ommit.push(i)
                    }
                }
                else{
                    mergedLogic +=l.value
                    if(mergedObject){
                        mergedObject.mainValue = mergedObject.mainValue.concat(l.mainValue)
                    }
                    else{
                        mergedObject = l
                    }
                    ommit.push(i)
                }
            }
        }
        console.log("OMIIT", ommit, "merge", mergedLogic);
        for (var i=0;i<this.state.logics.skip.length; i++){
            if(ommit.includes(i)){
                console.log(i);
                continue
            }
            else{
                newLogics.push(this.state.logics.skip[i])
            }
        }
        if(mergedLogic!="")
        newLogics.push(mergedObject)
        this.setState({...this.state, logics:{...this.state.logics, skip:newLogics}})

        newLogics = []
        mergedLogic = ""
        mergedObject = null
        ommit = []
        for (var i=0;i<this.state.logics.display.length; i++){
            var l = this.state.logics.display[i]
            if (l.checked){
                
                if(i>0 && this.state.logics.display[i-1].type == "operator")
                {
                    mergedLogic +=l.value
                    if (mergedObject){
                        mergedObject.mainValue = mergedObject.mainValue.concat(this.state.logics.display[i-1])
                        mergedObject.mainValue = mergedObject.mainValue.concat(l.mainValue)
                        ommit.push(i)
                        ommit.push(i-1)
                    }
                    else{
                        mergedObject=l
                        ommit.push(i)
                    }
                }
                else{
                    mergedLogic +=l.value
                    if(mergedObject){
                        mergedObject.mainValue = mergedObject.mainValue.concat(l.mainValue)
                    }
                    else{
                        mergedObject = l
                    }
                    ommit.push(i)
                }
            }
        }
        console.log("OMIIT", ommit, "merge", mergedLogic);
        for (var i=0;i<this.state.logics.display.length; i++){
            if(ommit.includes(i)){
                console.log(i);
                continue
            }
            else{
                newLogics.push(this.state.logics.display[i])
            }
        }
        if(mergedObject){
            newLogics.push(mergedObject)
            this.setState({...this.state, logics:{...this.state.logics, display:newLogics}})
        }
    }

    OnDeleteLogic=(e)=>{
        var logType = e.target.id.split('-')[0]
        var index = e.target.id.split('-')[1]
        console.log("DELETE...", logType, index);
        var data = this.state.logics[logType]
        var newData = []
        for(var i=0;i<data.length;i++){
            if(i==index){
                if(i==0){
                    i+=1
                }
                else{
                    newData.pop()
                }
            }
            else{
                newData.push(data[i])
            }
        }
        this.setState({
            logics:{
                ...this.state.logics,
                [logType]:newData
            }
        })
    }

    OnSaveLogic=(e)=>{
        var flag =false
        this.state.logics.skip.map(l =>{
            if(l.type == "operator"){
                flag = true
            }
        })

        this.state.logics.display.map(l =>{
            if(l.type == "operator"){
                flag = true
            }
        })

        if(flag){
            alert('There are unmerged logics with operators. Either merge or use NA type operators for independent logics.')
        }
        else
        this.props.SaveLogic(this.state.selectedSec, this.state.selectedQ, this.state.logics)
    }
  OnEdit = (e, type, data) => {
    console.log("On EDT", type, data);
    var q = this.props.questions.sections[this.state.selectedSec].questions[this.state.selectedQ]
    if (type == "question") { 
        this.setState({
            editText:q.question?q.question[this.state.selectedLanguage]:'',
            editMetaData:{type},
        })
    } 
    else if(type == 'option') {
        // console.log(q.options[data.optionId].option.default);
        this.setState({
            editText:q.options[data.optionId].option?q.options[data.optionId].option[this.state.selectedLanguage]:'' ,
            editMetaData:{type, optionId:data.optionId},
        })
    }
    else if(type == 'group') {
        this.setState({
            editText:q.groups[data.groupId].group?q.groups[data.groupId].group[this.state.selectedLanguage]:'',
            editMetaData:{type, groupId:data.groupId},
        })
    }
    var elem = document.getElementById("edit-modal");
    var instances = M.Modal.getInstance(elem);
    instances.open();
  };

  OnEditSave = (e) => {
    e.preventDefault();
    console.log("here");
    this.props.Save(this.state, this.props.questions);
    var elem = document.getElementById("edit-modal");
    var instances = M.Modal.getInstance(elem);
    this.setState({
        selectedTextBold:{status:false, start:null, end:null},
        selectedTextItalic:{status:false, start:null, end:null},
        selectedTextColor:{status:false, color:null, start:null, end:null},
    })
    instances.close();
};

  OnFinalSave = (e) => {
    var token = this.props.auth.user.accessToken;
    var surveyId = this.props.id;
    var versionId = this.props.versionId;
    if (this.state.surveyStatus == "Design") {
      this.props.WriteChanges(
        { surveyVersionInfo: { questionnaire: this.props.questions } },
        surveyId,
        versionId,
        token
      );
    } else if (this.state.surveyStatus == "Live") {
        if (this.state.versionStatus == "Design") {
            this.props.WriteChanges(
                { surveyVersionInfo: { questionnaire: this.props.questions } },
                surveyId,
                versionId,
                token
              );
        }
        else if (this.state.versionStatus == "Test") {
            console.log("right");
            var elem = document.getElementById("test-survey-modal");
            var instances = M.Modal.getInstance(elem);
            instances.open();
        }
        else{
            this.props.WriteChanges(
                { surveyInfo: { status: "Completed" } },
                surveyId,
                versionId,
                token
            );
            this.setState({surveyStatus:'Completed'})
        }
    }
    else if (this.state.surveyStatus == "Test") {
        console.log("right");
        var elem = document.getElementById("test-survey-modal");
        var instances = M.Modal.getInstance(elem);
        instances.open();
    }
  };

  onFileChangeHandler = (event) => {
    this.setState({
      languageFile: event.target.files[0],
    });
  };

  AddLanguage = (e) => {
    e.preventDefault();
    console.log("language Adding", this.state.languageFile);
    var user = this.props.auth.user;
    if (user){
      this.props.AddLanguage(
        this.state.languageFile,
        this.props.versionId,
        this.state.newLanguageCode,
        user.accessToken
      );
      this.setState({ languageAddReflected:false})
    }
  };

    OnOtherChange = (e) => {
        if(e.target.name == 'terminate'){
            this.setState({terminate:!this.state.terminate, logicResultSec:null, logicResultQ:null})
        }
        else
        this.setState({ ...this.state, [e.target.id]: e.target.value });
    };

    DownloadLanguageForm = (e) => {
        var user = this.props.auth.user;
        if (user)
        this.props.GetLanguageForm(this.props.versionId, user.accessToken);
    };
    LoadVersion =(e) =>{
        var user = this.props.auth.user
        if(user){
            this.props.LoadVersion(this.props.id, this.state.selectedVersion, user.accessToken)
            this.setState({
                versionLoadReflected:false,
            })
        }
    }

    handlePreviewChange = (e) => {
        if(e.target.name == "multiple"){
            if(e.target.value in this.state.multiple){
                console.log("found",e.target.value,!(this.state.multiple[e.target.value]))
            }
            else{
                console.log("not found");
            }
        }
        if(e.target.name == "multiple")
        this.setState({ ...this.state, 
          multiple:{
            ...this.state.multiple,
            [e.target.value]: e.target.value in this.state.multiple?!(this.state.multiple[e.target.value]):true
          }
        })
        else if(String( e.target.name).includes("gridGroup")){
            var dataFromId = e.target.id.split('-');
            var groupId = dataFromId[1]
            var optionId = dataFromId[3]
            console.log("GRID TYPE CHANGE", groupId, optionId, e.target.value);

            this.setState({
                ...this.state,
                grid:{
                    ...this.state.grid,
                    [groupId]:{
                        [optionId]:e.target.value=="on"?true:false
                    }
                }
            })
        }
        else if(e.target.name == "mix"){
            var dataFromId = e.target.id.split('-');
            var type = dataFromId[1]
            var optionId = dataFromId[2]
            console.log("MIX TYPE CHANGE", type, optionId, e.target.value);

            if(type == 'multiple')
            this.setState({
                ...this.state,
                mix:{
                    ...this.state.mix,
                    multiple:{
                        ...this.state.mix.multiple,
                        [optionId]:this.state.mix.multiple[optionId]?!this.state.mix.multiple[optionId]:true
                    },
                    single:{}
                    
                }
            })
            else if(e.target.value)
            this.setState({
                ...this.state,
                mix:{
                    multiple:{},
                    single:{
                        [optionId]:this.state.mix[optionId]?!this.state.mix[optionId]:true
                    }
                }
            })
        }
        else
            this.setState({ ...this.state, [e.target.id]: e.target.value })
        console.log(this.state);
      };
    
      PreviewHandleNextQuestionChange = (questions, selectedQ, selectedSec, selectedRank) => {
          var data = []
          if(questions.sections[selectedSec].questions[selectedQ].questionType ==
            "MULTIPLE"){
                var keys = Object.keys(this.state.multiple)
                for(var i=0;i<keys.length;i++){
                    if(this.state.multiple[keys[i]]){
                        data.push({optionId:keys[i]})
                    }
                }
            }
          else if(questions.sections[selectedSec].questions[selectedQ].questionType ==
            "GRID"){
                var groups = Object.keys(this.state.grid)
                for(var i=0;i<groups.length;i++){
                    var options = Object.keys(this.state.grid[groups[i]])
                    for(var j=0;j<options.length;j++){
                        if(this.state.grid[groups[i]]&&this.state.grid[groups[i]][options[j]]){
                            data.push({groupId:groups[i], optionId:options[j]})
                        }
                    }
                }
            }
          else if(questions.sections[selectedSec].questions[selectedQ].questionType ==
            "MIXED"){
                var keys = Object.keys(this.state.mix.single)
                var single=false
                for(var i=0;i<keys.length;i++){
                    if(this.state.mix.single[keys[i]]){
                        data.push({optionId:keys[i], optionTypeGroup:'SINGLE'})
                        single=true
                    }
                }
                if(!single){
                    keys = Object.keys(this.state.mix.multiple)
                    for(var i=0;i<keys.length;i++){
                        if(this.state.mix.multiple[keys[i]]){
                            data.push({optionId:keys[i], optionTypeGroup:'MULTIPLE'})
                        }
                    }
                }
            }
          else if(questions.sections[selectedSec].questions[selectedQ].questionType ==
            "RANK"){
                data = selectedRank
            }
        const answer =
          questions.sections[selectedSec].questions[selectedQ].questionType ==
            "TEXT" ||
          questions.sections[selectedSec].questions[selectedQ].questionType ==
            "NUMERIC"
            ||questions.sections[selectedSec].questions[selectedQ].questionType ==
            "DATETIME"
            ? 
            {
                answer: [
                    {
                        input: this.state.input
                    }
                ]
            }
            
            : questions.sections[selectedSec].questions[selectedQ].questionType ==
              "SINGLE"
            ?
            {
                answer: [
                    {
                        optionId: this.state.optionId,
                    }
                ]
            }
            : questions.sections[selectedSec].questions[selectedQ].questionType ==
            "MULTIPLE"
            ||questions.sections[selectedSec].questions[selectedQ].questionType ==
            "GRID"
            ||questions.sections[selectedSec].questions[selectedQ].questionType ==
            "MIXED"
          ? 
            {
                answer:data
            }
            : null;
    
        this.props.SaveAnswer(answer, this.state.selectedSec, this.state.selectedQ);

        var savedLogic = null
        var blockLogic = null
        var skip = null
        var block = null
        if(questions.sections[this.state.selectedSec].questions[this.state.selectedQ].logic){
            savedLogic = questions.sections[this.state.selectedSec].questions[this.state.selectedQ].logic.skip
        }
        savedLogic?
            skip = skipLogicEvaluator(this.props.previewAnswers, savedLogic)
        :
            console.log("NO SKIP LOGIC FOUND");
        
        var nextSec = null
        var nextQ = null
        if (skip && skip[1]){
            nextSec = skip[0].sectionId
            nextQ = skip[0].questionId
        }
        else{
            nextSec = questions.sections[this.state.selectedSec].questions[this.state.selectedQ].questionFlow.next.sectionId
            nextQ = questions.sections[this.state.selectedSec].questions[this.state.selectedQ].questionFlow.next.questionId
        }

        if(nextSec&&nextQ&&questions.sections[nextSec].questions[nextQ].logic){
            var blockLogic = questions.sections[nextSec].questions[nextQ].logic.display
            console.log("DISPLAY LOGIC",nextSec, nextQ);
            console.log("DISPLAY LOGIC",questions.sections[nextSec].questions[nextQ].logic.display);
            block = displayLogicEvaluator(this.props.previewAnswers, blockLogic)
            console.log("DISPLAY LOGIC ANSWER",block);

        }

        if(block && block[1]){
            if(block[0].question){
                this.setState({
                    selectedSec: questions.sections[nextSec].questions[nextQ].questionFlow.next.sectionId,
                    selectedQ: questions.sections[nextSec].questions[nextQ].questionFlow.next.questionId
                })
                nextSec=questions.sections[nextSec].questions[nextQ].questionFlow.next.sectionId
                nextQ=questions.sections[nextSec].questions[nextQ].questionFlow.next.questionId
            }
            else{
                this.setState({
                    ...this.state,
                    blockedOptions:block[0].options,
                    blockedGroups:block[0].groups
                })
            }
        }
        else{
            this.setState({
                ...this.state,
                blockedOptions:[],
                blockedGroups:[]
            })
        }

        this.setState({
            grid:{},
            mix:{multiple:{}, single:{}},
            multiple:{},
            input:null,
            optionId:null
        })
        if(!nextQ||!nextSec){
            this.setState({activateSubmit:true})
          }
        // console.log(nextSec, nextQ);
        if(nextSec&&nextQ&&this.props.previewAnswers.sections[nextSec]
            &&this.props.previewAnswers.sections[nextSec].questions
            &&this.props.previewAnswers.sections[nextSec].questions[nextQ]){

                if(this.props.questions.sections[nextSec].questions[nextQ].questionType == "TEXT"
                ||this.props.questions.sections[nextSec].questions[nextQ].questionType == "NUMERIC"
                ||this.props.questions.sections[nextSec].questions[nextQ].questionType == "DATETIME"
                ){
                    this.setState({
                        selectedSec: nextSec,
                        selectedQ: nextQ,
                        input:this.props.previewAnswers.sections[nextSec].questions[nextQ].answer[0].input
                      });
                }
                else if(this.props.questions.sections[nextSec].questions[nextQ].questionType == "SINGLE"){
                    this.setState({
                            selectedSec:nextSec,
                            selectedQ:nextQ,
                            optionId:this.props.previewAnswers.sections[nextSec].questions[nextQ].answer[0].optionId
                      });
                }
                else if(this.props.questions.sections[nextSec].questions[nextQ].questionType == "MULTIPLE"){
                    var m = {}
                    var ans = this.props.previewAnswers.sections[nextSec].questions[nextQ].answer
                    if(ans){
                        for (var i=0; i<ans.length;i++){
                            m[ans[i].optionId]=true
                        }
                        this.setState({
                            selectedSec:nextSec,
                            selectedQ:nextQ,
                            multiple:m
                        });
                    }
                }
                else if(this.props.questions.sections[nextSec].questions[nextQ].questionType == "GRID"){
                    var m = {}
                    var ans = this.props.previewAnswers.sections[nextSec].questions[nextQ].answer
                    if(ans){
                        for (var i=0; i<ans.length;i++){
                            if(m[ans[i].groupId])
                                m[ans[i].groupId][ans[i].optionId]=true
                            else{
                                m[ans[i].groupId]={}
                                m[ans[i].groupId][ans[i].optionId]=true
                            }

                            
                        }
                        this.setState({
                            selectedSec:nextSec,
                            selectedQ:nextQ,
                            grid:m
                        });
                    }
                }
                else if(this.props.questions.sections[nextSec].questions[nextQ].questionType == "MIXED"){
                    var m = {single:{}, multiple:{}}
                    var ans = this.props.previewAnswers.sections[nextSec].questions[nextQ].answer
                    if(ans){
                        for (var i=0; i<ans.length;i++){
                            if(ans[i].optionTypeGroup =='SINGLE'){
                                m.single[ans[i].optionId] = true
                            }
                            else{
                                m.multiple[ans[i].optionId] = true
                            }
                            
                        }
                        this.setState({
                            selectedSec:nextSec,
                            selectedQ:nextQ,
                            mix:m
                        });
                    }
                }
                else{
                    this.setState({
                        selectedSec:nextSec,
                        selectedQ:nextQ
                      });
                }
        }
        else   
            this.setState({
                selectedSec:nextSec,
                selectedQ:nextQ
            });
        
      };
    
      PreviewHandlePrevQuestionChange = (questions, selectedQ, selectedSec) => {
        var prevData = this.props.prevElements.sections[selectedSec]&&
        this.props.prevElements.sections[selectedSec].questions[selectedQ]?
            this.props.prevElements.sections[selectedSec].questions[selectedQ]
        :
            this.props.prevData

        var prevSec = prevData.sectionId
        var prevQ = prevData.questionId
        // console.log("current sectionId", prevSec);
        // console.log("current questionId", prevQ);
        if(this.props.previewAnswers.sections[prevSec]
            &&this.props.previewAnswers.sections[prevSec].questions
            &&this.props.previewAnswers.sections[prevSec].questions[prevQ]){

                if(this.props.questions.sections[prevSec].questions[prevQ].questionType == "TEXT"
                ||this.props.questions.sections[prevSec].questions[prevQ].questionType == "NUMERIC"
                ||this.props.questions.sections[prevSec].questions[prevQ].questionType == "DATETIME"
                ){
                    this.setState({
                        selectedSec: prevSec,
                        selectedQ: prevQ,
                        input:this.props.previewAnswers.sections[prevSec].questions[prevQ].answer[0].input
                      });
                }
                else if(this.props.questions.sections[prevSec].questions[prevQ].questionType == "SINGLE"){
                    this.setState({
                        selectedSec: prevSec,
                        selectedQ: prevQ,
                        optionId:this.props.previewAnswers.sections[prevSec].questions[prevQ].answer[0].optionId
                      });
                }
                else if(this.props.questions.sections[prevSec].questions[prevQ].questionType == "MULTIPLE"){
                    var m = {}
                    var ans = this.props.previewAnswers.sections[prevSec].questions[prevQ].answer
                    if(ans){
                        for (var i=0; i<ans.length;i++){
                            m[ans[i].optionId]=true
                        }
                        this.setState({
                            selectedSec:prevSec,
                            selectedQ:prevQ,
                            multiple:m
                        });
                    }
                }
                else if(this.props.questions.sections[prevSec].questions[prevQ].questionType == "GRID"){
                    var m = {}
                    var ans = this.props.previewAnswers.sections[prevSec].questions[prevQ].answer
                    if(ans){
                        for (var i=0; i<ans.length;i++){
                            if(m[ans[i].groupId])
                                m[ans[i].groupId][ans[i].optionId]=true
                            else{
                                m[ans[i].groupId]={}
                                m[ans[i].groupId][ans[i].optionId]=true
                            }

                            
                        }
                        this.setState({
                            selectedSec:prevSec,
                            selectedQ:prevQ,
                            grid:m
                        });
                    }
                }
                else if(this.props.questions.sections[prevSec].questions[prevQ].questionType == "MIXED"){
                    var m = {single:{}, multiple:{}}
                    var ans = this.props.previewAnswers.sections[prevSec].questions[prevQ].answer
                    if(ans){
                        for (var i=0; i<ans.length;i++){
                            if(ans[i].optionTypeGroup =='SINGLE'){
                                m.single[ans[i].optionId] = true
                            }
                            else{
                                m.multiple[ans[i].optionId] = true
                            }
                            
                        }
                        this.setState({
                            selectedSec:prevSec,
                            selectedQ:prevQ,
                            mix:m
                        });
                    }
                }
                else{
                    this.setState({
                        selectedSec: prevSec,
                        selectedQ: prevQ,
                        });
                }
        }
        else   
            this.setState({
            selectedSec: prevSec,
            selectedQ: prevQ,
            });
      };

    commonUploader=()=>{
        // e.preventDefault();
        var user = this.props.auth.user
        var keys = Object.keys(this.state.designFiles)
        var flag = false
        for (var i =0; i<keys.length;i++){
            if(this.state.designFiles[keys[i]].status == "selected"){
                if(!(this.props.uploading)){
                    this.props.Upload(
                        this.state.designFiles[keys[i]].file,
                        keys[i], 
                        this.state.selectedSec,
                        this.state.selectedQ,
                        this.props.versionId,
                        user.accessToken,
                    )
                    flag = true
                    break
                }
            }
        }
        if (!flag){
            this.props.UploadComplete()
            this.props.LoadVersion(this.props.id, this.props.versionId, this.props.auth.user.accessToken)
        }
    }

    uploadFilechangeHandler=(e)=>{
        var type = e.target.id.split('-')[0]
        var id = e.target.id.split('-')[1]
        if(type == "option"){
            if(e.target.files[0])
            this.setState({
                designFiles:{...this.state.designFiles, [e.target.id]: {file:e.target.files[0], type:"option", status:"selected"}},
              });
            else
            this.setState({
                designFiles:{...this.state.designFiles, [e.target.id]: {file:e.target.files[0], type:"option", status:"none"}},
              });
        }
        else if(type == "question"){
            e.target.files[0]?
                this.setState({
                    designFiles:{...this.state.designFiles, [e.target.id]: {file:e.target.files[0], type:"question", status:"selected"}},
                })
            :
                this.setState({
                    designFiles:{...this.state.designFiles, [e.target.id]: {file:e.target.files[0], type:"question", status:"none"}},
                });
        }
    }

    SurveyImageChange=(e)=>{
        this.setState({
            ...this.state,
            surveyImage:e.target.files[0]
        })
    }

    UploadSurveyImage=(e)=>{
        var user = this.props.auth.user;
        var file = this.state.surveyImage;
        if(user && file){
            this.props.UploadSurveyImage(this.props.id, file, user.accessToken)
            this.setState({
                ...this.state,
                imageUploadReflected:false
            })
        }
    }

    OpenLogicModal=(e)=>{
        var exLogic = this.props.questions
        .sections[this.state.selectedSec]
        .questions[this.state.selectedQ].logic
        
        if(exLogic){
            console.log("skip",exLogic.skip);
            this.setState({
                logics:{skip:exLogic.skip, display:exLogic.display, pipe:exLogic.pipe},
            })
        }
        else{
            this.setState({
                logics:{skip:[], display:[], pipe:[]},
            })
        }
    }

    NewQuestionChangeHandler=(e)=>{
        if(e.target.name=="newOptionsData"||e.target.name=="newGroupsData"){
            var curInd = e.target.id.split('-')[0]
            var type = e.target.id.split('-')[1]
            var newOptionData = this.state.newOptionsData
            newOptionData[curInd][type] = e.target.value
            this.setState({[e.target.name]:newOptionData})
        }
        else if(e.target.id == 'newSection'){
            this.setState({[e.target.id]:!this.state[e.target.id]})
        }
        else
        {
            this.setState({[e.target.id]:e.target.value})

        }
    }

    OnAddOption=(e)=>{
        e.preventDefault();
        this.setState({
            newOptionsData:[
                ...this.state.newOptionsData,
                {newOptionBodyType:"", option:""}
            ]
        })
    }
    OnAddGroup=(e)=>{
        e.preventDefault();
        this.setState({
            newGroupsData:[
                ...this.state.newGroupsData,
                {newGroupBodyType:"", group:""}
            ]
        })
    }

    OnAddQuestionSubmit=(e)=>{
        e.preventDefault();
        console.log(this.state);
        var newQuestionData={
            questionType:this.state.newQuestionType,
            questionBodyType:this.state.newQuestionBodyType,
            question:this.state.newQuestionText,
            options:this.state.newOptionsData,
            groups:this.state.newGroupsData,
            sectionId:this.state.selectedSec,
            questionId:this.state.selectedQ,
            newSection:this.state.newSection,
        }
        this.setState({
            newQuestionType:"",
            newQuestionBodyType:"",
            newQuestionText:null,
            newOptionsData:[],
            newGroupsData:[],
            newSection: false,
        })
        this.props.AddQuestion(
            this.props.versionId,
            newQuestionData,
            this.props.auth.user.accessToken,
        )
    }

    SelectedStyle=(e)=>{
        var elem = document.getElementById('editText')
        if(e.target.id=='selectedTextItalic'){
            console.log("bold", elem.selectionStart, elem.selectionEnd);
            if(this.state.selectedTextItalic.status){
                this.setState({
                    selectedTextItalic:{
                        status:false,
                        start:null,
                        end:null
                    }
                })
            }
            else{
                this.setState({
                    selectedTextItalic:{
                        status:true,
                        start:elem.selectionStart,
                        end:elem.selectionEnd
                    }
                })
            }
        }
        else if(e.target.id=='selectedTextBold'){
            console.log("bold", elem.selectionStart, elem.selectionEnd);
            if(this.state.selectedTextBold.status){
                this.setState({
                    selectedTextBold:{
                        status:false,
                        start:null,
                        end:null
                    }
                })
            }
            else{
                console.log("false");
                this.setState({
                    selectedTextBold:{
                        status:true,
                        start:elem.selectionStart,
                        end:elem.selectionEnd
                    }
                })
            }
        }
        else if(e.target.id=='selectedTextColor'){
            // console.log("bold", elem.selectionStart, elem.selectionEnd);
            if(this.state.selectedTextColor.status){
                this.setState({
                    selectedTextColor:{
                        status:false,
                        start:null,
                        end:null
                    }
                })
            }
            else{
                console.log("false");
                this.setState({
                    selectedTextColor:{
                        status:true,
                        color:e.target.value,
                        start:elem.selectionStart,
                        end:elem.selectionEnd
                    }
                })
            }
        }
    }

    HandleMandatory=(e)=>{
        console.log('value', e.target.value);
        var status = true
        
        if(this.props.questions
            .sections[this.state.selectedSec]
            .questions[this.state.selectedQ]
            .mandatory
            &&this.props.questions
            .sections[this.state.selectedSec]
            .questions[this.state.selectedQ]
            .mandatory==true)
            status = false
        else
            status = true
        this.props.ChangeMandatory(
            status,
            this.props.questions,
            this.state.selectedSec, 
            this.state.selectedQ,
            this.props.id,
            this.props.versionId,
            this.props.auth.user.accessToken )
    }

    OnDeleteTrigger=()=>{
        this.setState({deleteQ:true})
    }

    DeleteQuestion=()=>{
        this.props.DeleteQuestion(this.props.versionId,
            this.state.selectedSec,
            this.state.selectedQ, 
            this.props.auth.user.accessToken
        )
    }

    HandleRandom=(e)=>{
        var d = null
        if(e.target.id.includes('sectionRandom')){
            var secId = e.target.id.split('-')[1]
            if(this.props.questions.sectionRandom){
                d = this.props.questions
                if(d.sectionRandom.random.includes(secId)){
                    d.sectionRandom.random = d.sectionRandom.random.filter(id=> id!= secId)
                }
                else{
                    d.sectionRandom.random.push(secId)
                }
                this.props.ApplyRandom('section', d)
            }
            else{
                d = this.props.questions
                d['sectionRandom'] = {
                    isRandom: true,
                    firstSection: false,
                    random: [
                        secId
                    ],
                }
                this.props.ApplyRandom('section', d)
            }
        }
        if(e.target.id == 'optionRandom'){
            if(this.props.questions.optionRandom){
                d = this.props.questions
                d.optionRandom = !d.optionRandom
                this.props.ApplyRandom('option', d)
            }
            else{
                d = this.props.questions
                d.optionRandom = true
                this.props.ApplyRandom('option', d)
            }
        }
        if(e.target.id.includes('questionRandom')){
            d = this.props.questions
            var secId = e.target.id.split('-')[1]
            if(d.sections[secId].questionRandom){
                d.sections[secId].questionRandom = !d.sections[secId].questionRandom
            }
            else{
                d.sections[secId]['questionRandom'] = true
            }
        }

    }

    TypeChange=(sid, qid, value)=>{
        console.log("type change", sid, qid, value);
        var d = this.props.questions
        d.sections[sid].questions[qid].questionType = value
        this.props.ChangeQType(d)
    }

    NewOption=()=>{
        var d = this.props.questions
        var opKeys = Object.keys(d.sections[this.state.selectedSec].questions[this.state.selectedQ].options)
        var m = 0
        for (var i=0;i<opKeys.length;i++){
            if(m<Number(opKeys[i])){
                m = Number(opKeys[i])
            }
        }
        console.log("last value", m);
        var x= JSON.parse(JSON.stringify(d.sections[this.state.selectedSec].questions[this.state.selectedQ].options[m]));
        x.option.default = this.state.newOption
        x.optionId = m+1

        d.sections[this.state.selectedSec].questions[this.state.selectedQ].options[m+1] = x

        this.setState({newOption:''})

        this.props.AddNewOption(d)
    }

    render() {
        const {questions, surveyInfo, auth, languages, versions} = this.props;
        const user = auth.user;
        console.log("local state",this.state);
        console.log("local props",this.props);
        if (!user) return <Redirect to="/login" />;
        return (
            <div>
                <script src="https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css"></script>
                <LanguageModal 
                    state={this.state} 
                    OnChange={this.OnOtherChange}
                    FileChangeHandler={this.onFileChangeHandler}
                    OnAdd={this.AddLanguage}
                    OnDownload={this.DownloadLanguageForm}
                    OnOtherChange={this.OnOtherChange}
                />
                <EditModal HandleChange={this.OnOtherChange} OnSubmit={this.OnEditSave} OnStyle={this.SelectedStyle} state={this.state}/>
                <ModalAddLogic 
                state={this.state} 
                HandleChange={this.OnOtherChange} 
                HandleSelectChange={this.HandleLogicSelect} 
                OnAdd={this.OnAdd} 
                OnMoreOperand={this.OnMoreOperand} 
                OnAddLogic={this.OnNewLogic}
                OnLogicDelete={this.OnDeleteLogic}
                OnMerge={this.OnMerge}
                OnSave={this.OnSaveLogic}
                questions = {questions}
                HandleExtraOperandChange={this.HandleExtraOperandChange}
                DeleteExtraOperand={this.DeleteExtraOperand}
                />
                <TestSurveyModal
                HandleTestSurvey={this.HandleTestSurvey}
                url={
                    window.location.origin +
                    "/survey/" +
                    this.props.surveyInfo.id +
                    "/version/" +
                    this.props.versionId +
                    "/test"
                }
                />
                <DeleteModal
                HandleDelete={this.state.deleteQ?this.DeleteQuestion: this.CompleteStatusChange}
                />
                <AddQuestion 
                    HandleChange={this.NewQuestionChangeHandler} 
                    state={this.state} 
                    OnAddOption={this.OnAddOption}
                    OnAddGroup={this.OnAddGroup}
                    OnSubmit={this.OnAddQuestionSubmit}
                />
                <NavBar heading="Design" user={this.props.auth.user}/>
                <div className="row"  style={{marginBottom:0}}>
                    <div>
                        <div className="row info-header">
                            <div className="col s12">
                                <div className="col s2">
                                    Exam Name: {surveyInfo?surveyInfo.name:""}
                                </div>
                                <div className="col s2">
                                    Date: {surveyInfo.datetime?moment(surveyInfo.datetime).format("MMM Do YYYY"):""}
                                </div>
                                <div className="col s2">
                                    <div className="col s4">
                                        <span>Status:</span>
                                    </div>
                                    <div className="col s8">
                                        <select
                                            className='statusSelector browser-default select-custom'
                                            id="surveyStatus"
                                            defaultValue="Design"
                                            value={this.state.surveyStatus}
                                            onChange={this.HandleStatusChange}
                                        >
                                            <option value='Design' 
                                            disabled={this.state.surveyStatus=="Live"||this.state.surveyStatus=="Completed"}>
                                                Design
                                            </option>
                                            <option value='Test'
                                            disabled={this.state.surveyStatus=="Live"||this.state.surveyStatus=="Completed"}
                                            >
                                                Test
                                            </option>
                                            <option disabled={this.state.surveyStatus=="Completed"} value='Live'>Live</option>
                                            <option disabled value='Completed'>Completed</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col s3">
                                    <button className="btn-small list-button" onClick={
                                        this.state.surveyStatus == "Design"?
                                            this.OnFinalSave
                                        :
                                            this.state.surveyStatus == "Test"?
                                                this.OnFinalSave
                                            :
                                                this.state.surveyStatus == "Live"?
                                                    this.OnFinalSave
                                                    :
                                                    null
                                        }>
                                        {
                                            this.state.surveyStatus == "Design"?
                                                "compile & save"
                                            :
                                                this.state.surveyStatus == "Test"?
                                                    "Create Test"
                                                :
                                                    this.state.versionStatus=="Design"?
                                                    "compile & save"
                                                    :   this.state.versionStatus=="Test"?
                                                            "Create Test"
                                                            :
                                                            this.state.surveyStatus == "Live"?
                                                            "Terminate"
                                                            :null

                                        }
                                    </button>
                                </div>
                                <div className="col s3">
                                    <button className="btn-small list-button" onClick={this.OnDefaultTheme}>choose default theme</button>
                                    <button data-target="language-modal" className="btn-floating btn-small right right-align modal-trigger">
                                        <i className="fa fa-language" aria-hidden="true"></i>
                                    </button>
                                </div>
                            </div>      
                        </div>
                    </div>
                    <div className="col s12" style={{marginBottom:5}}>  
                        <div className="col s4">
                            <div className="col s1">
                                <label>Versions</label>
                            </div>
                            <div className="col s3">
                                <select
                                    // required
                                    defaultValue={this.props.versionId}
                                    onChange={this.OnOtherChange}
                                    className='browser-default select-font'
                                    id='selectedVersion'
                                >
                                    {
                                        versions.map((version, index) =>
                                            <option selected={this.props.versionId==version.id} value={version.id}>{version.versionLabel}</option>
                                        )
                                    }
                                </select>
                            </div>
                            <div className="col s1">
                                <label>Status</label>
                            </div>
                            <div className="col s3">
                                <select
                                    // required
                                    defaultValue={this.props.versionInfo.status}
                                    onChange={this.HandleVersionStatusChange}
                                    className='browser-default select-font'
                                    id='versionStatus'
                                    value={this.state.versionStatus}
                                >
                                    <option value='Design' 
                                    disabled={this.state.versionStatus=='Live'||this.state.versionStatus=='Completed'}>
                                        Design
                                    </option>
                                    <option value='Test' disabled={this.state.versionStatus=='Live'||this.state.versionStatus=='Completed'}>
                                        Test
                                    </option>
                                    <option value='Live'>Live</option>
                                    <option value="Completed">Completed</option>
                                </select>
                            </div>   
                            
                            <div className="col s1">
                                <button className="extra-small" onClick={this.LoadVersion}>
                                    <i className="fa fa-download" aria-hidden="true"></i>
                                </button>
                            </div>  
                        </div> 
                        <div className="col s2">
                            <form>
                                {questions&&
                                <div className="row" style={{margin:0, padding:5}}>
                                    <div className="col s12">
                                            <label>
                                                <input
                                                id={"optionRandom"}
                                                // value={optionId}
                                                name="optionRandom"
                                                className="filled-in checkbox-blue"
                                                type="checkbox"
                                                checked={questions
                                                    .optionRandom?true:false}
                                                onChange={this.HandleRandom}
                                                />
                                                <span>Random options</span>
                                            </label>                                    
                                    </div>
                                </div>
                                }
                            </form>
                        </div>  
                            
                        <div className="col s6 right right-align">
                            <div className="col s2 offset-s8" style={{color:'#2762F7', fontSize:18}}>
                                <i className="fa fa-language" aria-hidden="true"></i>
                            </div>
                            <div className="col s2">
                                <select
                                    // required
                                    defaultValue="default"
                                    onChange={this.OnOtherChange}
                                    className='browser-default select-font'
                                    id='selectedLanguage'
                                >
                                    <option value="default" selected>default</option>
                                    {
                                        languages.map((lang) =>
                                            <option value={lang.code}>{lang.code}</option>
                                        )
                                    }
                                </select>
                            </div>     
                        </div>  
                    </div>
                    <div className="row" style={{ marginBottom: 0 }}>
                        <div className="col s4">
                            <div
                                className="container question-list-complete"
                                style={{ width: "90%" }}
                            >
                                {questions &&
                                questions.sections &&
                                Object.keys(questions.sections).map((sectionId) =>
                                <>
                                    <div className="row" style={{margin:0, padding:5}}>
                                        <div className="col s6">
                                            <label>
                                                <input
                                                id={"sectionRandom-"+sectionId}
                                                // value={optionId}
                                                name="sectionRandom"
                                                className="filled-in checkbox-blue"
                                                type="checkbox"
                                                checked={questions
                                                    .sectionRandom&&
                                                    questions
                                                    .sectionRandom.random.includes(sectionId)}
                                                onChange={this.HandleRandom}
                                                />
                                                <span>Random Sections</span>
                                            </label>
                                        </div>
                                        <div className="col s6">
                                            <label>
                                                <input
                                                id={"questionRandom-"+sectionId}
                                                // value={optionId}
                                                name="questionRandom"
                                                className="filled-in checkbox-blue"
                                                type="checkbox"
                                                checked={questions.sections[sectionId]
                                                    .questionRandom}
                                                onChange={this.HandleRandom}
                                                />
                                                <span>Random Questions</span>
                                            </label>
                                        </div>
                                    </div>
                                    {
                                    Object.keys(
                                    questions.sections[sectionId].questions
                                    ).map((questionId) => (
                                    <QuestionListItem
                                        key={sectionId + "." + questionId}
                                        sid={sectionId}
                                        qid={questionId}
                                        questionid={sectionId + "." + questionId}
                                        q={questions
                                            .sections[sectionId]
                                            .questions[questionId]
                                            .question
                                        }
                                        type={
                                        questions.sections[sectionId].questions[questionId]
                                            .questionType
                                        }
                                        cssType="question-list"
                                        onDetails={this.onQSelect}
                                        designer={true}
                                        typeChange={this.TypeChange}
                                    />
                                    ))
                                    }
                                </>
                                )}
                            </div>
                        </div>
                        <div className="col s4">
                            {this.state.selectedQ != null &&
                            this.state.selectedForMod != null? (
                                this.state.versionStatus=="Live"
                                ||this.state.versionStatus=="Completed"
                                ||this.state.surveyStatus=="Completed" ?
                                <div className="row">
                                    <div className="col s12 center center-align">
                                        <h3 className="light-h3">Live version or complete survey is not editable.</h3>
                                    </div>
                                </div>
                                :
                                <>
                                    <Style that={this} />
                                    <div className="col s12 single-question-editable">
                                        <div className="container" style={{ width: "90%" }}>
                                            <div>
                                                <EditableQuestion
                                                questions={questions}
                                                selectedQ={this.state.selectedQ}
                                                selectedSec={this.state.selectedSec}
                                                selectedLang={this.state.selectedLanguage}
                                                OnSelect={this.onClick}
                                                OnEdit={this.OnEdit}
                                                OnSave={this.OnEditSave}
                                                fileChange={this.uploadFilechangeHandler}
                                                state={this.state}
                                                upload={this.commonUploader}
                                                HadleMandatory={this.HandleMandatory}
                                                HandleChange={this.OnOtherChange}
                                                OnAddOption={this.NewOption}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col s4 center">
                                            <button
                                            className="btn btn-small modal-trigger"
                                            data-target="add-logic-modal"
                                            onClick={this.OpenLogicModal}
                                            >
                                                Add Logic
                                            </button>
                                            {
                                            this.state.selectedSec&&
                                            this.state.selectedQ&&
                                            this.props.questions.sections[this.state.selectedSec]
                                            .questions[this.state.selectedQ].logic?
                                            <i className="material-icons">check_circle</i>
                                            :null
                                            }
                                        </div>
                                        <div className="col s2 center">
                                            <button
                                            className="btn btn-floating green modal-trigger"
                                            data-target="add-question-modal"
                                            
                                            // onClick={this.OpenLogicModal}
                                            >
                                                <i className="material-icons">add</i>
                                            </button>
                                        </div>
                                        <div className="col s2 center">
                                            <button
                                            className="btn btn-floating red modal-trigger"
                                            data-target="delete-user-modal"
                                            // style={{backgroundColor:'red'}}
                                            onClick={this.OnDeleteTrigger}
                                            >
                                                <i className="material-icons">clear</i>
                                            </button>
                                        </div>
                                        <div className="col s4 center">
                                            <button
                                            className="btn btn-small "
                                            onClick={this.OnStyleSave}
                                            >
                                                Save Style
                                            </button>
                                        </div>
                                    </div>
                                </>
                            ) : null}
                        </div>
                        <div className="col s4">
                            <div className="container ">
                                <div className="row">
                                    <div className="col s12">
                                        <div className="col s6">
                                            <label>
                                                <input
                                                className="with-gap"
                                                id="orientation"
                                                name="orientation"
                                                type="radio"
                                                value="potrait"
                                                checked={
                                                    this.state.orientation == "potrait" ? true : false
                                                }
                                                onChange={this.OnOtherChange}
                                                />
                                                <span>Potrait</span>
                                            </label>            
                                        </div>
                                        <div className="col s6">
                                            <label>
                                            <input
                                                className="with-gap"
                                                id="orientation"
                                                name="orientation"
                                                type="radio"
                                                value="landscape"
                                                checked={
                                                this.state.orientation == "landscape" ? true : false
                                                }
                                                onChange={this.OnOtherChange}
                                            />
                                            <span>Landscape</span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className={this.state.orientation=="landscape"?"landscape-screen":"potrait-screen"}>
                                {
                                    this.state.selectedSec != null &&
                                    this.state.selectedQ != null ? (
                                    <SpecificQuestion
                                        questions={questions}
                                        selectedQ={this.state.selectedQ}
                                        selectedSec={this.state.selectedSec}
                                        selectedLang={this.state.selectedLanguage}
                                        languages={this.props.languages}
                                        OnLanguageChange={this.OnOtherChange}
                                        handleChange={this.handlePreviewChange}
                                        HandleNextQuestionChange={this.PreviewHandleNextQuestionChange}
                                        HandlePrevQuestionChange={this.PreviewHandlePrevQuestionChange}
                                        image={this.props.surveyInfo &&this.props.surveyInfo.image?this.props.surveyInfo.image:null}
                                        state={this.state}
                                        onSubmit={this.PreviewHandleNextQuestionChange}
                                        onFileUploader={()=>{}}
                                        uploadSuccess={this.state.dummyUpload}
                                        uploadError={false}
                                        uploadFile={()=>{this.setState({dummyUpload:true})}}
                                        mode='preview'
                                        sname={this.props.surveyInfo.name}
                                        sid={this.props.surveyInfo.id}
                                        vid={this.props.versionInfo.versionLabel}
                                        css="sp-question"
                                    />
                                    ) : null
                                }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
  console.log("State", state);
  return {
    auth: state.auth,
    id: ownProps.match.params.id,
    questions: state.designer.questions
        ? state.designer.questions
        : [],
    versionId: state.designer && state.designer.versionId,
    surveyInfo: state.designer.surveyInfo,
    versionInfo: state.designer.versionInfo,
    languages: state.designer.versionLanguages
      ? state.designer.versionLanguages
      : [],
    versions: state.designer.versions?state.designer.versions:[],
    previewAnswers: state.designer.savedAnswer,
    prevElements: state.designer.prevElements,
    prevData: state.designer.prevData,
    logicSaveSuccess: state.designer.logicSaveSuccess,
    uploadSuccess: state.designer.uploadSuccess,
    uploadError: state.designer.uploadError,
    uploading: state.designer.uploading,
    uploadId: state.designer.uploadElementId,
    versionLoad: state.designer.versionLoadSuccess,
    imageLoading: state.designer.surveyImageLoading,
    imageLoadSuccess: state.designer.surveyImageLoadSuccess,
    imageLoadError: state.designer.surveyImageLoadError,
    addLanguageLoading: state.designer.addLanguageLoading,
    addLanguageSuccess: state.designer.addLanguageSuccess,
    addLanguageError: state.designer.addLanguageError,

    addQuestionLoading:state.designer.addQuestionLoading,
    addQuestionSuccess:state.designer.addQuestionSuccess,
    addQuestionError:state.designer.addQuestionError,

    saveSurveySuccess: state.designer.saveSurveySuccess,
    saveSurveyError: state.designer.saveSurveyError,

    changeMandatorySuccess:state.designer.changeMandatorySuccess,
    changeMandatoryLoading:state.designer.changeMandatoryLoading,
    changeMandatoryError:state.designer.changeMandatoryError,
    
    deleteQSuccess:state.designer.deleteQSuccess,
    deleteQError:state.designer.deleteQError,
    deleteQLoading:state.designer.deleteQLoading,


  };
}
  const mapDispatchToProps = (dispatch)  => {
    return {
        SaveStyle: (sid, qid, state, qs) =>{
            dispatch(saveStyle(sid, qid, state, qs));
        },
        Save: (state, qs) =>{
            dispatch(save( state, qs));
        },
        WriteChanges: (data, sid, svid, token) =>{
            dispatch(writeSurveyChanges(data, sid, svid, token));   
        },
        SaveChanges: (data, sid, svid, token) =>{
            dispatch(writeSurveyChanges(data, sid, svid, token));   
        },
        GetLanguages: (svid, token) =>{
            dispatch(getLanguages( svid, token));   
        },
        GetVersions: (sid, token) =>{
            dispatch(getVersions( sid, token));   
        },
        GetLanguageForm: (svid, token) =>{
            dispatch(getLanguageForm( svid, token));   
        },
        AddLanguage: (languageFile, versionId, languageCode, token) =>{
            dispatch(uploadLanguageFile( languageFile, versionId, languageCode, token));   
        },
        LoadVersion: (serveyId, versionId, token) =>{
            dispatch(loadVersion(serveyId, versionId, token));   
        },
        SaveAnswer: (answer, sid, qid) => {
            dispatch(saveAnswer(answer, sid, qid));
        },
        SaveLogic: (sid, qid, logics) => {
            dispatch(saveLogic(sid, qid, logics));
        },
        LogicSaveReset:()=>{
            dispatch(logicSaveReset())
        },
        Upload:(file, id,sec, q, versionId, token)=>{
            dispatch(uploadDesignFile(file, id, sec, q, versionId, token))
        },
        UploadComplete:()=>{
            dispatch(uploadSequenceComplete(    ))
        },
        SetAlert:(type, message)=>{
            dispatch(setAlert(type, message));
        },
        ResetAlert: ()=> {
            dispatch(resetAlert());
        },
        UploadSurveyImage:(id,file, token)=>{
            dispatch(uploadSurveyImage(id,file, token));
        },
        ResetSurveySaveStatus:()=>{
            dispatch(resetSurveySaveStatus());
        },
        ResetQuestionAddStatus:()=>{
            dispatch(resetQuestionAddStatus());
        },
        CopyStyle:(state, questions)=>{
            dispatch(copyStyle(state, questions));
        },
        AddQuestion:(versionId, newQuestionData, token)=>{
            dispatch(addNewQuestion(versionId, newQuestionData, token))
        },
        ChangeMandatory:( status, qs, selectedSec, selectedQ, sid, vid, token )=>{
            dispatch(changeMandatory(status, qs, selectedSec, selectedQ, sid, vid, token))
        },
        DeleteQuestion:(svid, selectedSec, selectedQ, token )=>{
            dispatch(deleteQuestion(svid, selectedSec, selectedQ, token ))
        },
        ResetQuestionDeleteStatus:()=>{
            dispatch(resetQuestionDeleteStatus())
        },
        ApplyRandom:(type, data)=>{
            dispatch(applyRandom(type, data))
        },
        ChangeQType:(data)=>{
            dispatch(changeQType(data))
        },
        AddNewOption:(data)=>{
            dispatch(addNewOption(data))
        },
        // Write: (data) =>{
        //     dispatch(write(data));
        // }
    }
  };

export default connect(mapStateToProps, mapDispatchToProps)(DesignSurvey);
