
import React from "react";
// import { useMediaQuery } from 'react-responsive'

const StatItem = ({ heading, value, color, additional }) => {
  // const isTabletOrMobileDevice = useMediaQuery({
  //   query: '(max-device-width: 1224px)'
  // })
  // const isTabletOrMobileDeviceMax = useMediaQuery({
  //   query: '(max-device-width: 1366px)'
  // })
  // const isTabletOrMobileDeviceMin = useMediaQuery({
  //   query: '(min-device-width: 1000px)'
  // })


  // const isLandscape = useMediaQuery({ query: '(orientation: landscape)' })
  // const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })

  return (
    additional?
      <div className="card tooltipped" data-position="bottom" data-tooltip={additional}style={{ background: "#fff", padding: 10, borderRadius: "3%" }}>
        <span style={{fontSize: 24 }}>{heading}</span>
        <br />
        <span style={{fontSize: 36, color: color }}>{value}</span>
      </div>
    :
    <div className="card" style={{ background: "#fff", padding: 10, borderRadius: "3%"}}>
      <span style={{fontSize:24  }}>{heading}</span>
      <br />
      <span style={{fontSize:36, color: color }}>{value}</span>
    </div>
  );
};

export default StatItem;
