import React from "react";
import BarChartStudent from "./BarChartStudent";

function StatisticCard({ state, score }) {
  return (
    <div>
      <div className="row" style={{ margin: 40 }}>
        <div class="col s12 m3 l6">
          <div
            class="card horizontal"
            style={{
              width: 700,
              borderRadius: 10,
              height: 200,
              marginLeft: "-70px",
            }}
          >
            <div class="card-stacked">
              <div class="card-content">
                <p>
                  <b>Statistics</b>
                </p>
                <BarChartStudent
                  data={[
                    {
                      name: "",
                      color: "",
                      Semester1: parseFloat(state.Semester1),
                      Semester2: parseFloat(state.Semester2),
                      Semester3: parseFloat(state.Semester3),
                    },
                  ]}
                  // data={state.data}
                  dimensions={[{ name: "name" }]}
                  measures={[
                    { name: "Semester1" },
                    { name: "Semester2" },
                    { name: "Semester3" },
                  ]}
                  height="100"
                  width="500"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="row"
        style={{ marginLeft: 10, marginRight: 60, marginTop: "-46px" }}
      >
        <div class="col s12 m3 l3" style={{ marginLeft: "-24%" }}>
          <div
            class="card horizontal"
            style={{
              width: 992,
              borderRadius: 10,
              height: 260,
              overflow: "auto",
              marginLeft: "-523px",
            }}
          >
            <div class="card-stacked">
              <div class="card-content">
                <p>
                  <b>Student Performance:</b>
                </p>
                <table className="striped">
                  <thead>
                    <tr>
                      <th>Subject</th>
                      <th>Marks</th>
                      <th>Highest</th>
                      <th>Median</th>
                      <th>Lowest</th>
                      <th>Attendance</th>
                    </tr>
                  </thead>

                  <tbody>
                    {score.length == 0
                      ? null
                      : score.map((value) => (
                          <tr>
                            <td>{value.courseName}</td>
                            {value.marksInfo ? (
                              <>
                                <td>{value.marksInfo.marks}</td>
                                <td>{value.marksInfo.Highest}</td>
                                <td>{value.marksInfo.Median}</td>
                                <td>{value.marksInfo.Lowest}</td>
                              </>
                            ) : null}
                            <td>{value.Attendance}</td>
                          </tr>
                        ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default StatisticCard;
