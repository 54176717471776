import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router";
import SelectCard from "../layout/SelectCard";
import TeacherRatingCard from "../layout/TeacherRatingCard";
import TeacherAboutCard from "../layout/TeacherAboutCard";
import TeacherStatCard from "../layout/TeacherStatCard";
import Navbar from "../layout/Navbar";
import {
  professorCourseTaken,
  studentMarks,
  professorFeedback,
} from "../../store/actions/ProfessorAction";

export class YourPerformance extends Component {
  state = {
    profCourse: [],
    flag: true,
    marksFlag: false,
    feedbackFlag: true,
    feedback: [],
    rating: null,
    title: "Ratings",
    Attendent: null,
    notAttendent: null,
    studentMarksArr:[]
  };

  componentDidMount() {
    this.props.ProfessorCourseTaken(
      this.props.auth.user.id,
      this.props.auth.user.accessToken
    );
    this.props.ProfessorFeedback(
      this.props.auth.user.id,
      this.props.auth.user.accessToken
    );
  }

  getUniqueListBy=(arr, key)=> {
    return [...new Map(arr.map(item => [item[key], item])).values()]
  }
  
  componentDidUpdate() {
    var arrPresent = [];
    var arrAbsent = [];
    var arrFeedback = [];
    var greaterThan80=[];
    var greaterThan60=[];
    var greaterThan40=[];
    var belowThan40=[];
    var TestInfo=[]
    if (this.state.flag && this.props.professorCourseSuccess) {
      this.setState({ profCourse: this.props.professorCourse, flag: false });
    }
    if (this.state.marksFlag && this.props.studentMarksSuccess && !this.props.studentMarksLoading) {
      var studentMarks = this.props.studentMarks;
      for (var i = 0; i < studentMarks.length; i++) {
        if (studentMarks[i].isPresent == true) {
          arrPresent.push(studentMarks[i].marks);
        } else if (studentMarks[i].isPresent == false) {
          arrAbsent.push(studentMarks[i].marks);
        }
      }
      const sumPresent = arrPresent.reduce((a, b) => a + b, 0);
      const sumAbsent = arrAbsent.reduce((a, b) => a + b, 0);
      const studentMarksArr = this.getUniqueListBy(studentMarks, 'userId')
      for(var j=0;j<studentMarksArr.length;j++){
        if(studentMarksArr[j].marks > 80){
          greaterThan80.push(studentMarksArr[j].marks)
        }
        if(studentMarksArr[j].marks > 60){
          greaterThan60.push(studentMarksArr[j].marks)
        }
        if(studentMarksArr[j].marks > 40){
          greaterThan40.push(studentMarksArr[j].marks)
        }
        if(studentMarksArr[j].marks < 40){
          belowThan40.push(studentMarksArr[j].marks)
        }
      }
      console.log(greaterThan80,greaterThan60,greaterThan40,belowThan40)
      var day = new Date();
      var year = day.getFullYear(); 
      var testInfo={
        greaterThan80:greaterThan80.length,
        greaterThan60:  greaterThan60.length,
        greaterThan40: greaterThan40.length,
        belowThan40: belowThan40.length,
        year: year
      }
      TestInfo.push(testInfo)
      console.log(TestInfo)
      this.setState({
        studentMarksArr:TestInfo,
        Attendent: sumPresent,
        notAttendent: sumAbsent,
        marksFlag: false,
      });
    }
    if(this.state.marksFlag && this.props.studentMarksError && !this.props.studentMarksLoading){
      this.setState({
        studentMarksArr: [],
        Attendent: [],
        notAttendent: [],
        marksFlag: false
      })
    }
    if (this.state.feedbackFlag && this.props.professorFeedbackSuccess) {
      var professorFeedback = this.props.professorFeedback["0"];
      for (var i = 0; i < professorFeedback.length; i++) {
        var Feedback = {
          helpful: Number(professorFeedback[i].feedback.helpful),
          lecture: Number(professorFeedback[i].feedback.lecture),
          behaviour: Number(professorFeedback[i].feedback.behaviour),
          syllebusCovered: Number(
            professorFeedback[i].feedback.syllebusCovered
          ),
        };
        arrFeedback.push(Feedback);
      }
      var arrFedbackTotal = [];
      var feedback = {
        lecture: Math.ceil(
          arrFeedback
            .map((item) => item.lecture)
            .reduce((prev, next) => prev + next) / arrFeedback.length
        ),
        syllebusCovered: Math.ceil(
          arrFeedback
            .map((item) => item.syllebusCovered)
            .reduce((prev, next) => prev + next) / arrFeedback.length
        ),
        behaviour: Math.ceil(
          arrFeedback
            .map((item) => item.behaviour)
            .reduce((prev, next) => prev + next) / arrFeedback.length
        ),
        helpful: Math.ceil(
          arrFeedback
            .map((item) => item.helpful)
            .reduce((prev, next) => prev + next) / arrFeedback.length
        ),
      };
      arrFedbackTotal.push(feedback);
      this.setState({feedback:arrFedbackTotal})

      var ratingsBreakup = this.props.professorFeedback["1"].ratingBreakups;
      console.log(ratingsBreakup);
      for (var k = 0; k < ratingsBreakup.length; k++) {
        if (
          ratingsBreakup[k].criteria == Object.keys(arrFedbackTotal["0"])[k]
        ) {
          console.log(
            k,
            ratingsBreakup[k].criteria,
            Object.keys(arrFedbackTotal["0"])[k],
            ratingsBreakup[k].percent,
            arrFedbackTotal["0"][Object.keys(arrFedbackTotal["0"])[k]]
          );
          var lecture =
            arrFedbackTotal["0"][Object.keys(arrFedbackTotal["0"])[k]] *
            (ratingsBreakup[k].percent / 100);
          var syllebusCovered =
            arrFedbackTotal["0"][Object.keys(arrFedbackTotal["0"])[k]] *
            (ratingsBreakup[k].percent / 100);
          var behaviour =
            arrFedbackTotal["0"][Object.keys(arrFedbackTotal["0"])[k]] *
            (ratingsBreakup[k].percent / 100);
          var helpful =
            arrFedbackTotal["0"][Object.keys(arrFedbackTotal["0"])[k]] *
            (ratingsBreakup[k].percent / 100);
        }
      }
      console.log("total", lecture + syllebusCovered + behaviour + helpful);
      var total = Math.ceil(lecture + syllebusCovered + behaviour + helpful)
      this.setState({rating: total,feedbackFlag: false })
    }
  }

  handleChange = (e) => {
    var pid = this.props.auth.user.id;
    var cid = e.target.value;
    var token = this.props.auth.user.accessToken;
    this.setState({ marksFlag: true });
    this.props.StudentMarks(pid, cid, token);
  };

  render() {
    const user = this.props.auth.user;
    console.log(this.state.profCourse);
    if (!user) return <Redirect to="/login" />;
    return (
      <div style={{ display: "flex", flexDirection: "column" }}>
        <Navbar heading="Admin" user={user} />
        <div
          style={{
            height: 130,
            backgroundColor: "#5d1b8f",
            borderBottomLeftRadius: 55,
            // borderBottomRightRadius: 55,
          }}
        >
          <p
            className="col s12 m6 l6"
            style={{ fontSize: 30, paddingLeft: "4%", color: "whitesmoke" }}
          >
            <strong>{user.name}</strong>
          </p>
        </div>
        <div
          className="row" /* style={{ display: "flex", justifyContent: "space-around" }} */
        >
          <div className="col">
            <TeacherRatingCard
              name={this.state.name}
              title={this.state.title}
              value={this.state.rating}
              feedback={this.state.feedback}
            />
          </div>
          <div className="col">
            <TeacherAboutCard user={user} state={this.state} />
          </div>
          <div className="col">
            <TeacherStatCard
              state={this.state}
              handleChange={this.handleChange}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    professorCourse: state.Professor.professorCourse,
    professorCourseSuccess: state.Professor.professorCourseSuccess,
    studentMarksLoading: state.Professor.studentMarksLoading,
    studentMarksError: state.Professor.studentMarksError,
    studentMarksSuccess: state.Professor.studentMarksSuccess,
    studentMarks: state.Professor.studentMarks,
    professorFeedbackSuccess: state.Professor.professorFeedbackSuccess,
    professorFeedback: state.Professor.professorFeedback,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    ProfessorCourseTaken: (id, token) => {
      dispatch(professorCourseTaken(id, token));
    },
    StudentMarks: (pid, cid, token) => {
      dispatch(studentMarks(pid, cid, token));
    },
    ProfessorFeedback: (id, token) => {
      dispatch(professorFeedback(id, token));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(YourPerformance);
