import React from 'react'

export default function NewGroup({state, HandleChange, ind}) {
    return (
        <div className="col s12">
            <div className="input-field col s12">
                <select
                  className="browser-default"
                  type="text"
                  required
                  onChange={HandleChange}
                  name="newGroupsData"
                  value={state.newGroupsData[ind]?state.newGroupsData[ind].newGroupBodyType:""}
                  id={ind+"-newGroupBodyType"}
                >
                  <option value="" disabled selected defaultValue="">
                    Choose the group body type
                  </option>
                    <option value="text" key="1">
                      Text
                    </option>
                    <option value="image" key="2">
                      Image
                    </option>
                    <option value="audio" key="3">
                      Audio
                    </option>
                    <option value="vidoe" key="4">
                      Video
                    </option>
                </select>
              </div>
              {
                  state.newGroupsData[ind]&&state.newGroupsData[ind].newGroupBodyType=="text"?
                  <div className="input-field col s12">
                    <input
                      type="text"
                      name="newGroupsData"
                      id={ind+"-group"}
                      onChange={HandleChange}
                      value={state.newGroupsData[ind]?state.newGroupsData[ind].group:""}
                      required
                    />
                    <label>Group</label>
                  </div>
                  :null
              }
        </div>
    )
}
